export const getEmptyUserConnectionsV2 = {
    getCompanyById: {
        id: 1,
        userConnectionsOverview: null,
        userConnections: [],
        __typename: 'Company'
    }
};
export const getUserConnectionsV2Test = {
    getCompanyById: {
        id: 34793114,
        userConnectionsOverview: null,
        userConnections: [
            {
                latestCalendarEvent: null,
                latestEmail: null,
                targetPersonEmailAddress: null,
                targetPerson: {
                    contact: null,
                    experience: [],
                    emailEnrichmentStatus: null,
                    id: 339618,
                    fullName: 'Jim Kim',
                    profilePictureUrl: 'https://assets.harmonic.ai/person_6a50744c49e718e8f76987a1d964327679b2fba6b4a168af8f4af7c6bdb1a30a.png',
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/in/jim-kim-1754b359',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    __typename: 'Person'
                },
                user: {
                    email: 'soham@harmonic.ai',
                    name: 'Soham Kamat',
                    __typename: 'User'
                },
                connectionSources: ['LINKEDIN'],
                __typename: 'UserConnection'
            }
        ],
        __typename: 'Company'
    }
};
export const mockUserConnectionsOverview = {
    dateFirstConnected: '2023-01-15T00:00:00',
    dateLastConnected: '2023-08-15T00:00:00',
    bestConnectedConnection: {
        name: 'Max Ruderman'
    },
    correspondenceSummary: {
        lastEmailAt: '2023-08-15T00:00:00',
        lastEmailContactPersonEmail: 'Max Ruderman',
        lastEmailContactPerson: {
            fullName: 'Max Ruderman',
            profilePictureUrl: 'https://example.com/image.jpg'
        },
        lastMeetingAt: '2023-08-15T00:00:00',
        lastMeetingContactPersonEmail: 'Max Ruderman',
        lastMeetingContactPerson: {
            fullName: 'Max Ruderman',
            profilePictureUrl: 'https://example.com/image.jpg'
        }
    },
    __typename: 'UserConnectionOverview'
};
export const getUserConnectionsV2 = {
    getCompanyById: {
        id: 1,
        userConnectionsOverview: {
            correspondenceSummary: {
                lastEmailAt: '2025-03-11T23:55:45.000Z',
                lastEmailContactPersonEmail: 'carter@harmonic.ai',
                lastEmailContactPerson: {
                    fullName: 'Carter Headley',
                    profilePictureUrl: 'https://assets.harmonic.ai/_personc0560f93384f2c016bcf96db27988885c9db1136518694c865149fdafcb356db.jpeg',
                    __typename: 'Person'
                },
                lastMeetingAt: null,
                lastMeetingContactPersonEmail: null,
                lastMeetingContactPerson: null,
                __typename: 'CorrespondenceSummary'
            },
            __typename: 'UserConnectionOverview'
        },
        userConnections: [
            {
                targetPerson: {
                    id: 8658696,
                    fullName: 'Aaron Bartel',
                    profilePictureUrl: 'https://assets.harmonic.ai/person_ddec1a3f8a865ff196b139702dc20cf1e0e15213f1068c74e83ab40973441223.png',
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/in/aaronbartel',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    contact: {
                        emails: [
                            'aaron.bartel@gmail.com',
                            'aaron@dropbox.com',
                            'aaronbartel@gmail.com'
                        ],
                        __typename: 'ContactMetadata'
                    },
                    emailEnrichmentStatus: {
                        enrichedAt: '2024-09-05T17:54:53.000Z',
                        __typename: 'EmailEnrichmentStatus'
                    },
                    experience: [
                        {
                            title: 'VP of Revenue',
                            roleType: 'EXECUTIVE',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-11-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Sales Director, Americas',
                            roleType: 'OPERATOR',
                            company: {
                                id: 4214831,
                                name: 'RingCentral Events',
                                logoUrl: 'https://assets.harmonic.ai/company_1e010f241e06587c2ca281686a80b1268b27a4b5e682fc66976d6e46f70f99de.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2021-07-01T00:00:00Z',
                            endDate: '2022-07-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Sales Leader',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 7720814,
                                name: 'Dropbox',
                                logoUrl: 'https://assets.harmonic.ai/company_16accf4bdc5055b83baa1a7d4911cdb22c522ee563c84d30db6b12231b45e83e.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2014-05-01T00:00:00Z',
                            endDate: '2021-07-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Account Manager',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 7720814,
                                name: 'Dropbox',
                                logoUrl: 'https://assets.harmonic.ai/company_16accf4bdc5055b83baa1a7d4911cdb22c522ee563c84d30db6b12231b45e83e.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2012-12-01T00:00:00Z',
                            endDate: '2014-05-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Consulting Analyst',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 2002784,
                                name: 'The New England Consulting Group',
                                logoUrl: 'https://assets.harmonic.ai/company_3bed91e9434a050783a8fa481639264ddf45e0b54ee5cb9c7be9c95749bea2cf.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2012-08-01T00:00:00Z',
                            endDate: '2012-11-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Lecturer',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 1376844,
                                name: 'Tufts University',
                                logoUrl: 'https://assets.harmonic.ai/company_7119325590a85333488484e99b46738befe69bab0cf68c42fccb68bdd209b9e7.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2012-01-01T00:00:00Z',
                            endDate: '2012-05-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Summer Intern',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 7720814,
                                name: 'Dropbox',
                                logoUrl: 'https://assets.harmonic.ai/company_16accf4bdc5055b83baa1a7d4911cdb22c522ee563c84d30db6b12231b45e83e.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2011-05-01T00:00:00Z',
                            endDate: '2011-08-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                },
                targetPersonEmailAddress: 'bartel@harmonic.ai',
                latestCalendarEvent: null,
                latestEmail: {
                    timestamp: '2025-03-11T04:10:21',
                    title: null,
                    sender: {
                        emailAddress: {
                            emailAddress: 'bartel@harmonic.ai',
                            name: "'Taylor Guymon' Via Bartel",
                            __typename: 'EmailAddressMetadata'
                        },
                        person: {
                            id: 8658696,
                            entityUrn: 'urn:harmonic:person:8658696',
                            fullName: 'Aaron Bartel',
                            __typename: 'Person'
                        },
                        __typename: 'EmailAddressDerivedPerson'
                    },
                    receivers: [
                        {
                            emailAddress: {
                                emailAddress: 'erin@harmonic.ai',
                                name: 'Erin Mccarthy',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 114375482,
                                entityUrn: 'urn:harmonic:person:114375482',
                                fullName: 'Erin Mccarthy',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'bartel@harmonic.ai',
                                name: "'Taylor Guymon' Via Bartel",
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 8658696,
                                entityUrn: 'urn:harmonic:person:8658696',
                                fullName: 'Aaron Bartel',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        }
                    ],
                    __typename: 'UserEmailMessage'
                },
                user: {
                    email: 'erin@harmonic.ai',
                    name: 'Erin McCarthy',
                    __typename: 'User'
                },
                connectionSources: ['EMAIL', 'LINKEDIN'],
                __typename: 'UserConnection'
            },
            {
                targetPerson: {
                    id: 160636294,
                    fullName: 'Shafaq Tirmizi',
                    profilePictureUrl: 'https://assets.harmonic.ai/_personbbfde1dcb211425bdb67131475b0b20fa868435ea545953f4b48b44a807a88d3.jpeg',
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/in/shafaqtirmizi',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    contact: {
                        emails: ['shafaq@harmonic.ai'],
                        __typename: 'ContactMetadata'
                    },
                    emailEnrichmentStatus: {
                        enrichedAt: '2024-04-16T19:22:26.000Z',
                        __typename: 'EmailEnrichmentStatus'
                    },
                    experience: [
                        {
                            title: 'Operations',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 58349446,
                                name: 'RebuildTO',
                                logoUrl: 'https://assets.harmonic.ai/company_4d95910f889e3e8c7cb8ab73aaed8644b44c440eeac7f31ce7be1d753671b896.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2023-10-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Data Operations',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-09-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Data Operations',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-09-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Operations',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-09-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Community Member',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 21563040,
                                name: 'The Commons',
                                logoUrl: 'https://assets.harmonic.ai/company_029226131278f9a9b3473af96b66bb0575de397eac3d9da8b983900a46a23955.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Projects Lead',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 7371807,
                                name: 'JELLY Social',
                                logoUrl: 'https://media.licdn.com/dms/image/v2/C4D0BAQEuxuPszNLYBw/company-logo_200_200/company-logo_200_200/0/1630522906805/jellysocial_logo?e=2147483647&v=beta&t=Lm7ytCA3HIFRu9cSd6QVG0iTVSbvcV_TUMbDJoQbFXU',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-03-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Strategy Consultant and Mentor',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 28872600,
                                name: 'Phoenicia Coaching',
                                logoUrl: 'https://assets.harmonic.ai/company_8d3379a338e91341191778fedfd387f908dbcf088545475964bd81032217f3f7.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-05-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Mindset Coach',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 28872600,
                                name: 'Phoenicia Coaching',
                                logoUrl: 'https://assets.harmonic.ai/company_8d3379a338e91341191778fedfd387f908dbcf088545475964bd81032217f3f7.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-05-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Contributor',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 32931484,
                                name: 'Thrive Thru',
                                logoUrl: 'https://assets.harmonic.ai/company_fb53d3dc4fb2c7533b90a101ef5ed1111ed34752adef3c96cb8ff84b490becdc.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2020-01-01T00:00:00Z',
                            endDate: '2021-01-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Corporate Development Analyst',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 9287724,
                                name: 'Cogir Senior Living',
                                logoUrl: 'https://assets.harmonic.ai/company_d27388e60e9e9d1bd094c7f44002d54394bc25655d9a9cb50b90b8e246433ad8.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2018-01-01T00:00:00Z',
                            endDate: '2019-10-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Private Investment Valuations Intern',
                            roleType: 'INTERN',
                            company: {
                                id: 697118,
                                name: "Ontario Teachers' Pension Plan",
                                logoUrl: 'https://assets.harmonic.ai/company_90d9aceb2720c54eb3fa6cd1b691dcb7e9a4a2e169da47f623092d241901ec0d.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-05-01T00:00:00Z',
                            endDate: '2017-08-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Property Accountant',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 9366061,
                                name: 'Colliers',
                                logoUrl: 'https://assets.harmonic.ai/company_e0e05a0c865b0a6af46b0c20a0f6720390978cc05e67b22257c4df1229aa9152.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2016-09-01T00:00:00Z',
                            endDate: '2016-12-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Corporate Accountant',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 118680,
                                name: 'Brookfield Properties',
                                logoUrl: 'https://assets.harmonic.ai/company_f939e797f96b87bfaf081a94b195a03192c4a17fef225c9bdc8a3ffe47a648b1.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2015-09-01T00:00:00Z',
                            endDate: '2015-12-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                },
                targetPersonEmailAddress: 'shafaq@harmonic.ai',
                latestCalendarEvent: null,
                latestEmail: {
                    timestamp: '2025-03-11T06:01:15',
                    title: 'New invoice from Psychometric, Inc. (Persona) #CCD45FF4-0035',
                    sender: {
                        emailAddress: {
                            emailAddress: 'invoice+statements+acct_1ckfqgcj1cdcyagu@stripe.com',
                            name: 'Psychometric, Inc. (Persona)',
                            __typename: 'EmailAddressMetadata'
                        },
                        person: null,
                        __typename: 'EmailAddressDerivedPerson'
                    },
                    receivers: [
                        {
                            emailAddress: {
                                emailAddress: 'johnmatthews@harmonic.ai',
                                name: 'John Matthews Johnmatthews@Harmonic.Ai',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 31189396,
                                entityUrn: 'urn:harmonic:person:31189396',
                                fullName: 'John Matthews',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'ap@harmonic.ai',
                                name: 'Accounts Payable',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'shafaq@harmonic.ai',
                                name: 'Shafaq@Harmonic.Ai',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 160636294,
                                entityUrn: 'urn:harmonic:person:160636294',
                                fullName: 'Shafaq Tirmizi',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        }
                    ],
                    __typename: 'UserEmailMessage'
                },
                user: {
                    email: 'johnmatthews@harmonic.ai',
                    name: 'John Matthews',
                    __typename: 'User'
                },
                connectionSources: ['LINKEDIN', 'EMAIL'],
                __typename: 'UserConnection'
            },
            {
                targetPerson: {
                    id: 312642,
                    fullName: 'Samrat Luintel',
                    profilePictureUrl: 'https://assets.harmonic.ai/person_c3537ca61eeab6b0ffb3aaa897bcef09894f5afde773a1a62c72feb96d216648.png',
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/in/samrat-luitel',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    contact: {
                        emails: ['samrat.luintel.58@gmail.com'],
                        __typename: 'ContactMetadata'
                    },
                    emailEnrichmentStatus: {
                        enrichedAt: '2024-04-16T19:35:24.000Z',
                        __typename: 'EmailEnrichmentStatus'
                    },
                    experience: [
                        {
                            title: 'Software Developer',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-05-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Full Stack Developer',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 3638296,
                                name: 'Acid Integrations Ltd.',
                                logoUrl: 'https://assets.harmonic.ai/company_68b0912079f3b7a891e80365957784ae0d6796d26cdb7190886dc9b847176fc3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2019-05-01T00:00:00Z',
                            endDate: '2021-06-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Web Developer',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: -1,
                                name: 'Softech Infosys',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-06-01T00:00:00Z',
                            endDate: '2019-04-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                },
                targetPersonEmailAddress: 'samrat@harmonic.ai',
                latestCalendarEvent: null,
                latestEmail: {
                    timestamp: '2025-03-10T18:34:00',
                    title: 'Updated invitation: 🎯 Tracking standup @ Wed Mar 12, 2025 1:15am - 1:45am (GMT+9) (Matthias Link)',
                    sender: {
                        emailAddress: {
                            emailAddress: 'miguel@harmonic.ai',
                            name: 'Miguel Caballero Pinto',
                            __typename: 'EmailAddressMetadata'
                        },
                        person: {
                            id: 91437404,
                            entityUrn: 'urn:harmonic:person:91437404',
                            fullName: 'Miguel Caballero Pinto',
                            __typename: 'Person'
                        },
                        __typename: 'EmailAddressDerivedPerson'
                    },
                    receivers: [
                        {
                            emailAddress: {
                                emailAddress: 'matthiaslink@harmonic.ai',
                                name: 'Matthias Link',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 118535728,
                                entityUrn: 'urn:harmonic:person:118535728',
                                fullName: 'Matthias Link',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'stephanie@harmonic.ai',
                                name: 'Stephanie Lee',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'johnny@harmonic.ai',
                                name: 'Johnny Choi',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 72946983,
                                entityUrn: 'urn:harmonic:person:72946983',
                                fullName: 'Johnny Choi',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'samrat@harmonic.ai',
                                name: 'Samrat Luintel',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 312642,
                                entityUrn: 'urn:harmonic:person:312642',
                                fullName: 'Samrat Luintel',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'willdimondi@harmonic.ai',
                                name: 'Will Dimondi',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'seth@harmonic.ai',
                                name: 'Seth Wieder',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 1117192,
                                entityUrn: 'urn:harmonic:person:1117192',
                                fullName: 'Seth Wieder',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'kenny@harmonic.ai',
                                name: 'Kenny Xu',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 72840952,
                                entityUrn: 'urn:harmonic:person:72840952',
                                fullName: 'Junfeng Kenny Xu',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'ali@harmonic.ai',
                                name: 'Ali',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 102917529,
                                entityUrn: 'urn:harmonic:person:102917529',
                                fullName: 'Ali Hashemi',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'sang@harmonic.ai',
                                name: 'Sang Mercado',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 311816,
                                entityUrn: 'urn:harmonic:person:311816',
                                fullName: 'Sang M.',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        }
                    ],
                    __typename: 'UserEmailMessage'
                },
                user: {
                    email: 'matthiaslink@harmonic.ai',
                    name: 'Matthias Link',
                    __typename: 'User'
                },
                connectionSources: ['EMAIL', 'LINKEDIN'],
                __typename: 'UserConnection'
            },
            {
                targetPerson: {
                    id: 55951673,
                    fullName: 'Sam Weiss',
                    profilePictureUrl: 'https://static.licdn.com/aero-v1/sc/h/bgaqk7x4ntjz0wg67d8u723eb',
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/in/sambweiss',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    contact: {
                        emails: ['sam.weiss@powerschool.com', 'sam@harmonic.ai'],
                        __typename: 'ContactMetadata'
                    },
                    emailEnrichmentStatus: {
                        enrichedAt: '2024-04-17T05:29:42.000Z',
                        __typename: 'EmailEnrichmentStatus'
                    },
                    experience: [
                        {
                            title: 'Account Executive',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2023-05-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Senior SDR',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2022-10-01T00:00:00Z',
                            endDate: '2023-05-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Senior Business Development Representative',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 902,
                                name: 'Clearbit',
                                logoUrl: 'https://assets.harmonic.ai/company_6a97982dccf77dd3dafa27fcbdf75c017301f730ba186b1d9e8ea212eee73f54.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2022-02-01T00:00:00Z',
                            endDate: '2022-10-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Business Development Representative',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 902,
                                name: 'Clearbit',
                                logoUrl: 'https://assets.harmonic.ai/company_6a97982dccf77dd3dafa27fcbdf75c017301f730ba186b1d9e8ea212eee73f54.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2021-04-01T00:00:00Z',
                            endDate: '2022-02-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Sales Development Representative',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 9550189,
                                name: 'PowerSchool',
                                logoUrl: 'https://assets.harmonic.ai/company_4b81abd646a2c5500631fa571804301d792bbbdfc6e88fa62f4fcdf8ff412f46.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2018-07-01T00:00:00Z',
                            endDate: '2021-03-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Sales Development Representative',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 9550189,
                                name: 'PowerSchool',
                                logoUrl: 'https://assets.harmonic.ai/company_4b81abd646a2c5500631fa571804301d792bbbdfc6e88fa62f4fcdf8ff412f46.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2018-07-01T00:00:00Z',
                            endDate: '2021-03-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Sales Development Representative II',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 9550189,
                                name: 'PowerSchool',
                                logoUrl: 'https://assets.harmonic.ai/company_4b81abd646a2c5500631fa571804301d792bbbdfc6e88fa62f4fcdf8ff412f46.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2019-04-01T00:00:00Z',
                            endDate: '2021-02-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Shift Lead',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 4259252,
                                name: 'MINISO USA',
                                logoUrl: 'https://assets.harmonic.ai/company_839bcc5e84c7bcf5696562874f0321189cd81d00029533f4983b7930f5a77e0c.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2018-01-01T00:00:00Z',
                            endDate: '2018-03-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Sales and Marketing Coordinator',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 2194482,
                                name: 'Native State Foods',
                                logoUrl: 'https://assets.harmonic.ai/company_edb0dfc3b988a55c7056d1e6c58c7d80ca6e82edd322c0cc28223005ef3f3e86.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-09-01T00:00:00Z',
                            endDate: '2017-12-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Supervisor-Burns Recreation Center',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 1432305,
                                name: 'Loyola Marymount University',
                                logoUrl: 'https://assets.harmonic.ai/company_48ce3b1b521263d67d0751afca042e04a55d98704319cba0cdf2fdd3fde23009.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2016-08-01T00:00:00Z',
                            endDate: '2017-09-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Fundraising & Grant Writing Intern',
                            roleType: 'INTERN',
                            company: {
                                id: 8135620,
                                name: 'YOLO FAMILY SERVICE AGENCY',
                                logoUrl: 'https://static.licdn.com/aero-v1/sc/h/cs8pjfgyw96g44ln9r7tct85f',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-05-01T00:00:00Z',
                            endDate: '2017-08-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Senior Sales Rep- Mondavi Center for the Arts',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 10178101,
                                name: 'US Davis California Raptor Center',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2016-05-01T00:00:00Z',
                            endDate: '2016-08-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Youth Basketball Coach',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: -1,
                                name: 'National Academy of Athletics',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2011-01-01T00:00:00Z',
                            endDate: '2014-03-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                },
                targetPersonEmailAddress: 'sam@harmonic.ai',
                latestCalendarEvent: null,
                latestEmail: {
                    timestamp: '2025-03-11T19:05:01',
                    title: 'Re: Harmonic.ai - Follow Up',
                    sender: {
                        emailAddress: {
                            emailAddress: 'colin@harmonic.ai',
                            name: 'Colin Cook',
                            __typename: 'EmailAddressMetadata'
                        },
                        person: {
                            id: 155926185,
                            entityUrn: 'urn:harmonic:person:155926185',
                            fullName: 'Colin Cook',
                            __typename: 'Person'
                        },
                        __typename: 'EmailAddressDerivedPerson'
                    },
                    receivers: [
                        {
                            emailAddress: {
                                emailAddress: 'michael@manifestlabs.com',
                                name: 'Michael Zarian',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'sam@harmonic.ai',
                                name: 'Sam Weiss',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 55951673,
                                entityUrn: 'urn:harmonic:person:55951673',
                                fullName: 'Sam Weiss',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        }
                    ],
                    __typename: 'UserEmailMessage'
                },
                user: {
                    email: 'colin@harmonic.ai',
                    name: 'Colin Cook',
                    __typename: 'User'
                },
                connectionSources: ['EMAIL', 'LINKEDIN'],
                __typename: 'UserConnection'
            },
            {
                targetPerson: {
                    id: 312645,
                    fullName: 'Eric Kim',
                    profilePictureUrl: 'https://assets.harmonic.ai/_person9e7f9f1086359b20fe3f91077268d6132debf37c247dd66e394bf927cb9594e2.jpeg',
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/in/eric-kim-75839a4',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    contact: {
                        emails: ['eric.kim@haynesboone.com', 'usctrunks@gmail.com'],
                        __typename: 'ContactMetadata'
                    },
                    emailEnrichmentStatus: {
                        enrichedAt: null,
                        __typename: 'EmailEnrichmentStatus'
                    },
                    experience: [
                        {
                            title: 'Head of Legal',
                            roleType: 'OPERATOR',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2024-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Deputy General Counsel',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 10229360,
                                name: 'Aalyria',
                                logoUrl: 'https://assets.harmonic.ai/company_b876a4fa2fb3f4b5807b0c227397ca36d47ccce25dcef78dff0f7dd5dffee4ca.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2023-09-01T00:00:00Z',
                            endDate: '2024-04-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Head Of Legal',
                            roleType: 'OPERATOR',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2021-07-01T00:00:00Z',
                            endDate: '2023-08-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Lead Counsel (Privacy)',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 4194472,
                                name: 'Facebook',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQHi-wrXiQcbxw/company-logo_200_200/0/1635988509331/facebook__logo?e=2147483647&v=beta&t=qoeq94pWmnjA1qyK_ViRaIPcn_obXBgsWzpJFKJJdJ0',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2020-11-01T00:00:00Z',
                            endDate: '2021-07-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Lead Patent Counsel',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 4194472,
                                name: 'Facebook',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQHi-wrXiQcbxw/company-logo_200_200/0/1635988509331/facebook__logo?e=2147483647&v=beta&t=qoeq94pWmnjA1qyK_ViRaIPcn_obXBgsWzpJFKJJdJ0',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-09-01T00:00:00Z',
                            endDate: '2021-07-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'IP Counsel',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 2140886,
                                name: 'PayPal',
                                logoUrl: 'https://assets.harmonic.ai/company_c6971bcb359c1bf74da688b0273e27c1615663ed3100f8924e87f9449f2c09bb.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2016-04-01T00:00:00Z',
                            endDate: '2017-09-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Associate',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 689932,
                                name: 'Haynes and Boone, LLP',
                                logoUrl: 'https://assets.harmonic.ai/company_299fe9547419dcea0136a5eb24aaecced03d07309ca36284f51609542b41f3b7.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2014-08-01T00:00:00Z',
                            endDate: '2016-04-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Associate',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 3638297,
                                name: 'Fulwider Patton LLP',
                                logoUrl: 'https://assets.harmonic.ai/company_4e01a5539a61b80cbd980bd496c053009819d9405b7c2dc01a90b394894dcd56.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2013-01-01T00:00:00Z',
                            endDate: '2014-07-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Associate',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: -1,
                                name: 'Kenyon & Kenyon',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2012-01-01T00:00:00Z',
                            endDate: '2012-12-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Legal Intern',
                            roleType: 'INTERN',
                            company: {
                                id: 2261160,
                                name: 'Google',
                                logoUrl: 'https://assets.harmonic.ai/company_e89a672d916e5a0c0bc90ef94739a4c42f0486211e68c6e8e1075c3046b526d1.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2010-09-01T00:00:00Z',
                            endDate: '2011-05-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Summer Associate',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: -1,
                                name: 'Kenyon & Kenyon LLP',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2010-06-01T00:00:00Z',
                            endDate: '2010-07-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Engineer II',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 1460347,
                                name: 'General Atomics',
                                logoUrl: 'https://assets.harmonic.ai/company_fdd738354c463fab68920de2d6333f0f6111ef5195129b094bbf2774a98cac55.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2007-10-01T00:00:00Z',
                            endDate: '2008-06-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'QA Engineer',
                            roleType: 'EMPLOYEE',
                            company: {
                                id: 7773357,
                                name: 'Vishay Intertechnology, Inc.',
                                logoUrl: 'https://assets.harmonic.ai/company_3a9f37bfc069398ec9c7769dcb4fbc8db3910b74a0397c5861d481ea407605a3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2007-04-01T00:00:00Z',
                            endDate: '2007-06-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                },
                targetPersonEmailAddress: 'eric@harmonic.ai',
                latestCalendarEvent: null,
                latestEmail: {
                    timestamp: '2025-03-11T15:53:47',
                    title: 'Re: Alpine Solutions X Harmonic : Recruitment Support',
                    sender: {
                        emailAddress: {
                            emailAddress: 'eric@harmonic.ai',
                            name: 'Eric Kim',
                            __typename: 'EmailAddressMetadata'
                        },
                        person: {
                            id: 312645,
                            entityUrn: 'urn:harmonic:person:312645',
                            fullName: 'Eric Kim',
                            __typename: 'Person'
                        },
                        __typename: 'EmailAddressDerivedPerson'
                    },
                    receivers: [
                        {
                            emailAddress: {
                                emailAddress: 'seth@harmonic.ai',
                                name: 'Seth Wieder',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 1117192,
                                entityUrn: 'urn:harmonic:person:1117192',
                                fullName: 'Seth Wieder',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'mark.mallari@alpinesolves.com',
                                name: 'Mark Mallari',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'jonnie.wagner@alpinesolves.com',
                                name: 'Jonnie Wagner',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'soham@harmonic.ai',
                                name: 'Soham@Harmonic.Ai',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 72676044,
                                entityUrn: 'urn:harmonic:person:72676044',
                                fullName: 'Soham Kamat',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'emmie.buck@alpinesolves.com',
                                name: 'Emmie Buck',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 177748368,
                                entityUrn: 'urn:harmonic:person:177748368',
                                fullName: 'Emmie Buck',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        }
                    ],
                    __typename: 'UserEmailMessage'
                },
                user: {
                    email: 'seth@harmonic.ai',
                    name: 'Seth Wieder',
                    __typename: 'User'
                },
                connectionSources: ['LINKEDIN', 'EMAIL'],
                __typename: 'UserConnection'
            }
        ],
        __typename: 'Company'
    }
};
