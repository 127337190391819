export const GET_SIMILAR_COMPANIES_MOCKED_RESPONSE = {
    getSimilarCompanies: {
        edges: [
            {
                node: {
                    id: 12455555,
                    logoUrl: 'https://assets.harmonic.ai/company_baf1388ceef6125e605731c6ae82581ee578da8b8763e495f0f2267409a09f5e.png',
                    headcount: 33,
                    name: 'Sidekick',
                    location: {
                        country: 'United States',
                        __typename: 'LocationMetadata'
                    },
                    funding: {
                        fundingTotal: '500000',
                        __typename: 'Funding'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Communications and Information Technology',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:8b2dd506-2e20-4c46-9526-5fe6faf14e5a',
                            __typename: 'Tag'
                        }
                    ],
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            },
            {
                node: {
                    id: 4953930,
                    logoUrl: 'https://assets.harmonic.ai/company_a2e2a0349ef150f6eb9e871cd6aed092fd184f5ff99f1dd011d09e520629c363.png',
                    headcount: 44,
                    name: 'Ramp',
                    location: {
                        country: 'United Kingdom',
                        __typename: 'LocationMetadata'
                    },
                    funding: {
                        fundingTotal: '5363240',
                        __typename: 'Funding'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        }
                    ],
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            }
        ]
    }
};
