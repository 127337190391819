export const UPDATE_PEOPLE_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE = {
    updatePeopleWatchlistCustomField: {
        __typename: 'PeopleListCustomField',
        urn: 'urn:harmonic:person_list_custom_field:1158',
        name: '12',
        type: 'TEXT',
        createdAt: '2025-02-08T16:55:38Z',
        defaultValue: {
            textDefaultValue: '',
            __typename: 'TextCustomFieldValue'
        },
        metadata: null
    }
};
