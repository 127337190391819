export const UPDATE_SAVED_SEARCH_MOCKED_RESPONSE = {
    updateSavedSearch: {
        id: 131200,
        entityUrn: 'urn:harmonic:saved_search:131200',
        isPrivate: true,
        name: '1',
        searchQuery: {
            filterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: []
            },
            pagination: {
                start: 0,
                pageSize: 25
            },
            sort: [
                {
                    sortField: 'featured',
                    descending: true,
                    sortContextValues: null
                }
            ],
            controlledFilterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: []
            },
            mergeQueryForNestedFields: false
        },
        type: 'COMPANIES_LIST',
        visibleColumns: [
            'urn:harmonic:company_field:company_id',
            'urn:harmonic:company_field:company_description',
            'urn:harmonic:company_field:company_highlights',
            'urn:harmonic:company_field:company_founders_and_ceos',
            'urn:harmonic:company_field:company_leadership_last_companies',
            'urn:harmonic:company_field:relevance_score',
            'urn:harmonic:company_field:company_website_url',
            'urn:harmonic:company_field:company_created_at',
            'urn:harmonic:company_field:company_funding_total',
            'urn:harmonic:company_field:company_last_funding_type',
            'urn:harmonic:company_field:company_investor_names',
            'urn:harmonic:company_field:company_headcount',
            'urn:harmonic:company_field:company_headcount_percent_change_90d_ago'
        ],
        search_type: 'COMPANIES_LIST',
        __typename: 'SavedSearch',
        creator: {
            email: 'matthiaslink@harmonic.ai',
            name: 'Matthias Link',
            entityUrn: 'urn:harmonic:user:11022',
            __typename: 'User'
        },
        userOptions: {
            isPinned: false,
            lastViewedAt: null,
            __typename: 'SavedSearchUserOptions'
        }
    }
};
