import { COMPANY_WATCHLIST_MOCK } from './get_company_watchlist';
import { PEOPLE_WATCHLIST_MOCK } from './getPeopleWatchlist';
export const GET_ALL_LISTS_MOCKED_RESPONSE = {
    getCompanyWatchlistsForTeam: [
        {
            ...COMPANY_WATCHLIST_MOCK,
            namedViews: [],
            userRelevanceScoreInfluenceType: '',
            updatedAt: '2024-09-04T14:55:00.487707',
            createdAt: '2024-08-15T20:07:13.913379',
            userOptions: {
                id: '294',
                isPinned: true,
                __typename: 'ListUserOptions'
            }
        }
    ],
    getPeopleWatchlistsForTeam: [
        {
            ...PEOPLE_WATCHLIST_MOCK,
            updatedAt: '2024-09-04T14:54:30.807615',
            createdAt: '2024-07-25T18:20:59.044004',
            userOptions: {
                id: '364',
                isPinned: true,
                __typename: 'ListUserOptions'
            }
        }
    ]
};
