const getCompanyEmployeesMockedResponse = {
    getEmployeesByCompanyId: {
        __typename: 'EntitySearchResponseConnection',
        totalCount: 1,
        edges: [
            {
                cursor: 'urn:harmonic:person:159049861',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    id: 159049861,
                    fullName: 'Sam Kirschner',
                    firstName: 'Sam',
                    profilePictureUrl: 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry',
                    highlights: [],
                    experience: [
                        {
                            title: 'Visiting Partner',
                            company: {
                                id: 680532,
                                name: 'The House Fund',
                                logoUrl: 'https://assets.harmonic.ai/company_15966fd1afbb836f055ab79e4796718b5cda9a98765383613051dd3b4b921bdb.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2023-05-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 11259237,
                                name: 'GovForce - Where Primes Stay Compliant and Reduce Risk',
                                logoUrl: 'https://assets.harmonic.ai/company_179052d8bcb6869469b0874d173835872ed141faaa2dcae43d85068300519a07.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-12-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 656566,
                                name: 'On Deck',
                                logoUrl: 'https://assets.harmonic.ai/company_a579814dfd6b5871c82b1de300529af134942025c8c30ffdff1eb7f42221e4af.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-10-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-05-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 4295431,
                                name: 'Sendoso',
                                logoUrl: 'https://media.licdn.com/dms/image/D560BAQHNaiKxJM0PMg/company-logo_200_200/0/1689618046452?e=2147483647&v=beta&t=jGqJpM-YkECEyTWb7NTmijdM8Hn1bVJf4cfBHGACQH0',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 792422,
                                name: 'Scarf',
                                logoUrl: 'https://assets.harmonic.ai/company_caade6ee6a9100aa1d923f04d8a6474fbafd055846926f6b40cb4d6a02a9bfcb.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2019-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 3069933,
                                name: 'SimpleLab, Inc.',
                                logoUrl: 'https://assets.harmonic.ai/company_c545cd2f561f9cdddb90a478707b744f2120c2450ed6dcc57da0db0205dce18b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2015-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Director Of Operations',
                            company: {
                                id: 673534,
                                name: 'Village Global',
                                logoUrl: 'https://assets.harmonic.ai/company_75ada3395bd2cd1257f3ba96bb403828425d0a3bafc3389a26024b04f0db78d3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2021-03-01T00:00:00Z',
                            endDate: '2021-10-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Data Science',
                            company: {
                                id: 12295214,
                                name: 'Distributed Systems (acq. Coinbase)',
                                logoUrl: 'https://assets.harmonic.ai/company_1bf4b5b1d625d020b34b79ecdc24c97b271a91238ee17abde30da45cceb68037.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2017-01-01T00:00:00Z',
                            endDate: '2017-10-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 680532,
                                name: 'The House Fund',
                                logoUrl: 'https://assets.harmonic.ai/company_15966fd1afbb836f055ab79e4796718b5cda9a98765383613051dd3b4b921bdb.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2015-03-01T00:00:00Z',
                            endDate: '2017-10-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Data Science',
                            company: {
                                id: 75109,
                                name: 'Grand Rounds Health',
                                logoUrl: 'https://assets.harmonic.ai/company_466ff407da0e8f93420c309037c96c020abec4dc1c695d210133c4054b8ed6f1.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2015-06-01T00:00:00Z',
                            endDate: '2017-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Co-Founder & Managing Director',
                            company: {
                                id: 767231,
                                name: 'Free Ventures',
                                logoUrl: 'https://assets.harmonic.ai/company_c752a3cddab7e3e324ac765048917cec30f4a3b4c06bfc12f2375432c7694253.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2013-01-01T00:00:00Z',
                            endDate: '2014-05-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Software Engineering & Data Science',
                            company: {
                                id: 1461015,
                                name: 'Autodesk',
                                logoUrl: 'https://assets.harmonic.ai/company_7ecbdf5ed605a74f5f3ba1b551942cffd648482b91b1e334bea26088f905e78b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2013-06-01T00:00:00Z',
                            endDate: '2013-08-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Data Research Assistant',
                            company: {
                                id: 1712845,
                                name: 'Energy & Biosciences Institute',
                                logoUrl: 'https://assets.harmonic.ai/company_3f56c306840f76b5afd15dd78a8f6b45b37d50212c4476bc17f89559e3cf6847.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2012-04-01T00:00:00Z',
                            endDate: '2013-05-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    education: [
                        {
                            school: {
                                name: 'University of Toronto'
                            }
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            },
            {
                cursor: 'urn:harmonic:person:311819',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    id: 311819,
                    fullName: 'Miles Konstantin',
                    firstName: 'Miles',
                    profilePictureUrl: 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry',
                    highlights: [],
                    experience: [
                        {
                            title: 'Operations + Experience',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-09-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Chief Of Staff',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-12-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Creative Technical Director',
                            company: {
                                id: -1,
                                name: 'Donald Glover / Childish Gambino',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2015-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    education: [
                        {
                            school: {
                                name: 'University of Toronto'
                            }
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            },
            {
                cursor: 'urn:harmonic:person:20815076',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    id: 20815076,
                    fullName: 'Ashley Hanson',
                    firstName: 'Ashley',
                    profilePictureUrl: 'https://assets.harmonic.ai/person_df848d8353fac2841698dc0d8be27c80b48c872317e0690042e37a6fe1cdd4db.png',
                    highlights: [],
                    experience: [
                        {
                            title: 'Assistant Manager',
                            company: {
                                id: 5150698,
                                name: 'Guess Europe Sagl',
                                logoUrl: 'https://assets.harmonic.ai/company_120d79c5fe95695bd0bff88217ef99f7facf8e6b54e177e4ddf862adb007aa57.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2016-10-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Assistant Manager',
                            company: {
                                id: 137927,
                                name: 'GUESS?, Inc.',
                                logoUrl: 'https://assets.harmonic.ai/company_c25457d36faca94f78412af75cb8cf9aa57c59a547d322aa7a6ce6bfa7c7df2a.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2016-10-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Sales Representative',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2015-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Sales Associate',
                            company: {
                                id: 5150698,
                                name: 'Guess Europe Sagl',
                                logoUrl: 'https://assets.harmonic.ai/company_120d79c5fe95695bd0bff88217ef99f7facf8e6b54e177e4ddf862adb007aa57.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2014-08-01T00:00:00Z',
                            endDate: '2015-08-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Assistant Manager',
                            company: {
                                id: 10177147,
                                name: "Victoria's Secret",
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2011-08-01T00:00:00Z',
                            endDate: '2014-07-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    education: [
                        {
                            school: {
                                name: 'University of Toronto'
                            }
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            },
            {
                cursor: 'urn:harmonic:person:101695926',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    id: 101695926,
                    fullName: 'Ryan James',
                    firstName: 'Ryan',
                    profilePictureUrl: 'https://media-exp1.licdn.com/dms/image/C4E03AQFXDc7Hq-Yn-g/profile-displayphoto-shrink_200_200/0/1630463025837?e=1658966400&v=beta&t=lJlJsQ9NigTaQ_FW35QYbji_WHeD26EGohBrncvBhPM',
                    highlights: [],
                    experience: [
                        {
                            title: 'Owner',
                            company: {
                                id: -1,
                                name: 'Camden Sports Group',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-11-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-10-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Co-Founder',
                            company: {
                                id: 3258131,
                                name: 'Stylebase',
                                logoUrl: 'https://assets.harmonic.ai/company_5110a3ebc36a11234b5b30a9fa6e56aea3bc8490bb773c6776d98f191f8ebcec.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-07-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Director Marketing & Sponsorships',
                            company: {
                                id: 1789731,
                                name: 'Dominance MMA',
                                logoUrl: 'https://assets.harmonic.ai/company_58b88f5b011a03517a69c6d35293a0d5edfe009f461538cb46ef67570b773480.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Founder & CEO',
                            company: {
                                id: 793245,
                                name: 'Seahawk',
                                logoUrl: 'https://assets.harmonic.ai/company_bb1a333f085f53db48b1f85cacb0fa338fd024546e7318fc453419beea8868af.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2015-08-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 12930203,
                                name: 'Emergency Room Beirut',
                                logoUrl: 'https://assets.harmonic.ai/company_a1a74ad75a84c2511ff226e439465219e31e6faf30c115d986eb072ba44b8325.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Founder & CEO',
                            company: {
                                id: 3258131,
                                name: 'Stylebase',
                                logoUrl: 'https://assets.harmonic.ai/company_5110a3ebc36a11234b5b30a9fa6e56aea3bc8490bb773c6776d98f191f8ebcec.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Intern',
                            company: {
                                id: 1789731,
                                name: 'Dominance MMA',
                                logoUrl: 'https://assets.harmonic.ai/company_58b88f5b011a03517a69c6d35293a0d5edfe009f461538cb46ef67570b773480.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 1789731,
                                name: 'Dominance MMA',
                                logoUrl: 'https://assets.harmonic.ai/company_58b88f5b011a03517a69c6d35293a0d5edfe009f461538cb46ef67570b773480.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Co-Founder & CEO',
                            company: {
                                id: 793245,
                                name: 'Seahawk',
                                logoUrl: 'https://assets.harmonic.ai/company_bb1a333f085f53db48b1f85cacb0fa338fd024546e7318fc453419beea8868af.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Operator',
                            company: {
                                id: -1,
                                name: 'Brandy',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: -1,
                                name: 'Emergency Room Beirut',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    education: [
                        {
                            school: {
                                name: 'University of Toronto'
                            }
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            },
            {
                cursor: 'urn:harmonic:person:160926459',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    id: 160926459,
                    fullName: 'Ryan James',
                    firstName: 'Ryan',
                    profilePictureUrl: 'https://assets.harmonic.ai/person_de216d27cdcb07afe33283056e3a781d24bbf4cf65fc698df99baebb153f1154.png',
                    highlights: [],
                    experience: [
                        {
                            title: 'Operator',
                            company: {
                                id: -1,
                                name: 'codesign',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2023-02-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 12930203,
                                name: 'Emergency Room Beirut',
                                logoUrl: 'https://assets.harmonic.ai/company_a1a74ad75a84c2511ff226e439465219e31e6faf30c115d986eb072ba44b8325.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-11-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: -1,
                                name: 'Emergency Room Beirut',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-11-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Operator',
                            company: {
                                id: 11704419,
                                name: 'Brandy',
                                logoUrl: 'https://assets.harmonic.ai/company_04a84e889fb61d9028d39f6a79363eb68373ce8028f07bc65ff326c2203873df.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-07-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Founder & CEO',
                            company: {
                                id: 3258131,
                                name: 'Stylebase',
                                logoUrl: 'https://assets.harmonic.ai/company_5110a3ebc36a11234b5b30a9fa6e56aea3bc8490bb773c6776d98f191f8ebcec.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-10-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-10-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Advisor',
                            company: {
                                id: 1789731,
                                name: 'Dominance MMA',
                                logoUrl: 'https://assets.harmonic.ai/company_58b88f5b011a03517a69c6d35293a0d5edfe009f461538cb46ef67570b773480.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Co-Founder & CEO',
                            company: {
                                id: 793245,
                                name: 'Seahawk',
                                logoUrl: 'https://assets.harmonic.ai/company_bb1a333f085f53db48b1f85cacb0fa338fd024546e7318fc453419beea8868af.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2015-08-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Intern',
                            company: {
                                id: 1789731,
                                name: 'Dominance MMA',
                                logoUrl: 'https://assets.harmonic.ai/company_58b88f5b011a03517a69c6d35293a0d5edfe009f461538cb46ef67570b773480.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-01-01T00:00:00Z',
                            endDate: '2018-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Operator',
                            company: {
                                id: -1,
                                name: 'Brandy',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    education: [
                        {
                            school: {
                                name: 'University of Toronto'
                            }
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            },
            {
                cursor: 'urn:harmonic:person:182482',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    id: 182482,
                    fullName: 'Spencer Foust',
                    firstName: 'Spencer',
                    profilePictureUrl: 'https://assets.harmonic.ai/person_e0359d2a7701897b454bd3be666c1833d0da4ac9239f95517e8bd3110e79223a.png',
                    highlights: [
                        {
                            text: 'Top University, Spencer Foust: Master of Science (MS), Management Science and Engineering @ Stanford',
                            category: 'Top University',
                            __typename: 'PersonHighlight'
                        },
                        {
                            text: 'Top University, Spencer Foust: Stanford',
                            category: 'Top University',
                            __typename: 'PersonHighlight'
                        }
                    ],
                    experience: [
                        {
                            title: 'Kauffman Fellow',
                            company: {
                                id: 32466425,
                                name: 'Kauffman Fellows',
                                logoUrl: 'https://assets.harmonic.ai/company_bc0590efeb69249419fa89a5912e943270aa79dc63a825396d45891e88f0f18e.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2023-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 801448,
                                name: 'Workera',
                                logoUrl: 'https://assets.harmonic.ai/company_7d75d726f841d4feb3be6cf0f5177f40b04a53b4d92acac7ca62a86bdf5fe5b3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2023-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1775373,
                                name: 'Select Star',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQHWnpcl0ryzJg/company-logo_200_200/0/1630655475942/selectstarhq_logo?e=2147483647&v=beta&t=yKwfGVivqgoxzn-fbpaxA9uO22S2cZOUiqH5fZS57a8',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-12-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Observer',
                            company: {
                                id: 106472,
                                name: 'Vendr',
                                logoUrl: 'https://assets.harmonic.ai/company_e486bf579a8b7c9fddcf3a84da3a8f9a40fa5c7dcc9243d37f09102757cd90fe.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-05-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1461771,
                                name: 'Stord',
                                logoUrl: 'https://assets.harmonic.ai/company_e5cdcd303f6773471e0cbf7ef896734d3b2d56000ca8da17e6eefc853a9af8b3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Member',
                            company: {
                                id: 793561,
                                name: 'SmartHop',
                                logoUrl: 'https://assets.harmonic.ai/company_98f3a0434e899d911faad7d1ebab999a5837f9e925a4e710c0a7213073780cf2.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Member',
                            company: {
                                id: 3093978,
                                name: 'Sparrow',
                                logoUrl: 'https://assets.harmonic.ai/company_e4225cc7baaa70551f90b6b812ec0e545befd8bf86fa37a1cb17da6f2bca3015.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3093978,
                                name: 'Sparrow',
                                logoUrl: 'https://assets.harmonic.ai/company_e4225cc7baaa70551f90b6b812ec0e545befd8bf86fa37a1cb17da6f2bca3015.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Member',
                            company: {
                                id: 9022,
                                name: 'Leaf Logistics',
                                logoUrl: 'https://assets.harmonic.ai/company_929c524a9a14c0d4db6433c9f70faedb9ce988f1d9fabca5cee1637a1ce07c14.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 170266,
                                name: 'Viz.ai',
                                logoUrl: 'https://assets.harmonic.ai/company_a8b06553250048adf89065a6e8c83c13caae63b66371d3864d2f3cda0fe15e2e.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-11-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Member',
                            company: {
                                id: 1706690,
                                name: 'Kargo',
                                logoUrl: 'https://assets.harmonic.ai/company_2eee3824ab49cdbcc65397cbd3424a23f2726da50b35503dc700790d52fc4316.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-10-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Managing Director',
                            company: {
                                id: 773269,
                                name: 'Sozo Ventures',
                                logoUrl: 'https://assets.harmonic.ai/company_a9e7168d6b99c95038db4b5f499999ce70bf1888f49ef0c67fabdeef41765bd3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Observer',
                            company: {
                                id: 795838,
                                name: 'RenoRun',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQHnb0fKPqaj1A/company-logo_200_200/0/1596660947626?e=2147483647&v=beta&t=1lJWEr2kXz2NB0dzafk8KIM50M6AF574XwD6NzkUyUk',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Observer',
                            company: {
                                id: 463602,
                                name: 'project44',
                                logoUrl: 'https://assets.harmonic.ai/company_a4dde39d187f78042d3c57205d7c1be21fa7176b6711a8b5102cc04f75e31ac7.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 134012,
                                name: 'Carta',
                                logoUrl: 'https://assets.harmonic.ai/company_dd382def3f68703114bb59720c58957884b31a9bdb6e23d687209e47c94435dd.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-05-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 164430,
                                name: 'Cohesity',
                                logoUrl: 'https://assets.harmonic.ai/company_bce10fac2c65fbbf888ee1b3f8c6f163d5a4c51302d179d64167b347497faaba.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Member',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor and Advisor',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1460872,
                                name: 'Clearco',
                                logoUrl: 'https://assets.harmonic.ai/company_266ffed3c917710d9fd21d9401f48839ee744a20386494f892de1e7023a241c6.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-03-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Angel / LP / Advisor',
                            company: {
                                id: -1,
                                name: 'Investor',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2019-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 9277,
                                name: 'Chainalysis',
                                logoUrl: 'https://assets.harmonic.ai/company_087741e65b10b020c63ab8d960058b503ca9bee74dbd7a343f4eaddd67380833.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2019-03-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 142545,
                                name: 'Flexport',
                                logoUrl: 'https://assets.harmonic.ai/company_ea77ccae6bfdbc91c21d91a3a963847fb79419a044d557266e66d4182d839c2f.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-09-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3178126,
                                name: 'Zoom',
                                logoUrl: 'https://assets.harmonic.ai/company_e24d73606c8ca88f3cbcc6aa806a04bb1d6a4d014d502f47ec745219a4fe8b7f.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 76308,
                                name: 'Fastly',
                                logoUrl: 'https://assets.harmonic.ai/company_4f4d4ba2605a664b9fbc7b609d9158684621c33a35c9a4b05fa6cc820c5c52c6.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Principal',
                            company: {
                                id: 773269,
                                name: 'Sozo Ventures',
                                logoUrl: 'https://assets.harmonic.ai/company_a9e7168d6b99c95038db4b5f499999ce70bf1888f49ef0c67fabdeef41765bd3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2016-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 2140725,
                                name: 'Coinbase',
                                logoUrl: 'https://assets.harmonic.ai/company_c4cc705546c7a25ecd4f74f1720f0fedc3316c4ea2513afb873e1cb09b18f519.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2016-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Partner',
                            company: {
                                id: 773269,
                                name: 'Sozo Ventures',
                                logoUrl: 'https://assets.harmonic.ai/company_a9e7168d6b99c95038db4b5f499999ce70bf1888f49ef0c67fabdeef41765bd3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2016-06-01T00:00:00Z',
                            endDate: '2021-10-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Observer',
                            company: {
                                id: 191203,
                                name: 'Revinate',
                                logoUrl: 'https://assets.harmonic.ai/company_246ed3e721651c7b0a8e63113f8c6ca62744433d5bdd635a770df5c5762b6ce0.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-01-01T00:00:00Z',
                            endDate: '2020-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Board Observer',
                            company: {
                                id: 686148,
                                name: 'Handshake (acquired by Shopify)',
                                logoUrl: 'https://assets.harmonic.ai/company_0d69ac936670aad2f9829b885171ec33309c5f49c6c0180d43e9f4b129b1627d.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-01-01T00:00:00Z',
                            endDate: '2018-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Engineering Program Manager - Systems R&D',
                            company: {
                                id: 284975,
                                name: 'Sandia National Laboratories',
                                logoUrl: 'https://assets.harmonic.ai/company_82be2070c4025717c19d4a98717e18bb113f0c1c32b829feff574ebcbc9de567.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2013-06-01T00:00:00Z',
                            endDate: '2015-05-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'iPhone Operations Program Manager, Post-Ramp Qualification (PRQ)',
                            company: {
                                id: 1554006,
                                name: 'Trinity Investors',
                                logoUrl: 'https://assets.harmonic.ai/company_69f551c6060bdca2f6423c1ac795f3064dfb3b250f60758b058aa25cbb30c3ec.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2015-06-01T00:00:00Z',
                            endDate: '2015-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'iPhone Operations Program Manager, Post-Ramp Qualification (PRQ)',
                            company: {
                                id: -1,
                                name: 'Apple',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2015-01-01T00:00:00Z',
                            endDate: '2015-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'iPhone Operations Program Manager, Post-Ramp Qualification (PRQ)',
                            company: {
                                id: 12692674,
                                name: 'Apple',
                                logoUrl: 'https://assets.harmonic.ai/company_a8cb4f578f9e571e12c5caa97fefe0df364a7b0f7fffbbad1115acda0ecc56c0.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2015-01-01T00:00:00Z',
                            endDate: '2015-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    education: [
                        {
                            school: {
                                name: 'University of Toronto'
                            }
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            },
            {
                cursor: 'urn:harmonic:person:1104913',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    id: 1104913,
                    fullName: 'Aantorik Ganguly',
                    firstName: 'Aantorik',
                    profilePictureUrl: 'https://assets.harmonic.ai/person_709822c1ce9dcda350c2092c8176988045330e1b672995cc258e54e2571d2266.png',
                    highlights: [
                        {
                            text: 'Top University, Aantorik Ganguly: University of Oxford',
                            category: 'Top University',
                            __typename: 'PersonHighlight'
                        }
                    ],
                    experience: [
                        {
                            title: 'GTM Intern',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2023-05-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Fellow',
                            company: {
                                id: 4792972,
                                name: 'The Germination Project',
                                logoUrl: 'https://assets.harmonic.ai/company_19dc4ed1fd0a430e168fce080e5b9984e22040e7de504a4b5063c92a77ccf941.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2017-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Analyst',
                            company: {
                                id: 773269,
                                name: 'Sozo Ventures',
                                logoUrl: 'https://assets.harmonic.ai/company_a9e7168d6b99c95038db4b5f499999ce70bf1888f49ef0c67fabdeef41765bd3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: '2022-08-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Co-director Finance committee',
                            company: {
                                id: 8204886,
                                name: 'Middlebury College SGA: Student Government Association',
                                logoUrl: 'https://assets.harmonic.ai/company_adc51622202e30e6bd0b6dec7b1da8cd4ed7108af8c44fb704fda80593d3bd98.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2021-08-01T00:00:00Z',
                            endDate: '2022-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Team Leader',
                            company: {
                                id: 4061991,
                                name: 'Middlebury Consulting Group',
                                logoUrl: 'https://assets.harmonic.ai/company_129bb918747deb864bdab3f598e2a8f3e7612efdc31437e109a78da747e9fc1f.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2020-09-01T00:00:00Z',
                            endDate: '2022-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Strategic Market Access Intern',
                            company: {
                                id: 3333176,
                                name: 'Novartis',
                                logoUrl: 'https://assets.harmonic.ai/company_7fa8ea9529a1725ccf79042017d9dfef615c0f99cf1bdcb18c8508d9284371e9.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2021-06-01T00:00:00Z',
                            endDate: '2021-08-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'International Project Head',
                            company: {
                                id: 13516891,
                                name: 'Kolkata Society For Cultural Heritage',
                                logoUrl: 'https://assets.harmonic.ai/company_a0822b621699af5d1e1074dcd5c43eaf31a334b1e384b264caeaec66a2b0f846.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2018-02-01T00:00:00Z',
                            endDate: '2021-02-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'International Project Head',
                            company: {
                                id: -1,
                                name: 'Kolkata Society For Cultural Heritage',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2018-02-01T00:00:00Z',
                            endDate: '2021-02-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Analyst Intern',
                            company: {
                                id: 3757777,
                                name: 'ThreeHill Capital Partners',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQHdWmU7DXa5Kg/company-logo_200_200/0/1578666467059?e=2147483647&v=beta&t=VFBkAPdrzzX0NepGmtOPLkz-KwxkNjyMEKfWWoktmRw',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2020-03-01T00:00:00Z',
                            endDate: '2020-08-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Finance Committee member',
                            company: {
                                id: 8204886,
                                name: 'Middlebury College SGA: Student Government Association',
                                logoUrl: 'https://assets.harmonic.ai/company_adc51622202e30e6bd0b6dec7b1da8cd4ed7108af8c44fb704fda80593d3bd98.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: '2019-09-01T00:00:00Z',
                            endDate: '2020-08-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Intern',
                            company: {
                                id: 773269,
                                name: 'Sozo Ventures',
                                logoUrl: 'https://assets.harmonic.ai/company_a9e7168d6b99c95038db4b5f499999ce70bf1888f49ef0c67fabdeef41765bd3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Student Consultant',
                            company: {
                                id: 4061991,
                                name: 'Middlebury Consulting Group',
                                logoUrl: 'https://assets.harmonic.ai/company_129bb918747deb864bdab3f598e2a8f3e7612efdc31437e109a78da747e9fc1f.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Chief Liaison, Finance Comittee',
                            company: {
                                id: 8204886,
                                name: 'Middlebury College SGA: Student Government Association',
                                logoUrl: 'https://assets.harmonic.ai/company_adc51622202e30e6bd0b6dec7b1da8cd4ed7108af8c44fb704fda80593d3bd98.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: null,
                            startDate: null,
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    education: [
                        {
                            school: {
                                name: 'University of Toronto'
                            }
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            },
            {
                cursor: 'urn:harmonic:person:102287',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    id: 102287,
                    fullName: 'Ludwig Pierre Schulze',
                    firstName: 'Ludwig',
                    profilePictureUrl: 'https://assets.harmonic.ai/person_1c2a03ebb18f47d3b80873f29f0895182447d5afa6d81d94dc8dac179a9b56b6.png',
                    highlights: [
                        {
                            text: 'Top University, Ludwig Pierre Schulze: Bachelor of Arts - BA @ Brown',
                            category: 'Top University',
                            __typename: 'PersonHighlight'
                        },
                        {
                            text: 'Prior VC Backed Executive, Ludwig Pierre Schulze: General Manager & Business Development, Digital Financial Services @ Nokia, ',
                            category: 'Prior VC Backed Executive',
                            __typename: 'PersonHighlight'
                        },
                        {
                            text: 'Seasoned Executive, Ludwig Pierre Schulze: General Manager & Business Development, Digital Financial Services @ Nokia (83859 Employees) 1/2008-1/2012',
                            category: 'Seasoned Executive',
                            __typename: 'PersonHighlight'
                        }
                    ],
                    experience: [
                        {
                            title: 'Investor',
                            company: {
                                id: 3075034,
                                name: 'Precision Neuroscience',
                                logoUrl: 'https://assets.harmonic.ai/company_132c52a49b4e662857be702467c0afece852fde2aab5fe852e71ab33167cbabd.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2023-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 438676,
                                name: 'Genetesis',
                                logoUrl: 'https://assets.harmonic.ai/company_c5b99ec73b187a308adc0ed81083119a2addd5471d71d0ff43bf813e4785188c.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3216406,
                                name: 'ForceMetrics',
                                logoUrl: 'https://assets.harmonic.ai/company_feb0548f6b574ea5a2c6d1f61e223a3b9257ed3b6c141dd853239be750b000f5.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 5133651,
                                name: 'Forta',
                                logoUrl: 'https://assets.harmonic.ai/company_2ae73ef0d3580b28da59c8d3ca4bbb69e53f89c9b463614e1fdad909ff3072e2.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 12467466,
                                name: 'Runwise',
                                logoUrl: 'https://assets.harmonic.ai/company_96f9e279380fffb767bd8bd86c2d629f1d849b69d54c77345e067b0f1e98ea4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3372183,
                                name: 'Galvanick',
                                logoUrl: 'https://assets.harmonic.ai/company_2e6dfad5cbf60741e07513f33e68695f80893c5d1f031ad0ab7084b73ced098d.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1478049,
                                name: 'NoCodeOps',
                                logoUrl: 'https://assets.harmonic.ai/company_0a50091378b7bc9fb12551ea89a7798f41d78c978e42e92349adfa9ae1de93e9.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 6709560,
                                name: 'Quiltt',
                                logoUrl: 'https://assets.harmonic.ai/company_0d982a34aaf0d5814b12dcfbed98fd140f9dfd934bcfa6ac611dd3344cef4894.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1730332,
                                name: 'mayk.it',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQFnmsac7geNAw/company-logo_200_200/0/1630543123477/mayk_it_logo?e=2147483647&v=beta&t=9XVep9AEP0a2rdERB9_uH6QGjnP9Nx6VEl6nO_-s-2I',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 760941,
                                name: 'Brave Health',
                                logoUrl: 'https://assets.harmonic.ai/company_47c987071bb9a44c462f50c1c17ee1fe74f496fcd6723a54a27bb68f71c79ae0.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 840774,
                                name: 'Primer',
                                logoUrl: 'https://assets.harmonic.ai/company_4510da45daf8a226a1393592ad8c6a91940dce321eb771dd40b55026ea4ce5f4.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 10229460,
                                name: 'Remento',
                                logoUrl: 'https://assets.harmonic.ai/company_f999a7632c386c65a4612ac4319c8dfd90e0a3c65897aa44bf8fda105451009d.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: -1,
                                name: 'Hyperspace',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 10721440,
                                name: 'Nixtla',
                                logoUrl: 'https://assets.harmonic.ai/company_0b95b9c02083bace93f83bacd17e989aa3b6311112db5b055db8076acce1ceb2.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3534037,
                                name: 'Hyperspace',
                                logoUrl: 'https://assets.harmonic.ai/company_a243f85312a5316c04532222c1afc4adc9b70a104b9f205796d5773e7b9b96cb.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1678830,
                                name: 'Build A Rocket Boy',
                                logoUrl: 'https://assets.harmonic.ai/company_22ac86d598bba710ec9350d12eed1d7ad9abdfb478c8f7939831ab3f5db40e1b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 73060,
                                name: 'Grata',
                                logoUrl: 'https://assets.harmonic.ai/company_7e2981ff3e77337a7ba4b715ee7ef32bf1fb90af93b4d1b12d5946425c6fe5cf.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3637087,
                                name: 'Modicus Prime',
                                logoUrl: 'https://assets.harmonic.ai/company_eece25d8a7b2d84c4104ee0a9cd3add5d7ad90e59082d03574e84d6eec69e73a.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 4298049,
                                name: 'Intus Care',
                                logoUrl: 'https://assets.harmonic.ai/company_6f825921540b4098f2d4ced0ace42b0be19f3cf491b9469d195236e21e2f6453.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 4317756,
                                name: 'Instill',
                                logoUrl: 'https://assets.harmonic.ai/company_f573164fe91f8251947b65a0473eb54f63a40b2ab76b2a81936825213092a3bc.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2022-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 107200,
                                name: 'Vouch Insurance',
                                logoUrl: 'https://assets.harmonic.ai/company_042b90488f4e5859d481fdd80e2e933932c59eadb93f5113783504f7df33d0c1.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 2185205,
                                name: 'Dextrous Robotics',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQFqXi_sgbVD0w/company-logo_200_200/0/1593023852680?e=2147483647&v=beta&t=vQe0t0QO02cqa86zI2iJ8oh7PxoA8W9flcQ1Id86NPs',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3922297,
                                name: 'Graphite',
                                logoUrl: 'https://assets.harmonic.ai/company_8bf201b8cdca4a72fc926fcea5d2745b52c2086cd6707a7769d506d021d6b351.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 733894,
                                name: 'Formlogic',
                                logoUrl: 'https://assets.harmonic.ai/company_55a022bf380727369ceda8daa03a78208aeb3d6f639ab69703533ec40b1c92f5.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 687164,
                                name: 'Kettle',
                                logoUrl: 'https://assets.harmonic.ai/company_1fe62f8448ad83d3ece6ef66ba62dfe7804b37cca2c2b8907f1cc7636fffd778.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 714838,
                                name: 'Simplist',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQF0PhZtuMJdew/company-logo_200_200/0/1548786368867?e=2147483647&v=beta&t=w8InyMuOD0g43n7e9ixNNgMjtwH5BVjXtDwhSxe6xe0',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1842586,
                                name: 'Satellite Bio',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQFCiSYmzYEUrg/company-logo_200_200/0/1657304324317?e=2147483647&v=beta&t=HnmBtQj_tKG_H9p-yp7jnsey3-eCotkdXRxsmtbKEIM',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3748759,
                                name: 'FreshCut',
                                logoUrl: 'https://assets.harmonic.ai/company_e84e48661fab2871f57f0b8b1c857a1b9a58627030837df290973e88f7f1e0fd.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 4520045,
                                name: 'Chooch',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQGmR1VsoLD0iw/company-logo_200_200/0/1679755189741?e=2147483647&v=beta&t=pFdBsmKWRsvJjCEQFYcpNbXdaMWwxIqDT59DgsKT6f8',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 794788,
                                name: 'Ockam',
                                logoUrl: 'https://assets.harmonic.ai/company_bfbe16f8dfe1d4e9e4006e4aed3bc3eb3b94cb2861aecf71be22a9a6ca1330d0.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3054143,
                                name: 'B Generous',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQEzwNse-Nrcgw/company-logo_200_200/0/1644027324105?e=2147483647&v=beta&t=oQM-gWrpareF-teHTBfrrNrADi5PimhE99q1DGpMA1Q',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 34775194,
                                name: 'Mylance',
                                logoUrl: 'https://assets.harmonic.ai/company_06adfa314f7b7c72289350fb7789fc17317f7faea57764053332729f24d6c024.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1463183,
                                name: 'NeuReality',
                                logoUrl: 'https://assets.harmonic.ai/company_37c59b9cd3879d258d535b6d351549db2e42192b9c5525cf16241dcc34dbd725.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1370344,
                                name: 'Pacaso',
                                logoUrl: 'https://assets.harmonic.ai/company_d229b701f3b9e75ae146ce4fea2da31169cbd5a1e95253740152e86b97392651.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 772964,
                                name: 'JumpCloud',
                                logoUrl: 'https://assets.harmonic.ai/company_52ab41070565dceb3a67b1b09b68c99412c8e7ebd98fa4975a79433a18bf97d2.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1448630,
                                name: 'Tango',
                                logoUrl: 'https://assets.harmonic.ai/company_a89c0f6391a5c2f0481800a11be469b1c97ea04b2a082a9f7ce7144b27f912fe.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Managing Partner',
                            company: {
                                id: -1,
                                name: '116 Street Ventures',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3570218,
                                name: 'Cruisebound, inc.',
                                logoUrl: 'https://assets.harmonic.ai/company_ead65423cede823e0e700552a4b701aea9f1190c6e5738bbe315b5abe65ac5b5.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 76160,
                                name: 'HawkEye 360',
                                logoUrl: 'https://assets.harmonic.ai/company_1ae419e2805bdd04977eab1b671b121ddfe5c1ec340135f56ad5cc9ed908f2d8.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 3786622,
                                name: 'Synthesis',
                                logoUrl: 'https://assets.harmonic.ai/company_9a2ae5b37d705df017dffcb0268ebdf483b386e1e9686a72d39769085d34ceec.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 4414952,
                                name: 'Socrates AI',
                                logoUrl: 'https://assets.harmonic.ai/company_a1cfbcadfc9c36cfecf8cc768882da3a71383beb913d1fc5a1d6ac513ed78e10.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 34628023,
                                name: 'Market',
                                logoUrl: 'https://assets.harmonic.ai/company_c558c22140108b00d743d5c55cc2b25bc4c351ed56bef899650cbbe8983392df.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 1,
                                name: 'Harmonic',
                                logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2021-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 606346,
                                name: 'Future Family',
                                logoUrl: 'https://assets.harmonic.ai/company_27dc63bad5601c2c6e17f39419c37503391ed19d3dddd592d5d44beb15320bba.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2020-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 170224,
                                name: 'Glympse Bio',
                                logoUrl: 'https://assets.harmonic.ai/company_6f6db2ef0cc2115d1dbd6d0a2379265487e61d50c6786deac4aa735fef23d333.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2019-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 188762,
                                name: 'Loadsmart',
                                logoUrl: 'https://assets.harmonic.ai/company_28992abf9b4b1d999deb90a6c207bd8bfe824de200ee35b5ae062de389f80b31.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2019-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 745605,
                                name: 'Ellevest',
                                logoUrl: 'https://assets.harmonic.ai/company_b2a9a75635d0848e8aa558fedbbd9651ea3f29fd194a6e4376a0275f64021bac.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2019-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 5146237,
                                name: 'Perennial',
                                logoUrl: 'https://assets.harmonic.ai/company_10c9d9325242517d276fdb2e1cbae19b147982f9d257df464f3acd1c5f70aae1.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2019-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 2146458,
                                name: 'Tara AI',
                                logoUrl: 'https://assets.harmonic.ai/company_478abb79fc73ef18642a3beb0fd0fc9f5606b5408b5b9e62f65bbc64e29e2187.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2019-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Chairman',
                            company: {
                                id: 1879174,
                                name: 'Mistral Mobile',
                                logoUrl: 'https://assets.harmonic.ai/company_afce36d7e36cbec4eb588828e8d6bfac4e6f7bfdbf6facbfe6cdf23c39e65a26.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-06-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Managing Partner',
                            company: {
                                id: 3291582,
                                name: 'Alumni Ventures',
                                logoUrl: 'https://assets.harmonic.ai/company_f6e00ebb48fcc5bc30f204f65815428305f92db9b6929c82caa55572da1253f0.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Managing Partner & NYC Office Leader',
                            company: {
                                id: 3291582,
                                name: 'Alumni Ventures',
                                logoUrl: 'https://assets.harmonic.ai/company_f6e00ebb48fcc5bc30f204f65815428305f92db9b6929c82caa55572da1253f0.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Managing Partner',
                            company: {
                                id: 1491474,
                                name: 'Waterman Ventures',
                                logoUrl: 'https://assets.harmonic.ai/company_2105f58dd073595f80d93456b05cb52bd5460c8961d47b411badd06c74aca998.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor',
                            company: {
                                id: 684543,
                                name: 'akorda',
                                logoUrl: 'https://assets.harmonic.ai/company_16a65a46a43110f8cc8b65a905300e332e8a2ac4132383a5fc819d4c513700f3.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2018-01-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investor & Board Member',
                            company: {
                                id: -1,
                                name: 'EMR Ventures, LLC',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2011-01-01T00:00:00Z',
                            endDate: '2022-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Founder & CEO',
                            company: {
                                id: 1879174,
                                name: 'Mistral Mobile',
                                logoUrl: 'https://assets.harmonic.ai/company_afce36d7e36cbec4eb588828e8d6bfac4e6f7bfdbf6facbfe6cdf23c39e65a26.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2012-01-01T00:00:00Z',
                            endDate: '2018-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Founder & Investor',
                            company: {
                                id: -1,
                                name: 'Truffle Piggy',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2010-01-01T00:00:00Z',
                            endDate: '2014-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Co-Founder',
                            company: {
                                id: -1,
                                name: 'Truffle Piggy',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2010-01-01T00:00:00Z',
                            endDate: '2014-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'General Manager & Business Development, Digital Financial Services',
                            company: {
                                id: 12206000,
                                name: 'Nokia',
                                logoUrl: 'https://assets.harmonic.ai/company_537f313fabb045783867caf761bb6897caf0f1c31c700778df4b95d488851cba.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2008-01-01T00:00:00Z',
                            endDate: '2012-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Corporate Development & Strategy',
                            company: {
                                id: 12206000,
                                name: 'Nokia',
                                logoUrl: 'https://assets.harmonic.ai/company_537f313fabb045783867caf761bb6897caf0f1c31c700778df4b95d488851cba.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2004-01-01T00:00:00Z',
                            endDate: '2007-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Corporate Development Manager',
                            company: {
                                id: 12206000,
                                name: 'Nokia',
                                logoUrl: 'https://assets.harmonic.ai/company_537f313fabb045783867caf761bb6897caf0f1c31c700778df4b95d488851cba.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2004-01-01T00:00:00Z',
                            endDate: '2007-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Venture Capital Investor',
                            company: {
                                id: -1,
                                name: 'Group Arnault LVMH Venture Fund & LPS Group',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2000-01-01T00:00:00Z',
                            endDate: '2004-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Strategy Consultant',
                            company: {
                                id: 335090,
                                name: 'Boston Consulting Group (BCG)',
                                logoUrl: 'https://assets.harmonic.ai/company_135fd4e18b7bd26711b8be8fced3ef3781c4b84bdadee78f4758ba2b6c715bc2.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '1997-01-01T00:00:00Z',
                            endDate: '2000-01-01T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    education: [
                        {
                            school: {
                                name: 'University of Toronto'
                            }
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            }
        ]
    }
};
const getCompanyZeroEmployeesMockResponse = {
    getEmployeesByCompanyId: {
        __typename: 'EntitySearchResponseConnection',
        totalCount: 0,
        edges: []
    }
};
export { getCompanyZeroEmployeesMockResponse, getPartnersMockResponse, getPartnersZeroPartnersMockResponse };
const getPartnersMockResponse = {
    getPartnersByCompanyId: {
        __typename: 'PeopleWithMetadata',
        count: 2,
        people: [
            {
                socials: {
                    linkedin: null,
                    __typename: 'Socials'
                },
                userConnections: [],
                __typename: 'Person',
                id: 1,
                fullName: 'Ali Hashemi',
                firstName: 'Ali',
                profilePictureUrl: null,
                highlights: [
                    {
                        __typename: 'PersonHighlight',
                        category: 'Major Tech Company Experience',
                        text: 'Major Tech Company Experience'
                    }
                ],
                experience: [
                    {
                        __typename: 'ExperienceMetadata',
                        title: 'Software Engineer',
                        isCurrentPosition: true,
                        company: {
                            logoUrl: '',
                            __typename: 'Company',
                            id: 1,
                            name: 'Harmonic'
                        },
                        startDate: '2021-01-01',
                        endDate: null
                    }
                ],
                education: [
                    {
                        school: {
                            name: 'University of Toronto',
                            __typename: 'School'
                        },
                        __typename: 'EducationMetadata'
                    }
                ]
            }
        ],
        pageInfo: {
            current: 'urn:harmonic:company:1',
            next: 'urn:harmonic:company:2',
            hasNext: false
        }
    }
};
const getPartnersZeroPartnersMockResponse = {
    getPartnersByCompanyId: {
        __typename: 'PeopleWithMetadata',
        count: 0,
        people: [],
        pageInfo: {
            current: 'urn:harmonic:company:1',
            next: 'urn:harmonic:company:1',
            hasNext: false
        }
    }
};
export default getCompanyEmployeesMockedResponse;
export const mockNoConnectionsAndPriorExperience = [
    {
        __typename: 'SearchResultNode',
        cursor: 'urn:harmonic:person:1',
        node: {
            __typename: 'Person',
            id: 1,
            socials: {
                linkedin: null,
                __typename: 'Socials'
            },
            userConnections: [],
            fullName: 'Ali Hashemi',
            firstName: 'Ali',
            profilePictureUrl: null,
            highlights: [],
            experience: [
                {
                    __typename: 'ExperienceMetadata',
                    title: 'Software Engineer',
                    isCurrentPosition: true,
                    company: {
                        logoUrl: '',
                        __typename: 'Company',
                        id: 1,
                        name: 'Harmonic'
                    },
                    startDate: '2021-01-01',
                    endDate: null
                }
            ],
            education: [
                {
                    school: {
                        name: 'University of Toronto'
                    }
                }
            ]
        }
    }
];
export const mockWithPriorExperience = [
    {
        __typename: 'SearchResultNode',
        cursor: 'urn:harmonic:person:1',
        node: {
            __typename: 'Person',
            id: 1,
            socials: {
                linkedin: null,
                __typename: 'Socials'
            },
            userConnections: [],
            fullName: 'Ali Hashemi',
            firstName: 'Ali',
            profilePictureUrl: null,
            highlights: [],
            experience: [
                {
                    __typename: 'ExperienceMetadata',
                    title: 'Software Engineer',
                    isCurrentPosition: true,
                    company: {
                        logoUrl: '',
                        __typename: 'Company',
                        id: 1,
                        name: 'Harmonic'
                    },
                    startDate: '2021-01-01',
                    endDate: null
                },
                {
                    __typename: 'ExperienceMetadata',
                    title: 'Prior Software Engineer',
                    isCurrentPosition: false,
                    company: {
                        logoUrl: '',
                        __typename: 'Company',
                        id: 1,
                        name: 'Harmonic'
                    },
                    startDate: '2020-01-01',
                    endDate: '2021-01-01'
                }
            ],
            education: [
                {
                    school: {
                        name: 'University of Toronto'
                    }
                }
            ]
        }
    }
];
const getCompanyEmployeesWithNoConnectionsMockResponse = {
    getEmployeesByCompanyId: {
        __typename: 'EntitySearchResponseConnection',
        totalCount: 1,
        edges: mockNoConnectionsAndPriorExperience
    }
};
export const mockIncompletePriorExperience = [
    {
        __typename: 'SearchResultNode',
        cursor: 'urn:harmonic:person:1',
        node: {
            socials: {
                linkedin: null,
                __typename: 'Socials'
            },
            userConnections: [],
            __typename: 'Person',
            id: 1,
            fullName: 'Ali Hashemi',
            firstName: 'Ali',
            profilePictureUrl: null,
            highlights: [],
            experience: [
                {
                    __typename: 'ExperienceMetadata',
                    title: 'Software Engineer',
                    isCurrentPosition: true,
                    company: {
                        logoUrl: '',
                        __typename: 'Company',
                        id: 1,
                        name: 'Harmonic'
                    },
                    startDate: '2021-01-01',
                    endDate: null
                },
                {
                    __typename: 'ExperienceMetadata',
                    title: null,
                    isCurrentPosition: false,
                    company: {
                        logoUrl: '',
                        __typename: 'Company',
                        id: 1,
                        name: null
                    },
                    startDate: '2020-01-01',
                    endDate: '2021-01-01'
                }
            ],
            education: [
                {
                    school: {
                        name: 'University of Toronto'
                    }
                }
            ]
        }
    }
];
export const mockWithHighlights = [
    {
        __typename: 'SearchResultNode',
        cursor: 'urn:harmonic:person:1',
        node: {
            socials: {
                linkedin: null,
                __typename: 'Socials'
            },
            userConnections: [],
            __typename: 'Person',
            id: 1,
            fullName: 'Ali Hashemi',
            firstName: 'Ali',
            profilePictureUrl: null,
            highlights: [
                {
                    __typename: 'PersonHighlight',
                    category: 'Major Tech Company Experience',
                    text: 'Major Tech Company Experience'
                }
            ],
            experience: [
                {
                    __typename: 'ExperienceMetadata',
                    title: 'Software Engineer',
                    isCurrentPosition: true,
                    company: {
                        logoUrl: '',
                        __typename: 'Company',
                        id: 1,
                        name: 'Harmonic'
                    },
                    startDate: '2021-01-01',
                    endDate: null
                }
            ],
            education: [
                {
                    school: {
                        name: 'University of Toronto'
                    }
                }
            ]
        }
    }
];
export const mockWithLinkedinUrl = [
    {
        __typename: 'SearchResultNode',
        cursor: 'urn:harmonic:person:1',
        node: {
            socials: {
                linkedin: {
                    url: 'somelinkedinurl',
                    __typename: 'SocialMetadata'
                },
                __typename: 'Socials'
            },
            userConnections: [],
            __typename: 'Person',
            id: 1,
            fullName: 'Ali Hashemi',
            firstName: 'Ali',
            profilePictureUrl: null,
            highlights: [
                {
                    __typename: 'PersonHighlight',
                    category: 'Major Tech Company Experience',
                    text: 'Major Tech Company Experience'
                }
            ],
            experience: [
                {
                    __typename: 'ExperienceMetadata',
                    title: 'Software Engineer',
                    isCurrentPosition: true,
                    company: {
                        logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                        __typename: 'Company',
                        id: 1,
                        name: 'Harmonic'
                    },
                    startDate: '2021-01-01',
                    endDate: null
                },
                {
                    __typename: 'ExperienceMetadata',
                    title: 'Software Engineer',
                    isCurrentPosition: false,
                    company: {
                        logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                        __typename: 'Company',
                        id: 1,
                        name: 'Harmonic'
                    },
                    startDate: '2021-01-01',
                    endDate: '2022-02-02'
                }
            ],
            education: [
                {
                    school: {
                        name: 'University of Toronto'
                    }
                }
            ]
        }
    }
];
