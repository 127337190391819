const GET_PERSON_EXPERIENCE_MOCK = {
    getPersonById: {
        id: 1,
        experience: [
            {
                title: 'Core Services Integrator',
                department: null,
                description: 'Creating the future of financial world right in the center of it. Building robust, fast, reliable, secure and easily scalable financial cloud system in cooperation with other teams and clients.',
                company: {
                    id: 207876,
                    logoUrl: 'https://assets.harmonic.ai/company_f57bf431177f70b676ce62dfaab6e81b97f4be235528116f22549e5e96c239f9.png',
                    name: 'Swift',
                    headcount: 4907,
                    fundingPerEmployee: 370221.85,
                    funding: {
                        fundingTotal: '0',
                        fundingStage: null,
                        investors: [],
                        fundingRounds: [],
                        __typename: 'Funding'
                    },
                    highlights: [],
                    foundingDate: {
                        date: '1973-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/swift',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 0.1224239951030402,
                                value: 4901,
                                change: 6,
                                __typename: 'TractionMetricsPoint'
                            },
                            metrics: [
                                {
                                    timestamp: '2023-09-27T02:12:15.456Z',
                                    metricValue: 4907,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-26T02:10:29.261Z',
                                    metricValue: 4907,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-11T04:50:50.281Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-10T03:54:17.630Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T04:55:33.060Z',
                                    metricValue: 4921,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-22T04:34:35.137Z',
                                    metricValue: 4921,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-10T03:23:47.245Z',
                                    metricValue: 4917,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-09T04:45:46.070Z',
                                    metricValue: 4917,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-04T04:27:06.475Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-02T09:13:56.449Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-01T19:39:31.030Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-19T01:40:22.112Z',
                                    metricValue: 4909,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-18T02:58:56.804Z',
                                    metricValue: 4909,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-07T03:11:39.480Z',
                                    metricValue: 4900,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-06T03:56:17.879Z',
                                    metricValue: 4900,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-28T05:43:32.014Z',
                                    metricValue: 4901,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-14T02:00:47.200Z',
                                    metricValue: 5107,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-13T23:50:28.752Z',
                                    metricValue: 5107,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-10T07:21:57.775Z',
                                    metricValue: 5107,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-28T04:44:26.163Z',
                                    metricValue: 5086,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-27T03:16:00.800Z',
                                    metricValue: 5086,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-30T01:43:54.605Z',
                                    metricValue: 4938,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-29T02:59:55.815Z',
                                    metricValue: 4938,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-27T01:36:08.642Z',
                                    metricValue: 4938,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-26T10:56:12.487Z',
                                    metricValue: 4938,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-25T19:26:57.038Z',
                                    metricValue: 4957,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-22T21:10:50.503Z',
                                    metricValue: 4957,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-21T14:05:46.427Z',
                                    metricValue: 4957,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-20T05:48:27.363Z',
                                    metricValue: 4957,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-05T13:28:36.060Z',
                                    metricValue: 5005,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-04T02:36:02.131Z',
                                    metricValue: 5005,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-25T03:40:29.429Z',
                                    metricValue: 5004,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-12T01:36:49.734Z',
                                    metricValue: 4977,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-03T02:27:00.545Z',
                                    metricValue: 4963,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-02T02:23:26.007Z',
                                    metricValue: 4963,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-01T02:24:52.033Z',
                                    metricValue: 4963,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-21T04:06:06.718Z',
                                    metricValue: 4951,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-13T02:20:54.982Z',
                                    metricValue: 4964,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-05T01:49:59.452Z',
                                    metricValue: 4956,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T01:42:50.735Z',
                                    metricValue: 4940,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-28T13:17:17.620Z',
                                    metricValue: 4940,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-27T22:40:00.853Z',
                                    metricValue: 4940,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-17T04:38:56.164Z',
                                    metricValue: 4912,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-16T05:13:17.190Z',
                                    metricValue: 4912,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-04T07:55:06.920Z',
                                    metricValue: 4894,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-23T02:22:09.789Z',
                                    metricValue: 4881,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T16:31:11.076Z',
                                    metricValue: 4859,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-03T06:39:48.595Z',
                                    metricValue: 4860,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-11-19T06:38:14.063Z',
                                    metricValue: 4857,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-12-01T00:00:01.000Z',
                                    metricValue: 4548,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-11-01T00:00:01.000Z',
                                    metricValue: 4537,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-10-01T00:00:01.000Z',
                                    metricValue: 4514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-09-01T00:00:01.000Z',
                                    metricValue: 4490,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-08-01T00:00:01.000Z',
                                    metricValue: 4472,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-07-01T00:00:01.000Z',
                                    metricValue: 4445,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-06-01T00:00:01.000Z',
                                    metricValue: 4417,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-05-01T00:00:01.000Z',
                                    metricValue: 4409,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-04-01T00:00:01.000Z',
                                    metricValue: 4388,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-03-01T00:00:01.000Z',
                                    metricValue: 4358,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-02-01T00:00:01.000Z',
                                    metricValue: 4328,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-01-01T00:00:01.000Z',
                                    metricValue: 4305,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-12-01T00:00:01.000Z',
                                    metricValue: 4286,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-11-01T00:00:01.000Z',
                                    metricValue: 4301,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-10-01T00:00:01.000Z',
                                    metricValue: 4275,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-09-01T00:00:01.000Z',
                                    metricValue: 4260,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-08-01T00:00:01.000Z',
                                    metricValue: 4172,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-07-01T00:00:01.000Z',
                                    metricValue: 4159,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-06-01T00:00:01.000Z',
                                    metricValue: 4091,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-05-01T00:00:01.000Z',
                                    metricValue: 4051,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-04-01T00:00:01.000Z',
                                    metricValue: 4013,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-03-01T00:00:01.000Z',
                                    metricValue: 3962,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-02-01T00:00:01.000Z',
                                    metricValue: 3920,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-01-01T00:00:01.000Z',
                                    metricValue: 3887,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2018-12-01T00:00:01.000Z',
                                    metricValue: 3840,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2021-10-01T00:00:00Z',
                endDate: null,
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Chief Executive Officer',
                department: 'Operations',
                description: "Like to grow 25x in a single year? Wish to over-achieve your hopes and dreams? Want to focus more on your skills and creations than paperworks and technicalities? Then welcome aboard! Let's achieve it together.",
                company: {
                    id: 2246535,
                    logoUrl: 'https://assets.harmonic.ai/company_9722b7baa5cd6e60d3a22c1b154a47376e29dcf5f80049eee6119abb5c08e8cd.png',
                    name: 'IT Perfectionists LLC',
                    headcount: 6,
                    funding: {
                        fundingTotal: '0',
                        fundingStage: null,
                        investors: [],
                        fundingRounds: [],
                        __typename: 'Funding'
                    },
                    highlights: [],
                    foundingDate: {
                        date: '2017-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/it-perfectionist',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 20,
                                value: 5,
                                change: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            metrics: [
                                {
                                    timestamp: '2023-09-26T03:35:50.012Z',
                                    metricValue: 6,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-25T02:02:52.044Z',
                                    metricValue: 6,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-10T01:57:44.195Z',
                                    metricValue: 6,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-09T02:48:12.925Z',
                                    metricValue: 6,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-20T03:20:20.544Z',
                                    metricValue: 6,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-19T04:01:21.598Z',
                                    metricValue: 6,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-07T07:56:24.638Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-06T02:41:25.288Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-01T01:30:30.768Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-31T20:49:11.640Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-27T13:29:46.255Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-26T01:45:45.318Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-15T03:12:02.442Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-14T05:57:14.339Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-03T04:04:16.865Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-02T03:11:43.774Z',
                                    metricValue: 5,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-02T01:41:21.644Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-01T02:08:02.311Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-05T19:28:44.284Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-19T07:26:36.624Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-17T01:48:17.223Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-02T01:48:32.403Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-01T03:17:20.790Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-24T02:12:06.405Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-11T03:39:30.467Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-28T04:51:00.748Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-20T03:06:46.245Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-12T03:39:02.438Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-04T02:05:55.874Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-28T10:19:49.083Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-27T23:42:05.848Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-26T08:44:20.753Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-17T02:25:43.877Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-16T03:31:13.082Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-04T07:32:20.167Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-24T03:16:16.469Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T17:41:15.094Z',
                                    metricValue: 1,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2017-09-01T00:00:00Z',
                endDate: null,
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Senior Quality Engineer',
                department: 'Engineering',
                description: "Working within PAC (Product Assurance & Certification) team, mainly on Billing and Business Intelligence (BI) projects. Main areas:BI - leading the verification process of BI portion of several new SWIFT initatives (processing and analysis of data from new payment platforms of various central banks, including European Central Bank, Reserve Bank of Australia or Bank of England) as well and SLA-related projects (showing main SWIFT performance measures towards its key partners). This includes…  Working within PAC (Product Assurance & Certification) team, mainly on Billing and Business Intelligence (BI) projects. Main areas:BI - leading the verification process of BI portion of several new SWIFT initatives (processing and analysis of data from new payment platforms of various central banks, including European Central Bank, Reserve Bank of Australia or Bank of England) as well and SLA-related projects (showing main SWIFT performance measures towards its key partners). This includes coordination of tasks with business owners, developers and other testers as well as preparing complex Regression Test Sets (including automated verifications) to properly validate the quality throughout the development of those releases.BILLING - I've prepared extensive Regression Sets to validate proper invoicing of main traffic of SWIFT (called FIN) towards its customers - all of them fully automated (both generation of messages and verification of results). Beside BI and Billing I have participated on other large projects - including Microstrategy reporting adoption. Salesforce CRM implementation and SAP integration. ",
                company: {
                    id: 207876,
                    logoUrl: 'https://assets.harmonic.ai/company_f57bf431177f70b676ce62dfaab6e81b97f4be235528116f22549e5e96c239f9.png',
                    name: 'Swift',
                    headcount: 4907,
                    funding: {
                        fundingTotal: '0',
                        fundingStage: null,
                        investors: [],
                        fundingRounds: [],
                        __typename: 'Funding'
                    },
                    highlights: [],
                    foundingDate: {
                        date: '1973-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/swift',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: null,
                            metrics: [
                                {
                                    timestamp: '2023-09-27T02:12:15.456Z',
                                    metricValue: 4907,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-26T02:10:29.261Z',
                                    metricValue: 4907,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-11T04:50:50.281Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-10T03:54:17.630Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T04:55:33.060Z',
                                    metricValue: 4921,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-22T04:34:35.137Z',
                                    metricValue: 4921,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-10T03:23:47.245Z',
                                    metricValue: 4917,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-09T04:45:46.070Z',
                                    metricValue: 4917,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-04T04:27:06.475Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-02T09:13:56.449Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-01T19:39:31.030Z',
                                    metricValue: 4913,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-19T01:40:22.112Z',
                                    metricValue: 4909,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-18T02:58:56.804Z',
                                    metricValue: 4909,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-07T03:11:39.480Z',
                                    metricValue: 4900,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-06T03:56:17.879Z',
                                    metricValue: 4900,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-28T05:43:32.014Z',
                                    metricValue: 4901,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-14T02:00:47.200Z',
                                    metricValue: 5107,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-13T23:50:28.752Z',
                                    metricValue: 5107,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-10T07:21:57.775Z',
                                    metricValue: 5107,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-28T04:44:26.163Z',
                                    metricValue: 5086,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-27T03:16:00.800Z',
                                    metricValue: 5086,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-30T01:43:54.605Z',
                                    metricValue: 4938,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-29T02:59:55.815Z',
                                    metricValue: 4938,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-27T01:36:08.642Z',
                                    metricValue: 4938,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-26T10:56:12.487Z',
                                    metricValue: 4938,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-25T19:26:57.038Z',
                                    metricValue: 4957,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-22T21:10:50.503Z',
                                    metricValue: 4957,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-21T14:05:46.427Z',
                                    metricValue: 4957,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-20T05:48:27.363Z',
                                    metricValue: 4957,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-05T13:28:36.060Z',
                                    metricValue: 5005,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-04T02:36:02.131Z',
                                    metricValue: 5005,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-25T03:40:29.429Z',
                                    metricValue: 5004,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-12T01:36:49.734Z',
                                    metricValue: 4977,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-03T02:27:00.545Z',
                                    metricValue: 4963,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-02T02:23:26.007Z',
                                    metricValue: 4963,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-01T02:24:52.033Z',
                                    metricValue: 4963,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-21T04:06:06.718Z',
                                    metricValue: 4951,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-13T02:20:54.982Z',
                                    metricValue: 4964,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-05T01:49:59.452Z',
                                    metricValue: 4956,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T01:42:50.735Z',
                                    metricValue: 4940,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-28T13:17:17.620Z',
                                    metricValue: 4940,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-27T22:40:00.853Z',
                                    metricValue: 4940,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-17T04:38:56.164Z',
                                    metricValue: 4912,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-16T05:13:17.190Z',
                                    metricValue: 4912,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-04T07:55:06.920Z',
                                    metricValue: 4894,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-23T02:22:09.789Z',
                                    metricValue: 4881,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T16:31:11.076Z',
                                    metricValue: 4859,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-03T06:39:48.595Z',
                                    metricValue: 4860,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-11-19T06:38:14.063Z',
                                    metricValue: 4857,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-12-01T00:00:01.000Z',
                                    metricValue: 4548,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-11-01T00:00:01.000Z',
                                    metricValue: 4537,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-10-01T00:00:01.000Z',
                                    metricValue: 4514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-09-01T00:00:01.000Z',
                                    metricValue: 4490,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-08-01T00:00:01.000Z',
                                    metricValue: 4472,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-07-01T00:00:01.000Z',
                                    metricValue: 4445,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-06-01T00:00:01.000Z',
                                    metricValue: 4417,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-05-01T00:00:01.000Z',
                                    metricValue: 4409,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-04-01T00:00:01.000Z',
                                    metricValue: 4388,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-03-01T00:00:01.000Z',
                                    metricValue: 4358,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-02-01T00:00:01.000Z',
                                    metricValue: 4328,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2020-01-01T00:00:01.000Z',
                                    metricValue: 4305,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-12-01T00:00:01.000Z',
                                    metricValue: 4286,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-11-01T00:00:01.000Z',
                                    metricValue: 4301,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-10-01T00:00:01.000Z',
                                    metricValue: 4275,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-09-01T00:00:01.000Z',
                                    metricValue: 4260,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-08-01T00:00:01.000Z',
                                    metricValue: 4172,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-07-01T00:00:01.000Z',
                                    metricValue: 4159,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-06-01T00:00:01.000Z',
                                    metricValue: 4091,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-05-01T00:00:01.000Z',
                                    metricValue: 4051,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-04-01T00:00:01.000Z',
                                    metricValue: 4013,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-03-01T00:00:01.000Z',
                                    metricValue: 3962,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-02-01T00:00:01.000Z',
                                    metricValue: 3920,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2019-01-01T00:00:01.000Z',
                                    metricValue: 3887,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2018-12-01T00:00:01.000Z',
                                    metricValue: 3840,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2012-11-01T00:00:00Z',
                endDate: '2018-12-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Project Development Manager',
                department: 'Operations',
                description: 'Helping our customers to make their software systems more effective and bug-free - from initial analysis, through development coordination, up to final quality checks. Complete software quality assurance and management.',
                company: {
                    id: -1,
                    logoUrl: null,
                    name: 'Top Testing s.r.o.',
                    headcount: null,
                    funding: {
                        fundingTotal: null,
                        fundingStage: null,
                        investors: null,
                        fundingRounds: [],
                        __typename: 'Funding'
                    },
                    highlights: [],
                    foundingDate: null,
                    socials: null,
                    tractionMetrics: {
                        headcount: null,
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2014-06-01T00:00:00Z',
                endDate: '2017-07-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Senior Test Analyst',
                department: 'Data',
                description: 'Preparing Test Plans and QTP Test Scripts for Unit and Integration Tests of new Core and Application Services of DHL Global Forwarding, especially XML parsing and checking. By preparing proper test approach and automation scripts, I was able to change the view of the Testing Team on this huge project and give them starting tools to make testing much more faster and effective.',
                company: {
                    id: 536124,
                    logoUrl: null,
                    name: 'DHL Supply Chain',
                    headcount: null,
                    funding: {
                        fundingTotal: '0',
                        fundingStage: null,
                        investors: [],
                        fundingRounds: [],
                        __typename: 'Funding'
                    },
                    highlights: [],
                    foundingDate: null,
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/dhl-express-norge',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: null,
                                value: null,
                                change: null,
                                __typename: 'TractionMetricsPoint'
                            },
                            metrics: [],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2012-07-01T00:00:00Z',
                endDate: '2012-10-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Senior Test Analyst',
                department: 'Data',
                description: 'Preparing Test Plans and QTP Test Scripts for Unit and Integration Tests of new Core and Application Services of DHL Global Forwarding, especially XML parsing and checking. By preparing proper test approach and automation scripts, I was able to change the view of the Testing Team on this huge project and give them starting tools to make testing much more faster and effective.',
                company: {
                    id: 10186077,
                    logoUrl: null,
                    name: 'DHL IT Services',
                    headcount: 3559,
                    funding: {
                        fundingTotal: '0',
                        fundingStage: null,
                        investors: [],
                        fundingRounds: [],
                        __typename: 'Funding'
                    },
                    highlights: [],
                    foundingDate: null,
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/dhl-it-services',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 1.2805919180421172,
                                value: 3514,
                                change: 45,
                                __typename: 'TractionMetricsPoint'
                            },
                            metrics: [
                                {
                                    timestamp: '2023-09-18T05:21:05.794Z',
                                    metricValue: 3559,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-17T01:31:35.898Z',
                                    metricValue: 3559,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-31T01:40:31.527Z',
                                    metricValue: 3553,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-17T04:56:52.986Z',
                                    metricValue: 3539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-16T03:08:53.299Z',
                                    metricValue: 3539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-05T02:08:00.242Z',
                                    metricValue: 3538,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-22T06:02:21.387Z',
                                    metricValue: 3530,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-21T01:30:09.839Z',
                                    metricValue: 3530,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-11T05:22:48.165Z',
                                    metricValue: 3531,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-30T02:19:06.553Z',
                                    metricValue: 3514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-29T04:21:31.389Z',
                                    metricValue: 3514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-28T04:54:53.558Z',
                                    metricValue: 3514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-13T17:46:51.869Z',
                                    metricValue: 3316,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-10T01:48:32.292Z',
                                    metricValue: 3316,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-29T04:33:33.428Z',
                                    metricValue: 3301,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-04T08:05:56.602Z',
                                    metricValue: 3201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-02T07:25:35.256Z',
                                    metricValue: 3207,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-27T07:57:07.444Z',
                                    metricValue: 3207,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-26T09:07:27.534Z',
                                    metricValue: 3207,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-25T22:32:26.122Z',
                                    metricValue: 3217,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-23T01:41:59.562Z',
                                    metricValue: 3217,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-21T14:13:19.074Z',
                                    metricValue: 3217,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-20T03:19:25.865Z',
                                    metricValue: 3217,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-05T13:31:38.171Z',
                                    metricValue: 3264,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-04T03:29:35.329Z',
                                    metricValue: 3264,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-25T03:42:10.281Z',
                                    metricValue: 3266,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-12T03:56:38.529Z',
                                    metricValue: 3259,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-03T03:40:25.666Z',
                                    metricValue: 3250,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-02T03:01:09.282Z',
                                    metricValue: 3250,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-01T04:35:40.524Z',
                                    metricValue: 3250,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-21T04:38:36.170Z',
                                    metricValue: 3243,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-13T04:32:36.699Z',
                                    metricValue: 3246,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-05T02:47:41.120Z',
                                    metricValue: 3239,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T02:56:09.083Z',
                                    metricValue: 3223,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-28T13:24:22.174Z',
                                    metricValue: 3223,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-27T23:45:58.956Z',
                                    metricValue: 3223,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-17T03:11:22.782Z',
                                    metricValue: 3206,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-16T03:38:14.438Z',
                                    metricValue: 3206,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-04T09:30:30.778Z',
                                    metricValue: 3212,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-23T02:41:36.432Z',
                                    metricValue: 3211,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T12:12:03.641Z',
                                    metricValue: 3207,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2012-07-01T00:00:00Z',
                endDate: '2012-10-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Technical Delivery Manager',
                department: 'Other',
                description: 'Responsible for coordination of various tasks within company, preparation of promotion materials and web pages, ensuring quick delivery and high quality of the products and satisfaction of the customers. I have prepared several web applications in cooperation with other programmers to support the business and to ensure proper and quick delivery.',
                company: {
                    id: 12856462,
                    logoUrl: null,
                    name: 'Dr.Vitamin',
                    headcount: 3,
                    funding: {
                        fundingTotal: '0',
                        fundingStage: null,
                        investors: [],
                        fundingRounds: [],
                        __typename: 'Funding'
                    },
                    highlights: [],
                    foundingDate: null,
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/dr-vitamin',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 50,
                                value: 2,
                                change: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            metrics: [
                                {
                                    timestamp: '2023-09-27T04:32:31.923Z',
                                    metricValue: 3,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-26T04:25:28.149Z',
                                    metricValue: 3,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-11T03:08:56.617Z',
                                    metricValue: 3,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-10T03:28:57.311Z',
                                    metricValue: 3,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T02:49:16.344Z',
                                    metricValue: 3,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-22T01:31:42.798Z',
                                    metricValue: 3,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-09T02:19:49.619Z',
                                    metricValue: 3,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-08T03:52:33.073Z',
                                    metricValue: 3,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-24T01:23:09.189Z',
                                    metricValue: 2,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-23T01:16:52.642Z',
                                    metricValue: 2,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-13T05:12:17.793Z',
                                    metricValue: 2,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2012-02-01T00:00:00Z',
                endDate: '2012-07-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Senior Testing Engineer for ABN AMRO Bank',
                department: 'Engineering',
                description: 'Preparation and execution of full flow for releases of new versions of Billing (CM, Rater, Billing, OIF), including Integrated System Test (IST), User Acceptance Tests (UAT) as well as Performance and Regression tests on Production-like environment in ABN AMRO Bank (Enabler 7). I also took important part on very succesfull testing and live migration of Fortis Bank customers to ABN AMRO Bank in 2 phases (private and business customers). I was awarded for this task by ABN AMRO managers.',
                company: {
                    id: 8607730,
                    logoUrl: 'https://media.licdn.com/dms/image/D4D0BAQHl00ZjxIp_kw/company-logo_200_200/0/1688279976770/amdocs_logo?e=2147483647&v=beta&t=MGn_6MSbjIDzP10lHeHQSli6U-relXY0ayY8SmDCFMw',
                    name: 'Amdocs',
                    headcount: 35666,
                    funding: {
                        fundingTotal: '73650000',
                        fundingStage: 'SERIES_C',
                        investors: [
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:681902',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Evergreen venture partners',
                                id: 681902
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:679379',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQE6gb1fzxAEKw/company-logo_200_200/0/1532274596122?e=2147483647&v=beta&t=nRpJtPXvzXJdSjdMZJl6PUcR70Y1qs35At7k59X60p8',
                                name: 'Rising Tide VC ',
                                id: 679379
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:13250224',
                                logoUrl: null,
                                name: 'ALE Rainbow',
                                id: 13250224
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:2127370',
                                logoUrl: null,
                                name: 'Newbury Ventures',
                                id: 2127370
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:725512',
                                logoUrl: 'https://assets.harmonic.ai/company_05c8a576ac6a7f3388c37bb54e29f0926fbc609268d17fa9c35a8ea64c4d3979.png',
                                name: 'StageOne Ventures',
                                id: 725512
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:754735',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQG53jzUY4rymg/company-logo_200_200/0/1556141283975?e=2147483647&v=beta&t=rrNuNJ03JKbe6OdfwrtGLKFhzWELfMNYNDtkxZZ7iZo',
                                name: 'Battery Ventures',
                                id: 754735
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:703319',
                                logoUrl: 'https://assets.harmonic.ai/company_7a96c27597e1995944d1540669858c46d9c60d10469f6a21cb048bc523aabc7b.png',
                                name: 'Accel',
                                id: 703319
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1497815',
                                logoUrl: null,
                                name: 'VenGrowth Asset Management',
                                id: 1497815
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3226124',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQG8U0EaYqTApg/company-logo_200_200/0/1519855866180?e=2147483647&v=beta&t=5tOmR1HpXC3q5fuwKd5b3jSK9-2lSu9Q0vqT-nFatlQ',
                                name: 'Sun Microsystems',
                                id: 3226124
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:671742',
                                logoUrl: 'https://assets.harmonic.ai/company_1b119c602d70c19d2cd17177be38e72722e75f7079721c0829ab113790503db2.png',
                                name: 'Norwest Venture Partners',
                                id: 671742
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:714458',
                                logoUrl: 'https://assets.harmonic.ai/company_8c6fc3dbe0629125d243e433e556d64e49862636546b9b2f896ad191726e0d5b.png',
                                name: 'Saints Capital',
                                id: 714458
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1517120',
                                logoUrl: 'https://assets.harmonic.ai/company_d7914bca6d3c7468c2555a648c13f6c2ea836b05e47613743ea061f8f3e89438.png',
                                name: 'Holland Capital',
                                id: 1517120
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1893740',
                                logoUrl: 'https://assets.harmonic.ai/company_8e9e9c3ace0b02a7082381839bbd65b5e37524893a15b5ff4333f8a36855818f.png',
                                name: 'Hyperion Israel Venture Partners',
                                id: 1893740
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:699591',
                                logoUrl: null,
                                name: 'Hyperion Israel Venture Partners',
                                id: 699591
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1716872',
                                logoUrl: null,
                                name: 'John Risley',
                                id: 1716872
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:724799',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Covington Capital Corporation',
                                id: 724799
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:10046181',
                                logoUrl: 'https://assets.harmonic.ai/company_f0c93c6d89c272369e32501cd5119ba0e6ffecd4ed2695309b3ac6938953511a.png',
                                name: 'PineBridge Investments',
                                id: 10046181
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3673701',
                                logoUrl: 'https://assets.harmonic.ai/company_317faf771da6818f855c1842ec62912796efd1d67eb3f75b95e0ad389008417e.png',
                                name: 'Sequoia Capital',
                                id: 3673701
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:7404511',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Alcatel Lucent Managed Services (Application support and analysis)',
                                id: 7404511
                            }
                        ],
                        fundingRounds: [
                            {
                                announcementDate: '2009-02-10T00:00:00.000Z',
                                fundingAmount: '19650000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'SERIES_C',
                                sourceUrl: null,
                                investors: [
                                    {
                                        entityUrn: 'urn:harmonic:company:3673701',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:3673701',
                                            logoUrl: null,
                                            name: 'Sequoia Capital',
                                            id: 3673701,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Sequoia Capital',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:681902',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:681902',
                                            logoUrl: null,
                                            name: 'Evergreen venture partners',
                                            id: 681902,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Evergreen venture partners',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:671742',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:671742',
                                            logoUrl: null,
                                            name: 'Norwest Venture Partners',
                                            id: 671742,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Norwest Venture Partners',
                                        isLead: true,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:703319',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:703319',
                                            logoUrl: null,
                                            name: 'Accel',
                                            id: 703319,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Accel',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    }
                                ],
                                __typename: 'FundingRound'
                            },
                            {
                                announcementDate: '2007-02-08T00:00:00.000Z',
                                fundingAmount: '54000000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'M_AND_A',
                                sourceUrl: null,
                                investors: [],
                                __typename: 'FundingRound'
                            }
                        ],
                        __typename: 'Funding'
                    },
                    highlights: [
                        {
                            category: 'VENTURE_BACKED',
                            text: 'Venture Backed, Amdocs: Backed By Covington Capital Corporation, VenGrowth Asset Management, Rising Tide VC , ALE Rainbow, Newbury Ventures, Alcatel Lucent Managed Services (Application support and analysis), John Risley, Battery Ventures, Hyperion Israel Venture Partners, StageOne Ventures, GMP Securities, Sprott, Sprott Asset Management, Saints Capital, Sun Microsystems, Holland Capital, PineBridge Investments, Accel, Evergreen venture partners, Norwest Venture Partners, Sequoia Capital (Total Funding: Undisclosed)',
                            __typename: 'Highlight'
                        }
                    ],
                    foundingDate: {
                        date: '1982-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/amdocs',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 0.07014393535534919,
                                value: 35641,
                                change: 25,
                                __typename: 'TractionMetricsPoint'
                            },
                            metrics: [
                                {
                                    timestamp: '2023-09-28T02:05:05.136Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-27T03:07:30.067Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-12T01:27:18.684Z',
                                    metricValue: 35808,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-24T06:30:34.296Z',
                                    metricValue: 35780,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T01:14:42.741Z',
                                    metricValue: 35745,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-12T02:15:04.746Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-11T05:39:16.623Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-10T02:39:14.768Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-02T20:24:51.590Z',
                                    metricValue: 35670,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-20T03:36:51.018Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-19T10:54:58.856Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-08T04:15:02.583Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-07T04:06:25.970Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-06T02:01:53.000Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-26T16:03:18.822Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-25T01:09:43.287Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-09T03:47:09.064Z',
                                    metricValue: 36092,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-28T01:23:45.784Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-27T01:25:06.342Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-26T03:38:29.315Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-25T04:30:49.612Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-24T13:31:18.430Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-29T02:26:42.798Z',
                                    metricValue: 34954,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-16T07:32:34.542Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-15T04:32:03.387Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-13T11:32:45.154Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-07T21:53:12.168Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-28T02:53:13.841Z',
                                    metricValue: 35445,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-20T03:05:55.250Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-19T02:18:14.974Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-18T02:32:07.575Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-17T02:30:42.469Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-07T01:29:35.261Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-24T03:01:56.897Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-16T04:27:29.161Z',
                                    metricValue: 35513,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-08T03:17:31.491Z',
                                    metricValue: 35514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T07:22:38.980Z',
                                    metricValue: 35495,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-18T06:45:41.842Z',
                                    metricValue: 38711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-05T04:45:55.714Z',
                                    metricValue: 38726,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-24T01:51:22.029Z',
                                    metricValue: 43335,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T17:27:15.029Z',
                                    metricValue: 52612,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2010-09-01T00:00:00Z',
                endDate: '2012-02-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Production Support Manager',
                department: 'Support',
                description: 'Full on-going support for MPS in Ensemble for Orange Switzerland (level 3 and 4 support) - initial analysis of problems in Production, teaching level 2 support people (from EDS, Hungary), analysis of new change requests in the system as well as preparation of solutions for MPS problems and coordinating MPS developers. Cooperation on various tasks with Orange Testing Team.',
                company: {
                    id: 8607730,
                    logoUrl: 'https://media.licdn.com/dms/image/D4D0BAQHl00ZjxIp_kw/company-logo_200_200/0/1688279976770/amdocs_logo?e=2147483647&v=beta&t=MGn_6MSbjIDzP10lHeHQSli6U-relXY0ayY8SmDCFMw',
                    name: 'Amdocs',
                    headcount: 35666,
                    funding: {
                        fundingTotal: '73650000',
                        fundingStage: 'SERIES_C',
                        investors: [
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:681902',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Evergreen venture partners',
                                id: 681902
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:679379',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQE6gb1fzxAEKw/company-logo_200_200/0/1532274596122?e=2147483647&v=beta&t=nRpJtPXvzXJdSjdMZJl6PUcR70Y1qs35At7k59X60p8',
                                name: 'Rising Tide VC ',
                                id: 679379
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:13250224',
                                logoUrl: null,
                                name: 'ALE Rainbow',
                                id: 13250224
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:2127370',
                                logoUrl: null,
                                name: 'Newbury Ventures',
                                id: 2127370
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:725512',
                                logoUrl: 'https://assets.harmonic.ai/company_05c8a576ac6a7f3388c37bb54e29f0926fbc609268d17fa9c35a8ea64c4d3979.png',
                                name: 'StageOne Ventures',
                                id: 725512
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:754735',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQG53jzUY4rymg/company-logo_200_200/0/1556141283975?e=2147483647&v=beta&t=rrNuNJ03JKbe6OdfwrtGLKFhzWELfMNYNDtkxZZ7iZo',
                                name: 'Battery Ventures',
                                id: 754735
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:703319',
                                logoUrl: 'https://assets.harmonic.ai/company_7a96c27597e1995944d1540669858c46d9c60d10469f6a21cb048bc523aabc7b.png',
                                name: 'Accel',
                                id: 703319
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1497815',
                                logoUrl: null,
                                name: 'VenGrowth Asset Management',
                                id: 1497815
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3226124',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQG8U0EaYqTApg/company-logo_200_200/0/1519855866180?e=2147483647&v=beta&t=5tOmR1HpXC3q5fuwKd5b3jSK9-2lSu9Q0vqT-nFatlQ',
                                name: 'Sun Microsystems',
                                id: 3226124
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:671742',
                                logoUrl: 'https://assets.harmonic.ai/company_1b119c602d70c19d2cd17177be38e72722e75f7079721c0829ab113790503db2.png',
                                name: 'Norwest Venture Partners',
                                id: 671742
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:714458',
                                logoUrl: 'https://assets.harmonic.ai/company_8c6fc3dbe0629125d243e433e556d64e49862636546b9b2f896ad191726e0d5b.png',
                                name: 'Saints Capital',
                                id: 714458
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1517120',
                                logoUrl: 'https://assets.harmonic.ai/company_d7914bca6d3c7468c2555a648c13f6c2ea836b05e47613743ea061f8f3e89438.png',
                                name: 'Holland Capital',
                                id: 1517120
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1893740',
                                logoUrl: 'https://assets.harmonic.ai/company_8e9e9c3ace0b02a7082381839bbd65b5e37524893a15b5ff4333f8a36855818f.png',
                                name: 'Hyperion Israel Venture Partners',
                                id: 1893740
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:699591',
                                logoUrl: null,
                                name: 'Hyperion Israel Venture Partners',
                                id: 699591
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1716872',
                                logoUrl: null,
                                name: 'John Risley',
                                id: 1716872
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:724799',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Covington Capital Corporation',
                                id: 724799
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:10046181',
                                logoUrl: 'https://assets.harmonic.ai/company_f0c93c6d89c272369e32501cd5119ba0e6ffecd4ed2695309b3ac6938953511a.png',
                                name: 'PineBridge Investments',
                                id: 10046181
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3673701',
                                logoUrl: 'https://assets.harmonic.ai/company_317faf771da6818f855c1842ec62912796efd1d67eb3f75b95e0ad389008417e.png',
                                name: 'Sequoia Capital',
                                id: 3673701
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:7404511',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Alcatel Lucent Managed Services (Application support and analysis)',
                                id: 7404511
                            }
                        ],
                        fundingRounds: [
                            {
                                announcementDate: '2009-02-10T00:00:00.000Z',
                                fundingAmount: '19650000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'SERIES_C',
                                sourceUrl: null,
                                investors: [
                                    {
                                        entityUrn: 'urn:harmonic:company:3673701',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:3673701',
                                            logoUrl: null,
                                            name: 'Sequoia Capital',
                                            id: 3673701,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Sequoia Capital',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:681902',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:681902',
                                            logoUrl: null,
                                            name: 'Evergreen venture partners',
                                            id: 681902,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Evergreen venture partners',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:671742',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:671742',
                                            logoUrl: null,
                                            name: 'Norwest Venture Partners',
                                            id: 671742,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Norwest Venture Partners',
                                        isLead: true,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:703319',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:703319',
                                            logoUrl: null,
                                            name: 'Accel',
                                            id: 703319,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Accel',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    }
                                ],
                                __typename: 'FundingRound'
                            },
                            {
                                announcementDate: '2007-02-08T00:00:00.000Z',
                                fundingAmount: '54000000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'M_AND_A',
                                sourceUrl: null,
                                investors: [],
                                __typename: 'FundingRound'
                            }
                        ],
                        __typename: 'Funding'
                    },
                    highlights: [
                        {
                            category: 'VENTURE_BACKED',
                            text: 'Venture Backed, Amdocs: Backed By Covington Capital Corporation, VenGrowth Asset Management, Rising Tide VC , ALE Rainbow, Newbury Ventures, Alcatel Lucent Managed Services (Application support and analysis), John Risley, Battery Ventures, Hyperion Israel Venture Partners, StageOne Ventures, GMP Securities, Sprott, Sprott Asset Management, Saints Capital, Sun Microsystems, Holland Capital, PineBridge Investments, Accel, Evergreen venture partners, Norwest Venture Partners, Sequoia Capital (Total Funding: Undisclosed)',
                            __typename: 'Highlight'
                        }
                    ],
                    foundingDate: {
                        date: '1982-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/amdocs',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: null,
                            metrics: [
                                {
                                    timestamp: '2023-09-28T02:05:05.136Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-27T03:07:30.067Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-12T01:27:18.684Z',
                                    metricValue: 35808,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-24T06:30:34.296Z',
                                    metricValue: 35780,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T01:14:42.741Z',
                                    metricValue: 35745,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-12T02:15:04.746Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-11T05:39:16.623Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-10T02:39:14.768Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-02T20:24:51.590Z',
                                    metricValue: 35670,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-20T03:36:51.018Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-19T10:54:58.856Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-08T04:15:02.583Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-07T04:06:25.970Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-06T02:01:53.000Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-26T16:03:18.822Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-25T01:09:43.287Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-09T03:47:09.064Z',
                                    metricValue: 36092,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-28T01:23:45.784Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-27T01:25:06.342Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-26T03:38:29.315Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-25T04:30:49.612Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-24T13:31:18.430Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-29T02:26:42.798Z',
                                    metricValue: 34954,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-16T07:32:34.542Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-15T04:32:03.387Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-13T11:32:45.154Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-07T21:53:12.168Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-28T02:53:13.841Z',
                                    metricValue: 35445,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-20T03:05:55.250Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-19T02:18:14.974Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-18T02:32:07.575Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-17T02:30:42.469Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-07T01:29:35.261Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-24T03:01:56.897Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-16T04:27:29.161Z',
                                    metricValue: 35513,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-08T03:17:31.491Z',
                                    metricValue: 35514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T07:22:38.980Z',
                                    metricValue: 35495,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-18T06:45:41.842Z',
                                    metricValue: 38711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-05T04:45:55.714Z',
                                    metricValue: 38726,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-24T01:51:22.029Z',
                                    metricValue: 43335,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T17:27:15.029Z',
                                    metricValue: 52612,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2008-06-01T00:00:00Z',
                endDate: '2010-08-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Integration Tester for Orange Swiss',
                department: 'Engineering',
                description: 'Tester for Orange, Switzerland. Testing migration of Billing flow from Ensamble to Enabler 7, from initial testing of various functions and events to final Regression and Performance tests, which included precise comparison of various results in databases.',
                company: {
                    id: 8607730,
                    logoUrl: 'https://media.licdn.com/dms/image/D4D0BAQHl00ZjxIp_kw/company-logo_200_200/0/1688279976770/amdocs_logo?e=2147483647&v=beta&t=MGn_6MSbjIDzP10lHeHQSli6U-relXY0ayY8SmDCFMw',
                    name: 'Amdocs',
                    headcount: 35666,
                    funding: {
                        fundingTotal: '73650000',
                        fundingStage: 'SERIES_C',
                        investors: [
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:681902',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Evergreen venture partners',
                                id: 681902
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:679379',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQE6gb1fzxAEKw/company-logo_200_200/0/1532274596122?e=2147483647&v=beta&t=nRpJtPXvzXJdSjdMZJl6PUcR70Y1qs35At7k59X60p8',
                                name: 'Rising Tide VC ',
                                id: 679379
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:13250224',
                                logoUrl: null,
                                name: 'ALE Rainbow',
                                id: 13250224
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:2127370',
                                logoUrl: null,
                                name: 'Newbury Ventures',
                                id: 2127370
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:725512',
                                logoUrl: 'https://assets.harmonic.ai/company_05c8a576ac6a7f3388c37bb54e29f0926fbc609268d17fa9c35a8ea64c4d3979.png',
                                name: 'StageOne Ventures',
                                id: 725512
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:754735',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQG53jzUY4rymg/company-logo_200_200/0/1556141283975?e=2147483647&v=beta&t=rrNuNJ03JKbe6OdfwrtGLKFhzWELfMNYNDtkxZZ7iZo',
                                name: 'Battery Ventures',
                                id: 754735
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:703319',
                                logoUrl: 'https://assets.harmonic.ai/company_7a96c27597e1995944d1540669858c46d9c60d10469f6a21cb048bc523aabc7b.png',
                                name: 'Accel',
                                id: 703319
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1497815',
                                logoUrl: null,
                                name: 'VenGrowth Asset Management',
                                id: 1497815
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3226124',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQG8U0EaYqTApg/company-logo_200_200/0/1519855866180?e=2147483647&v=beta&t=5tOmR1HpXC3q5fuwKd5b3jSK9-2lSu9Q0vqT-nFatlQ',
                                name: 'Sun Microsystems',
                                id: 3226124
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:671742',
                                logoUrl: 'https://assets.harmonic.ai/company_1b119c602d70c19d2cd17177be38e72722e75f7079721c0829ab113790503db2.png',
                                name: 'Norwest Venture Partners',
                                id: 671742
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:714458',
                                logoUrl: 'https://assets.harmonic.ai/company_8c6fc3dbe0629125d243e433e556d64e49862636546b9b2f896ad191726e0d5b.png',
                                name: 'Saints Capital',
                                id: 714458
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1517120',
                                logoUrl: 'https://assets.harmonic.ai/company_d7914bca6d3c7468c2555a648c13f6c2ea836b05e47613743ea061f8f3e89438.png',
                                name: 'Holland Capital',
                                id: 1517120
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1893740',
                                logoUrl: 'https://assets.harmonic.ai/company_8e9e9c3ace0b02a7082381839bbd65b5e37524893a15b5ff4333f8a36855818f.png',
                                name: 'Hyperion Israel Venture Partners',
                                id: 1893740
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:699591',
                                logoUrl: null,
                                name: 'Hyperion Israel Venture Partners',
                                id: 699591
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1716872',
                                logoUrl: null,
                                name: 'John Risley',
                                id: 1716872
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:724799',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Covington Capital Corporation',
                                id: 724799
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:10046181',
                                logoUrl: 'https://assets.harmonic.ai/company_f0c93c6d89c272369e32501cd5119ba0e6ffecd4ed2695309b3ac6938953511a.png',
                                name: 'PineBridge Investments',
                                id: 10046181
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3673701',
                                logoUrl: 'https://assets.harmonic.ai/company_317faf771da6818f855c1842ec62912796efd1d67eb3f75b95e0ad389008417e.png',
                                name: 'Sequoia Capital',
                                id: 3673701
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:7404511',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Alcatel Lucent Managed Services (Application support and analysis)',
                                id: 7404511
                            }
                        ],
                        fundingRounds: [
                            {
                                announcementDate: '2009-02-10T00:00:00.000Z',
                                fundingAmount: '19650000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'SERIES_C',
                                sourceUrl: null,
                                investors: [
                                    {
                                        entityUrn: 'urn:harmonic:company:3673701',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:3673701',
                                            logoUrl: null,
                                            name: 'Sequoia Capital',
                                            id: 3673701,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Sequoia Capital',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:681902',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:681902',
                                            logoUrl: null,
                                            name: 'Evergreen venture partners',
                                            id: 681902,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Evergreen venture partners',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:671742',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:671742',
                                            logoUrl: null,
                                            name: 'Norwest Venture Partners',
                                            id: 671742,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Norwest Venture Partners',
                                        isLead: true,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:703319',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:703319',
                                            logoUrl: null,
                                            name: 'Accel',
                                            id: 703319,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Accel',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    }
                                ],
                                __typename: 'FundingRound'
                            },
                            {
                                announcementDate: '2007-02-08T00:00:00.000Z',
                                fundingAmount: '54000000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'M_AND_A',
                                sourceUrl: null,
                                investors: [],
                                __typename: 'FundingRound'
                            }
                        ],
                        __typename: 'Funding'
                    },
                    highlights: [
                        {
                            category: 'VENTURE_BACKED',
                            text: 'Venture Backed, Amdocs: Backed By Covington Capital Corporation, VenGrowth Asset Management, Rising Tide VC , ALE Rainbow, Newbury Ventures, Alcatel Lucent Managed Services (Application support and analysis), John Risley, Battery Ventures, Hyperion Israel Venture Partners, StageOne Ventures, GMP Securities, Sprott, Sprott Asset Management, Saints Capital, Sun Microsystems, Holland Capital, PineBridge Investments, Accel, Evergreen venture partners, Norwest Venture Partners, Sequoia Capital (Total Funding: Undisclosed)',
                            __typename: 'Highlight'
                        }
                    ],
                    foundingDate: {
                        date: '1982-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/amdocs',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: null,
                            metrics: [
                                {
                                    timestamp: '2023-09-28T02:05:05.136Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-27T03:07:30.067Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-12T01:27:18.684Z',
                                    metricValue: 35808,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-24T06:30:34.296Z',
                                    metricValue: 35780,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T01:14:42.741Z',
                                    metricValue: 35745,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-12T02:15:04.746Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-11T05:39:16.623Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-10T02:39:14.768Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-02T20:24:51.590Z',
                                    metricValue: 35670,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-20T03:36:51.018Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-19T10:54:58.856Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-08T04:15:02.583Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-07T04:06:25.970Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-06T02:01:53.000Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-26T16:03:18.822Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-25T01:09:43.287Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-09T03:47:09.064Z',
                                    metricValue: 36092,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-28T01:23:45.784Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-27T01:25:06.342Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-26T03:38:29.315Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-25T04:30:49.612Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-24T13:31:18.430Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-29T02:26:42.798Z',
                                    metricValue: 34954,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-16T07:32:34.542Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-15T04:32:03.387Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-13T11:32:45.154Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-07T21:53:12.168Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-28T02:53:13.841Z',
                                    metricValue: 35445,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-20T03:05:55.250Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-19T02:18:14.974Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-18T02:32:07.575Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-17T02:30:42.469Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-07T01:29:35.261Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-24T03:01:56.897Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-16T04:27:29.161Z',
                                    metricValue: 35513,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-08T03:17:31.491Z',
                                    metricValue: 35514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T07:22:38.980Z',
                                    metricValue: 35495,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-18T06:45:41.842Z',
                                    metricValue: 38711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-05T04:45:55.714Z',
                                    metricValue: 38726,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-24T01:51:22.029Z',
                                    metricValue: 43335,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T17:27:15.029Z',
                                    metricValue: 52612,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2008-01-01T00:00:00Z',
                endDate: '2008-01-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Rater Developer',
                department: 'Engineering',
                description: 'Rater Developer for T-Mobile Czech Republic and Telefonica O2 Czech Republic - preparing scripts and functions for Rater 7 as well as setting up Product Catalog in Enabler 7 at T-Mobile. Preparing new functionality to control switches at TO2CZ.',
                company: {
                    id: 8607730,
                    logoUrl: 'https://media.licdn.com/dms/image/D4D0BAQHl00ZjxIp_kw/company-logo_200_200/0/1688279976770/amdocs_logo?e=2147483647&v=beta&t=MGn_6MSbjIDzP10lHeHQSli6U-relXY0ayY8SmDCFMw',
                    name: 'Amdocs',
                    headcount: 35666,
                    funding: {
                        fundingTotal: '73650000',
                        fundingStage: 'SERIES_C',
                        investors: [
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:681902',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Evergreen venture partners',
                                id: 681902
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:679379',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQE6gb1fzxAEKw/company-logo_200_200/0/1532274596122?e=2147483647&v=beta&t=nRpJtPXvzXJdSjdMZJl6PUcR70Y1qs35At7k59X60p8',
                                name: 'Rising Tide VC ',
                                id: 679379
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:13250224',
                                logoUrl: null,
                                name: 'ALE Rainbow',
                                id: 13250224
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:2127370',
                                logoUrl: null,
                                name: 'Newbury Ventures',
                                id: 2127370
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:725512',
                                logoUrl: 'https://assets.harmonic.ai/company_05c8a576ac6a7f3388c37bb54e29f0926fbc609268d17fa9c35a8ea64c4d3979.png',
                                name: 'StageOne Ventures',
                                id: 725512
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:754735',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQG53jzUY4rymg/company-logo_200_200/0/1556141283975?e=2147483647&v=beta&t=rrNuNJ03JKbe6OdfwrtGLKFhzWELfMNYNDtkxZZ7iZo',
                                name: 'Battery Ventures',
                                id: 754735
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:703319',
                                logoUrl: 'https://assets.harmonic.ai/company_7a96c27597e1995944d1540669858c46d9c60d10469f6a21cb048bc523aabc7b.png',
                                name: 'Accel',
                                id: 703319
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1497815',
                                logoUrl: null,
                                name: 'VenGrowth Asset Management',
                                id: 1497815
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3226124',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQG8U0EaYqTApg/company-logo_200_200/0/1519855866180?e=2147483647&v=beta&t=5tOmR1HpXC3q5fuwKd5b3jSK9-2lSu9Q0vqT-nFatlQ',
                                name: 'Sun Microsystems',
                                id: 3226124
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:671742',
                                logoUrl: 'https://assets.harmonic.ai/company_1b119c602d70c19d2cd17177be38e72722e75f7079721c0829ab113790503db2.png',
                                name: 'Norwest Venture Partners',
                                id: 671742
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:714458',
                                logoUrl: 'https://assets.harmonic.ai/company_8c6fc3dbe0629125d243e433e556d64e49862636546b9b2f896ad191726e0d5b.png',
                                name: 'Saints Capital',
                                id: 714458
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1517120',
                                logoUrl: 'https://assets.harmonic.ai/company_d7914bca6d3c7468c2555a648c13f6c2ea836b05e47613743ea061f8f3e89438.png',
                                name: 'Holland Capital',
                                id: 1517120
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1893740',
                                logoUrl: 'https://assets.harmonic.ai/company_8e9e9c3ace0b02a7082381839bbd65b5e37524893a15b5ff4333f8a36855818f.png',
                                name: 'Hyperion Israel Venture Partners',
                                id: 1893740
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:699591',
                                logoUrl: null,
                                name: 'Hyperion Israel Venture Partners',
                                id: 699591
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1716872',
                                logoUrl: null,
                                name: 'John Risley',
                                id: 1716872
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:724799',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Covington Capital Corporation',
                                id: 724799
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:10046181',
                                logoUrl: 'https://assets.harmonic.ai/company_f0c93c6d89c272369e32501cd5119ba0e6ffecd4ed2695309b3ac6938953511a.png',
                                name: 'PineBridge Investments',
                                id: 10046181
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3673701',
                                logoUrl: 'https://assets.harmonic.ai/company_317faf771da6818f855c1842ec62912796efd1d67eb3f75b95e0ad389008417e.png',
                                name: 'Sequoia Capital',
                                id: 3673701
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:7404511',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Alcatel Lucent Managed Services (Application support and analysis)',
                                id: 7404511
                            }
                        ],
                        fundingRounds: [
                            {
                                announcementDate: '2009-02-10T00:00:00.000Z',
                                fundingAmount: '19650000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'SERIES_C',
                                sourceUrl: null,
                                investors: [
                                    {
                                        entityUrn: 'urn:harmonic:company:3673701',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:3673701',
                                            logoUrl: null,
                                            name: 'Sequoia Capital',
                                            id: 3673701,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Sequoia Capital',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:681902',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:681902',
                                            logoUrl: null,
                                            name: 'Evergreen venture partners',
                                            id: 681902,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Evergreen venture partners',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:671742',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:671742',
                                            logoUrl: null,
                                            name: 'Norwest Venture Partners',
                                            id: 671742,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Norwest Venture Partners',
                                        isLead: true,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:703319',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:703319',
                                            logoUrl: null,
                                            name: 'Accel',
                                            id: 703319,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Accel',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    }
                                ],
                                __typename: 'FundingRound'
                            },
                            {
                                announcementDate: '2007-02-08T00:00:00.000Z',
                                fundingAmount: '54000000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'M_AND_A',
                                sourceUrl: null,
                                investors: [],
                                __typename: 'FundingRound'
                            }
                        ],
                        __typename: 'Funding'
                    },
                    highlights: [
                        {
                            category: 'VENTURE_BACKED',
                            text: 'Venture Backed, Amdocs: Backed By Covington Capital Corporation, VenGrowth Asset Management, Rising Tide VC , ALE Rainbow, Newbury Ventures, Alcatel Lucent Managed Services (Application support and analysis), John Risley, Battery Ventures, Hyperion Israel Venture Partners, StageOne Ventures, GMP Securities, Sprott, Sprott Asset Management, Saints Capital, Sun Microsystems, Holland Capital, PineBridge Investments, Accel, Evergreen venture partners, Norwest Venture Partners, Sequoia Capital (Total Funding: Undisclosed)',
                            __typename: 'Highlight'
                        }
                    ],
                    foundingDate: {
                        date: '1982-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/amdocs',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: null,
                            metrics: [
                                {
                                    timestamp: '2023-09-28T02:05:05.136Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-27T03:07:30.067Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-12T01:27:18.684Z',
                                    metricValue: 35808,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-24T06:30:34.296Z',
                                    metricValue: 35780,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T01:14:42.741Z',
                                    metricValue: 35745,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-12T02:15:04.746Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-11T05:39:16.623Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-10T02:39:14.768Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-02T20:24:51.590Z',
                                    metricValue: 35670,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-20T03:36:51.018Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-19T10:54:58.856Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-08T04:15:02.583Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-07T04:06:25.970Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-06T02:01:53.000Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-26T16:03:18.822Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-25T01:09:43.287Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-09T03:47:09.064Z',
                                    metricValue: 36092,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-28T01:23:45.784Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-27T01:25:06.342Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-26T03:38:29.315Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-25T04:30:49.612Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-24T13:31:18.430Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-29T02:26:42.798Z',
                                    metricValue: 34954,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-16T07:32:34.542Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-15T04:32:03.387Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-13T11:32:45.154Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-07T21:53:12.168Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-28T02:53:13.841Z',
                                    metricValue: 35445,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-20T03:05:55.250Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-19T02:18:14.974Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-18T02:32:07.575Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-17T02:30:42.469Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-07T01:29:35.261Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-24T03:01:56.897Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-16T04:27:29.161Z',
                                    metricValue: 35513,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-08T03:17:31.491Z',
                                    metricValue: 35514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T07:22:38.980Z',
                                    metricValue: 35495,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-18T06:45:41.842Z',
                                    metricValue: 38711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-05T04:45:55.714Z',
                                    metricValue: 38726,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-24T01:51:22.029Z',
                                    metricValue: 43335,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T17:27:15.029Z',
                                    metricValue: 52612,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2008-01-01T00:00:00Z',
                endDate: '2008-01-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Billing Tester for Comcast, USA',
                department: 'Finance',
                description: 'Tester (Senior Matter Expert) for Comcast, USA - working in migration of Comcast Billing to Amdocs systems - side-by-side implementation with various Comcast systems and Amdocs Enabler 7.',
                company: {
                    id: 8607730,
                    logoUrl: 'https://media.licdn.com/dms/image/D4D0BAQHl00ZjxIp_kw/company-logo_200_200/0/1688279976770/amdocs_logo?e=2147483647&v=beta&t=MGn_6MSbjIDzP10lHeHQSli6U-relXY0ayY8SmDCFMw',
                    name: 'Amdocs',
                    headcount: 35666,
                    funding: {
                        fundingTotal: '73650000',
                        fundingStage: 'SERIES_C',
                        investors: [
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:681902',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Evergreen venture partners',
                                id: 681902
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:679379',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQE6gb1fzxAEKw/company-logo_200_200/0/1532274596122?e=2147483647&v=beta&t=nRpJtPXvzXJdSjdMZJl6PUcR70Y1qs35At7k59X60p8',
                                name: 'Rising Tide VC ',
                                id: 679379
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:13250224',
                                logoUrl: null,
                                name: 'ALE Rainbow',
                                id: 13250224
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:2127370',
                                logoUrl: null,
                                name: 'Newbury Ventures',
                                id: 2127370
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:725512',
                                logoUrl: 'https://assets.harmonic.ai/company_05c8a576ac6a7f3388c37bb54e29f0926fbc609268d17fa9c35a8ea64c4d3979.png',
                                name: 'StageOne Ventures',
                                id: 725512
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:754735',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQG53jzUY4rymg/company-logo_200_200/0/1556141283975?e=2147483647&v=beta&t=rrNuNJ03JKbe6OdfwrtGLKFhzWELfMNYNDtkxZZ7iZo',
                                name: 'Battery Ventures',
                                id: 754735
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:703319',
                                logoUrl: 'https://assets.harmonic.ai/company_7a96c27597e1995944d1540669858c46d9c60d10469f6a21cb048bc523aabc7b.png',
                                name: 'Accel',
                                id: 703319
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1497815',
                                logoUrl: null,
                                name: 'VenGrowth Asset Management',
                                id: 1497815
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3226124',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQG8U0EaYqTApg/company-logo_200_200/0/1519855866180?e=2147483647&v=beta&t=5tOmR1HpXC3q5fuwKd5b3jSK9-2lSu9Q0vqT-nFatlQ',
                                name: 'Sun Microsystems',
                                id: 3226124
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:671742',
                                logoUrl: 'https://assets.harmonic.ai/company_1b119c602d70c19d2cd17177be38e72722e75f7079721c0829ab113790503db2.png',
                                name: 'Norwest Venture Partners',
                                id: 671742
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:714458',
                                logoUrl: 'https://assets.harmonic.ai/company_8c6fc3dbe0629125d243e433e556d64e49862636546b9b2f896ad191726e0d5b.png',
                                name: 'Saints Capital',
                                id: 714458
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1517120',
                                logoUrl: 'https://assets.harmonic.ai/company_d7914bca6d3c7468c2555a648c13f6c2ea836b05e47613743ea061f8f3e89438.png',
                                name: 'Holland Capital',
                                id: 1517120
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1893740',
                                logoUrl: 'https://assets.harmonic.ai/company_8e9e9c3ace0b02a7082381839bbd65b5e37524893a15b5ff4333f8a36855818f.png',
                                name: 'Hyperion Israel Venture Partners',
                                id: 1893740
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:699591',
                                logoUrl: null,
                                name: 'Hyperion Israel Venture Partners',
                                id: 699591
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1716872',
                                logoUrl: null,
                                name: 'John Risley',
                                id: 1716872
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:724799',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Covington Capital Corporation',
                                id: 724799
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:10046181',
                                logoUrl: 'https://assets.harmonic.ai/company_f0c93c6d89c272369e32501cd5119ba0e6ffecd4ed2695309b3ac6938953511a.png',
                                name: 'PineBridge Investments',
                                id: 10046181
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3673701',
                                logoUrl: 'https://assets.harmonic.ai/company_317faf771da6818f855c1842ec62912796efd1d67eb3f75b95e0ad389008417e.png',
                                name: 'Sequoia Capital',
                                id: 3673701
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:7404511',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Alcatel Lucent Managed Services (Application support and analysis)',
                                id: 7404511
                            }
                        ],
                        fundingRounds: [
                            {
                                announcementDate: '2009-02-10T00:00:00.000Z',
                                fundingAmount: '19650000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'SERIES_C',
                                sourceUrl: null,
                                investors: [
                                    {
                                        entityUrn: 'urn:harmonic:company:3673701',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:3673701',
                                            logoUrl: null,
                                            name: 'Sequoia Capital',
                                            id: 3673701,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Sequoia Capital',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:681902',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:681902',
                                            logoUrl: null,
                                            name: 'Evergreen venture partners',
                                            id: 681902,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Evergreen venture partners',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:671742',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:671742',
                                            logoUrl: null,
                                            name: 'Norwest Venture Partners',
                                            id: 671742,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Norwest Venture Partners',
                                        isLead: true,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:703319',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:703319',
                                            logoUrl: null,
                                            name: 'Accel',
                                            id: 703319,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Accel',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    }
                                ],
                                __typename: 'FundingRound'
                            },
                            {
                                announcementDate: '2007-02-08T00:00:00.000Z',
                                fundingAmount: '54000000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'M_AND_A',
                                sourceUrl: null,
                                investors: [],
                                __typename: 'FundingRound'
                            }
                        ],
                        __typename: 'Funding'
                    },
                    highlights: [
                        {
                            category: 'VENTURE_BACKED',
                            text: 'Venture Backed, Amdocs: Backed By Covington Capital Corporation, VenGrowth Asset Management, Rising Tide VC , ALE Rainbow, Newbury Ventures, Alcatel Lucent Managed Services (Application support and analysis), John Risley, Battery Ventures, Hyperion Israel Venture Partners, StageOne Ventures, GMP Securities, Sprott, Sprott Asset Management, Saints Capital, Sun Microsystems, Holland Capital, PineBridge Investments, Accel, Evergreen venture partners, Norwest Venture Partners, Sequoia Capital (Total Funding: Undisclosed)',
                            __typename: 'Highlight'
                        }
                    ],
                    foundingDate: {
                        date: '1982-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/amdocs',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: null,
                            metrics: [
                                {
                                    timestamp: '2023-09-28T02:05:05.136Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-27T03:07:30.067Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-12T01:27:18.684Z',
                                    metricValue: 35808,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-24T06:30:34.296Z',
                                    metricValue: 35780,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T01:14:42.741Z',
                                    metricValue: 35745,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-12T02:15:04.746Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-11T05:39:16.623Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-10T02:39:14.768Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-02T20:24:51.590Z',
                                    metricValue: 35670,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-20T03:36:51.018Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-19T10:54:58.856Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-08T04:15:02.583Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-07T04:06:25.970Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-06T02:01:53.000Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-26T16:03:18.822Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-25T01:09:43.287Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-09T03:47:09.064Z',
                                    metricValue: 36092,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-28T01:23:45.784Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-27T01:25:06.342Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-26T03:38:29.315Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-25T04:30:49.612Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-24T13:31:18.430Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-29T02:26:42.798Z',
                                    metricValue: 34954,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-16T07:32:34.542Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-15T04:32:03.387Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-13T11:32:45.154Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-07T21:53:12.168Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-28T02:53:13.841Z',
                                    metricValue: 35445,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-20T03:05:55.250Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-19T02:18:14.974Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-18T02:32:07.575Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-17T02:30:42.469Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-07T01:29:35.261Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-24T03:01:56.897Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-16T04:27:29.161Z',
                                    metricValue: 35513,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-08T03:17:31.491Z',
                                    metricValue: 35514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T07:22:38.980Z',
                                    metricValue: 35495,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-18T06:45:41.842Z',
                                    metricValue: 38711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-05T04:45:55.714Z',
                                    metricValue: 38726,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-24T01:51:22.029Z',
                                    metricValue: 43335,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T17:27:15.029Z',
                                    metricValue: 52612,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2008-01-01T00:00:00Z',
                endDate: '2008-01-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Tester (Senior Matter Expert) for T-Mobile Czech',
                department: 'Engineering',
                description: 'Tester for T-Mobile Czech Republic (Billing) - testing Enabler 5.5 and later on working on succesfull migration to Enabler 7.0 (first project to upgrade to Ena 7 in the world). After the upgrade I was working on testing and developing new Change Requests in Rater area, like Credit (Money) Tariffs and unlimited rolling of Credits.',
                company: {
                    id: 8607730,
                    logoUrl: 'https://media.licdn.com/dms/image/D4D0BAQHl00ZjxIp_kw/company-logo_200_200/0/1688279976770/amdocs_logo?e=2147483647&v=beta&t=MGn_6MSbjIDzP10lHeHQSli6U-relXY0ayY8SmDCFMw',
                    name: 'Amdocs',
                    headcount: 35666,
                    funding: {
                        fundingTotal: '73650000',
                        fundingStage: 'SERIES_C',
                        investors: [
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:681902',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Evergreen venture partners',
                                id: 681902
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:679379',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQE6gb1fzxAEKw/company-logo_200_200/0/1532274596122?e=2147483647&v=beta&t=nRpJtPXvzXJdSjdMZJl6PUcR70Y1qs35At7k59X60p8',
                                name: 'Rising Tide VC ',
                                id: 679379
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:13250224',
                                logoUrl: null,
                                name: 'ALE Rainbow',
                                id: 13250224
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:2127370',
                                logoUrl: null,
                                name: 'Newbury Ventures',
                                id: 2127370
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:725512',
                                logoUrl: 'https://assets.harmonic.ai/company_05c8a576ac6a7f3388c37bb54e29f0926fbc609268d17fa9c35a8ea64c4d3979.png',
                                name: 'StageOne Ventures',
                                id: 725512
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:754735',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQG53jzUY4rymg/company-logo_200_200/0/1556141283975?e=2147483647&v=beta&t=rrNuNJ03JKbe6OdfwrtGLKFhzWELfMNYNDtkxZZ7iZo',
                                name: 'Battery Ventures',
                                id: 754735
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:703319',
                                logoUrl: 'https://assets.harmonic.ai/company_7a96c27597e1995944d1540669858c46d9c60d10469f6a21cb048bc523aabc7b.png',
                                name: 'Accel',
                                id: 703319
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1497815',
                                logoUrl: null,
                                name: 'VenGrowth Asset Management',
                                id: 1497815
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3226124',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQG8U0EaYqTApg/company-logo_200_200/0/1519855866180?e=2147483647&v=beta&t=5tOmR1HpXC3q5fuwKd5b3jSK9-2lSu9Q0vqT-nFatlQ',
                                name: 'Sun Microsystems',
                                id: 3226124
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:671742',
                                logoUrl: 'https://assets.harmonic.ai/company_1b119c602d70c19d2cd17177be38e72722e75f7079721c0829ab113790503db2.png',
                                name: 'Norwest Venture Partners',
                                id: 671742
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:714458',
                                logoUrl: 'https://assets.harmonic.ai/company_8c6fc3dbe0629125d243e433e556d64e49862636546b9b2f896ad191726e0d5b.png',
                                name: 'Saints Capital',
                                id: 714458
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1517120',
                                logoUrl: 'https://assets.harmonic.ai/company_d7914bca6d3c7468c2555a648c13f6c2ea836b05e47613743ea061f8f3e89438.png',
                                name: 'Holland Capital',
                                id: 1517120
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1893740',
                                logoUrl: 'https://assets.harmonic.ai/company_8e9e9c3ace0b02a7082381839bbd65b5e37524893a15b5ff4333f8a36855818f.png',
                                name: 'Hyperion Israel Venture Partners',
                                id: 1893740
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:699591',
                                logoUrl: null,
                                name: 'Hyperion Israel Venture Partners',
                                id: 699591
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1716872',
                                logoUrl: null,
                                name: 'John Risley',
                                id: 1716872
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:724799',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Covington Capital Corporation',
                                id: 724799
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:10046181',
                                logoUrl: 'https://assets.harmonic.ai/company_f0c93c6d89c272369e32501cd5119ba0e6ffecd4ed2695309b3ac6938953511a.png',
                                name: 'PineBridge Investments',
                                id: 10046181
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3673701',
                                logoUrl: 'https://assets.harmonic.ai/company_317faf771da6818f855c1842ec62912796efd1d67eb3f75b95e0ad389008417e.png',
                                name: 'Sequoia Capital',
                                id: 3673701
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:7404511',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Alcatel Lucent Managed Services (Application support and analysis)',
                                id: 7404511
                            }
                        ],
                        fundingRounds: [
                            {
                                announcementDate: '2009-02-10T00:00:00.000Z',
                                fundingAmount: '19650000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'SERIES_C',
                                sourceUrl: null,
                                investors: [
                                    {
                                        entityUrn: 'urn:harmonic:company:3673701',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:3673701',
                                            logoUrl: null,
                                            name: 'Sequoia Capital',
                                            id: 3673701,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Sequoia Capital',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:681902',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:681902',
                                            logoUrl: null,
                                            name: 'Evergreen venture partners',
                                            id: 681902,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Evergreen venture partners',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:671742',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:671742',
                                            logoUrl: null,
                                            name: 'Norwest Venture Partners',
                                            id: 671742,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Norwest Venture Partners',
                                        isLead: true,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:703319',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:703319',
                                            logoUrl: null,
                                            name: 'Accel',
                                            id: 703319,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Accel',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    }
                                ],
                                __typename: 'FundingRound'
                            },
                            {
                                announcementDate: '2007-02-08T00:00:00.000Z',
                                fundingAmount: '54000000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'M_AND_A',
                                sourceUrl: null,
                                investors: [],
                                __typename: 'FundingRound'
                            }
                        ],
                        __typename: 'Funding'
                    },
                    highlights: [
                        {
                            category: 'VENTURE_BACKED',
                            text: 'Venture Backed, Amdocs: Backed By Covington Capital Corporation, VenGrowth Asset Management, Rising Tide VC , ALE Rainbow, Newbury Ventures, Alcatel Lucent Managed Services (Application support and analysis), John Risley, Battery Ventures, Hyperion Israel Venture Partners, StageOne Ventures, GMP Securities, Sprott, Sprott Asset Management, Saints Capital, Sun Microsystems, Holland Capital, PineBridge Investments, Accel, Evergreen venture partners, Norwest Venture Partners, Sequoia Capital (Total Funding: Undisclosed)',
                            __typename: 'Highlight'
                        }
                    ],
                    foundingDate: {
                        date: '1982-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/amdocs',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: null,
                            metrics: [
                                {
                                    timestamp: '2023-09-28T02:05:05.136Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-27T03:07:30.067Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-12T01:27:18.684Z',
                                    metricValue: 35808,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-24T06:30:34.296Z',
                                    metricValue: 35780,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T01:14:42.741Z',
                                    metricValue: 35745,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-12T02:15:04.746Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-11T05:39:16.623Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-10T02:39:14.768Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-02T20:24:51.590Z',
                                    metricValue: 35670,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-20T03:36:51.018Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-19T10:54:58.856Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-08T04:15:02.583Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-07T04:06:25.970Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-06T02:01:53.000Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-26T16:03:18.822Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-25T01:09:43.287Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-09T03:47:09.064Z',
                                    metricValue: 36092,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-28T01:23:45.784Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-27T01:25:06.342Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-26T03:38:29.315Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-25T04:30:49.612Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-24T13:31:18.430Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-29T02:26:42.798Z',
                                    metricValue: 34954,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-16T07:32:34.542Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-15T04:32:03.387Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-13T11:32:45.154Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-07T21:53:12.168Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-28T02:53:13.841Z',
                                    metricValue: 35445,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-20T03:05:55.250Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-19T02:18:14.974Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-18T02:32:07.575Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-17T02:30:42.469Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-07T01:29:35.261Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-24T03:01:56.897Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-16T04:27:29.161Z',
                                    metricValue: 35513,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-08T03:17:31.491Z',
                                    metricValue: 35514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T07:22:38.980Z',
                                    metricValue: 35495,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-18T06:45:41.842Z',
                                    metricValue: 38711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-05T04:45:55.714Z',
                                    metricValue: 38726,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-24T01:51:22.029Z',
                                    metricValue: 43335,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T17:27:15.029Z',
                                    metricValue: 52612,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2007-01-01T00:00:00Z',
                endDate: '2008-01-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            },
            {
                title: 'Billing Tester for Pannon, Hungary',
                department: 'Finance',
                description: 'Tester (Senior Matter Expert) for Pannon, Hungary - Billing (side-by-side, Enabler and Ensemble) - basic sanity testing and testing of new hotfixes and releases',
                company: {
                    id: 8607730,
                    logoUrl: 'https://media.licdn.com/dms/image/D4D0BAQHl00ZjxIp_kw/company-logo_200_200/0/1688279976770/amdocs_logo?e=2147483647&v=beta&t=MGn_6MSbjIDzP10lHeHQSli6U-relXY0ayY8SmDCFMw',
                    name: 'Amdocs',
                    headcount: 35666,
                    funding: {
                        fundingTotal: '73650000',
                        fundingStage: 'SERIES_C',
                        investors: [
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:681902',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Evergreen venture partners',
                                id: 681902
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:679379',
                                logoUrl: 'https://media.licdn.com/dms/image/C560BAQE6gb1fzxAEKw/company-logo_200_200/0/1532274596122?e=2147483647&v=beta&t=nRpJtPXvzXJdSjdMZJl6PUcR70Y1qs35At7k59X60p8',
                                name: 'Rising Tide VC ',
                                id: 679379
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:13250224',
                                logoUrl: null,
                                name: 'ALE Rainbow',
                                id: 13250224
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:2127370',
                                logoUrl: null,
                                name: 'Newbury Ventures',
                                id: 2127370
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:725512',
                                logoUrl: 'https://assets.harmonic.ai/company_05c8a576ac6a7f3388c37bb54e29f0926fbc609268d17fa9c35a8ea64c4d3979.png',
                                name: 'StageOne Ventures',
                                id: 725512
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:754735',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQG53jzUY4rymg/company-logo_200_200/0/1556141283975?e=2147483647&v=beta&t=rrNuNJ03JKbe6OdfwrtGLKFhzWELfMNYNDtkxZZ7iZo',
                                name: 'Battery Ventures',
                                id: 754735
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:703319',
                                logoUrl: 'https://assets.harmonic.ai/company_7a96c27597e1995944d1540669858c46d9c60d10469f6a21cb048bc523aabc7b.png',
                                name: 'Accel',
                                id: 703319
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1497815',
                                logoUrl: null,
                                name: 'VenGrowth Asset Management',
                                id: 1497815
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3226124',
                                logoUrl: 'https://media.licdn.com/dms/image/C4D0BAQG8U0EaYqTApg/company-logo_200_200/0/1519855866180?e=2147483647&v=beta&t=5tOmR1HpXC3q5fuwKd5b3jSK9-2lSu9Q0vqT-nFatlQ',
                                name: 'Sun Microsystems',
                                id: 3226124
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:671742',
                                logoUrl: 'https://assets.harmonic.ai/company_1b119c602d70c19d2cd17177be38e72722e75f7079721c0829ab113790503db2.png',
                                name: 'Norwest Venture Partners',
                                id: 671742
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:714458',
                                logoUrl: 'https://assets.harmonic.ai/company_8c6fc3dbe0629125d243e433e556d64e49862636546b9b2f896ad191726e0d5b.png',
                                name: 'Saints Capital',
                                id: 714458
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1517120',
                                logoUrl: 'https://assets.harmonic.ai/company_d7914bca6d3c7468c2555a648c13f6c2ea836b05e47613743ea061f8f3e89438.png',
                                name: 'Holland Capital',
                                id: 1517120
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1893740',
                                logoUrl: 'https://assets.harmonic.ai/company_8e9e9c3ace0b02a7082381839bbd65b5e37524893a15b5ff4333f8a36855818f.png',
                                name: 'Hyperion Israel Venture Partners',
                                id: 1893740
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:699591',
                                logoUrl: null,
                                name: 'Hyperion Israel Venture Partners',
                                id: 699591
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:1716872',
                                logoUrl: null,
                                name: 'John Risley',
                                id: 1716872
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:724799',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Covington Capital Corporation',
                                id: 724799
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:10046181',
                                logoUrl: 'https://assets.harmonic.ai/company_f0c93c6d89c272369e32501cd5119ba0e6ffecd4ed2695309b3ac6938953511a.png',
                                name: 'PineBridge Investments',
                                id: 10046181
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:3673701',
                                logoUrl: 'https://assets.harmonic.ai/company_317faf771da6818f855c1842ec62912796efd1d67eb3f75b95e0ad389008417e.png',
                                name: 'Sequoia Capital',
                                id: 3673701
                            },
                            {
                                __typename: 'Company',
                                companyUrn: 'urn:harmonic:company:7404511',
                                logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                                name: 'Alcatel Lucent Managed Services (Application support and analysis)',
                                id: 7404511
                            }
                        ],
                        fundingRounds: [
                            {
                                announcementDate: '2009-02-10T00:00:00.000Z',
                                fundingAmount: '19650000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'SERIES_C',
                                sourceUrl: null,
                                investors: [
                                    {
                                        entityUrn: 'urn:harmonic:company:3673701',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:3673701',
                                            logoUrl: null,
                                            name: 'Sequoia Capital',
                                            id: 3673701,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Sequoia Capital',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:681902',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:681902',
                                            logoUrl: null,
                                            name: 'Evergreen venture partners',
                                            id: 681902,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Evergreen venture partners',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:671742',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:671742',
                                            logoUrl: null,
                                            name: 'Norwest Venture Partners',
                                            id: 671742,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Norwest Venture Partners',
                                        isLead: true,
                                        __typename: 'FundingRoundInvestor'
                                    },
                                    {
                                        entityUrn: 'urn:harmonic:company:703319',
                                        investor: {
                                            companyUrn: 'urn:harmonic:company:703319',
                                            logoUrl: null,
                                            name: 'Accel',
                                            id: 703319,
                                            __typename: 'Company'
                                        },
                                        investorName: 'Accel',
                                        isLead: false,
                                        __typename: 'FundingRoundInvestor'
                                    }
                                ],
                                __typename: 'FundingRound'
                            },
                            {
                                announcementDate: '2007-02-08T00:00:00.000Z',
                                fundingAmount: '54000000',
                                fundingCurrency: 'USD',
                                fundingRoundType: 'M_AND_A',
                                sourceUrl: null,
                                investors: [],
                                __typename: 'FundingRound'
                            }
                        ],
                        __typename: 'Funding'
                    },
                    highlights: [
                        {
                            category: 'VENTURE_BACKED',
                            text: 'Venture Backed, Amdocs: Backed By Covington Capital Corporation, VenGrowth Asset Management, Rising Tide VC , ALE Rainbow, Newbury Ventures, Alcatel Lucent Managed Services (Application support and analysis), John Risley, Battery Ventures, Hyperion Israel Venture Partners, StageOne Ventures, GMP Securities, Sprott, Sprott Asset Management, Saints Capital, Sun Microsystems, Holland Capital, PineBridge Investments, Accel, Evergreen venture partners, Norwest Venture Partners, Sequoia Capital (Total Funding: Undisclosed)',
                            __typename: 'Highlight'
                        }
                    ],
                    foundingDate: {
                        date: '1982-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/company/amdocs',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    tractionMetrics: {
                        headcount: {
                            ago90d: null,
                            metrics: [
                                {
                                    timestamp: '2023-09-28T02:05:05.136Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-27T03:07:30.067Z',
                                    metricValue: 35666,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-09-12T01:27:18.684Z',
                                    metricValue: 35808,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-24T06:30:34.296Z',
                                    metricValue: 35780,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-23T01:14:42.741Z',
                                    metricValue: 35745,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-12T02:15:04.746Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-11T05:39:16.623Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-10T02:39:14.768Z',
                                    metricValue: 35711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-08-02T20:24:51.590Z',
                                    metricValue: 35670,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-20T03:36:51.018Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-19T10:54:58.856Z',
                                    metricValue: 35574,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-08T04:15:02.583Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-07T04:06:25.970Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-07-06T02:01:53.000Z',
                                    metricValue: 35539,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-26T16:03:18.822Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-25T01:09:43.287Z',
                                    metricValue: 35701,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-06-09T03:47:09.064Z',
                                    metricValue: 36092,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-28T01:23:45.784Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-27T01:25:06.342Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-26T03:38:29.315Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-25T04:30:49.612Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-05-24T13:31:18.430Z',
                                    metricValue: 35722,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-29T02:26:42.798Z',
                                    metricValue: 34954,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-16T07:32:34.542Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-15T04:32:03.387Z',
                                    metricValue: 35201,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-13T11:32:45.154Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-04-07T21:53:12.168Z',
                                    metricValue: 35280,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-28T02:53:13.841Z',
                                    metricValue: 35445,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-20T03:05:55.250Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-19T02:18:14.974Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-18T02:32:07.575Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-17T02:30:42.469Z',
                                    metricValue: 35477,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-03-07T01:29:35.261Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-24T03:01:56.897Z',
                                    metricValue: 35510,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-16T04:27:29.161Z',
                                    metricValue: 35513,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-02-08T03:17:31.491Z',
                                    metricValue: 35514,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-29T07:22:38.980Z',
                                    metricValue: 35495,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-18T06:45:41.842Z',
                                    metricValue: 38711,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2023-01-05T04:45:55.714Z',
                                    metricValue: 38726,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-24T01:51:22.029Z',
                                    metricValue: 43335,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                },
                                {
                                    timestamp: '2022-12-07T17:27:15.029Z',
                                    metricValue: 52612,
                                    __typename: 'TractionMetricsTimeSeriesMetric'
                                }
                            ],
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    __typename: 'Company'
                },
                startDate: '2006-10-01T00:00:00Z',
                endDate: '2007-01-01T00:00:00Z',
                isCurrentPosition: false,
                __typename: 'ExperienceMetadata'
            }
        ],
        __typename: 'Person'
    }
};
const GET_PERSON_EMPTY_EXPERIENCE_MOCK = {
    getPersonById: {
        id: 1,
        experience: []
    }
};
export { GET_PERSON_EMPTY_EXPERIENCE_MOCK, GET_PERSON_EXPERIENCE_MOCK };
