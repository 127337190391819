import { PeopleWatchlistType } from '__generated__/graphql';
const getPeopleWatchlistsForTeam = {
    getPeopleWatchlistsForTeam: [
        {
            owner: {
                entityUrn: 'urn:harmonic:user:411',
                email: 'samrat.luintel.58@gmail.com',
                __typename: 'User'
            },
            id: 'cf92259e-5620-4a05-ab27-fbf6c29bfa74',
            entityUrn: 'urn:harmonic:people_watchlist:cf92259e-5620-4a05-ab27-fbf6c29bfa74',
            name: 'test',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:6124',
                email: 'aantorik@harmonic.ai',
                __typename: 'User'
            },
            id: '6db89fb8-4712-4d0e-bd75-1d7a99523157',
            entityUrn: 'urn:harmonic:people_watchlist:6db89fb8-4712-4d0e-bd75-1d7a99523157',
            name: 'Harmonic Team',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:4253',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: '398eb362-5bed-4429-95de-0805619436f1',
            entityUrn: 'urn:harmonic:people_watchlist:398eb362-5bed-4429-95de-0805619436f1',
            name: 'People with updates',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:4253',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: 'c4e6bd0b-f44e-45ac-ab09-02a1f0b7f6b4',
            entityUrn: 'urn:harmonic:people_watchlist:c4e6bd0b-f44e-45ac-ab09-02a1f0b7f6b4',
            name: 'test',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:4253',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: '532c1fc3-7ad8-4255-8d64-bb258fe0a263',
            entityUrn: 'urn:harmonic:people_watchlist:532c1fc3-7ad8-4255-8d64-bb258fe0a263',
            name: 'foothill list',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:4253',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: 'c7a6c9ad-0ad2-4fad-b2d4-173e3a405838',
            entityUrn: 'urn:harmonic:people_watchlist:c7a6c9ad-0ad2-4fad-b2d4-173e3a405838',
            name: 'Notable Engineers',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:4253',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: 'c7a6c9ad-0ad2-4fad-b2d4-173e3a405828',
            entityUrn: 'urn:harmonic:people_watchlist:c7a6c9ad-0ad2-4fad-b2d4-173e3a405838',
            name: 'Team Connections',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.TEAM_CONNECTIONS,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:4253',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: 'c7a6c9ad-0ad2-4fad-b2d4-173e3a405138',
            entityUrn: 'urn:harmonic:people_watchlist:c7a6c9ad-0ad2-4fad-b2d4-173e3a405838',
            name: 'User Connections',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_CONNECTIONS,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        }
    ]
};
export const GET_PEOPLE_WATCHLISTS_CONNECTIONS_MOCKED_RESPONSE = {
    getPeopleWatchlistsForTeam: [
        {
            owner: {
                entityUrn: 'urn:harmonic:user:2885',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: '5ab230cc-f93a-4cfb-82f7-25bcf3fe6664',
            entityUrn: 'urn:harmonic:people_watchlist:5ab230cc-f93a-4cfb-82f7-25bcf3fe6664',
            name: 'My Connections',
            sharedWithTeam: false,
            __typename: 'PeopleWatchlist',
            userWatchlistType: PeopleWatchlistType.USER_CONNECTIONS,
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:2885',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: '5bb230cc-f93a-4cfb-82f7-25bcf3fe6664',
            entityUrn: 'urn:harmonic:people_watchlist:5ab230cc-f93a-4cfb-82f7-25bcf3fe6664',
            name: 'My Connections',
            sharedWithTeam: false,
            __typename: 'PeopleWatchlist',
            userWatchlistType: PeopleWatchlistType.TEAM_CONNECTIONS,
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        }
    ]
};
export const GET_PEOPLE_WATCHLISTS_MOCKED_RESPONSE = {
    getPeopleWatchlistsForTeam: [
        {
            owner: {
                entityUrn: 'urn:harmonic:user:4253',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: 'c4e6bd0b-f44e-45ac-ab09-02a1f0b7f6b4',
            entityUrn: 'urn:harmonic:people_watchlist:c4e6bd0b-f44e-45ac-ab09-02a1f0b7f6b4',
            name: 'test',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:4253',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: '532c1fc3-7ad8-4255-8d64-bb258fe0a263',
            entityUrn: 'urn:harmonic:people_watchlist:532c1fc3-7ad8-4255-8d64-bb258fe0a263',
            name: 'foothill list',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:4253',
                email: 'ali@harmonic.ai',
                __typename: 'User'
            },
            id: 'c7a6c9ad-0ad2-4fad-b2d4-173e3a405838',
            entityUrn: 'urn:harmonic:people_watchlist:c7a6c9ad-0ad2-4fad-b2d4-173e3a405838',
            name: 'Notable Engineers',
            sharedWithTeam: true,
            userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
            __typename: 'PeopleWatchlist',
            customFields: [],
            namedViews: [],
            namedViewsOrder: []
        }
    ]
};
export default getPeopleWatchlistsForTeam;
