const getCompanyFoundersMock = {
    getEmployeesByCompanyId: {
        edges: [
            {
                cursor: 'urn:harmonic:person:52536',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    linkedinHeadline: 'CTO & Founder at 9fin',
                    fullName: 'Huss E.',
                    id: 52536,
                    profilePictureUrl: 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry',
                    education: [
                        {
                            school: {
                                name: 'University of Bristol',
                                entityUrn: 'urn:harmonic:school:1fc4f78c-1480-43c1-8229-381382fba6e4',
                                __typename: 'School'
                            },
                            degree: null,
                            startDate: '2008-01-01T00:00:00.000Z',
                            endDate: '2012-01-01T00:00:00.000Z',
                            __typename: 'EducationMetadata'
                        },
                        {
                            school: {
                                name: 'Bristol University',
                                entityUrn: 'urn:harmonic:school:1fc4f78c-1480-43c1-8229-381382fba6e4',
                                __typename: 'School'
                            },
                            degree: 'Aerospace Engineering MEng',
                            startDate: '2008-01-01T00:00:00.000Z',
                            endDate: '2012-01-01T00:00:00.000Z',
                            __typename: 'EducationMetadata'
                        }
                    ],
                    highlights: [
                        {
                            text: 'Elite Industry Experience, Huss E.: Business Analyst @ Deutsche Bank, 9/2012-10/2016',
                            category: 'Elite Industry Experience',
                            __typename: 'PersonHighlight'
                        }
                    ],
                    experience: [
                        {
                            title: 'CTO & Founder',
                            endDate: null,
                            startDate: '2016-11-01T00:00:00Z',
                            isCurrentPosition: true,
                            company: {
                                name: '9fin',
                                logoUrl: 'https://assets.harmonic.ai/company_4b227777d4dd1fc61c6f884f48641d02b4d121d3fd328cb08b5531fcacdabf8a.png',
                                id: 4,
                                highlights: [
                                    {
                                        text: 'Venture Backed, 9fin: Backed By Tech Nation, Fly Ventures, 500 Global, Redalpine, Kintsugi, Michael Orland, Adfisco, Paul Forster, Alan Morgan, Ilavska Vuillermoz Capital, Spark Capital, Google for Startups EU, Seedcamp (Total Funding: $37,171,257)',
                                        category: 'VENTURE_BACKED',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Business Analyst',
                            endDate: '2016-10-01T00:00:00Z',
                            startDate: '2012-09-01T00:00:00Z',
                            isCurrentPosition: null,
                            company: {
                                name: 'Deutsche Bank',
                                logoUrl: 'https://assets.harmonic.ai/company_1564a003bb57891940c830b3b8997f18ea5ffdacd7dba949df14d9e0d9047363.png',
                                id: 2138655,
                                highlights: [
                                    {
                                        text: 'Venture Backed, Deutsche Bank: Backed By Capital Group, Cerberus Capital Management, Element Resources, Interpublic Group (IPG), Sandler Capital Management, Mannix Marketing, Scottrade (Total Funding: Undisclosed)',
                                        category: 'VENTURE_BACKED',
                                        __typename: 'Highlight'
                                    },
                                    {
                                        text: 'Strong Team Growth, Deutsche Bank: 67470+ employees in < 151 years.',
                                        category: 'STRONG_TEAM_GROWTH',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Business Analyst',
                            endDate: '2016-10-01T00:00:00Z',
                            startDate: '2012-09-01T00:00:00Z',
                            isCurrentPosition: false,
                            company: {
                                name: 'Deutsche Bank',
                                logoUrl: null,
                                id: 10178486,
                                highlights: [],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Head of Technology',
                            endDate: '2012-08-01T00:00:00Z',
                            startDate: '2011-03-01T00:00:00Z',
                            isCurrentPosition: null,
                            company: {
                                name: 'Bristol Investment Society',
                                logoUrl: 'https://assets.harmonic.ai/company_8625dc81c040d3366ca40c04ebbeab732b9c2c3181e6296730cc9da4c75df53d.png',
                                id: 2085820,
                                highlights: [],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Summer Intern, Commodities Technology',
                            endDate: '2011-08-01T00:00:00Z',
                            startDate: '2011-06-01T00:00:00Z',
                            isCurrentPosition: false,
                            company: {
                                name: 'Deutsche Bank',
                                logoUrl: 'https://assets.harmonic.ai/company_1564a003bb57891940c830b3b8997f18ea5ffdacd7dba949df14d9e0d9047363.png',
                                id: 2138655,
                                highlights: [
                                    {
                                        text: 'Venture Backed, Deutsche Bank: Backed By Capital Group, Cerberus Capital Management, Element Resources, Interpublic Group (IPG), Sandler Capital Management, Mannix Marketing, Scottrade (Total Funding: Undisclosed)',
                                        category: 'VENTURE_BACKED',
                                        __typename: 'Highlight'
                                    },
                                    {
                                        text: 'Strong Team Growth, Deutsche Bank: 67470+ employees in < 151 years.',
                                        category: 'STRONG_TEAM_GROWTH',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Summer Intern, Commodities Technology',
                            endDate: '2011-08-01T00:00:00Z',
                            startDate: '2011-06-01T00:00:00Z',
                            isCurrentPosition: false,
                            company: {
                                name: 'Deutsche Bank',
                                logoUrl: null,
                                id: 10178486,
                                highlights: [],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Joint masters research project with Rolls-Royce',
                            endDate: '2011-06-01T00:00:00Z',
                            startDate: '2010-10-01T00:00:00Z',
                            isCurrentPosition: null,
                            company: {
                                name: 'Rolls-Royce',
                                logoUrl: 'https://assets.harmonic.ai/company_fcea054a72dea45acbbff56a42ed81734640436356b55319ea8ae0169979dd43.png',
                                id: 202394,
                                highlights: [
                                    {
                                        text: 'Venture Backed, Rolls-Royce: Backed By Exelon, BNF Resources, US Navy (Total Funding: $265,103,694)',
                                        category: 'VENTURE_BACKED',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Joint masters research project with Rolls-Royce',
                            endDate: '2011-06-01T00:00:00Z',
                            startDate: '2010-10-01T00:00:00Z',
                            isCurrentPosition: false,
                            company: {
                                name: 'Rolls-Royce',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQF-emwjlZ__pw/company-logo_200_200/0/1621241527116?e=2147483647&v=beta&t=90o8rtlqv1VnD3dczzcdzlUYWtp6HXhO0zQAWgF-iM0',
                                id: 4038508,
                                highlights: [],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Summer Intern, Advanced Projects',
                            endDate: '2010-08-01T00:00:00Z',
                            startDate: '2010-06-01T00:00:00Z',
                            isCurrentPosition: null,
                            company: {
                                name: 'Rolls-Royce',
                                logoUrl: 'https://assets.harmonic.ai/company_fcea054a72dea45acbbff56a42ed81734640436356b55319ea8ae0169979dd43.png',
                                id: 202394,
                                highlights: [
                                    {
                                        text: 'Venture Backed, Rolls-Royce: Backed By Exelon, BNF Resources, US Navy (Total Funding: $265,103,694)',
                                        category: 'VENTURE_BACKED',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Summer Intern, Advanced Projects',
                            endDate: '2010-08-01T00:00:00Z',
                            startDate: '2010-06-01T00:00:00Z',
                            isCurrentPosition: false,
                            company: {
                                name: 'Rolls-Royce',
                                logoUrl: 'https://media.licdn.com/dms/image/C4E0BAQF-emwjlZ__pw/company-logo_200_200/0/1621241527116?e=2147483647&v=beta&t=90o8rtlqv1VnD3dczzcdzlUYWtp6HXhO0zQAWgF-iM0',
                                id: 4038508,
                                highlights: [],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            },
            {
                cursor: 'urn:harmonic:person:52535',
                node: {
                    socials: {
                        linkedin: null,
                        __typename: 'Socials'
                    },
                    userConnections: [],
                    linkedinHeadline: 'CEO at 9fin',
                    fullName: 'Steven Hunter',
                    id: 52535,
                    profilePictureUrl: 'https://assets.harmonic.ai/person_feb82900fe121fe9b850d6cd18f22ad4f0b19017fe32eda9a0a90a888860c796.png',
                    education: [
                        {
                            school: {
                                name: 'Royal School Dungannon',
                                entityUrn: 'urn:harmonic:school:1fc4f78c-1480-43c1-8229-381382fba6e1',
                                __typename: 'School'
                            },
                            degree: 'A* A* A* A',
                            startDate: '2003-01-01T00:00:00.000Z',
                            endDate: '2010-01-01T00:00:00.000Z',
                            __typename: 'EducationMetadata'
                        },
                        {
                            school: {
                                name: 'University of Bristol',
                                entityUrn: 'urn:harmonic:school:1fc4f78c-1480-43c1-8229-381382fba6e4',
                                __typename: 'School'
                            },
                            degree: 'Bachelor of Laws (LLB)',
                            startDate: '2010-01-01T00:00:00.000Z',
                            endDate: '2013-01-01T00:00:00.000Z',
                            __typename: 'EducationMetadata'
                        },
                        {
                            school: {
                                name: 'Bristol University',
                                entityUrn: 'urn:harmonic:school:1fc4f78c-1480-43c1-8229-381382fba6e4',
                                __typename: 'School'
                            },
                            degree: 'Bachelor of Laws (LLB)',
                            startDate: '2010-01-01T00:00:00.000Z',
                            endDate: '2013-01-01T00:00:00.000Z',
                            __typename: 'EducationMetadata'
                        }
                    ],
                    highlights: [],
                    experience: [
                        {
                            title: 'CEO & Founder',
                            endDate: null,
                            startDate: '2016-11-01T00:00:00Z',
                            isCurrentPosition: true,
                            company: {
                                name: '9fin',
                                logoUrl: 'https://assets.harmonic.ai/company_4b227777d4dd1fc61c6f884f48641d02b4d121d3fd328cb08b5531fcacdabf8a.png',
                                id: 4,
                                highlights: [
                                    {
                                        text: 'Venture Backed, 9fin: Backed By Tech Nation, Fly Ventures, 500 Global, Redalpine, Kintsugi, Michael Orland, Adfisco, Paul Forster, Alan Morgan, Ilavska Vuillermoz Capital, Spark Capital, Google for Startups EU, Seedcamp (Total Funding: $37,171,257)',
                                        category: 'VENTURE_BACKED',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Investment Executive',
                            endDate: '2016-10-01T00:00:00Z',
                            startDate: '2015-09-01T00:00:00Z',
                            isCurrentPosition: false,
                            company: {
                                name: 'Babson Capital Management',
                                logoUrl: 'https://assets.harmonic.ai/company_a56665df5ca8fe1b9d032cc3cd14af8d21d301d6858bb741ee64ab48a4866e59.png',
                                id: 666817,
                                highlights: [
                                    {
                                        text: 'Venture Backed, Babson Capital Management:  (Total Funding: Undisclosed)',
                                        category: 'VENTURE_BACKED',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Analyst',
                            endDate: '2015-09-01T00:00:00Z',
                            startDate: '2013-08-01T00:00:00Z',
                            isCurrentPosition: false,
                            company: {
                                name: 'J.P. Morgan',
                                logoUrl: 'https://assets.harmonic.ai/company_5721dfdb9ad7d5993705986fb570c373cbafe77b95622808b73247d23cad0d29.png',
                                id: 3177714,
                                highlights: [
                                    {
                                        text: 'Strong Team Growth, J.P. Morgan: 63048+ employees in < 199 years.',
                                        category: 'STRONG_TEAM_GROWTH',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Ambassador',
                            endDate: '2013-07-01T00:00:00Z',
                            startDate: '2013-02-01T00:00:00Z',
                            isCurrentPosition: false,
                            company: {
                                name: 'Start Up Loans Company',
                                logoUrl: 'https://assets.harmonic.ai/company_22db561cdd3b8263c9a624979482bcea1b994081ce43aeec7a7b2369383fcdba.png',
                                id: 1631540,
                                highlights: [],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Founder',
                            endDate: '2013-07-01T00:00:00Z',
                            startDate: '2012-11-01T00:00:00Z',
                            isCurrentPosition: false,
                            company: {
                                name: 'Bristol Law Conference',
                                logoUrl: 'https://assets.harmonic.ai/company_6fec7829445f7be10eef8d505884fa6829c0af122be211d3f076f3e1878776a5.png',
                                id: 1998714,
                                highlights: [],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'President',
                            endDate: '2013-03-01T00:00:00Z',
                            startDate: '2012-03-01T00:00:00Z',
                            isCurrentPosition: null,
                            company: {
                                name: 'Bristol Banking and Investment Society',
                                logoUrl: 'https://assets.harmonic.ai/company_3e875035d6d27b48d0d4de2709926c4579c496fee0a2c642dd840e492f6e95f6.png',
                                id: 1998715,
                                highlights: [],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Summer Analyst',
                            endDate: '2012-09-01T00:00:00Z',
                            startDate: '2012-06-01T00:00:00Z',
                            isCurrentPosition: null,
                            company: {
                                name: 'J.P. Morgan',
                                logoUrl: 'https://assets.harmonic.ai/company_5721dfdb9ad7d5993705986fb570c373cbafe77b95622808b73247d23cad0d29.png',
                                id: 3177714,
                                highlights: [
                                    {
                                        text: 'Strong Team Growth, J.P. Morgan: 63048+ employees in < 199 years.',
                                        category: 'STRONG_TEAM_GROWTH',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Spring Intern',
                            endDate: '2011-03-01T00:00:00Z',
                            startDate: '2011-03-01T00:00:00Z',
                            isCurrentPosition: null,
                            company: {
                                name: 'Credit Suisse',
                                logoUrl: 'https://assets.harmonic.ai/company_333bc559a2600ba152b1474d252d9f39398d804d1cc0089004494b38240469a8.png',
                                id: 12187626,
                                highlights: [
                                    {
                                        text: 'Venture Backed, Credit Suisse:  (Total Funding: $1,946,847,222)',
                                        category: 'VENTURE_BACKED',
                                        __typename: 'Highlight'
                                    }
                                ],
                                __typename: 'Company'
                            },
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                },
                __typename: 'SearchResultNode'
            }
        ],
        totalCount: 2,
        __typename: 'EntitySearchResponseConnection'
    }
};
const getCompanyFoundersEmptyMock = {
    getEmployeesByCompanyId: {
        edges: [],
        totalCount: 0,
        __typename: 'EntitySearchResponseConnection'
    }
};
export { getCompanyFoundersEmptyMock, getCompanyFoundersMock };
