export const REMOVE_PEOPLE_LIST_NAMED_VIEW_MOCKED_RESPONSE = {
    removePeopleListNamedView: {
        name: '1',
        id: 19182,
        searchQuery: {
            filterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: []
            },
            pagination: {
                start: null,
                pageSize: 25
            },
            sort: [
                {
                    sortField: 'person_entry_created_at',
                    descending: true,
                    sortContextValues: null
                }
            ],
            controlledFilterGroup: null,
            mergeQueryForNestedFields: false
        },
        __typename: 'PersonListNamedView'
    }
};
