export const GET_PEOPLE_SAVED_SEARCH_MOCKED_RESPONSE = {
    getSavedSearch: {
        id: 117805,
        entityUrn: 'urn:harmonic:saved_search:117805',
        isPrivate: true,
        name: 'People saved search #1',
        searchQuery: {
            filterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: []
            },
            pagination: {
                start: 0,
                pageSize: 25
            },
            sort: [
                {
                    sortField: 'person_customers_connections_last_email_at',
                    descending: false,
                    sortContextValues: null
                }
            ],
            controlledFilterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: [
                    {
                        generatorId: 'search_v2_people_general_highlights',
                        arguments: {
                            highlights: [
                                'Prior Exit',
                                'Major Tech Company Experience',
                                'HBCU Alum'
                            ]
                        }
                    },
                    {
                        generatorId: 'search_v2_people_education_experience',
                        arguments: {
                            degrees: ['PHD', 'MASTERS_DEGREE']
                        }
                    }
                ]
            },
            mergeQueryForNestedFields: false
        },
        type: 'PERSONS',
        visibleColumns: [
            'urn:harmonic:person_field:person_name',
            'urn:harmonic:person_field:person_position_company_id',
            'urn:harmonic:person_field:person_highlights_category_list',
            'urn:harmonic:person_field:person_position_title',
            'urn:harmonic:person_field:person_position_department',
            'urn:harmonic:person_field:person_location',
            'urn:harmonic:person_field:person_linkedin_headline',
            'urn:harmonic:person_field:person_position_company_tags',
            'urn:harmonic:person_field:person_last_company_id',
            'urn:harmonic:person_field:person_last_company_tags',
            'urn:harmonic:person_field:person_education_experience_school',
            'urn:harmonic:person_field:person_experience_company_id',
            'urn:harmonic:person_field:person_linkedin_url',
            'urn:harmonic:person_field:person_emails',
            'urn:harmonic:person_field:person_customers_connections'
        ],
        search_type: 'PERSONS',
        __typename: 'SavedSearch',
        creator: {
            email: 'matthiaslink@harmonic.ai',
            name: 'Matthias Link',
            entityUrn: 'urn:harmonic:user:11022',
            __typename: 'User'
        },
        userOptions: {
            isPinned: false,
            lastViewedAt: null,
            __typename: 'SavedSearchUserOptions'
        }
    }
};
export const GET_COMPANY_SAVED_SEARCH_MOCKED_RESPONSE = {
    getSavedSearch: {
        id: 107960,
        entityUrn: 'urn:harmonic:saved_search:107960',
        isPrivate: true,
        name: 'Companies saved search #1',
        searchQuery: {
            filterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: null
            },
            pagination: {
                start: 0,
                pageSize: 10
            },
            sort: [
                {
                    sortField: 'company_id',
                    descending: false,
                    sortContextValues: null
                }
            ],
            controlledFilterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: [
                    {
                        generatorId: 'search_v2_company_list_and_more_accelerators',
                        arguments: {
                            accelerators: ['W21', 'S21', 'W22', 'W23', 'S22', 'S23']
                        }
                    },
                    {
                        generatorId: 'search_v2_company_sector_include_industry_tags',
                        arguments: {
                            industryTags: [
                                'Communications and information technology',
                                'Automotive and transportation',
                                'Aerospace and defense',
                                'Social Impact',
                                'Media and entertainment',
                                'Industrial and manufacturing'
                            ]
                        }
                    },
                    {
                        generatorId: 'search_v2_company_list_and_more_location',
                        arguments: {
                            region: ['NORTH_AMERICA', 'AFRICA']
                        }
                    }
                ]
            },
            mergeQueryForNestedFields: false
        },
        type: 'COMPANIES_LIST',
        visibleColumns: [
            'urn:harmonic:company_field:company_name',
            'urn:harmonic:company_field:company_description',
            'urn:harmonic:company_field:company_highlights',
            'urn:harmonic:company_field:company_founders_and_ceos',
            'urn:harmonic:company_field:company_leadership_last_companies',
            'urn:harmonic:company_field:relevance_score',
            'urn:harmonic:company_field:company_website_url',
            'urn:harmonic:company_field:company_created_at',
            'urn:harmonic:company_field:company_funding_total',
            'urn:harmonic:company_field:company_last_funding_type',
            'urn:harmonic:company_field:company_investor_names',
            'urn:harmonic:company_field:company_headcount',
            'urn:harmonic:company_field:company_headcount_percent_change_90d_ago'
        ],
        search_type: 'COMPANIES_LIST',
        __typename: 'SavedSearch',
        creator: {
            email: 'matthiaslink@harmonic.ai',
            name: 'Matthias',
            entityUrn: 'urn:harmonic:user:11022',
            __typename: 'User'
        },
        userOptions: {
            isPinned: true,
            lastViewedAt: '2024-04-22T18:16:54.704898',
            __typename: 'SavedSearchUserOptions'
        }
    }
};
