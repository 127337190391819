export const UPSERT_PEOPLE_WATCHLIST_CUSTOM_FIELD_VALUE_MOCKED_RESPONSE = {
    upsertPeopleWatchlistCustomFieldValue: {
        customField: {
            urn: 'urn:harmonic:person_list_custom_field:1155',
            type: 'TEXT',
            __typename: 'PeopleListCustomField'
        },
        data: {
            __typename: 'TextCustomFieldValue',
            textValue: '1'
        },
        updatedAt: '2025-02-08T17:11:06Z',
        urn: 'urn:harmonic:person_list_custom_field_value:987042',
        createdAt: '2025-02-08T17:11:06Z',
        __typename: 'PeopleListCustomFieldValue'
    }
};
