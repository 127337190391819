export const CREATE_PEOPLE_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE = {
    createPeopleWatchlistCustomField: {
        urn: 'urn:harmonic:person_list_custom_field:1158',
        name: '1',
        type: 'TEXT',
        defaultValue: {
            textDefaultValue: '',
            __typename: 'TextCustomFieldValue'
        },
        metadata: null,
        __typename: 'PeopleListCustomField'
    }
};
