export default {
    getCompaniesByIds: [
        {
            id: 1571,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '2104fd16-f23e-49f5-a965-4894e76f114f',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '7a1a1a48-a06e-4ae1-b5c1-ec2b951e19d6',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '8ec16f35-3385-4214-83ff-d56651a61620',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'a6fe4a28-b516-42f6-b77a-13f2ea3f121a',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 122197,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 794197,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '1ef7f67f-5314-4ed4-a192-2f50e7db5b56',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '2104fd16-f23e-49f5-a965-4894e76f114f',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '366ad46b-76c8-4af0-9964-6ba754063ff9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b7056d04-708c-4c4c-91bd-e7647e86ad92',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'f67e9d98-87b3-4419-8b41-8051d9ca817c',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 4297387,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b7056d04-708c-4c4c-91bd-e7647e86ad92',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 1460909,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '7a1a1a48-a06e-4ae1-b5c1-ec2b951e19d6',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b7056d04-708c-4c4c-91bd-e7647e86ad92',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 9345,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '74cbcdf7-2d75-4df7-b453-ba9e6ffa5af9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b7056d04-708c-4c4c-91bd-e7647e86ad92',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 608259,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 1372997,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '9e21f7a4-73c0-40e1-8fac-92664b3d3dd6',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b7056d04-708c-4c4c-91bd-e7647e86ad92',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'd2908200-6a82-40a0-8640-a1d148169e2f',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'f67e9d98-87b3-4419-8b41-8051d9ca817c',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 2127598,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [],
            __typename: 'Company'
        },
        {
            id: 149099,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 767527,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b7056d04-708c-4c4c-91bd-e7647e86ad92',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 6566,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '103caf21-30cd-4679-9e5f-1e574518d2db',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '399c65c5-343a-423e-b0a4-5cc943bccdb1',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b86a8cf0-b640-41ba-b2ef-1d577c23dcc1',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 11339306,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: 'b7056d04-708c-4c4c-91bd-e7647e86ad92',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 123154,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b7056d04-708c-4c4c-91bd-e7647e86ad92',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 2137917,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [],
            __typename: 'Company'
        },
        {
            id: 252702,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '1ecc73d7-80a8-4c24-8da8-9aea8cb0bfe7',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '74cbcdf7-2d75-4df7-b453-ba9e6ffa5af9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '7a1a1a48-a06e-4ae1-b5c1-ec2b951e19d6',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b7056d04-708c-4c4c-91bd-e7647e86ad92',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'f67e9d98-87b3-4419-8b41-8051d9ca817c',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 11346096,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [],
            __typename: 'Company'
        },
        {
            id: 126612,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [],
            __typename: 'Company'
        },
        {
            id: 1777889,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 2139165,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [],
            __typename: 'Company'
        },
        {
            id: 1816351,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [],
            __typename: 'Company'
        },
        {
            id: 465376,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '46fd7771-5b87-4d16-9588-706035c50080',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '499ca42a-526c-47e2-8c16-8558bbf67822',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '97495ee6-11de-4d97-b05a-69397e128ef9',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'c0aec7b6-a583-4946-b8db-41f9199c9fdc',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 800627,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [
                {
                    id: '38bdc127-0600-44d9-878a-40db8eadb165',
                    __typename: 'CompanyWatchlist'
                }
            ],
            __typename: 'Company'
        },
        {
            id: 3225750,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [],
            __typename: 'Company'
        },
        {
            id: 10586219,
            isLikedByCurrentUser: false,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [],
            __typename: 'Company'
        }
    ]
};
export const getCompaniesAllEntityActionsLikedCompany = {
    getCompaniesByIds: [
        {
            id: 1,
            isLikedByCurrentUser: true,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: null,
            watchlists: [],
            __typename: 'Company'
        }
    ]
};
