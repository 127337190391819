export const GET_USE_FETCH_SAVED_SEARCHES_WITH_USER_OPTIONS_MOCKED_RESPONSE = {
    getSavedSearchesForTeam: [
        {
            __typename: 'SavedSearch',
            id: 107960,
            entityUrn: 'urn:harmonic:saved_search:107960',
            isPrivate: true,
            name: 'Companies saved search #1',
            type: 'COMPANIES_LIST',
            creator: {
                entityUrn: 'urn:harmonic:user:11022',
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias',
                __typename: 'User'
            },
            userOptions: {
                isPinned: true,
                lastViewedAt: '2024-04-22T18:16:54.704898',
                __typename: 'SavedSearchUserOptions'
            },
            search_type: 'COMPANIES_LIST',
            updatedAt: '2024-06-25T14:56:27.469516',
            createdAt: '2024-04-22T18:16:54.288967'
        },
        {
            __typename: 'SavedSearch',
            id: 117805,
            entityUrn: 'urn:harmonic:saved_search:117805',
            isPrivate: false,
            name: 'People saved search #1',
            type: 'PERSONS',
            creator: {
                entityUrn: 'urn:harmonic:user:11022',
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias',
                __typename: 'User'
            },
            userOptions: {
                isPinned: true,
                lastViewedAt: '2024-09-10T22:00:45.825678',
                __typename: 'SavedSearchUserOptions'
            },
            search_type: 'PERSONS',
            updatedAt: '2024-08-08T19:52:00.801634',
            createdAt: '2024-08-08T19:51:31.574610'
        }
    ]
};
