import { RoleType } from '__generated__/graphql';
const GET_PERSON_USER_CONNECTIONS = {
    getPersonById: {
        id: 109141323,
        correspondenceSummary: {
            lastEmailAt: '2024-07-25T04:08:11.000Z',
            lastEmailContactPersonEmail: 'carter@harmonic.ai',
            lastEmailContactPerson: {
                fullName: 'Carter Headley',
                profilePictureUrl: 'https://assets.harmonic.ai/_personc0560f93384f2c016bcf96db27988885c9db1136518694c865149fdafcb356db.jpeg',
                __typename: 'Person'
            },
            lastMeetingAt: null,
            lastMeetingContactPersonEmail: null,
            lastMeetingContactPerson: null,
            __typename: 'CorrespondenceSummary'
        },
        userConnections: [
            {
                targetPersonEmailAddress: null,
                latestCalendarEvent: null,
                latestEmail: {
                    timestamp: '2024-07-25T00:08:00',
                    title: 'Re: Harmonic.ai Outstanding Invoices',
                    sender: {
                        emailAddress: {
                            emailAddress: 'william@harmonic.ai',
                            name: 'William Chen',
                            __typename: 'EmailAddressMetadata'
                        },
                        person: null,
                        __typename: 'EmailAddressDerivedPerson'
                    },
                    receivers: [
                        {
                            emailAddress: {
                                emailAddress: 'max@harmonic.ai',
                                name: 'Max@Harmonic.Ai',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 161780079,
                                entityUrn: 'urn:harmonic:person:161780079',
                                fullName: 'Max Ruderman',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'carter@harmonic.ai',
                                name: 'Carter Headley',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 75304441,
                                entityUrn: 'urn:harmonic:person:75304441',
                                fullName: 'Carter Headley',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'ndavies@ramp.com',
                                name: 'Natalie Davies',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 109141323,
                                entityUrn: 'urn:harmonic:person:109141323',
                                fullName: 'Natalie Davies',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'ramp-payables@ramp.com',
                                name: 'Ramp Payables',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'accounting@harmonic.ai',
                                name: 'Harmonic Accounting Team',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        }
                    ],
                    __typename: 'UserEmailMessage'
                },
                user: {
                    email: 'max@harmonic.ai',
                    name: 'max ruderman',
                    __typename: 'User'
                },
                connectionSources: ['EMAIL'],
                __typename: 'UserConnection',
                targetPerson: {
                    id: 109141323,
                    fullName: 'Natalie Davies',
                    profilePictureUrl: 'https://assets.harmonic.ai/_personb89fb92e05bdae3f475995973f54dbe4c5d90618a77c7967c74848fb8706c194.jpeg',
                    experience: [
                        {
                            title: 'Accounts Payable Specialist',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 3639957,
                                name: 'Ramp',
                                logoUrl: 'https://assets.harmonic.ai/company_9cc4eaa5b11f825dd49b1d6252cf6d60739e4bc6813252c74b650fdf5903db0c.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2024-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accountant',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 8429577,
                                name: 'Climb CFO',
                                logoUrl: 'https://assets.harmonic.ai/company_61290c03ec6c9573745162c87871764b643316eda10ab7e74bf7e1c728d3d226.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2022-04-01T00:00:00Z',
                            endDate: '2024-03-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accounts Payable Specialist',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 2140650,
                                name: 'Opendoor',
                                logoUrl: 'https://assets.harmonic.ai/company_29ea68f1402371a04dcc6fd46f228fcf2478caf0d4c29c1d632008552130247a.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2021-03-01T00:00:00Z',
                            endDate: '2022-04-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accounts Payable Specialist',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 209997,
                                name: 'Tuft & Needle',
                                logoUrl: 'https://assets.harmonic.ai/company_5647e6a3cdc5b7a7d82fcb63a7415925f503cf1435d97f18b75c058ce2a54aca.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-06-01T00:00:00Z',
                            endDate: '2021-01-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accounts Payable Clerk',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: -1,
                                name: 'All American Asphalt',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2006-10-01T00:00:00Z',
                            endDate: '2016-09-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                }
            },
            {
                targetPersonEmailAddress: null,
                latestCalendarEvent: null,
                latestEmail: {
                    timestamp: '2024-07-25T04:08:11',
                    title: null,
                    sender: {
                        emailAddress: {
                            emailAddress: 'ndavies@ramp.com',
                            name: 'Natalie Davies',
                            __typename: 'EmailAddressMetadata'
                        },
                        person: {
                            id: 109141323,
                            entityUrn: 'urn:harmonic:person:109141323',
                            fullName: 'Natalie Davies',
                            __typename: 'Person'
                        },
                        __typename: 'EmailAddressDerivedPerson'
                    },
                    receivers: [
                        {
                            emailAddress: {
                                emailAddress: 'carter@harmonic.ai',
                                name: 'Carter Headley',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 75304441,
                                entityUrn: 'urn:harmonic:person:75304441',
                                fullName: 'Carter Headley',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'william@harmonic.ai',
                                name: 'William Chen',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'ramp-payables@ramp.com',
                                name: 'Ramp Payables',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'accounting@harmonic.ai',
                                name: 'Harmonic Accounting Team',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        }
                    ],
                    __typename: 'UserEmailMessage'
                },
                user: {
                    email: 'carter@harmonic.ai',
                    name: 'Carter',
                    __typename: 'User'
                },
                connectionSources: ['EMAIL'],
                __typename: 'UserConnection',
                targetPerson: {
                    id: 109141323,
                    fullName: 'Natalie Davies',
                    profilePictureUrl: 'https://assets.harmonic.ai/_personb89fb92e05bdae3f475995973f54dbe4c5d90618a77c7967c74848fb8706c194.jpeg',
                    experience: [
                        {
                            title: 'Accounts Payable Specialist',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 3639957,
                                name: 'Ramp',
                                logoUrl: 'https://assets.harmonic.ai/company_9cc4eaa5b11f825dd49b1d6252cf6d60739e4bc6813252c74b650fdf5903db0c.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2024-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accountant',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 8429577,
                                name: 'Climb CFO',
                                logoUrl: 'https://assets.harmonic.ai/company_61290c03ec6c9573745162c87871764b643316eda10ab7e74bf7e1c728d3d226.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2022-04-01T00:00:00Z',
                            endDate: '2024-03-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accounts Payable Specialist',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 2140650,
                                name: 'Opendoor',
                                logoUrl: 'https://assets.harmonic.ai/company_29ea68f1402371a04dcc6fd46f228fcf2478caf0d4c29c1d632008552130247a.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2021-03-01T00:00:00Z',
                            endDate: '2022-04-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accounts Payable Specialist',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 209997,
                                name: 'Tuft & Needle',
                                logoUrl: 'https://assets.harmonic.ai/company_5647e6a3cdc5b7a7d82fcb63a7415925f503cf1435d97f18b75c058ce2a54aca.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-06-01T00:00:00Z',
                            endDate: '2021-01-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accounts Payable Clerk',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: -1,
                                name: 'All American Asphalt',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2006-10-01T00:00:00Z',
                            endDate: '2016-09-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                }
            },
            {
                targetPersonEmailAddress: null,
                latestCalendarEvent: null,
                latestEmail: {
                    timestamp: '2024-07-25T00:08:00',
                    title: 'Re: Harmonic.ai Outstanding Invoices',
                    sender: {
                        emailAddress: {
                            emailAddress: 'william@harmonic.ai',
                            name: 'William Chen',
                            __typename: 'EmailAddressMetadata'
                        },
                        person: null,
                        __typename: 'EmailAddressDerivedPerson'
                    },
                    receivers: [
                        {
                            emailAddress: {
                                emailAddress: 'soham@harmonic.ai',
                                name: 'Soham@Harmonic.Ai',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 72676044,
                                entityUrn: 'urn:harmonic:person:72676044',
                                fullName: 'Soham Kamat',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'carter@harmonic.ai',
                                name: 'Carter Headley',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 75304441,
                                entityUrn: 'urn:harmonic:person:75304441',
                                fullName: 'Carter Headley',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'ndavies@ramp.com',
                                name: 'Natalie Davies',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: {
                                id: 109141323,
                                entityUrn: 'urn:harmonic:person:109141323',
                                fullName: 'Natalie Davies',
                                __typename: 'Person'
                            },
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'ramp-payables@ramp.com',
                                name: 'Ramp Payables',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        },
                        {
                            emailAddress: {
                                emailAddress: 'accounting@harmonic.ai',
                                name: 'Harmonic Accounting Team',
                                __typename: 'EmailAddressMetadata'
                            },
                            person: null,
                            __typename: 'EmailAddressDerivedPerson'
                        }
                    ],
                    __typename: 'UserEmailMessage'
                },
                user: {
                    email: 'soham@harmonic.ai',
                    name: 'Soham Kamat',
                    __typename: 'User'
                },
                connectionSources: ['EMAIL'],
                __typename: 'UserConnection',
                targetPerson: {
                    id: 109141323,
                    fullName: 'Natalie Davies',
                    profilePictureUrl: 'https://assets.harmonic.ai/_personb89fb92e05bdae3f475995973f54dbe4c5d90618a77c7967c74848fb8706c194.jpeg',
                    experience: [
                        {
                            title: 'Accounts Payable Specialist',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 3639957,
                                name: 'Ramp',
                                logoUrl: 'https://assets.harmonic.ai/company_9cc4eaa5b11f825dd49b1d6252cf6d60739e4bc6813252c74b650fdf5903db0c.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: true,
                            startDate: '2024-04-01T00:00:00Z',
                            endDate: null,
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accountant',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 8429577,
                                name: 'Climb CFO',
                                logoUrl: 'https://assets.harmonic.ai/company_61290c03ec6c9573745162c87871764b643316eda10ab7e74bf7e1c728d3d226.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2022-04-01T00:00:00Z',
                            endDate: '2024-03-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accounts Payable Specialist',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 2140650,
                                name: 'Opendoor',
                                logoUrl: 'https://assets.harmonic.ai/company_29ea68f1402371a04dcc6fd46f228fcf2478caf0d4c29c1d632008552130247a.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2021-03-01T00:00:00Z',
                            endDate: '2022-04-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accounts Payable Specialist',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: 209997,
                                name: 'Tuft & Needle',
                                logoUrl: 'https://assets.harmonic.ai/company_5647e6a3cdc5b7a7d82fcb63a7415925f503cf1435d97f18b75c058ce2a54aca.png',
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2017-06-01T00:00:00Z',
                            endDate: '2021-01-31T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        },
                        {
                            title: 'Accounts Payable Clerk',
                            roleType: RoleType.EMPLOYEE,
                            company: {
                                id: -1,
                                name: 'All American Asphalt',
                                logoUrl: null,
                                __typename: 'Company'
                            },
                            isCurrentPosition: false,
                            startDate: '2006-10-01T00:00:00Z',
                            endDate: '2016-09-30T00:00:00Z',
                            __typename: 'ExperienceMetadata'
                        }
                    ],
                    __typename: 'Person'
                }
            }
        ],
        __typename: 'Person'
    }
};
export { GET_PERSON_USER_CONNECTIONS };
