const SEARCH_PEOPLE_FIRST_PAGE = {
    pageInfo: null,
    edges: [
        {
            cursor: 'WzJd',
            node: {
                __typename: 'Person',
                personUrn: 'urn:harmonic:person:2',
                id: 2,
                fullName: 'Harry Schmiedeke',
                firstName: 'Harry',
                lastName: 'Schmiedeke',
                about: null,
                watchlists: [],
                profilePictureUrl: null,
                linkedinHeadline: null,
                location: null,
                highlights: [],
                correspondenceSummary: null,
                socials: {
                    linkedin: null,
                    twitter: null
                },
                experience: [],
                education: [],
                contact: null,
                emailEnrichmentStatus: null,
                updates: [],
                userConnections: []
            }
        },
        {
            cursor: 'WzNd',
            node: {
                __typename: 'Person',
                personUrn: 'urn:harmonic:person:3',
                id: 3,
                fullName: 'Jimmy Chebat',
                firstName: 'Jimmy',
                lastName: 'Chebat',
                about: null,
                watchlists: [],
                profilePictureUrl: null,
                linkedinHeadline: null,
                location: null,
                highlights: [],
                correspondenceSummary: null,
                socials: {
                    linkedin: null,
                    twitter: null
                },
                experience: [],
                education: [],
                contact: null,
                emailEnrichmentStatus: null,
                updates: [],
                userConnections: []
            }
        }
    ],
    totalCount: 25,
    __typename: 'EntitySearchResponseConnection'
};
export { SEARCH_PEOPLE_FIRST_PAGE };
