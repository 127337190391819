const GET_PERSON_EDUCATION_MOCK = {
    getPersonById: {
        id: 1,
        education: [
            {
                school: {
                    name: 'Vanderbilt University',
                    linkedinUrl: 'https://linkedin.com/school/vanderbilt-university',
                    logoUrl: 'https://assets.harmonic.ai/school_ef52d1811e20575ababb36913516d45a8a7c20a647883772244d4d7190b09bea.png',
                    __typename: 'School'
                },
                degree: 'Bachelor of Science (BS)',
                field: null,
                grade: null,
                startDate: '2000-01-01T00:00:00.000Z',
                endDate: '2004-01-01T00:00:00.000Z',
                __typename: 'EducationMetadata'
            },
            {
                school: {
                    name: 'The London School of Economics and Political Science (LSE)',
                    linkedinUrl: 'https://linkedin.com/school/london-school-of-economics',
                    logoUrl: 'https://assets.harmonic.ai/school_755e49bd33ff450513128b199ca56fc70564557771809c7a3325d850efa9fc6e.png',
                    __typename: 'School'
                },
                degree: null,
                field: null,
                grade: null,
                startDate: '2002-01-01T00:00:00.000Z',
                endDate: '2002-01-01T00:00:00.000Z',
                __typename: 'EducationMetadata'
            }
        ],
        __typename: 'Person'
    }
};
const GET_PERSON_EMPTY_EDUCATION_MOCK = {
    getPersonById: {
        __typename: 'Person',
        id: 1,
        education: []
    }
};
export { GET_PERSON_EDUCATION_MOCK, GET_PERSON_EMPTY_EDUCATION_MOCK };
