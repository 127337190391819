export const REMOVE_COMPANY_LIST_NAMED_VIEW_MOCKED_RESPONSE = {
    removeCompanyListNamedView: {
        name: '12 (Copy)',
        id: 39264,
        searchQuery: {
            filterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: []
            },
            pagination: {
                start: null,
                pageSize: 25
            },
            sort: [
                {
                    sortField: 'company_entry_created_at',
                    descending: true,
                    sortContextValues: null
                }
            ],
            controlledFilterGroup: null,
            mergeQueryForNestedFields: false
        },
        __typename: 'CompanyListNamedView'
    }
};
