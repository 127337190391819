export const GET_USE_FETCH_PAGINATED_SAVED_SEARCHES_WITH_USER_OPTIONS_COMPANIES_MOCKED_RESPONSE = {
    getPaginatedSavedSearchesForTeam: {
        __typename: 'SavedSearchesForTeam',
        totalCount: 1,
        pageInfo: {
            hasNextPage: false,
            startCursor: null,
            endCursor: null
        },
        edges: [
            {
                cursor: null,
                node: {
                    __typename: 'SavedSearch',
                    id: 107960,
                    entityUrn: 'urn:harmonic:saved_search:107960',
                    isPrivate: true,
                    name: 'Companies saved search #1',
                    type: 'COMPANIES_LIST',
                    creator: {
                        entityUrn: 'urn:harmonic:user:11022',
                        email: 'matthiaslink@harmonic.ai',
                        name: 'Matthias',
                        __typename: 'User'
                    },
                    userOptions: {
                        isPinned: true,
                        lastViewedAt: '2024-04-22T18:16:54.704898',
                        __typename: 'SavedSearchUserOptions'
                    },
                    search_type: 'COMPANIES_LIST',
                    updatedAt: '2024-06-25T14:56:27.469516',
                    createdAt: '2024-04-22T18:16:54.288967'
                }
            }
        ]
    }
};
