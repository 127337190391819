export const LIKE_COMPANIES_MOCKED_RESPONSE = {
    data: {
        likeCompanies: [
            {
                isLikedByCurrentUser: true,
                id: 1,
                __typename: 'Company'
            }
        ]
    }
};
export const UNLIKE_COMPANIES_MOCKED_RESPONSE = {
    data: {
        unlikeCompanies: [
            {
                isLikedByCurrentUser: false,
                id: 1,
                __typename: 'Company'
            }
        ]
    }
};
