import { ConnectionIntegrationType, TeamRole, TeamStatus } from '__generated__/graphql';
export const MOCK_GET_CURRENT_USER = {
    getCurrentUser: {
        name: 'Matthias',
        entityUrn: 'urn:harmonic:user:11022',
        email: 'matthiaslink@harmonic.ai',
        customer: {
            identifier: 'harmonic.ai',
            name: 'Harmonic.ai',
            contactCredits: 709,
            maxLiteUserSeats: null,
            maxUserSeats: null,
            slackIntegrations: [
                {
                    incomingWebhookChannel: '@matthiaslink',
                    incomingWebhookChannelId: 'D06UEGK8DH9',
                    incomingWebhookConfigurationUrl: 'https://harmonicaiworkspace.slack.com/services/B07KL3D9VME',
                    incomingWebhookUrl: 'https://hooks.slack.com/services/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',
                    teamId: 'T0150U8EMST',
                    teamName: 'Harmonic.ai',
                    id: 1802,
                    __typename: 'SlackIntegration'
                },
                {
                    incomingWebhookChannel: '@matthiaslink',
                    incomingWebhookChannelId: 'D06UEGK8DH9',
                    incomingWebhookConfigurationUrl: 'https://harmonicaiworkspace.slack.com/services/B07KL3JJ9QQ',
                    incomingWebhookUrl: 'https://hooks.slack.com/services/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',
                    teamId: 'T0150U8EMST',
                    teamName: 'Harmonic.ai',
                    id: 1803,
                    __typename: 'SlackIntegration'
                }
            ],
            __typename: 'Customer'
        },
        role: 'MEMBER',
        status: 'ACTIVE',
        connectionsIntegrationStatus: [
            {
                type: 'LINKEDIN',
                isEnabled: true,
                __typename: 'ConnectionIntegrationStatus'
            },
            {
                type: 'GOOGLE',
                isEnabled: true,
                __typename: 'ConnectionIntegrationStatus'
            }
        ],
        __typename: 'User'
    }
};
export const MOCK_GET_CURRENT_USER_WITH_SLACK_INTEGRATIONS = {
    getCurrentUser: {
        ...MOCK_GET_CURRENT_USER.getCurrentUser,
        customer: {
            ...MOCK_GET_CURRENT_USER.getCurrentUser.customer,
            slackIntegrations: [
                {
                    incomingWebhookChannel: 'test channel',
                    incomingWebhookChannelId: 'C1234567890',
                    incomingWebhookConfigurationUrl: 'https://example.com/configure',
                    incomingWebhookUrl: 'https://example.com/webhook',
                    teamId: 'T1234567890',
                    teamName: 'Example Team',
                    id: 1,
                    __typename: 'SlackIntegration'
                }
            ]
        }
    }
};
export const MOCK_GET_CURRENT_USER_WITH_INTEGRATED_SETTINGS = {
    getCurrentUser: {
        name: 'Ali Hashemi',
        entityUrn: 'urn:harmonic:user:1',
        email: 'ali@ali.com',
        customer: {
            identifier: 'harmonic.ai',
            name: 'Harmonic',
            contactCredits: 200,
            maxLiteUserSeats: 100,
            maxUserSeats: 100,
            slackIntegrations: [],
            __typename: 'Customer'
        },
        role: TeamRole.ADMIN,
        status: TeamStatus.ACTIVE,
        connectionsIntegrationStatus: [
            {
                type: ConnectionIntegrationType.LINKEDIN,
                isEnabled: true,
                __typename: 'ConnectionIntegrationStatus'
            },
            {
                type: ConnectionIntegrationType.GOOGLE,
                isEnabled: true,
                __typename: 'ConnectionIntegrationStatus'
            }
        ],
        __typename: 'User'
    }
};
