export const GET_COMPANY_SAVED_SEARCH_RESULTS_MOCKED_RESPONSE = {
    getSavedSearch: {
        id: 107960,
        results: {
            totalCount: 518,
            pageInfo: {
                endCursor: 'WzE1OSwxMjgzOTc4OV0=',
                hasNextPage: true,
                startCursor: 'WzI3NiwxODE1OTI5XQ==',
                __typename: 'PageInfo'
            },
            edges: [
                {
                    cursor: 'WzMwMTUyLDFd',
                    node: {
                        __typename: 'SavedSearchResultNode',
                        netNewId: null,
                        entity: {
                            __typename: 'Company',
                            id: 1,
                            name: 'Harmonic',
                            legal_name: 'Harmonic AI, Inc.',
                            description: "Harmonic's main product is a data engine that provides daily-updated company and professional profiles, allowing users to track company growth, team changes, and funding rounds. The main technologies used include a platform and API for accessing real-time company, team, and traction information.",
                            external_description: 'Harmonic is on a mission to make the startup universe discoverable.\n\nToday, harmonic.ai is used by teams like Brex, Greylock, Index, a16z, MongoDB, Notion, and Bloomberg to connect startups with the capital and resources that they need to drive growth. Our team is backed by top-tier investors like Craft, Sozo, Floodgate, SOMA, Teamworthy, AVG and many others.\n\nOpen roles: https://harmonic.ai/careers',
                            logo_url: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
                            updatedAt: '2024-09-11T11:38:49.000Z',
                            created_at: '2020-07-20T02:30:32.000Z'
                        }
                    },
                    __typename: 'SavedSearchResultEdge'
                },
                {
                    cursor: 'Wy05MjIzMzcyMDM2ODU0Nzc1ODA4LDM5NzM2NDFd',
                    node: {
                        __typename: 'SavedSearchResultNode',
                        netNewId: null,
                        entity: {
                            __typename: 'Company',
                            id: 3973641,
                            name: 'Fizz',
                            legal_name: null,
                            description: 'Fizz provides a secure platform for college communities, prioritizing safety and privacy through university email verification and community-based moderation. The main technologies utilized include university email verification and community-based moderation to ensure safety and privacy within college communities.',
                            external_description: 'Fizz is a private and authenticated platform designed to revolutionize the social fabric of communities, starting with colleges. After verifying a university email address, students use Fizz to post questions, memes, polls, and chat directly with other verified students, all while maintaining full control of their identity and privacy. Fizz communities are safe, authentic, and inclusive, made possible through community-based moderation. Launched by two Stanford dropouts, Fizz is currently expanding into colleges all across the US. In a time when social media is plagued by curated highlight reals and lack of authenticity, Fizz is on a mission to redefine the definition of a true social network.',
                            logo_url: 'https://assets.harmonic.ai/company_76616a4d191a70f9ff17f4feed87d8f566ae5453540125f0e6bcc6948d42f75c.png',
                            updatedAt: '2024-09-11T01:40:50.000Z',
                            created_at: '2021-12-17T01:17:53.000Z'
                        }
                    },
                    __typename: 'SavedSearchResultEdge'
                }
            ],
            __typename: 'SavedSearchResultsConnection'
        },
        __typename: 'SavedSearch'
    }
};
