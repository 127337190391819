export const UPSERT_COMPANY_WATCHLIST_USER_RELEVANCE_TYPE_MOCKED_RESPONSE = {
    data: {
        upsertCompanyWatchlistUserRelevanceType: {
            id: '29821e99-559b-4991-8890-9898df6b6acd',
            entityUrn: 'urn:harmonic:company_watchlist:29821e99-559b-4991-8890-9898df6b6acd',
            userRelevanceScoreInfluenceType: 'SIMILAR',
            __typename: 'CompanyWatchlist'
        }
    }
};
