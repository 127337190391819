const getSimilarCompaniesV2 = {
    getSimilarCompanies: {
        edges: [
            {
                node: {
                    id: 12455555,
                    logoUrl: 'https://assets.harmonic.ai/company_baf1388ceef6125e605731c6ae82581ee578da8b8763e495f0f2267409a09f5e.png',
                    headcount: 33,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 1000,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Sidekick',
                    description: 'Open source data infrastructure for LLM powered apps',
                    funding: {
                        fundingTotal: '500000',
                        fundingStage: 'PRE__SEED',
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2022-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Communications and Information Technology',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:8b2dd506-2e20-4c46-9526-5fe6faf14e5a',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Software',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b253d2df-c06b-4976-a048-f89590166908',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Artificial Intelligence',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b3ebc5e6-12f2-42f6-a63b-4b4bfd7af9c7',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 3,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            },
            {
                node: {
                    id: 4953930,
                    logoUrl: 'https://assets.harmonic.ai/company_a2e2a0349ef150f6eb9e871cd6aed092fd184f5ff99f1dd011d09e520629c363.png',
                    headcount: 44,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 22.22222222222222,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Ramp',
                    description: 'Elevating mobile gaming through Advanced Analytics. Your partner for precision forecasting & strategic decision-making.',
                    funding: {
                        fundingTotal: '5363240',
                        fundingStage: 'SEED_ROUND',
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2018-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business Services',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:a45c2811-9efc-4256-b592-2a5000d8cfb2',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Data Analytics',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:f61817af-def2-4cb8-9dea-9f1ecfb4768b',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 8,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            },
            {
                node: {
                    id: 12578706,
                    logoUrl: 'https://assets.harmonic.ai/company_bc4d8c692a3687c4024033f7f19ad4097c72fc2cbb33464b43028f42986b710f.png',
                    headcount: 2,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 0,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Emergent Layer',
                    description: 'The fastest way for finance teams to do their work. Create workflows that are directly connected to live data sources, like bank accounts and accounting systems, to automate operations and reporting.',
                    funding: {
                        fundingTotal: '0',
                        fundingStage: null,
                        __typename: 'Funding'
                    },
                    foundingDate: null,
                    fundingAttributeNullStatus: 'NONE_ANNOUNCED',
                    tagsV2: [
                        {
                            displayValue: 'Financial Services',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:9153c387-1902-4136-be30-d082fa0ce2d8',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business Services',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:a45c2811-9efc-4256-b592-2a5000d8cfb2',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Data Analytics',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:f61817af-def2-4cb8-9dea-9f1ecfb4768b',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 12,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            },
            {
                node: {
                    id: 3599001,
                    logoUrl: 'https://assets.harmonic.ai/company_fa5a9fa8408802a72c286909c934b8e54e6117835c21f4848135feb60e8ce639.png',
                    headcount: 7,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 0,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Spindle AI (spindle.app)',
                    description: 'Backed by Accel Partners (Slack/Dropbox/Atlassian) and Caffeinated Capital (Notion/Airtable/IFTTT), Spindle AI’s “intelligent copilot for scenario planning” puts a virtual data scientist & operations analyst at the fingertips of every FP&A stakeholder, instantly. 🤖 Generate scenarios, simulate “what‑ifs”, summarize trade‑offs… and still have time for lunch. 🍕 Spindle AI is lovingly built by machine learning & BI entrepreneurs/engineers from Tableau, Google, Stanford, Facebook, Brainspace, and others who share in our mission to augment human cognition with software. Shape the future with us: https://spindle.app',
                    funding: {
                        fundingTotal: null,
                        fundingStage: 'SEED',
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2021-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business Services',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:a45c2811-9efc-4256-b592-2a5000d8cfb2',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Artificial Intelligence',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b3ebc5e6-12f2-42f6-a63b-4b4bfd7af9c7',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Data Analytics',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:f61817af-def2-4cb8-9dea-9f1ecfb4768b',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 1,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            },
            {
                node: {
                    id: 774540,
                    logoUrl: 'https://assets.harmonic.ai/company_70192b7b98bd3754cd0986749977565ea1936d26363b9e05c565a0f556575786.png',
                    headcount: 27,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: -3.4482758620689653,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Pitchly',
                    description: 'Pitchly was founded on the belief that the future of work is data-driven, not task-driven. With our focus on solving the last mile dilemma for business data, we have created a place where people and data come together to generate real business outcomes. We believe in putting data to work, and our data productivity suite of applications and integrations has been built to empower collaboration, automation, and bottom-line value.',
                    funding: {
                        fundingTotal: '9800000',
                        fundingStage: 'GRANT',
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2014-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business Services',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:a45c2811-9efc-4256-b592-2a5000d8cfb2',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Data Analytics',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:f61817af-def2-4cb8-9dea-9f1ecfb4768b',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 1,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            },
            {
                node: {
                    id: 4424718,
                    logoUrl: 'https://assets.harmonic.ai/company_422b8d3b13fc3a8c42e12a8599721d5c4bac4ae08514ddf35b6ed122678598d0.png',
                    headcount: 2,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 0,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Blankly Finance',
                    description: 'An open-sourced framework built for professional quantitative development. Blankly enables developers to backtest, paper trade, and deploy cross-exchange without modifying a single line of trading logic.',
                    funding: {
                        fundingTotal: '0',
                        fundingStage: null,
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2021-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'NONE_ANNOUNCED',
                    tagsV2: [
                        {
                            displayValue: 'Product Hunt',
                            type: 'PRODUCT_HUNT',
                            entityUrn: 'urn:harmonic:tag:0eae9f4b-c3be-41c7-97fb-d123353e8dff',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Communications and Information Technology',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:8b2dd506-2e20-4c46-9526-5fe6faf14e5a',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Software',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b253d2df-c06b-4976-a048-f89590166908',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 1,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            },
            {
                node: {
                    id: 705557,
                    logoUrl: 'https://assets.harmonic.ai/company_463fe0a2597278e7c212befff4d663996d17913caabef0cdfd64e4f99252111c.png',
                    headcount: 24,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 20,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'QuantConnect',
                    description: 'QuantConnect is an open-source algorithmic trading platform that provides its community of over 220,000 quants with access to financial data, cloud computing, and a coding environment where they can design algorithms. Users develop trading strategies in Python and C#, backtest them, and then deploy them into their brokerage trading accounts! We are a truly global company with users from all over the world who have created more than 8M algorithms and live traded more than $12B in notional volume.',
                    funding: {
                        fundingTotal: '8600000',
                        fundingStage: 'SERIES_A2',
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2011-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Financial Services',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:9153c387-1902-4136-be30-d082fa0ce2d8',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Software',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b253d2df-c06b-4976-a048-f89590166908',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Consumer (B2C)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:ffdfc81e-4506-4a6b-b51d-2d3ad4a8ebed',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 1,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            },
            {
                node: {
                    id: 1478230,
                    logoUrl: 'https://assets.harmonic.ai/company_9349d6600c66430be0b0c7e0e25e59b683ba5f88f35bf9e91b7bf545f5e7bbcc.png',
                    headcount: 35,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 6.0606060606060606,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Union.ai',
                    description: 'We believe that orchestration is the foundational technology that will power data and machine learning stacks all over the world - powered by Kubernetes and Flyte.',
                    funding: {
                        fundingTotal: '39100000',
                        fundingStage: 'SERIES_A',
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2021-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Communications and Information Technology',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:8b2dd506-2e20-4c46-9526-5fe6faf14e5a',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Data Analytics',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:f61817af-def2-4cb8-9dea-9f1ecfb4768b',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 2,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            },
            {
                node: {
                    id: 3090317,
                    logoUrl: 'https://assets.harmonic.ai/company_41ec4530cc89732e18671d9b6d4bbdddeb5f274cea9a3568de33730844d628bb.png',
                    headcount: 15,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 15.384615384615385,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Lightdash',
                    description: 'Lightdash is an open source BI alternative to Looker, built for analysts on top of the modern data tools they already use and love. Lightdash brings your visual layer together with your data modelling and transformation layer, creating a single source of truth for data metrics for teams.',
                    funding: {
                        fundingTotal: '8400000',
                        fundingStage: 'SEED',
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2020-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business Services',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:a45c2811-9efc-4256-b592-2a5000d8cfb2',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Data Analytics',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:f61817af-def2-4cb8-9dea-9f1ecfb4768b',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'S20',
                            type: 'YC_BATCH',
                            entityUrn: 'urn:harmonic:tag:f98089bf-f02c-4595-98c5-70be6a4b9642',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 1,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            }
        ],
        totalCount: 9,
        __typename: 'EntitySearchResponseConnection'
    }
};
export const getSimilarCompaniesV2NoFundingTotal = {
    getSimilarCompanies: {
        edges: [
            {
                node: {
                    id: 12455555,
                    logoUrl: 'https://assets.harmonic.ai/company_baf1388ceef6125e605731c6ae82581ee578da8b8763e495f0f2267409a09f5e.png',
                    headcount: 33,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 1000,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Sidekick',
                    description: 'Open source data infrastructure for LLM powered apps',
                    funding: {
                        fundingTotal: null,
                        fundingStage: 'PRE__SEED',
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2022-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Communications and Information Technology',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:8b2dd506-2e20-4c46-9526-5fe6faf14e5a',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Software',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b253d2df-c06b-4976-a048-f89590166908',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Artificial Intelligence',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b3ebc5e6-12f2-42f6-a63b-4b4bfd7af9c7',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 3,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            }
        ],
        totalCount: 9,
        __typename: 'EntitySearchResponseConnection'
    }
};
export const getSimilaCompaniesV2ZeroValueAsFundingTotal = {
    getSimilarCompanies: {
        edges: [
            {
                node: {
                    id: 12455555,
                    logoUrl: 'https://assets.harmonic.ai/company_baf1388ceef6125e605731c6ae82581ee578da8b8763e495f0f2267409a09f5e.png',
                    headcount: 33,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 1000,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Sidekick',
                    description: 'Open source data infrastructure for LLM powered apps',
                    funding: {
                        fundingTotal: 0,
                        fundingStage: 'PRE__SEED',
                        __typename: 'Funding'
                    },
                    foundingDate: {
                        date: '2022-01-01T00:00:00Z',
                        __typename: 'FoundingDate'
                    },
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Communications and Information Technology',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:8b2dd506-2e20-4c46-9526-5fe6faf14e5a',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Software',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b253d2df-c06b-4976-a048-f89590166908',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Artificial Intelligence',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b3ebc5e6-12f2-42f6-a63b-4b4bfd7af9c7',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 3,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            }
        ],
        totalCount: 9,
        __typename: 'EntitySearchResponseConnection'
    }
};
export const getSimilarCompaniesV2NoFoundingDate = {
    getSimilarCompanies: {
        edges: [
            {
                node: {
                    id: 12455555,
                    logoUrl: 'https://assets.harmonic.ai/company_baf1388ceef6125e605731c6ae82581ee578da8b8763e495f0f2267409a09f5e.png',
                    headcount: 33,
                    tractionMetrics: {
                        headcount: {
                            ago90d: {
                                percentChange: 1000,
                                change: 0,
                                value: 1,
                                __typename: 'TractionMetricsPoint'
                            },
                            __typename: 'TractionMetricsInfo'
                        },
                        __typename: 'TractionMetrics'
                    },
                    name: 'Sidekick',
                    description: 'Open source data infrastructure for LLM powered apps',
                    funding: {
                        fundingTotal: null,
                        fundingStage: 'PRE__SEED',
                        __typename: 'Funding'
                    },
                    foundingDate: null,
                    fundingAttributeNullStatus: 'EXISTS_BUT_UNDISCLOSED',
                    tagsV2: [
                        {
                            displayValue: 'Communications and Information Technology',
                            type: 'MARKET_VERTICAL',
                            entityUrn: 'urn:harmonic:tag:8b2dd506-2e20-4c46-9526-5fe6faf14e5a',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Business (B2B)',
                            type: 'CUSTOMER_TYPE',
                            entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Software',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b253d2df-c06b-4976-a048-f89590166908',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Artificial Intelligence',
                            type: 'TECHNOLOGY_TYPE',
                            entityUrn: 'urn:harmonic:tag:b3ebc5e6-12f2-42f6-a63b-4b4bfd7af9c7',
                            __typename: 'Tag'
                        },
                        {
                            displayValue: 'Service',
                            type: 'PRODUCT_TYPE',
                            entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                            __typename: 'Tag'
                        }
                    ],
                    relevanceScore: 3,
                    __typename: 'Company'
                },
                __typename: 'SearchResultNode'
            }
        ],
        totalCount: 9,
        __typename: 'EntitySearchResponseConnection'
    }
};
export default getSimilarCompaniesV2;
