import { LocationType } from '../../__generated__/graphql';
export const TYPEAHEAD_ALL_QUERY_MOCKED_RESPONSE = {
    getCompaniesWithTypeahead: [
        {
            id: 1518027,
            logoUrl: null,
            name: 'Cosan S.A.',
            headcount: null,
            funding: {
                lastFundingType: null,
                __typename: 'Funding'
            },
            location: null,
            __typename: 'Company'
        }
    ],
    __typename: 'Query',
    getPeopleWithTypeahead: [
        {
            id: 61750040,
            fullName: 'S Chm',
            experience: [
                {
                    company: {
                        id: 606452,
                        name: 'JeffreyGroup',
                        __typename: 'Company'
                    },
                    title: 'wsedrser',
                    department: null,
                    isCurrentPosition: false,
                    __typename: 'ExperienceMetadata'
                }
            ],
            profilePictureUrl: 'https://static-exp1.licdn.com/sc/h/244xhbkr7g40x6bsu4gi6q4ry',
            __typename: 'Person'
        }
    ]
};
export const mockTypeaheadLocations = {
    getLocationsWithTypeahead: [
        {
            entityUrn: 'urn:harmonic:location:United_States|California|San_Francisco',
            text: 'San Francisco, California, United States',
            locationType: LocationType.CITY,
            city: 'San Francisco',
            state: 'California',
            country: 'United States',
            metroAreaId: null
        },
        {
            entityUrn: 'urn:harmonic:location:United_States|California|San_Jose',
            text: 'San Jose, California, United States',
            locationType: LocationType.CITY,
            city: 'San Jose',
            state: 'California',
            country: 'United States',
            metroAreaId: null
        },
        {
            entityUrn: 'urn:harmonic:location:United_States',
            text: 'United States',
            locationType: LocationType.COUNTRY,
            city: null,
            state: null,
            country: 'United States',
            metroAreaId: null
        },
        {
            entityUrn: 'urn:harmonic:location_metro_area:1',
            text: 'San Francisco Bay Area',
            locationType: LocationType.METRO_AREA,
            city: null,
            state: null,
            country: '',
            metroAreaId: 1
        }
    ]
};
