export const CREATE_COMPANY_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE = {
    createCompanyWatchlistCustomField: {
        urn: 'urn:harmonic:company_list_custom_field:2636',
        name: '1',
        type: 'TEXT',
        defaultValue: {
            textDefaultValue: '',
            __typename: 'TextCustomFieldValue'
        },
        metadata: null,
        __typename: 'CompanyListCustomField'
    }
};
