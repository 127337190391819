const firebaseSignInWithPasswordMockData = {
    displayName: 'Test user',
    email: 'test@test.com',
    expiresIn: '3600',
    idToken: 'mockIdOrToken',
    kind: 'identitytoolkit#VerifyPasswordResponse',
    localId: 'mockLocalId',
    profilePicture: 'https://lh3.googleusercontent.com/a/AATXAJwJXY9d7tsqo_ZMzvJwDO--ViJ5A_Ec3htWLpN0=s96-c',
    refreshToken: 'mockRefreshToken',
    registered: true
};
const firebaseLookupMockData = {
    kind: 'identitytoolkit#GetAccountInfoResponse',
    users: [
        {
            localId: 'IojH6q8v5nM9CVgkB4rkj2mid3j1',
            email: 'test@test.com',
            displayName: 'Test user',
            photoUrl: 'https://lh3.googleusercontent.com/a/AATXAJwJXY9d7tsqo_ZMzvJwDO--ViJ5A_Ec3htWLpN0=s96-c',
            passwordHash: 'UkVEQUNURUQ=',
            emailVerified: true,
            passwordUpdatedAt: 1690911831150,
            providerUserInfo: [
                {
                    providerId: 'password',
                    displayName: 'Test user',
                    photoUrl: 'https://lh3.googleusercontent.com/a/AATXAJwJXY9d7tsqo_ZMzvJwDO--ViJ5A_Ec3htWLpN0=s96-c',
                    federatedId: 'test@test.com',
                    email: 'test@test.com',
                    rawId: 'test@test.com'
                }
            ],
            validSince: '1690911831',
            lastLoginAt: '1694423223444',
            createdAt: '1621329535221',
            customAuth: true,
            emailLinkSignin: true,
            lastRefreshAt: '2023-09-11T09:07:03.444Z'
        }
    ]
};
const authenticateRouteMockResponse = {
    email: 'test@test.com',
    name: 'Test',
    customer: 'urn:harmonic:customer:1',
    entity_urn: 'urn:harmonic:user:374',
    company_watchlists: [],
    saved_searches: [],
    firebase_token: null,
    apikey: 'mockApikey',
    customer_apikey: 'mockCustomerApiKey',
    permissions: ['FRONTEND_PUBLIC', 'ALL'],
    customer_permissions: [
        'ALL',
        'GET_PEOPLE_WATCHLIST',
        'UPDATE_PEOPLE_WATCHLIST'
    ],
    role: 'ADMIN',
    status: 'ACTIVE',
    settings: {
        pinned_saved_searches_order: [],
        company_preferences: {
            stages: ['STEALTH', 'PRESEED', 'SEED', 'SERIES_A', 'SERIES_B_PLUS'],
            signals: [
                'FOUNDER_EDUCATION',
                'FOUNDER_WORK_HISTORY',
                'FOUNDER_STARTUP_EXP'
            ],
            sectors: ['ARTIFICIAL_INTELLIGENCE', 'BLOCKCHAIN', 'EDTECH', 'FINTECH'],
            regions: [],
            keywords: []
        },
        has_completed_onboarding: true
    },
    max_user_seats: null,
    max_lite_user_seats: 10,
    customer_name: 'Harmonic.ai'
};
const splitKeysMockResponse = {
    till: 1694201575739,
    since: -1,
    splits: [
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'enable-company-profile-v2',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'on',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@harmonic.ai']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'enable-company-engagement-tab',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'on',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@harmonic.ai']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'enable-edit-column-field',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'on',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: [
                                        'test@harmonic.ai',
                                        'samrat.luintel.58@gmail.com',
                                        'samrat@harmonic.ai'
                                    ]
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'contact-data',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'on',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@test.com']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'enable-person-profile-v2',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'on',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@test.com']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'contact-data',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'on',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@test.com']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'enable-company-list-on-team-search',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'off',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@harmonic.ai']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'corrected-headcount-enabled',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'off',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@harmonic.ai']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'new-people-import',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'off',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@harmonic.ai']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'enable-new-colors',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'off',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@harmonic.ai']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'people-simple-search',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'on',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@harmonic.ai']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'enable-edit-column-field',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'on',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@harmonic.ai']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1694201575739,
            trafficTypeName: 'user',
            name: 'enable-edit-columns-v2',
            trafficAllocation: 100,
            trafficAllocationSeed: -870709685,
            seed: 526156676,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'on',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: ['test@harmonic.ai']
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [test@harmonic.ai, test2@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {}
        },
        {
            changeNumber: 1731694550042,
            trafficTypeName: 'user',
            name: 'enable-named-views',
            trafficAllocation: 100,
            trafficAllocationSeed: 1215224694,
            seed: 86769517,
            status: 'ACTIVE',
            killed: false,
            defaultTreatment: 'off',
            algo: 2,
            conditions: [
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: 'email'
                                },
                                matcherType: 'WHITELIST',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: {
                                    whitelist: [
                                        'samrat.luintel.58@gmail.com',
                                        'samrat@harmonic.ai',
                                        'david@harmonic.ai',
                                        'johnny@harmonic.ai',
                                        'miguel@harmonic.ai',
                                        'seth@harmonic.ai',
                                        'ali@harmonic.ai',
                                        'matthias@harmonic.ai',
                                        'paolo@harmonic.ai',
                                        'paolo.permanent.is',
                                        'emma@harmonic.ai',
                                        'max@harmonic.ai',
                                        'willdimondi@harmonic.ai',
                                        'david@permanent.is'
                                    ]
                                },
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null,
                                betweenStringMatcherData: null,
                                userDefinedLargeSegmentMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'email in list [samrat.luintel.58@gmail.com, samrat@harmonic.ai, ...]'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'IN_SEGMENT',
                                negate: false,
                                userDefinedSegmentMatcherData: {
                                    segmentName: 'harmonic_internal'
                                },
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null,
                                betweenStringMatcherData: null,
                                userDefinedLargeSegmentMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'in segment harmonic_internal'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'IN_SEGMENT',
                                negate: false,
                                userDefinedSegmentMatcherData: {
                                    segmentName: 'flow_alpha_testers'
                                },
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null,
                                betweenStringMatcherData: null,
                                userDefinedLargeSegmentMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'in segment flow_alpha_testers'
                },
                {
                    conditionType: 'ROLLOUT',
                    matcherGroup: {
                        combiner: 'AND',
                        matchers: [
                            {
                                keySelector: {
                                    trafficType: 'user',
                                    attribute: null
                                },
                                matcherType: 'ALL_KEYS',
                                negate: false,
                                userDefinedSegmentMatcherData: null,
                                whitelistMatcherData: null,
                                unaryNumericMatcherData: null,
                                betweenMatcherData: null,
                                dependencyMatcherData: null,
                                booleanMatcherData: null,
                                stringMatcherData: null,
                                betweenStringMatcherData: null,
                                userDefinedLargeSegmentMatcherData: null
                            }
                        ]
                    },
                    partitions: [
                        {
                            treatment: 'on',
                            size: 100
                        },
                        {
                            treatment: 'off',
                            size: 0
                        }
                    ],
                    label: 'default rule'
                }
            ],
            configurations: {},
            sets: []
        }
    ]
};
export { authenticateRouteMockResponse, firebaseLookupMockData, firebaseSignInWithPasswordMockData, splitKeysMockResponse };
