export const UPSERT_PEOPLE_LIST_NAMED_VIEW_MOCKED_RESPONSE = {
    upsertPeopleListNamedView: {
        id: 19182,
        entityUrn: 'urn:harmonic:person_list_named_view:19182',
        name: '1',
        visibleColumns: [
            'urn:harmonic:person_field:person_customers_connections',
            'urn:harmonic:person_field:person_emails',
            'urn:harmonic:person_field:person_linkedin_url',
            'urn:harmonic:person_field:person_linkedin_headline',
            'urn:harmonic:person_field:person_highlights_category_list',
            'urn:harmonic:person_field:person_position_title',
            'urn:harmonic:person_field:person_position_company_id',
            'urn:harmonic:person_field:person_position_company_tags',
            'urn:harmonic:person_field:person_last_company_id',
            'urn:harmonic:person_field:person_location',
            'urn:harmonic:person_field:person_education_experience_school'
        ],
        searchQuery: {
            filterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: []
            },
            pagination: {
                start: null,
                pageSize: 25
            },
            sort: [
                {
                    sortField: 'person_entry_created_at',
                    descending: true,
                    sortContextValues: null
                }
            ],
            controlledFilterGroup: null,
            mergeQueryForNestedFields: false
        },
        displayType: 'GRID',
        groupByField: null,
        hideEmptyColumns: false,
        __typename: 'PersonListNamedView'
    }
};
