const ADD_PEOPLE_TO_WATCHLIST_WITH_CANONCIALS_MOCKED_RESPONSE = {
    addPeopleToWatchlistWithCanonicals: {
        userPeopleImportUrn: 'urn:harmonic:user_people_import:817aedb0-50e6-4a0b-b433-5bc43d9f270e',
        totalPeopleCount: 291,
        peopleFoundCount: 291,
        peopleNotFoundCount: 0,
        peopleInvalidCount: 0,
        __typename: 'AddPeopleToWatchlistWithCanonicalsPayload'
    }
};
export default ADD_PEOPLE_TO_WATCHLIST_WITH_CANONCIALS_MOCKED_RESPONSE;
