import { CompanyStage, CompanyType, FundingAttributeNullStatus, OwnershipStatus, TagType } from '__generated__/graphql';
export const COMPANIES_BY_IDS_HARMONIC_DETAILS_MOCK = {
    id: 1,
    name: 'Harmonic',
    legal_name: 'Harmonic AI, Inc.',
    description: "Harmonic's main product is a data engine that provides daily-updated company and professional profiles, allowing users to track company growth, team changes, and funding rounds. The main technologies used include a platform and API for accessing real-time company, team, and traction information.",
    external_description: 'Harmonic is on a mission to make the startup universe discoverable.\n\nToday, harmonic.ai is used by teams like Brex, Greylock, Index, a16z, MongoDB, Notion, and Bloomberg to connect startups with the capital and resources that they need to drive growth. Our team is backed by top-tier investors like Craft, Sozo, Floodgate, SOMA, Teamworthy, AVG and many others.\n\nOpen roles: https://harmonic.ai/careers',
    logo_url: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
    updatedAt: '2024-09-11T11:38:49.000Z',
    created_at: '2020-07-20T02:30:32.000Z',
    company_type: CompanyType.STARTUP,
    contact: {
        emails: ['info@harmonic.ai', 'support@harmonic.ai'],
        execEmails: [
            'aaron@dropbox.com',
            'bryan@withplural.com',
            'max@harmonic.ai',
            'aaron.bartel@gmail.com',
            'ajay14@gmail.com',
            'aaronbartel@gmail.com'
        ],
        phoneNumbers: [],
        primaryEmail: 'max@harmonic.ai',
        __typename: 'ContactMetadata'
    },
    likelihoodOfBacking: null,
    fundingPerEmployee: 579807.69,
    person_relationships: [],
    person_relationships_founders_and_ceos: null,
    person_relationships_executives: null,
    leadership_prior_companies: null,
    customer_type: 'B2B & B2C',
    entityUrn: 'urn:harmonic:company:1',
    stage: CompanyStage.SERIES_A,
    foundingDate: {
        date: '2021-07-01T04:00:00Z',
        granularity: 'MONTH',
        __typename: 'FoundingDate'
    },
    funding: {
        fundingTotal: '30150000',
        numFundingRounds: 3,
        investors: [],
        lastFundingAt: '2022-11-07T00:00:00Z',
        lastFundingType: 'SERIES_A',
        lastFundingTotal: '23000000',
        fundingStage: 'SERIES_A',
        __typename: 'Funding'
    },
    funding_attribute_null_status: FundingAttributeNullStatus.EXISTS_BUT_UNDISCLOSED,
    headcount: 52,
    correctedHeadcount: 52,
    externalHeadcount: 50,
    highlights: [
        {
            category: 'VENTURE_BACKED',
            text: 'Backed By Alumni Ventures, Builders VC, Craft Ventures, Fin Capital, Flexport, Floodgate, MVP Ventures, NNS, Pelion Venture Partners, Shasta Ventures, Soma Capital, Sozo Ventures, Teamworthy Ventures, The 6ixth Event 🦕',
            __typename: 'Highlight'
        }
    ],
    employeeHighlights: [
        {
            category: 'LEGACY_TECH_COMPANY_EXPERIENCE',
            text: 'Legacy Tech Company Experience, Carter Headley: Global Business Transformation Services Senior Consultant @ IBM, 3/2013-1/2015',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: "Top University, Travis Hairfield: Bachelor's degree @ University of North Carolina",
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Miguel Caballero Pinto: Software Engineer II (Microsoft Visual Studio) @ Microsoft, 10/2012-6/2016',
            __typename: 'Highlight'
        },
        {
            category: 'PRIOR_VC_BACKED_EXECUTIVE',
            text: 'Prior VC Backed Executive, Miguel Caballero Pinto: CTO @ Blanket, Backed By: Chingona Ventures, Adam Caplan, ANIMO Ventures, Brighton Angel Partners, David Morin',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Sam Kirschner: BA @ University of California, Berkeley',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_ADVISER',
            text: 'Seasoned Adviser, Sam Kirschner: Advisor @ On Deck (1322 Employees) 10/2022-10/2023',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Emma Lindsay: Bachelor of Science @ Massachusetts Institute of Technology',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Spencer Foust: Master of Science (MS), Management Science and Engineering @ Stanford',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Spencer Foust: Master of Science (MS) @ Stanford',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Spencer Foust: iPhone Operations Program Manager, Post-Ramp Qualification (PRQ) @ Apple, 1/2015-12/2015',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Ludwig Pierre Schulze: Bachelor of Arts - BA @ Brown',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Ludwig Pierre Schulze: Bachelor of Arts - BA @ Brown',
            __typename: 'Highlight'
        },
        {
            category: 'PRIOR_VC_BACKED_EXECUTIVE',
            text: 'Prior VC Backed Executive, Ludwig Pierre Schulze: General Manager & Business Development, Digital Financial Services @ Nokia, ',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_EXECUTIVE',
            text: 'Seasoned Executive, Ludwig Pierre Schulze: General Manager & Business Development, Digital Financial Services @ Nokia (85476 Employees) 1/2008-12/2012',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Sang M.: Staff Software Engineer @ LinkedIn, 9/2018-5/2021',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Colin Cook: Coach @ Google, 4/2015-7/2020',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Junfeng Kenny Xu: Senior Software Engineer @ LinkedIn, 10/2018-5/2021',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Austin Berke: Bachelor’s Degree @ UCLA',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Austin Berke: Software Engineer @ Microsoft, 9/2019-2/2021',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, John Matthews: B.S @ Massachusetts Institute of Technology',
            __typename: 'Highlight'
        },
        {
            category: 'ELITE_INDUSTRY_EXPERIENCE',
            text: 'Elite Industry Experience, John Matthews: Associate Consultant @ Bain, 8/2017-8/2019',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_OPERATOR',
            text: 'Seasoned Operator, John Matthews: Head of International Expansion @ Scale AI (4018 Employees) 1/2022-1/2023',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Darri Atlason: Stanford International Honors Program @ Stanford',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Darri Atlason: Stanford',
            __typename: 'Highlight'
        },
        {
            category: 'PRIOR_VC_BACKED_EXECUTIVE',
            text: 'Prior VC Backed Executive, Darri Atlason: VP Growth and Strategy @ Lucinity, Backed By: byFounders, Karma Ventures, Crowberry Capital, Preceptor Capital',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_EXECUTIVE',
            text: 'Seasoned Executive, Divya Jakatdar: President @ USC Undergraduate Student Government (146 Employees) 2/2023-7/2024',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Seth Wieder: Senior Product Manager @ Google, 1/2022-8/2024',
            __typename: 'Highlight'
        },
        {
            category: 'PRIOR_VC_BACKED_EXECUTIVE',
            text: 'Prior VC Backed Executive, Sam Taylor: Chief Revenue Officer (CRO) @ Endgame, Backed By: Boris Jabes, Des Traynor, Elena Verna, Guillaume Cabane, Jason Warner',
            __typename: 'Highlight'
        },
        {
            category: 'PRIOR_VC_BACKED_EXECUTIVE',
            text: 'Prior VC Backed Executive, Sam Taylor: Vice President, Commercial Business - Quip @ Salesforce, ',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Sam Taylor: Vice President, Commercial Business - Quip @ Salesforce, 8/2017-10/2020',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_EXECUTIVE',
            text: 'Seasoned Executive, Sam Taylor: Vice President, Commercial Business - Quip @ Salesforce (79260 Employees) 8/2017-10/2020',
            __typename: 'Highlight'
        },
        {
            category: 'LEGACY_TECH_COMPANY_EXPERIENCE',
            text: 'Legacy Tech Company Experience, Mike Palmer: Sales @ Oracle, 8/2017-4/2021',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_OPERATOR',
            text: 'Seasoned Operator, Aaron Bartel: Sales Director, Americas @ Hopin (292 Employees) 7/2021-7/2022',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Max Ruderman: Bachelor of Science (B.S.) @ Cornell',
            __typename: 'Highlight'
        },
        {
            category: 'PRIOR_VC_BACKED_EXECUTIVE',
            text: 'Prior VC Backed Executive, Max Ruderman: Chief Operating Officer @ Harmonic, ',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Max Ruderman: Senior Software Engineer @ Google, 6/2018-2/2021',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Stephanie Lee: Bachelor of Arts (B.A.) @ Princeton',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_OPERATOR',
            text: 'Seasoned Operator, Stephanie Lee: Director Of Preconstruction @ Villa (663 Employees) 11/2020-2/2022',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Mike Heller: Bachelors @ UCLA',
            __typename: 'Highlight'
        },
        {
            category: 'PRIOR_VC_BACKED_EXECUTIVE',
            text: 'Prior VC Backed Executive, Mike Heller: GM - Clearbit Marketing Solutions @ Clearbit, Backed By: Zetta Venture Partners, SV Angel, S2 Capital, Oyster Ventures, Othman Laraki, Naval Ravikant, Kenneth Ballenegger, Ilya Sukhar, Hiten Shah, Fuel Capital, Cross Creek, Bedrock Capital, Battery Ventures',
            __typename: 'Highlight'
        },
        {
            category: 'PRIOR_VC_BACKED_EXECUTIVE',
            text: 'Prior VC Backed Executive, Ben Fowler: Senior Vice President of Strategic Partnerships @ Silicon Valley Bank, ',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_EXECUTIVE',
            text: 'Seasoned Executive, Ben Fowler: Senior Vice President of Strategic Partnerships @ Silicon Valley Bank (5047 Employees) 6/2020-7/2023',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_OPERATOR',
            text: 'Seasoned Operator, Ben Fowler: Program Director @ Impact Hub Taipei (290 Employees) 9/2017-8/2018',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_OPERATOR',
            text: 'Seasoned Operator, Ryan Cahalane: Senior Mid-Market Account Executive @ Gem (351 Employees) 6/2022-8/2023',
            __typename: 'Highlight'
        },
        {
            category: 'SEASONED_OPERATOR',
            text: 'Seasoned Operator, Ryan Cahalane: Senior Enterprise Account Executive @ Dropbox (3697 Employees) 8/2018-6/2021',
            __typename: 'Highlight'
        },
        {
            category: 'DEEP_TECHNICAL_BACKGROUND',
            text: 'Deep Technical Background, Ajay Sohmshetty: Master’s Degree, Computer Science @ Stanford University',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Ajay Sohmshetty: University of Oxford',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: "Top University, Ajay Sohmshetty: Bachelor's of Science @ Stanford",
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Ajay Sohmshetty: Master’s Degree @ Stanford',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Ajay Sohmshetty: Machine Learning Software Engineer @ Google, 5/2019-10/2020',
            __typename: 'Highlight'
        },
        {
            category: 'ELITE_INDUSTRY_EXPERIENCE',
            text: 'Elite Industry Experience, Soham Kamat: Senior Strategy Analyst @ Accenture, 1/2020-12/2021',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Joseph Wang: Software Engineering Manager @ LinkedIn, 1/2023-12/2023',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Joseph Wang: Student Ambassador @ Google, 2/2012-12/2012',
            __typename: 'Highlight'
        },
        {
            category: 'PRIOR_VC_BACKED_FOUNDER',
            text: 'Prior VC Backed Founder, Bryan Casey: Cofounder & CEO @ Harmonic, ',
            __typename: 'Highlight'
        },
        {
            category: '30_M_CLUB',
            text: '$30M Club, Bryan Casey: Cofounder & CEO @ Harmonic, $30.1M Raised, ',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_RESEARCH_INSTITUTION_EXPERIENCE',
            text: 'Major Research Institution Experience, Jimmy G Lafontaine Rivera: Doctor of Philosophy (Ph.D.), Chemical Engineering @ University of California, Los Angeles',
            __typename: 'Highlight'
        },
        {
            category: 'DEEP_TECHNICAL_BACKGROUND',
            text: 'Deep Technical Background, Jimmy G Lafontaine Rivera: Doctor of Philosophy (Ph.D.), Chemical Engineering @ University of California, Los Angeles',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Jimmy G Lafontaine Rivera: Doctor of Philosophy (Ph.D.) @ UCLA',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Akshaya S Bhat: Staff Software Engineer @ LinkedIn, 3/2023-1/2024',
            __typename: 'Highlight'
        },
        {
            category: 'LEGACY_TECH_COMPANY_EXPERIENCE',
            text: 'Legacy Tech Company Experience, Mike Palmer: Sales @ Oracle, 8/2017-4/2021',
            __typename: 'Highlight'
        },
        {
            category: 'TOP_UNIVERSITY',
            text: 'Top University, Akbar Khan: Bachelor of Science - BS @ University of California, Berkeley',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Eric Kim: IP Counsel @ PayPal, 4/2016-9/2017',
            __typename: 'Highlight'
        },
        {
            category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
            text: 'Major Tech Company Experience, Eric Kim: Lead Counsel (Privacy) @ Facebook, 11/2020-7/2021',
            __typename: 'Highlight'
        }
    ],
    location: {
        addressFormatted: 'San Francisco, CA, USA',
        location: 'San Francisco, US',
        street: '',
        city: 'San Francisco',
        state: 'California',
        zip: '',
        country: 'United States',
        __typename: 'LocationMetadata'
    },
    ownership_status: OwnershipStatus.PRIVATE,
    socials: {
        facebook: null,
        twitter: {
            url: 'https://twitter.com/harmonic_ai',
            followerCount: 448,
            status: null,
            __typename: 'SocialMetadata'
        },
        linkedin: {
            url: 'https://linkedin.com/company/harmonicai',
            followerCount: 3083,
            status: null,
            __typename: 'SocialMetadata'
        },
        instagram: {
            url: 'https://www.instagram.com/harmonic.ai',
            followerCount: 147,
            status: null,
            __typename: 'SocialMetadata'
        },
        crunchbase: {
            url: 'https://www.crunchbase.com/organization/harmonic-ai',
            followerCount: null,
            status: null,
            __typename: 'SocialMetadata'
        },
        pitchbook: {
            url: 'https://pitchbook.com/profiles/company/453414-79',
            followerCount: null,
            status: null,
            __typename: 'SocialMetadata'
        },
        angellist: {
            url: 'https://angel.co/company/harmonic_ai',
            followerCount: null,
            status: null,
            __typename: 'SocialMetadata'
        },
        indeed: null,
        youtube: null,
        monster: null,
        stackoverflow: null,
        __typename: 'Socials'
    },
    typed_tags: [
        {
            entityUrn: 'urn:harmonic:tag:212e0b79-80fe-42c4-ac20-52c5e58b90e1',
            tag_value: 'Social Networking',
            dateAdded: '2022-05-18T10:19:24.000Z',
            tag_type: TagType.INDUSTRY,
            __typename: 'Tag'
        }
    ],
    typed_tags_v2: [],
    user_notes: 'aYo this company slaps!',
    team_notes: 'Harmonic is the best!',
    user_connections: [
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 171265602,
                fullName: 'Saman  Rizvi',
                __typename: 'Person'
            },
            user: {
                email: 'toobazehra2004@gmail.com',
                name: 'Tooba Zehra',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'toobazehra2004@gmail.com',
                name: 'Tooba Zehra',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166822766,
                fullName: 'Hisham Wolley',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165227680,
                fullName: 'Dillan Edwards',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160637508,
                fullName: 'Rob H.',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 171220038,
                fullName: 'Madeline C.',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 171220038,
                fullName: 'Madeline C.',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 171220038,
                fullName: 'Madeline C.',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 171220038,
                fullName: 'Madeline C.',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 180603641,
                fullName: 'ɴαvεεd Αнмαd',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 168299092,
                fullName: 'Alina Sabir',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166814550,
                fullName: 'Kayizzi Sulaiman',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165228657,
                fullName: 'Chris Vasquez',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164027186,
                fullName: 'Anusha Chatterjee',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166822766,
                fullName: 'Hisham Wolley',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160637508,
                fullName: 'Rob H.',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165228657,
                fullName: 'Chris Vasquez',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 247160,
                fullName: 'Alayzain (Zain) Rizavi',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165227680,
                fullName: 'Dillan Edwards',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166822766,
                fullName: 'Hisham Wolley',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165227680,
                fullName: 'Dillan Edwards',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160637508,
                fullName: 'Rob H.',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164027186,
                fullName: 'Anusha Chatterjee',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156628753,
                fullName: 'Tristen Cross',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156628753,
                fullName: 'Tristen Cross',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156628753,
                fullName: 'Tristen Cross',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506689,
                fullName: 'Justin Sharp',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 180603641,
                fullName: 'ɴαvεεd Αнмαd',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'akshaya@harmonic.ai',
                name: 'Akshaya Bhat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165228657,
                fullName: 'Chris Vasquez',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 247160,
                fullName: 'Alayzain (Zain) Rizavi',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 247160,
                fullName: 'Alayzain (Zain) Rizavi',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 247160,
                fullName: 'Alayzain (Zain) Rizavi',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 247160,
                fullName: 'Alayzain (Zain) Rizavi',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 247160,
                fullName: 'Alayzain (Zain) Rizavi',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 247160,
                fullName: 'Alayzain (Zain) Rizavi',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164027186,
                fullName: 'Anusha Chatterjee',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45443871,
                fullName: 'Madeline Le',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166814550,
                fullName: 'Kayizzi Sulaiman',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 25543601,
                fullName: 'Brady Berryessa',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 82718555,
                fullName: 'Esther Kim',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312641,
                fullName: 'Sumit Ghosh',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 122006955,
                fullName: 'Wade Minter',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 32120651,
                fullName: 'Tyler Lagaly',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156628753,
                fullName: 'Tristen Cross',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165228657,
                fullName: 'Chris Vasquez',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166814550,
                fullName: 'Kayizzi Sulaiman',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165227680,
                fullName: 'Dillan Edwards',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'samrat@harmonic.ai',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'andy@harmonic.ai',
                name: 'Andy Feng',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'miles@harmonic.ai',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 247160,
                fullName: 'Alayzain (Zain) Rizavi',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 165229182,
                fullName: 'Mubangizi Allan',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 182482,
                fullName: 'Spencer Foust',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'ben@harmonic.ai',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'darrel@harmonic.ai',
                name: null,
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 166690289,
                fullName: 'Patrick Hughes',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'manna.rizvi@gmail.com',
                name: 'Manna Rizvi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 171195513,
                fullName: 'Tooba Zehra',
                __typename: 'Person'
            },
            user: {
                email: 'manna.rizvi@gmail.com',
                name: 'Manna Rizvi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 160636294,
                fullName: 'Shafaq Tirmizi',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73568032,
                fullName: 'Olivia Haskell',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 64299185,
                fullName: 'Travis Hairfield',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312644,
                fullName: 'Tom Alexander',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312638,
                fullName: 'Arthur Nangai',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'seth@harmonic.ai',
                name: 'Seth Wieder',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'antoine@permanent.is',
                name: 'Antoine Plu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie+test@harmonic.ai',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'jimmy@harmonic.ai',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'matthiaslink@harmonic.ai',
                name: 'Matthias Link',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'joseph@harmonic.ai',
                name: 'Joseph Wang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'miguel@harmonic.ai',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 168299092,
                fullName: 'Alina Sabir',
                __typename: 'Person'
            },
            user: {
                email: 'patrickhughes@harmonic.ai',
                name: 'Patrick Hughes',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72840952,
                fullName: 'Junfeng Kenny Xu',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164582582,
                fullName: 'Mike Heller',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36197187,
                fullName: 'Kailee Hicks',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 162817028,
                fullName: 'Darrel Pollard',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157508371,
                fullName: 'John Yeglinski',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46883313,
                fullName: 'Ryan Cahalane',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36306009,
                fullName: 'Shiven Samant',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157506558,
                fullName: 'Aiham H.',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 96585871,
                fullName: 'Ben Fowler',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311822,
                fullName: 'Abdi Wibowo M.',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 125901467,
                fullName: 'Mohit Yadav',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 110209532,
                fullName: 'Nate Shirley',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311814,
                fullName: 'Bryan Casey',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1104913,
                fullName: 'Aantorik Ganguly',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 106689997,
                fullName: 'Purnima Kumar',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312639,
                fullName: 'Bill Kabanga Twinomuhwezi',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161085098,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312645,
                fullName: 'Eric Kim',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 73639745,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ryan@harmonic.ai',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 247160,
                fullName: 'Alayzain (Zain) Rizavi',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311820,
                fullName: 'Andrew Michelson',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 171195513,
                fullName: 'Tooba Zehra',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 171265602,
                fullName: 'Saman  Rizvi',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 312642,
                fullName: 'Samrat Luintel',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72946983,
                fullName: 'Johnny Choi',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311819,
                fullName: 'Miles Konstantin',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311824,
                fullName: 'Simant Kaushik Luitel',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156631532,
                fullName: 'Joseph Wang',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 55951673,
                fullName: 'Sam Weiss',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'erin@harmonic.ai',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 107507138,
                fullName: 'John Brkich',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'austin@harmonic.ai',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 159049861,
                fullName: 'Sam Kirschner',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'kenny@harmonic.ai',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46137307,
                fullName: 'Sam Taylor',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 91437404,
                fullName: 'Miguel Caballero Pinto',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102917529,
                fullName: 'Ali Hashemi',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155348322,
                fullName: 'Chad Packard',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 70274429,
                fullName: 'Naveed Ahmad',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 171220038,
                fullName: 'Madeline C.',
                __typename: 'Person'
            },
            user: {
                email: 'akbar@harmonic.ai',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'darri-embedded@harmonic.ai',
                name: 'Darri Atlason',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 1117192,
                fullName: 'Seth Wieder',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311816,
                fullName: 'Sang M.',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311821,
                fullName: 'Austin Berke',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'johnmatthews@harmonic.ai',
                name: 'John Matthews',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 75304441,
                fullName: 'Carter Headley',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 118535728,
                fullName: 'Matthias Link',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155924927,
                fullName: 'Gavin Ong',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'aaron@harmonic.ai',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 31189396,
                fullName: 'John Matthews',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 21628328,
                fullName: 'Zhansaya A',
                __typename: 'Person'
            },
            user: {
                email: 'shafaq@harmonic.ai',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 130139057,
                fullName: 'Jimmy G Lafontaine Rivera',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'simant@harmonic.ai',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'johnny@harmonic.ai',
                name: 'Johnny',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 46143982,
                fullName: 'Akshaya S Bhat',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'samsherman@harmonic.ai',
                name: 'Sam Sherman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 81500599,
                fullName: 'Darri Atlason',
                __typename: 'Person'
            },
            user: {
                email: 'nach@harmonic.ai',
                name: 'Nach Mehta',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 45952848,
                fullName: 'Emma Lindsay',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'max@harmonic.ai',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'kailee@harmonic.ai',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'mike@harmonic.ai',
                name: 'Mikey P',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 156628753,
                fullName: 'Tristen Cross',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 354919,
                fullName: 'Ajay Sohmshetty',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'soham@harmonic.ai',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'carter@harmonic.ai',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 102287,
                fullName: 'Ludwig Pierre Schulze',
                __typename: 'Person'
            },
            user: {
                email: 'olivia@harmonic.ai',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'emma@harmonic.ai',
                name: 'Emma Lindsay',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'gavin-embedded@harmonic.ai',
                name: 'Gavin 😬',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'jackbaylor@harmonic.ai',
                name: 'Jack Baylor',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 76329319,
                fullName: 'Divya Jakatdar',
                __typename: 'Person'
            },
            user: {
                email: 'vidur@harmonic.ai',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 157932872,
                fullName: 'Jack Baylor',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 161780079,
                fullName: 'Max Ruderman',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 369105,
                fullName: 'Stephanie Lee',
                __typename: 'Person'
            },
            user: {
                email: 'colin@harmonic.ai',
                name: 'Colin Cook',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 114375482,
                fullName: 'Erin Mccarthy',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 311818,
                fullName: 'Akbar Khan',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 72676044,
                fullName: 'Soham Kamat',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 8658696,
                fullName: 'Aaron Bartel',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181249309,
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 36992162,
                fullName: 'Vidur Bahal',
                __typename: 'Person'
            },
            user: {
                email: 'divya@harmonic.ai',
                name: 'Divya Jakatdar',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 164027186,
                fullName: 'Anusha Chatterjee',
                __typename: 'Person'
            },
            user: {
                email: 'sang@harmonic.ai',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'samw@harmonic.ai',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 155926185,
                fullName: 'Colin Cook',
                __typename: 'Person'
            },
            user: {
                email: 'stephanie@harmonic.ai',
                name: 'Stephanie Lee 🫣',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: {
                id: 181601801,
                fullName: 'Darius T',
                __typename: 'Person'
            },
            user: {
                email: 'samrat.luintel.58+depreceated@gmail.com',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        },
        {
            targetPerson: null,
            user: {
                email: 'ali@harmonic.ai',
                name: 'Ali 🎹',
                __typename: 'User'
            },
            __typename: 'UserConnection'
        }
    ],
    website: {
        url: 'https://harmonic.ai',
        domain: 'harmonic.ai',
        isBroken: false,
        __typename: 'Website'
    },
    userConnectionsOverview: {
        correspondenceSummary: {
            lastEmailAt: '2024-09-10T23:55:09.000Z',
            lastEmailContactPersonEmail: 'mike@harmonic.ai',
            lastEmailContactPerson: {
                fullName: 'Mike Palmer',
                __typename: 'Person'
            },
            lastMeetingAt: null,
            lastMeetingContactPersonEmail: null,
            lastMeetingContactPerson: null,
            __typename: 'CorrespondenceSummary'
        },
        __typename: 'UserConnectionOverview'
    },
    webTraffic: 35463,
    tractionMetrics: {
        headcount: {
            ago14d: {
                value_14d_ago: 53,
                real_change_14d_ago: -1,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 53,
                real_change_30d_ago: -1,
                percent_change_30d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 51,
                real_change_90d_ago: 1,
                percent_change_90d_ago: 1.96,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 47,
                real_change_180d_ago: 5,
                percent_change_180d_ago: 10.64,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 39,
                real_change_365d_ago: 13,
                percent_change_365d_ago: 33.33,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountAdvisor: {
            latestMetricValue: 8,
            ago14d: {
                value_14d_ago: 8,
                real_change_14d_ago: 0,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 8,
                real_change_30d_ago: 0,
                percent_change_30d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 8,
                real_change_90d_ago: 0,
                percent_change_90d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 9,
                real_change_180d_ago: -1,
                percent_change_180d_ago: -11.73,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 7,
                real_change_365d_ago: 1,
                percent_change_365d_ago: 17.4,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountCustomerSuccess: {
            latestMetricValue: 2,
            ago14d: {
                value_14d_ago: 2,
                real_change_14d_ago: 0,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 3,
                real_change_30d_ago: -1,
                percent_change_30d_ago: -34.59,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 3,
                real_change_90d_ago: -1,
                percent_change_90d_ago: -34.59,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 3,
                real_change_180d_ago: -1,
                percent_change_180d_ago: -22.76,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 1,
                real_change_365d_ago: 1,
                percent_change_365d_ago: 76.1,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountData: {
            latestMetricValue: 2,
            ago14d: {
                value_14d_ago: 2,
                real_change_14d_ago: 0,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 3,
                real_change_30d_ago: -1,
                percent_change_30d_ago: -34.59,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 3,
                real_change_90d_ago: -1,
                percent_change_90d_ago: -34.59,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 3,
                real_change_180d_ago: -1,
                percent_change_180d_ago: -22.76,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 1,
                real_change_365d_ago: 1,
                percent_change_365d_ago: 76.1,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountDesign: {
            latestMetricValue: 1,
            ago14d: {
                value_14d_ago: 1,
                real_change_14d_ago: 0,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 2,
                real_change_30d_ago: -1,
                percent_change_30d_ago: -50.94,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 2,
                real_change_90d_ago: -1,
                percent_change_90d_ago: -50.94,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 1,
                real_change_180d_ago: 0,
                percent_change_180d_ago: -22.76,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 1,
                real_change_365d_ago: 0,
                percent_change_365d_ago: -11.95,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountEngineering: {
            latestMetricValue: 15,
            ago14d: {
                value_14d_ago: 16,
                real_change_14d_ago: -1,
                percent_change_14d_ago: -8.02,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 16,
                real_change_30d_ago: -1,
                percent_change_30d_ago: -8.02,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 16,
                real_change_90d_ago: -1,
                percent_change_90d_ago: -8.02,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 13,
                real_change_180d_ago: 2,
                percent_change_180d_ago: 15.86,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 10,
                real_change_365d_ago: 5,
                percent_change_365d_ago: 46.75,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountFinance: {
            latestMetricValue: null,
            ago14d: {
                value_14d_ago: null,
                real_change_14d_ago: null,
                percent_change_14d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: null,
                real_change_30d_ago: null,
                percent_change_30d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: null,
                real_change_90d_ago: null,
                percent_change_90d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: null,
                real_change_180d_ago: null,
                percent_change_180d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: null,
                real_change_365d_ago: null,
                percent_change_365d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountLegal: {
            latestMetricValue: 1,
            ago14d: {
                value_14d_ago: 1,
                real_change_14d_ago: 0,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 2,
                real_change_30d_ago: -1,
                percent_change_30d_ago: -50.94,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 2,
                real_change_90d_ago: -1,
                percent_change_90d_ago: -50.94,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 2,
                real_change_180d_ago: -1,
                percent_change_180d_ago: -50.94,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 2,
                real_change_365d_ago: -1,
                percent_change_365d_ago: -50.94,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountMarketing: {
            latestMetricValue: 5,
            ago14d: {
                value_14d_ago: 5,
                real_change_14d_ago: 0,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 6,
                real_change_30d_ago: -1,
                percent_change_30d_ago: -18.24,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 3,
                real_change_90d_ago: 2,
                percent_change_90d_ago: 63.52,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 3,
                real_change_180d_ago: 2,
                percent_change_180d_ago: 93.1,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 2,
                real_change_365d_ago: 3,
                percent_change_365d_ago: 120.13,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountOperations: {
            latestMetricValue: 9,
            ago14d: {
                value_14d_ago: 9,
                real_change_14d_ago: 0,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 10,
                real_change_30d_ago: -1,
                percent_change_30d_ago: -11.7,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 10,
                real_change_90d_ago: -1,
                percent_change_90d_ago: -11.7,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 8,
                real_change_180d_ago: 1,
                percent_change_180d_ago: 15.86,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 7,
                real_change_365d_ago: 2,
                percent_change_365d_ago: 32.08,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountOther: {
            latestMetricValue: 1,
            ago14d: {
                value_14d_ago: null,
                real_change_14d_ago: null,
                percent_change_14d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: null,
                real_change_30d_ago: null,
                percent_change_30d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: null,
                real_change_90d_ago: null,
                percent_change_90d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: null,
                real_change_180d_ago: null,
                percent_change_180d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: null,
                real_change_365d_ago: null,
                percent_change_365d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountPeople: {
            latestMetricValue: 1,
            ago14d: {
                value_14d_ago: null,
                real_change_14d_ago: null,
                percent_change_14d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: null,
                real_change_30d_ago: null,
                percent_change_30d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: null,
                real_change_90d_ago: null,
                percent_change_90d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: null,
                real_change_180d_ago: null,
                percent_change_180d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: null,
                real_change_365d_ago: null,
                percent_change_365d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountProduct: {
            latestMetricValue: 2,
            ago14d: {
                value_14d_ago: 2,
                real_change_14d_ago: 0,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 2,
                real_change_30d_ago: 0,
                percent_change_30d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 2,
                real_change_90d_ago: 0,
                percent_change_90d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 2,
                real_change_180d_ago: 0,
                percent_change_180d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 2,
                real_change_365d_ago: 0,
                percent_change_365d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountSales: {
            latestMetricValue: 8,
            ago14d: {
                value_14d_ago: 8,
                real_change_14d_ago: 0,
                percent_change_14d_ago: -1.89,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 9,
                real_change_30d_ago: -1,
                percent_change_30d_ago: -12.79,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 10,
                real_change_90d_ago: -2,
                percent_change_90d_ago: -21.51,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 9,
                real_change_180d_ago: -1,
                percent_change_180d_ago: -11.73,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 10,
                real_change_365d_ago: -2,
                percent_change_365d_ago: -21.73,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        headcountSupport: {
            latestMetricValue: null,
            ago14d: {
                value_14d_ago: null,
                real_change_14d_ago: null,
                percent_change_14d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: null,
                real_change_30d_ago: null,
                percent_change_30d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: null,
                real_change_90d_ago: null,
                percent_change_90d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: null,
                real_change_180d_ago: null,
                percent_change_180d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: null,
                real_change_365d_ago: null,
                percent_change_365d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        fundingTotal: {
            ago14d: {
                value_14d_ago: 30150000,
                real_change_14d_ago: 0,
                percent_change_14d_ago: 0,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 30150000,
                real_change_30d_ago: 0,
                percent_change_30d_ago: 0,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 30150000,
                real_change_90d_ago: 0,
                percent_change_90d_ago: 0,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 30150000,
                real_change_180d_ago: 0,
                percent_change_180d_ago: 0,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 24142126,
                real_change_365d_ago: 6007874,
                percent_change_365d_ago: 24.89,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        facebookFollowerCount: {
            ago14d: {
                value_14d_ago: null,
                real_change_14d_ago: null,
                percent_change_14d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: null,
                real_change_30d_ago: null,
                percent_change_30d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: null,
                real_change_90d_ago: null,
                percent_change_90d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: null,
                real_change_180d_ago: null,
                percent_change_180d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: null,
                real_change_365d_ago: null,
                percent_change_365d_ago: null,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        linkedinFollowerCount: {
            ago14d: {
                value_14d_ago: 3087,
                real_change_14d_ago: 18,
                percent_change_14d_ago: 0.58,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 3023,
                real_change_30d_ago: 82,
                percent_change_30d_ago: 2.71,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 2798,
                real_change_90d_ago: 307,
                percent_change_90d_ago: 10.97,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 2442,
                real_change_180d_ago: 663,
                percent_change_180d_ago: 27.15,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 1699,
                real_change_365d_ago: 1406,
                percent_change_365d_ago: 82.75,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        instagramFollowerCount: {
            latestMetricValue: 147,
            ago14d: {
                value_14d_ago: 147,
                real_change_14d_ago: 0,
                percent_change_14d_ago: 0,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 145,
                real_change_30d_ago: 2,
                percent_change_30d_ago: 1.38,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 145,
                real_change_90d_ago: 2,
                percent_change_90d_ago: 1.38,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 145,
                real_change_180d_ago: 2,
                percent_change_180d_ago: 1.38,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 145,
                real_change_365d_ago: 2,
                percent_change_365d_ago: 1.38,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        twitterFollowerCount: {
            ago14d: {
                value_14d_ago: 447,
                real_change_14d_ago: 1,
                percent_change_14d_ago: 0.22,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 443,
                real_change_30d_ago: 5,
                percent_change_30d_ago: 1.13,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 410,
                real_change_90d_ago: 38,
                percent_change_90d_ago: 9.27,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 347,
                real_change_180d_ago: 101,
                percent_change_180d_ago: 29.11,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 216,
                real_change_365d_ago: 232,
                percent_change_365d_ago: 107.41,
                __typename: 'TractionMetricsPoint'
            },
            __typename: 'TractionMetricsInfo'
        },
        webTraffic: {
            ago14d: {
                value_14d_ago: 33162,
                real_change_14d_ago: 2301,
                percent_change_14d_ago: 6.94,
                __typename: 'TractionMetricsPoint'
            },
            ago30d: {
                value_30d_ago: 30330,
                real_change_30d_ago: 5133,
                percent_change_30d_ago: 16.92,
                __typename: 'TractionMetricsPoint'
            },
            ago90d: {
                value_90d_ago: 27880,
                real_change_90d_ago: 7583,
                percent_change_90d_ago: 27.2,
                __typename: 'TractionMetricsPoint'
            },
            ago180d: {
                value_180d_ago: 20770,
                real_change_180d_ago: 14693,
                percent_change_180d_ago: 70.74,
                __typename: 'TractionMetricsPoint'
            },
            ago365d: {
                value_365d_ago: 21190,
                real_change_365d_ago: 14273,
                percent_change_365d_ago: 67.36,
                __typename: 'TractionMetricsPoint'
            },
            latestMetricValue: 35463,
            __typename: 'TractionMetricsInfo'
        },
        __typename: 'TractionMetrics'
    },
    affinityLists: [],
    watchlists: [
        {
            id: '03895978-b582-4d6d-8d63-7d0fa87fc957',
            name: 'Edison Example',
            entityUrn: 'urn:harmonic:company_watchlist:03895978-b582-4d6d-8d63-7d0fa87fc957',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '067a52a1-a0eb-41fe-8cb4-641e026f96a2',
            name: 'test list 1/9/2023',
            entityUrn: 'urn:harmonic:company_watchlist:067a52a1-a0eb-41fe-8cb4-641e026f96a2',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '103caf21-30cd-4679-9e5f-1e574518d2db',
            name: 'blackbird_test',
            entityUrn: 'urn:harmonic:company_watchlist:103caf21-30cd-4679-9e5f-1e574518d2db',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '105c16ed-f955-4f57-872c-1a3a8d25b9e2',
            name: 'US VC portfolio list',
            entityUrn: 'urn:harmonic:company_watchlist:105c16ed-f955-4f57-872c-1a3a8d25b9e2',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '138edb9b-2a9d-433e-b8c6-bb9935e370a4',
            name: 'Harmonic Portfolio Test',
            entityUrn: 'urn:harmonic:company_watchlist:138edb9b-2a9d-433e-b8c6-bb9935e370a4',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '15d2281e-e177-408f-9f62-da4b0390ac5f',
            name: "Justin's picks",
            entityUrn: 'urn:harmonic:company_watchlist:15d2281e-e177-408f-9f62-da4b0390ac5f',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '17558a77-75b1-4a62-946e-d5078231d255',
            name: 'test2',
            entityUrn: 'urn:harmonic:company_watchlist:17558a77-75b1-4a62-946e-d5078231d255',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '1a910a62-9c60-40bb-a130-f572a1f5562e',
            name: 'harmonic list',
            entityUrn: 'urn:harmonic:company_watchlist:1a910a62-9c60-40bb-a130-f572a1f5562e',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '1b7ca091-6d2f-45e3-8558-31212717ce65',
            name: 'Core Competitor Tracking',
            entityUrn: 'urn:harmonic:company_watchlist:1b7ca091-6d2f-45e3-8558-31212717ce65',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '2104fd16-f23e-49f5-a965-4894e76f114f',
            name: '8k_test_delete_me_brr',
            entityUrn: 'urn:harmonic:company_watchlist:2104fd16-f23e-49f5-a965-4894e76f114f',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '243a7053-fc3f-4bb2-9bb7-7a702aee279b',
            name: 'kanban',
            entityUrn: 'urn:harmonic:company_watchlist:243a7053-fc3f-4bb2-9bb7-7a702aee279b',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '24462091-0f40-454a-9dfa-ab1bc81001d5',
            name: 'Georgian July 12',
            entityUrn: 'urn:harmonic:company_watchlist:24462091-0f40-454a-9dfa-ab1bc81001d5',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '38d72234-5fa0-4e07-bc69-9b339fd99fb8',
            name: "Darius' leads",
            entityUrn: 'urn:harmonic:company_watchlist:38d72234-5fa0-4e07-bc69-9b339fd99fb8',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '399c65c5-343a-423e-b0a4-5cc943bccdb1',
            name: 'Soma Companies',
            entityUrn: 'urn:harmonic:company_watchlist:399c65c5-343a-423e-b0a4-5cc943bccdb1',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '499ca42a-526c-47e2-8c16-8558bbf67822',
            name: 'Outbound - Needs contact',
            entityUrn: 'urn:harmonic:company_watchlist:499ca42a-526c-47e2-8c16-8558bbf67822',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '500e2177-cc8a-4b0d-9a9f-7af6c14ddc2d',
            name: '1test',
            entityUrn: 'urn:harmonic:company_watchlist:500e2177-cc8a-4b0d-9a9f-7af6c14ddc2d',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '508ffaf2-181f-478b-8be3-7b7331e3b554',
            name: 'Pipeline',
            entityUrn: 'urn:harmonic:company_watchlist:508ffaf2-181f-478b-8be3-7b7331e3b554',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '54502867-5618-4492-a14a-49c63728bb13',
            name: 'monitor',
            entityUrn: 'urn:harmonic:company_watchlist:54502867-5618-4492-a14a-49c63728bb13',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '5a7e8480-a45e-426c-9d66-0368ba3fbb7c',
            name: 'Exclusion Test',
            entityUrn: 'urn:harmonic:company_watchlist:5a7e8480-a45e-426c-9d66-0368ba3fbb7c',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '5bc0203f-59fb-4b0d-ae0d-f43e74b9e135',
            name: 'Layups - Stuffed Companies',
            entityUrn: 'urn:harmonic:company_watchlist:5bc0203f-59fb-4b0d-ae0d-f43e74b9e135',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '5ed5a1c4-659f-4395-bafb-f1e3c2cd9fd6',
            name: 'My Watchlist',
            entityUrn: 'urn:harmonic:company_watchlist:5ed5a1c4-659f-4395-bafb-f1e3c2cd9fd6',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '62be0935-7282-43d2-ad9f-dabf6e35da60',
            name: 'GPT_Tuning',
            entityUrn: 'urn:harmonic:company_watchlist:62be0935-7282-43d2-ad9f-dabf6e35da60',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '62d365cd-d60e-45e3-9510-62473d222e81',
            name: 'New Watchlist Right Now',
            entityUrn: 'urn:harmonic:company_watchlist:62d365cd-d60e-45e3-9510-62473d222e81',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '657d6efa-0af6-4fc1-b494-a991a3a28995',
            name: 'nfx march 2023',
            entityUrn: 'urn:harmonic:company_watchlist:657d6efa-0af6-4fc1-b494-a991a3a28995',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '6a16842a-0803-4c4a-b30f-3283a18699f3',
            name: 'My Watchlist',
            entityUrn: 'urn:harmonic:company_watchlist:6a16842a-0803-4c4a-b30f-3283a18699f3',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '6a86d080-0133-4f21-a24e-bdf70e8f52c4',
            name: 'kanban',
            entityUrn: 'urn:harmonic:company_watchlist:6a86d080-0133-4f21-a24e-bdf70e8f52c4',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '6c814a76-08bc-483a-85b1-485e378e2bf6',
            name: 'Brex Tag Support - July 2024',
            entityUrn: 'urn:harmonic:company_watchlist:6c814a76-08bc-483a-85b1-485e378e2bf6',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '72783068-a823-40cc-afe7-064b8f179d5f',
            name: 'company list',
            entityUrn: 'urn:harmonic:company_watchlist:72783068-a823-40cc-afe7-064b8f179d5f',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '77777dca-67b1-4a33-9126-1ff5da1c302c',
            name: 'New Northzone',
            entityUrn: 'urn:harmonic:company_watchlist:77777dca-67b1-4a33-9126-1ff5da1c302c',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '7de7ec8e-a398-4c96-90de-3976f9801a15',
            name: '0 - keyword - mary ridge vc - series a - exclude keyword',
            entityUrn: 'urn:harmonic:company_watchlist:7de7ec8e-a398-4c96-90de-3976f9801a15',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '8ec16f35-3385-4214-83ff-d56651a61620',
            name: 'Accel Watchlist',
            entityUrn: 'urn:harmonic:company_watchlist:8ec16f35-3385-4214-83ff-d56651a61620',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '8f5a24f5-b08e-4903-b5fe-6abb2a80029a',
            name: "May'24 Customers",
            entityUrn: 'urn:harmonic:company_watchlist:8f5a24f5-b08e-4903-b5fe-6abb2a80029a',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '9531b9b7-326a-465b-95b0-9a4d7e903937',
            name: "Johnny's companies",
            entityUrn: 'urn:harmonic:company_watchlist:9531b9b7-326a-465b-95b0-9a4d7e903937',
            __typename: 'CompanyWatchlist'
        },
        {
            id: '9d400e57-736b-45e9-b186-54e4e7314ff6',
            name: 'a-teamfilter-test2',
            entityUrn: 'urn:harmonic:company_watchlist:9d400e57-736b-45e9-b186-54e4e7314ff6',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'a2fad442-b70c-4167-9812-ae2ad33287e5',
            name: 'Threshold - Katie',
            entityUrn: 'urn:harmonic:company_watchlist:a2fad442-b70c-4167-9812-ae2ad33287e5',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'a3ca556f-98c5-44d6-8c4e-e4cceee7cf55',
            name: 'Test Ataraxia',
            entityUrn: 'urn:harmonic:company_watchlist:a3ca556f-98c5-44d6-8c4e-e4cceee7cf55',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'a65ec4b1-1fa8-4664-aa11-b57d12033567',
            name: 'Khosla AI + ML',
            entityUrn: 'urn:harmonic:company_watchlist:a65ec4b1-1fa8-4664-aa11-b57d12033567',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'b10f4df7-c3c9-4a66-85ab-75b345c1ae33',
            name: 'Conference List',
            entityUrn: 'urn:harmonic:company_watchlist:b10f4df7-c3c9-4a66-85ab-75b345c1ae33',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'b37fb991-bf3b-4002-8fdb-0c473f431284',
            name: 'Active Pipe + Recently Lost Aug 12',
            entityUrn: 'urn:harmonic:company_watchlist:b37fb991-bf3b-4002-8fdb-0c473f431284',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'b86a8cf0-b640-41ba-b2ef-1d577c23dcc1',
            name: 'Scouting Hunt',
            entityUrn: 'urn:harmonic:company_watchlist:b86a8cf0-b640-41ba-b2ef-1d577c23dcc1',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'b8ba82d3-d224-4563-a682-1536e77f9a9e',
            name: 'Harmonic.ai',
            entityUrn: 'urn:harmonic:company_watchlist:b8ba82d3-d224-4563-a682-1536e77f9a9e',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'b9fd89a7-1981-43b2-bdba-9fb768813123',
            name: 'My Watchlist',
            entityUrn: 'urn:harmonic:company_watchlist:b9fd89a7-1981-43b2-bdba-9fb768813123',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'bba85001-b710-44d1-be75-cb39f7260c84',
            name: 'test',
            entityUrn: 'urn:harmonic:company_watchlist:bba85001-b710-44d1-be75-cb39f7260c84',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'bf17b6dd-be62-4e81-abeb-4ed43031502f',
            name: '0 - chrome likes 2',
            entityUrn: 'urn:harmonic:company_watchlist:bf17b6dd-be62-4e81-abeb-4ed43031502f',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'c4884c88-0618-4fa5-886d-3f1f0b9bf0a3',
            name: 'SamK_outreach',
            entityUrn: 'urn:harmonic:company_watchlist:c4884c88-0618-4fa5-886d-3f1f0b9bf0a3',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'c83642e7-c9bd-47c7-9286-e1dc141d2ee4',
            name: 'DT Test 1337 ',
            entityUrn: 'urn:harmonic:company_watchlist:c83642e7-c9bd-47c7-9286-e1dc141d2ee4',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'cb7f90b0-23ef-44df-960d-b850d8fafa09',
            name: 'Palo Alto Test',
            entityUrn: 'urn:harmonic:company_watchlist:cb7f90b0-23ef-44df-960d-b850d8fafa09',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'db6b8927-7001-4639-8c4d-93c689f61fc2',
            name: 'MCP - New list',
            entityUrn: 'urn:harmonic:company_watchlist:db6b8927-7001-4639-8c4d-93c689f61fc2',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'e1cff389-b13b-45b6-93b3-d55982e4b3d2',
            name: 'NFX January Enrichment',
            entityUrn: 'urn:harmonic:company_watchlist:e1cff389-b13b-45b6-93b3-d55982e4b3d2',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'e3f143cd-cde8-42b6-af08-5d69e59d1397',
            name: 'Kanban - testing ali',
            entityUrn: 'urn:harmonic:company_watchlist:e3f143cd-cde8-42b6-af08-5d69e59d1397',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'ed4ccf5f-64c7-4c51-9928-0f316818c4d5',
            name: '[Master] Active + Pilot Customer List',
            entityUrn: 'urn:harmonic:company_watchlist:ed4ccf5f-64c7-4c51-9928-0f316818c4d5',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'fc6af73b-67f0-414f-9ac1-738112f478e3',
            name: 'abc',
            entityUrn: 'urn:harmonic:company_watchlist:fc6af73b-67f0-414f-9ac1-738112f478e3',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'fdc18174-7f1a-4bbe-a699-1cc44bde8ff4',
            name: 'MTest',
            entityUrn: 'urn:harmonic:company_watchlist:fdc18174-7f1a-4bbe-a699-1cc44bde8ff4',
            __typename: 'CompanyWatchlist'
        },
        {
            id: 'fdf60cd2-378c-4123-a10f-6a06de381509',
            name: 'Pipeline',
            entityUrn: 'urn:harmonic:company_watchlist:fdf60cd2-378c-4123-a10f-6a06de381509',
            __typename: 'CompanyWatchlist'
        }
    ],
    isLikedByCurrentUser: false,
    isHiddenFromSearchesByCurrentUser: false,
    viewRecord: {
        createdAt: '2024-07-02T23:00:49.526Z',
        updatedAt: '2024-09-09T17:34:48.408Z',
        __typename: 'CompanyViewRecord'
    },
    relevanceScore: 83,
    __typename: 'Company'
};
export const getCompaniesByIdsMockData = {
    getCompaniesByIds: [
        COMPANIES_BY_IDS_HARMONIC_DETAILS_MOCK,
        {
            id: 3973641,
            name: 'Fizz',
            legal_name: null,
            description: 'Fizz provides a secure platform for college communities, prioritizing safety and privacy through university email verification and community-based moderation. The main technologies utilized include university email verification and community-based moderation to ensure safety and privacy within college communities.',
            external_description: 'Fizz is a private and authenticated platform designed to revolutionize the social fabric of communities, starting with colleges. After verifying a university email address, students use Fizz to post questions, memes, polls, and chat directly with other verified students, all while maintaining full control of their identity and privacy. Fizz communities are safe, authentic, and inclusive, made possible through community-based moderation. Launched by two Stanford dropouts, Fizz is currently expanding into colleges all across the US. In a time when social media is plagued by curated highlight reals and lack of authenticity, Fizz is on a mission to redefine the definition of a true social network.',
            logo_url: 'https://assets.harmonic.ai/company_76616a4d191a70f9ff17f4feed87d8f566ae5453540125f0e6bcc6948d42f75c.png',
            updatedAt: '2024-09-11T01:40:50.000Z',
            created_at: '2021-12-17T01:17:53.000Z',
            company_type: CompanyType.STARTUP,
            contact: {
                emails: [],
                execEmails: [
                    'rakesh@fizzsocial.app',
                    'rakesh@webaroo.com',
                    'teddy@fizzsocial.app',
                    'ashton@fizzsocial.app'
                ],
                phoneNumbers: [],
                primaryEmail: 'rakesh@fizzsocial.app',
                __typename: 'ContactMetadata'
            },
            likelihoodOfBacking: null,
            fundingPerEmployee: 266025.64,
            person_relationships: [
                {
                    firstName: 'Ashton',
                    lastName: 'Cofer',
                    about: null,
                    profilePictureUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                    entityUrn: 'urn:harmonic:person:65431238',
                    id: 65431238,
                    fullName: 'Ashton Cofer',
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/in/ashton-cofer-194a82168',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    contact: {
                        emails: ['ashton@fizzsocial.app'],
                        __typename: 'ContactMetadata'
                    },
                    __typename: 'Person'
                },
                {
                    firstName: 'Teddy',
                    lastName: 'Solomon',
                    about: null,
                    profilePictureUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                    entityUrn: 'urn:harmonic:person:66851463',
                    id: 66851463,
                    fullName: 'Teddy Solomon',
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/in/teddy-solomon-85a183188',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    contact: {
                        emails: ['teddy@fizzsocial.app'],
                        __typename: 'ContactMetadata'
                    },
                    __typename: 'Person'
                },
                {
                    firstName: 'Rakesh',
                    lastName: 'Mathur',
                    about: null,
                    profilePictureUrl: 'https://assets.harmonic.ai/person_aba1a8a786577ad68e914ff97e1e4f368f81da233f71aa0c9b6239dc04b1e6fd.png',
                    entityUrn: 'urn:harmonic:person:15610',
                    id: 15610,
                    fullName: 'Rakesh Mathur',
                    socials: {
                        linkedin: {
                            url: 'https://linkedin.com/in/mathurrakesh',
                            __typename: 'SocialMetadata'
                        },
                        __typename: 'Socials'
                    },
                    contact: {
                        emails: ['rakesh@webaroo.com', 'rakesh@fizzsocial.app'],
                        __typename: 'ContactMetadata'
                    },
                    __typename: 'Person'
                }
            ],
            person_relationships_founders_and_ceos: null,
            person_relationships_executives: null,
            leadership_prior_companies: null,
            customer_type: null,
            entityUrn: 'urn:harmonic:company:3973641',
            stage: CompanyStage.SERIES_B,
            foundingDate: {
                date: '2020-01-01T00:00:00Z',
                granularity: 'YEAR',
                __typename: 'FoundingDate'
            },
            funding: {
                fundingTotal: '41500000',
                numFundingRounds: 3,
                investors: [
                    {
                        __typename: 'Company',
                        id: 660191,
                        companyUrn: 'urn:harmonic:company:660191',
                        name: 'New Enterprise Associates (NEA)',
                        logoUrl: 'https://assets.harmonic.ai/company_29ddff70d864732b322b8452f4f5337416aec0583fd0c5e45cc5ce2d7a17a0b4.png',
                        investorUrn: 'urn:harmonic:investor:148812'
                    },
                    {
                        __typename: 'Company',
                        id: 11337639,
                        companyUrn: 'urn:harmonic:company:11337639',
                        name: 'Green Sands Equity',
                        logoUrl: 'https://assets.harmonic.ai/company_6b190bf417f410297a67a376abc63b9697fa7a1c9e4ad441894249c08a2971fd.png',
                        investorUrn: 'urn:harmonic:investor:164116'
                    },
                    {
                        __typename: 'Company',
                        id: 662045,
                        companyUrn: 'urn:harmonic:company:662045',
                        name: 'Lightspeed',
                        logoUrl: 'https://assets.harmonic.ai/company_0009d3218c478283888080303fed14c46c61e6b3b8f55963a2671dac3afb3907.png',
                        investorUrn: 'urn:harmonic:investor:205545'
                    },
                    {
                        __typename: 'Company',
                        id: 665547,
                        companyUrn: 'urn:harmonic:company:665547',
                        name: 'Owl Ventures',
                        logoUrl: 'https://assets.harmonic.ai/company_5e91a2538bfa8c94f78697491d5fb21dd15de5c19946f032233774d1ede36ddf.png',
                        investorUrn: 'urn:harmonic:investor:169144'
                    },
                    {
                        __typename: 'Company',
                        id: 1492310,
                        companyUrn: 'urn:harmonic:company:1492310',
                        name: 'K5 Global',
                        logoUrl: 'https://assets.harmonic.ai/company_9285596f3290268ce393773e6dbdd4413ed1e66591e904fd254e6c885611930d.png',
                        investorUrn: 'urn:harmonic:investor:139822'
                    },
                    {
                        __typename: 'Company',
                        id: 7728371,
                        companyUrn: 'urn:harmonic:company:7728371',
                        name: 'The Octane Group',
                        logoUrl: 'https://assets.harmonic.ai/company_a7ca86168459687664b984bc41563a7ff13fb5a61d3f8170434681c499e3c0a8.png',
                        investorUrn: 'urn:harmonic:investor:206277'
                    },
                    {
                        __typename: 'Company',
                        id: 762489,
                        companyUrn: 'urn:harmonic:company:762489',
                        name: 'New Horizons Venture Capitals',
                        logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg',
                        investorUrn: 'urn:harmonic:investor:206300'
                    },
                    {
                        __typename: 'Person',
                        personUrn: 'urn:harmonic:person:15610',
                        id: 15610,
                        name: 'Rakesh Mathur',
                        logoUrl: 'https://assets.harmonic.ai/person_aba1a8a786577ad68e914ff97e1e4f368f81da233f71aa0c9b6239dc04b1e6fd.png',
                        investorUrn: 'urn:harmonic:investor:5116'
                    }
                ],
                lastFundingAt: '2023-08-10T00:00:00Z',
                lastFundingType: 'SERIES_B',
                lastFundingTotal: '25000000',
                fundingStage: 'SERIES_B',
                __typename: 'Funding'
            },
            funding_attribute_null_status: FundingAttributeNullStatus.EXISTS_BUT_UNDISCLOSED,
            headcount: 156,
            correctedHeadcount: 156,
            externalHeadcount: 156,
            highlights: [
                {
                    category: 'VENTURE_BACKED',
                    text: 'Backed By Green Sands Equity, K5 Global, Lightspeed, New Enterprise Associates (NEA), New Horizons Venture Capitals, Owl Ventures, The Octane Group',
                    __typename: 'Highlight'
                }
            ],
            employeeHighlights: [
                {
                    category: 'SEASONED_EXECUTIVE',
                    text: 'Seasoned Executive, Michaela Corbett: Vice President Finance @ Kappa Kappa Gamma (2133 Employees) 12/2020-12/2021',
                    __typename: 'Highlight'
                },
                {
                    category: 'SEASONED_OPERATOR',
                    text: 'Seasoned Operator, Mikayla Murphy: Director of External and Internal Events @ Alpha Phi International Fraternity (2289 Employees) 1/2019-6/2020',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, Emely Garcia: Bachelor of Arts - BA @ University of California, Berkeley',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, Teddy Solomon: Stanford',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: "Top University, Teddy Solomon: Bachelor's Degree @ Stanford",
                    __typename: 'Highlight'
                },
                {
                    category: 'SEASONED_OPERATOR',
                    text: 'Seasoned Operator, Finn Mallery: Co-Managing Director @ StartX. (231 Employees) 1/2022-1/2023',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: "Top University, Finn Mallery: Bachelor's degree @ Stanford",
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, Finn Mallery: Bachelor of Science (honors) @ Stanford',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, Finn Mallery: Bachelor of Science with Honors @ Stanford',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, Finn Mallery: Bachelor of Science - BS @ Stanford',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, Finn Mallery: Bachelor of Science (honors) @ Stanford',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, Finn Mallery: Bachelor of Science with Honors @ Stanford',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: "Top University, Finn Mallery: Bachelor's degree @ Stanford",
                    __typename: 'Highlight'
                },
                {
                    category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
                    text: 'Major Tech Company Experience, Tito Ciuro: Senior Software Engineer @ Apple, 1/2018-4/2020',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, George Fauver: The Tuck School of Business at Dartmouth',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, Eric Garland: Brown',
                    __typename: 'Highlight'
                },
                {
                    category: 'PRIOR_VC_BACKED_EXECUTIVE',
                    text: 'Prior VC Backed Executive, Eric Garland: EVP & GM, Live Nation Digital @ Live Nation Entertainment, ',
                    __typename: 'Highlight'
                },
                {
                    category: 'SEASONED_EXECUTIVE',
                    text: 'Seasoned Executive, Eric Garland: EVP & GM, Live Nation Digital @ Live Nation Entertainment (21491 Employees) 1/2013-12/2014',
                    __typename: 'Highlight'
                },
                {
                    category: 'PRIOR_VC_BACKED_EXECUTIVE',
                    text: 'Prior VC Backed Executive, Eric Garland: GM | Strategic Business Innovation @ The Walt Disney Company, ',
                    __typename: 'Highlight'
                },
                {
                    category: 'SEASONED_EXECUTIVE',
                    text: 'Seasoned Executive, Eric Garland: GM | Strategic Business Innovation @ The Walt Disney Company (175317 Employees) 1/2015-12/2018',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: "Top University, Baris Denizli: Bachelor's Degree @ Carnegie Mellon University",
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: 'Top University, Ashton Cofer: Stanford',
                    __typename: 'Highlight'
                },
                {
                    category: 'PRIOR_VC_BACKED_EXECUTIVE',
                    text: 'Prior VC Backed Executive, Rakesh Mathur: Vice President @ Amazon, ',
                    __typename: 'Highlight'
                },
                {
                    category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
                    text: 'Major Tech Company Experience, Rakesh Mathur: Vice President @ Amazon, 1/1998-12/1999',
                    __typename: 'Highlight'
                },
                {
                    category: 'SEASONED_EXECUTIVE',
                    text: 'Seasoned Executive, Rakesh Mathur: Vice President @ Amazon (743058 Employees) 1/1998-12/1999',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: "Top University, Sophia Kendall: Bachelor's degree @ Northwestern University",
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: "Top University, Sophia Kendall: Bachelor's degree @ Northwestern University",
                    __typename: 'Highlight'
                },
                {
                    category: 'SEASONED_EXECUTIVE',
                    text: 'Seasoned Executive, Sophia Kendall: Pledge Class Vice President @ Alpha Kappa Psi (1902 Employees) 10/2022-2/2023',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_COMPANY_ALUM',
                    text: 'Top Company Alum, Sohini Chowdhury: Cohost @ Airbnb, 9/2017-8/2018',
                    __typename: 'Highlight'
                },
                {
                    category: 'SEASONED_OPERATOR',
                    text: 'Seasoned Operator, Sohini Chowdhury: Director of Funds @ Delta Gamma (2022 Employees) 1/2021-1/2022',
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: "Top University, Kirill Dubovitskiy: Bachelor's degree @ UCLA",
                    __typename: 'Highlight'
                },
                {
                    category: 'TOP_UNIVERSITY',
                    text: "Top University, Aubrey Lemer: Bachelor's degree @ Stanford",
                    __typename: 'Highlight'
                },
                {
                    category: 'MAJOR_TECH_COMPANY_EXPERIENCE',
                    text: 'Major Tech Company Experience, Emily Bennett: Product Marketing Manager, News @ Facebook, 2/2017-1/2019',
                    __typename: 'Highlight'
                }
            ],
            location: {
                addressFormatted: '2335 El Camino Real, Palo Alto, CA 94306, USA',
                location: '2335 El Camino Real Palo Alto, California 94306, US',
                street: '2335 El Camino Real',
                city: 'Palo Alto',
                state: 'California',
                zip: '94306',
                country: 'United States',
                __typename: 'LocationMetadata'
            },
            ownership_status: OwnershipStatus.PRIVATE,
            socials: {
                facebook: null,
                twitter: null,
                linkedin: {
                    url: 'https://linkedin.com/company/fizzsocial',
                    followerCount: 3481,
                    status: null,
                    __typename: 'SocialMetadata'
                },
                instagram: null,
                crunchbase: null,
                pitchbook: {
                    url: 'https://pitchbook.com/profiles/company/482484-70',
                    followerCount: null,
                    status: null,
                    __typename: 'SocialMetadata'
                },
                angellist: null,
                indeed: null,
                youtube: null,
                monster: null,
                stackoverflow: null,
                __typename: 'Socials'
            },
            typed_tags: [
                {
                    entityUrn: 'urn:harmonic:tag:212e0b79-80fe-42c4-ac20-52c5e58b90e1',
                    tag_value: 'Social Networking',
                    dateAdded: '2022-10-25T07:38:46.000Z',
                    tag_type: TagType.PRODUCT_TYPE,
                    __typename: 'Tag'
                }
            ],
            typed_tags_v2: [
                {
                    entityUrn: 'urn:harmonic:tag:8b2dd506-2e20-4c46-9526-5fe6faf14e5a',
                    tag_value: 'Communications and information technology',
                    dateAdded: '2023-04-04T17:59:25.000Z',
                    tag_type: TagType.MARKET_VERTICAL,
                    __typename: 'Tag'
                },
                {
                    entityUrn: 'urn:harmonic:tag:ffdfc81e-4506-4a6b-b51d-2d3ad4a8ebed',
                    tag_value: 'Consumer (B2C)',
                    dateAdded: '2023-04-04T17:51:27.000Z',
                    tag_type: TagType.PRODUCT_TYPE,
                    __typename: 'Tag'
                },
                {
                    entityUrn: 'urn:harmonic:tag:d752f0ff-4ad7-43e9-86d3-4560bb82ee9c',
                    tag_value: 'Service',
                    dateAdded: '2023-04-04T16:58:57.000Z',
                    tag_type: TagType.PRODUCT_TYPE,
                    __typename: 'Tag'
                }
            ],
            user_notes: null,
            team_notes: null,
            user_connections: [
                {
                    targetPerson: {
                        id: 311027,
                        fullName: 'Emily Bennett',
                        __typename: 'Person'
                    },
                    user: {
                        email: 'aaron@harmonic.ai',
                        name: 'Aaron',
                        __typename: 'User'
                    },
                    __typename: 'UserConnection'
                },
                {
                    targetPerson: {
                        id: 311027,
                        fullName: 'Emily Bennett',
                        __typename: 'Person'
                    },
                    user: {
                        email: 'vidur@harmonic.ai',
                        name: 'Vidur Bahal',
                        __typename: 'User'
                    },
                    __typename: 'UserConnection'
                },
                {
                    targetPerson: {
                        id: 175771,
                        fullName: 'Tory Patterson',
                        __typename: 'Person'
                    },
                    user: {
                        email: 'akbar@harmonic.ai',
                        name: 'Akbar',
                        __typename: 'User'
                    },
                    __typename: 'UserConnection'
                },
                {
                    targetPerson: {
                        id: 188797416,
                        fullName: 'Cheyenne Mitchell',
                        __typename: 'Person'
                    },
                    user: {
                        email: 'sang@harmonic.ai',
                        name: 'Sang',
                        __typename: 'User'
                    },
                    __typename: 'UserConnection'
                },
                {
                    targetPerson: {
                        id: 175771,
                        fullName: 'Tory Patterson',
                        __typename: 'Person'
                    },
                    user: {
                        email: 'erin@harmonic.ai',
                        name: 'Erin McCarthy',
                        __typename: 'User'
                    },
                    __typename: 'UserConnection'
                }
            ],
            website: {
                url: 'https://buzzappofficial.com',
                domain: 'buzzappofficial.com',
                isBroken: false,
                __typename: 'Website'
            },
            userConnectionsOverview: {
                correspondenceSummary: {
                    lastEmailAt: null,
                    lastEmailContactPersonEmail: null,
                    lastEmailContactPerson: null,
                    lastMeetingAt: null,
                    lastMeetingContactPersonEmail: null,
                    lastMeetingContactPerson: null,
                    __typename: 'CorrespondenceSummary'
                },
                __typename: 'UserConnectionOverview'
            },
            webTraffic: null,
            tractionMetrics: {
                headcount: {
                    ago14d: {
                        value_14d_ago: 158,
                        real_change_14d_ago: -2,
                        percent_change_14d_ago: -1.27,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 165,
                        real_change_30d_ago: -9,
                        percent_change_30d_ago: -5.45,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 173,
                        real_change_90d_ago: -17,
                        percent_change_90d_ago: -9.83,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 199,
                        real_change_180d_ago: -43,
                        percent_change_180d_ago: -21.61,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 142,
                        real_change_365d_ago: 14,
                        percent_change_365d_ago: 9.86,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountAdvisor: {
                    latestMetricValue: 6,
                    ago14d: {
                        value_14d_ago: 7,
                        real_change_14d_ago: -1,
                        percent_change_14d_ago: -10.86,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 7,
                        real_change_30d_ago: -1,
                        percent_change_30d_ago: -10.86,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 7,
                        real_change_90d_ago: -1,
                        percent_change_90d_ago: -10.86,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 6,
                        real_change_180d_ago: 0,
                        percent_change_180d_ago: 4,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 4,
                        real_change_365d_ago: 2,
                        percent_change_365d_ago: 56,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountCustomerSuccess: {
                    latestMetricValue: null,
                    ago14d: {
                        value_14d_ago: null,
                        real_change_14d_ago: null,
                        percent_change_14d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: null,
                        real_change_30d_ago: null,
                        percent_change_30d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: null,
                        real_change_90d_ago: null,
                        percent_change_90d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: null,
                        real_change_180d_ago: null,
                        percent_change_180d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: null,
                        real_change_365d_ago: null,
                        percent_change_365d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountData: {
                    latestMetricValue: 16,
                    ago14d: {
                        value_14d_ago: 17,
                        real_change_14d_ago: -1,
                        percent_change_14d_ago: -4.56,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 17,
                        real_change_30d_ago: -1,
                        percent_change_30d_ago: -4.56,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 20,
                        real_change_90d_ago: -4,
                        percent_change_90d_ago: -18.88,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 21,
                        real_change_180d_ago: -5,
                        percent_change_180d_ago: -22.74,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 16,
                        real_change_365d_ago: 0,
                        percent_change_365d_ago: 1.4,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountDesign: {
                    latestMetricValue: 4,
                    ago14d: {
                        value_14d_ago: 4,
                        real_change_14d_ago: 0,
                        percent_change_14d_ago: -6.4,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 3,
                        real_change_30d_ago: 1,
                        percent_change_30d_ago: 24.8,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 2,
                        real_change_90d_ago: 2,
                        percent_change_90d_ago: 87.2,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 2,
                        real_change_180d_ago: 2,
                        percent_change_180d_ago: 87.2,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 2,
                        real_change_365d_ago: 2,
                        percent_change_365d_ago: 87.2,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountEngineering: {
                    latestMetricValue: 7,
                    ago14d: {
                        value_14d_ago: 7,
                        real_change_14d_ago: 0,
                        percent_change_14d_ago: 6.97,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 7,
                        real_change_30d_ago: 0,
                        percent_change_30d_ago: 6.97,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 9,
                        real_change_90d_ago: -2,
                        percent_change_90d_ago: -16.8,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 10,
                        real_change_180d_ago: -3,
                        percent_change_180d_ago: -25.12,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 7,
                        real_change_365d_ago: 0,
                        percent_change_365d_ago: 6.97,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountFinance: {
                    latestMetricValue: null,
                    ago14d: {
                        value_14d_ago: null,
                        real_change_14d_ago: null,
                        percent_change_14d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: null,
                        real_change_30d_ago: null,
                        percent_change_30d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: null,
                        real_change_90d_ago: null,
                        percent_change_90d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: null,
                        real_change_180d_ago: null,
                        percent_change_180d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: null,
                        real_change_365d_ago: null,
                        percent_change_365d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountLegal: {
                    latestMetricValue: null,
                    ago14d: {
                        value_14d_ago: null,
                        real_change_14d_ago: null,
                        percent_change_14d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: null,
                        real_change_30d_ago: null,
                        percent_change_30d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: null,
                        real_change_90d_ago: null,
                        percent_change_90d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: null,
                        real_change_180d_ago: null,
                        percent_change_180d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: null,
                        real_change_365d_ago: null,
                        percent_change_365d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountMarketing: {
                    latestMetricValue: 62,
                    ago14d: {
                        value_14d_ago: 65,
                        real_change_14d_ago: -3,
                        percent_change_14d_ago: -4,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 67,
                        real_change_30d_ago: -5,
                        percent_change_30d_ago: -6.87,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 65,
                        real_change_90d_ago: -3,
                        percent_change_90d_ago: -4,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 75,
                        real_change_180d_ago: -13,
                        percent_change_180d_ago: -16.8,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 53,
                        real_change_365d_ago: 9,
                        percent_change_365d_ago: 17.74,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountOperations: {
                    latestMetricValue: 9,
                    ago14d: {
                        value_14d_ago: 9,
                        real_change_14d_ago: 0,
                        percent_change_14d_ago: -2.93,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 12,
                        real_change_30d_ago: -3,
                        percent_change_30d_ago: -27.2,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 15,
                        real_change_90d_ago: -6,
                        percent_change_90d_ago: -41.76,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 19,
                        real_change_180d_ago: -10,
                        percent_change_180d_ago: -54.02,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 13,
                        real_change_365d_ago: -4,
                        percent_change_365d_ago: -32.8,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountOther: {
                    latestMetricValue: 41,
                    ago14d: {
                        value_14d_ago: 42,
                        real_change_14d_ago: -1,
                        percent_change_14d_ago: -1.94,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 45,
                        real_change_30d_ago: -4,
                        percent_change_30d_ago: -8.48,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 49,
                        real_change_90d_ago: -8,
                        percent_change_90d_ago: -15.95,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 57,
                        real_change_180d_ago: -16,
                        percent_change_180d_ago: -27.75,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 43,
                        real_change_365d_ago: -2,
                        percent_change_365d_ago: -4.22,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountPeople: {
                    latestMetricValue: 7,
                    ago14d: {
                        value_14d_ago: 8,
                        real_change_14d_ago: -1,
                        percent_change_14d_ago: -6.4,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 8,
                        real_change_30d_ago: -1,
                        percent_change_30d_ago: -6.4,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 7,
                        real_change_90d_ago: 0,
                        percent_change_90d_ago: 6.97,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 8,
                        real_change_180d_ago: -1,
                        percent_change_180d_ago: -6.4,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 6,
                        real_change_365d_ago: 1,
                        percent_change_365d_ago: 24.8,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountProduct: {
                    latestMetricValue: 1,
                    ago14d: {
                        value_14d_ago: null,
                        real_change_14d_ago: null,
                        percent_change_14d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: null,
                        real_change_30d_ago: null,
                        percent_change_30d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: null,
                        real_change_90d_ago: null,
                        percent_change_90d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: null,
                        real_change_180d_ago: null,
                        percent_change_180d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: null,
                        real_change_365d_ago: null,
                        percent_change_365d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountSales: {
                    latestMetricValue: 2,
                    ago14d: {
                        value_14d_ago: 3,
                        real_change_14d_ago: -1,
                        percent_change_14d_ago: -16.8,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 3,
                        real_change_30d_ago: -1,
                        percent_change_30d_ago: -16.8,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 4,
                        real_change_90d_ago: -2,
                        percent_change_90d_ago: -37.6,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 4,
                        real_change_180d_ago: -2,
                        percent_change_180d_ago: -37.6,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 3,
                        real_change_365d_ago: -1,
                        percent_change_365d_ago: -16.8,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                headcountSupport: {
                    latestMetricValue: null,
                    ago14d: {
                        value_14d_ago: null,
                        real_change_14d_ago: null,
                        percent_change_14d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: null,
                        real_change_30d_ago: null,
                        percent_change_30d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: null,
                        real_change_90d_ago: null,
                        percent_change_90d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: null,
                        real_change_180d_ago: null,
                        percent_change_180d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: null,
                        real_change_365d_ago: null,
                        percent_change_365d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                fundingTotal: {
                    ago14d: {
                        value_14d_ago: 41500000,
                        real_change_14d_ago: 0,
                        percent_change_14d_ago: 0,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 41500000,
                        real_change_30d_ago: 0,
                        percent_change_30d_ago: 0,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 41500000,
                        real_change_90d_ago: 0,
                        percent_change_90d_ago: 0,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 41500000,
                        real_change_180d_ago: 0,
                        percent_change_180d_ago: 0,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 29251725,
                        real_change_365d_ago: 12248275,
                        percent_change_365d_ago: 41.87,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                facebookFollowerCount: {
                    ago14d: {
                        value_14d_ago: null,
                        real_change_14d_ago: null,
                        percent_change_14d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: null,
                        real_change_30d_ago: null,
                        percent_change_30d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: null,
                        real_change_90d_ago: null,
                        percent_change_90d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: null,
                        real_change_180d_ago: null,
                        percent_change_180d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: null,
                        real_change_365d_ago: null,
                        percent_change_365d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                linkedinFollowerCount: {
                    ago14d: {
                        value_14d_ago: 3483,
                        real_change_14d_ago: 19,
                        percent_change_14d_ago: 0.55,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: 3457,
                        real_change_30d_ago: 45,
                        percent_change_30d_ago: 1.3,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: 3336,
                        real_change_90d_ago: 166,
                        percent_change_90d_ago: 4.98,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: 3105,
                        real_change_180d_ago: 397,
                        percent_change_180d_ago: 12.79,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: 2363,
                        real_change_365d_ago: 1139,
                        percent_change_365d_ago: 48.2,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                instagramFollowerCount: {
                    latestMetricValue: null,
                    ago14d: {
                        value_14d_ago: null,
                        real_change_14d_ago: null,
                        percent_change_14d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: null,
                        real_change_30d_ago: null,
                        percent_change_30d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: null,
                        real_change_90d_ago: null,
                        percent_change_90d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: null,
                        real_change_180d_ago: null,
                        percent_change_180d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: null,
                        real_change_365d_ago: null,
                        percent_change_365d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                twitterFollowerCount: {
                    ago14d: {
                        value_14d_ago: null,
                        real_change_14d_ago: null,
                        percent_change_14d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: null,
                        real_change_30d_ago: null,
                        percent_change_30d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: null,
                        real_change_90d_ago: null,
                        percent_change_90d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: null,
                        real_change_180d_ago: null,
                        percent_change_180d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: null,
                        real_change_365d_ago: null,
                        percent_change_365d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    __typename: 'TractionMetricsInfo'
                },
                webTraffic: {
                    ago14d: {
                        value_14d_ago: null,
                        real_change_14d_ago: null,
                        percent_change_14d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago30d: {
                        value_30d_ago: null,
                        real_change_30d_ago: null,
                        percent_change_30d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago90d: {
                        value_90d_ago: null,
                        real_change_90d_ago: null,
                        percent_change_90d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago180d: {
                        value_180d_ago: null,
                        real_change_180d_ago: null,
                        percent_change_180d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    ago365d: {
                        value_365d_ago: null,
                        real_change_365d_ago: null,
                        percent_change_365d_ago: null,
                        __typename: 'TractionMetricsPoint'
                    },
                    latestMetricValue: null,
                    __typename: 'TractionMetricsInfo'
                },
                __typename: 'TractionMetrics'
            },
            affinityLists: [],
            watchlists: [
                {
                    id: '105c16ed-f955-4f57-872c-1a3a8d25b9e2',
                    name: 'US VC portfolio list',
                    entityUrn: 'urn:harmonic:company_watchlist:105c16ed-f955-4f57-872c-1a3a8d25b9e2',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '15acce01-e76a-4370-bea7-9a9cde87fe59',
                    name: 'AI Tech Stack',
                    entityUrn: 'urn:harmonic:company_watchlist:15acce01-e76a-4370-bea7-9a9cde87fe59',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '24462091-0f40-454a-9dfa-ab1bc81001d5',
                    name: 'Georgian July 12',
                    entityUrn: 'urn:harmonic:company_watchlist:24462091-0f40-454a-9dfa-ab1bc81001d5',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '2d2c56da-0fc5-4da5-a398-5c0106a8b6c3',
                    name: 'NFX Carter List',
                    entityUrn: 'urn:harmonic:company_watchlist:2d2c56da-0fc5-4da5-a398-5c0106a8b6c3',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '52a48a59-4573-4992-81b8-3d90c3584f55',
                    name: 'NFX Enrichment',
                    entityUrn: 'urn:harmonic:company_watchlist:52a48a59-4573-4992-81b8-3d90c3584f55',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '6c814a76-08bc-483a-85b1-485e378e2bf6',
                    name: 'Brex Tag Support - July 2024',
                    entityUrn: 'urn:harmonic:company_watchlist:6c814a76-08bc-483a-85b1-485e378e2bf6',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: '839d837b-6ea6-473b-a5f1-dd84eef6b3ea',
                    name: 'Liked companies',
                    entityUrn: 'urn:harmonic:company_watchlist:839d837b-6ea6-473b-a5f1-dd84eef6b3ea',
                    __typename: 'CompanyWatchlist'
                },
                {
                    id: 'e1cff389-b13b-45b6-93b3-d55982e4b3d2',
                    name: 'NFX January Enrichment',
                    entityUrn: 'urn:harmonic:company_watchlist:e1cff389-b13b-45b6-93b3-d55982e4b3d2',
                    __typename: 'CompanyWatchlist'
                }
            ],
            isLikedByCurrentUser: true,
            isHiddenFromSearchesByCurrentUser: false,
            viewRecord: {
                createdAt: '2024-07-11T18:43:21.052Z',
                updatedAt: '2024-07-11T20:38:53.430Z',
                __typename: 'CompanyViewRecord'
            },
            relevanceScore: 70,
            __typename: 'Company'
        }
    ]
};
