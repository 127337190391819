export const UPSERT_COMPANY_LIST_NAMED_VIEW_MOCKED_RESPONSE = {
    upsertCompanyListNamedView: {
        id: 39263,
        entityUrn: 'urn:harmonic:company_list_named_view:39263',
        name: '1',
        visibleColumns: [
            'urn:harmonic:company_field:company_description',
            'urn:harmonic:company_field:company_highlights',
            'urn:harmonic:company_field:company_website_url',
            'urn:harmonic:company_field:company_team',
            'urn:harmonic:company_field:company_customers_connections',
            'urn:harmonic:company_field:company_customers_connections_last_email_at',
            'urn:harmonic:company_field:company_customers_connections_last_meeting_at',
            'urn:harmonic:company_field:company_team_notes',
            'urn:harmonic:company_field:company_investor_names',
            'urn:harmonic:company_field:company_funding_total',
            'urn:harmonic:company_field:company_last_funding_type',
            'urn:harmonic:company_field:company_last_funding_date',
            'urn:harmonic:company_field:company_headcount',
            'urn:harmonic:company_field:company_headcount_percent_change_90d_ago'
        ],
        searchQuery: {
            filterGroup: {
                joinOperator: 'and',
                filters: [],
                filterGroups: [],
                filterGroupGenerators: []
            },
            pagination: {
                start: null,
                pageSize: 25
            },
            sort: [
                {
                    sortField: 'company_entry_created_at',
                    descending: true,
                    sortContextValues: null
                }
            ],
            controlledFilterGroup: null,
            mergeQueryForNestedFields: false
        },
        displayType: 'GRID',
        hideEmptyColumns: false,
        groupByField: null,
        __typename: 'CompanyListNamedView'
    }
};
