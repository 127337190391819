import DefaultCompanySearchColumns from 'components/Dashboard/Grids/ColumnDefaultOrderings/DefaultCompanySearchColumns';
import { http, HttpResponse } from 'msw';
import { authenticateRouteMockResponse, firebaseLookupMockData, firebaseSignInWithPasswordMockData, splitKeysMockResponse } from './mock-data/auth';
import { COMPANY_WATCHLIST_EXPORT_MOCK } from './mock-data/export';
import { CompaniesSearchFieldSpecMockData, PeopleSearchFieldSpecMockData } from './mock-data/fieldspec';
import MOCK_PEOPLE_COUNT_HTTP_RESPONSE from './mock-data/people_count';
export default [
    http.post('https://identitytoolkit.googleapis.com/v1/accounts:accountMode', (info) => {
        if (info.params.accountMode === ':signInWithPassword') {
            return HttpResponse.json(firebaseSignInWithPasswordMockData, {
                status: 200
            });
        }
        if (info.params.accountMode === ':lookup') {
            return HttpResponse.json(firebaseLookupMockData, {
                status: 200
            });
        }
    }),
    http.post('*/users/authenticate', () => {
        return HttpResponse.json(authenticateRouteMockResponse, {
            status: 200
        });
    }),
    http.post('*/exports/companies', () => {
        return new HttpResponse(COMPANY_WATCHLIST_EXPORT_MOCK, {
            status: 200
        });
    }),
    http.get('*/users/firebaseToken/:token', () => {
        return HttpResponse.json(authenticateRouteMockResponse, {
            status: 200
        });
    }),
    http.get('https://sdk.split.io/api/splitChanges', () => {
        return HttpResponse.json(splitKeysMockResponse, {
            status: 200
        });
    }),
    http.get('*/search/searchFieldSpec', (info) => {
        const url = new URL(info.request.url);
        const outputMockData = url.searchParams.get('search_type') === 'COMPANIES_LIST'
            ? CompaniesSearchFieldSpecMockData
            : PeopleSearchFieldSpecMockData;
        return HttpResponse.json(outputMockData, {
            status: 200
        });
    }),
    http.get('*/datastore/user/exploreCompanies-customColumns', () => {
        return HttpResponse.json({
            value: {
                columns: DefaultCompanySearchColumns
            }
        }, {
            status: 200
        });
    }),
    http.post('*/search/people_count', () => {
        return HttpResponse.json(MOCK_PEOPLE_COUNT_HTTP_RESPONSE, {
            status: 200
        });
    })
];
