const getCompanyMetricsWithDepartments = {
    getCompanyById: {
        id: 1,
        headcount: 1000,
        correctedHeadcount: 1000,
        externalHeadcount: 1000,
        foundingDate: null,
        socials: {
            facebook: null,
            twitter: {
                status: null,
                followerCount: 171,
                url: 'https://twitter.com/harmonic_ai',
                __typename: 'SocialMetadata'
            },
            instagram: null,
            linkedin: {
                status: null,
                followerCount: 1682,
                url: 'https://linkedin.com/company/harmonicai',
                __typename: 'SocialMetadata'
            },
            crunchbase: {
                status: null,
                followerCount: null,
                url: 'https://www.crunchbase.com/organization/harmonic-ai',
                __typename: 'SocialMetadata'
            },
            pitchbook: {
                status: null,
                followerCount: null,
                url: 'https://pitchbook.com/profiles/company/453414-79',
                __typename: 'SocialMetadata'
            },
            indeed: null,
            youtube: null,
            monster: null,
            stackoverflow: null,
            angellist: null,
            __typename: 'Socials'
        },
        tractionMetrics: {
            facebookLikeCount: {
                metrics: [],
                ago90d: {
                    percentChange: null,
                    value: null,
                    change: null,
                    __typename: 'TractionMetricsPoint'
                },
                __typename: 'TractionMetricsInfo'
            },
            twitterFollowerCount: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-08T09:29:06.256Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-25T22:06:28.342Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-11T05:44:53.244Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-22T10:10:16.901Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-06T08:04:54.810Z',
                        metricValue: 156,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-23T08:38:14.768Z',
                        metricValue: 156,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-02T05:37:20.234Z',
                        metricValue: 151,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-17T18:09:01.726Z',
                        metricValue: 124,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-07T07:42:13.741Z',
                        metricValue: 124,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-16T05:21:21.165Z',
                        metricValue: 124,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-20T07:15:05.394Z',
                        metricValue: 116,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-06T08:32:21.551Z',
                        metricValue: 118,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-19T07:14:15.333Z',
                        metricValue: 108,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-05T08:11:36.508Z',
                        metricValue: 106,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-21T05:22:57.534Z',
                        metricValue: 90,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-07T07:08:30.015Z',
                        metricValue: 63,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-24T19:45:36.966Z',
                        metricValue: 146,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-13T13:54:58.230Z',
                        metricValue: 146,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-01T08:04:59.914Z',
                        metricValue: 146,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T09:54:05.514Z',
                        metricValue: 144,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T06:23:37.204Z',
                        metricValue: 144,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-25T09:36:20.217Z',
                        metricValue: 140,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-11T00:00:00.000Z',
                        metricValue: 133,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-07-13T08:24:52.810Z',
                        metricValue: 130,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-29T06:09:01.499Z',
                        metricValue: 129,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-15T07:52:45.964Z',
                        metricValue: 130,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-18T10:26:48.253Z',
                        metricValue: 130,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-04T14:43:48.020Z',
                        metricValue: 118,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-27T09:29:16.489Z',
                        metricValue: 118,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-26T18:48:01.885Z',
                        metricValue: 118,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-03-08T03:58:06.024Z',
                        metricValue: 102,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-22T01:26:52.497Z',
                        metricValue: 95,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-04T11:22:02.232Z',
                        metricValue: 69,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-20T05:57:19.839Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-13T06:20:53.110Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-30T07:04:33.086Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-16T02:07:09.931Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-01T15:31:37.925Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                ago90d: {
                    percentChange: 9.615384615384617,
                    value: 156,
                    change: 1,
                    __typename: 'TractionMetricsPoint'
                },
                __typename: 'TractionMetricsInfo'
            },
            instagramFollowerCount: {
                metrics: [],
                ago90d: {
                    percentChange: null,
                    value: null,
                    change: null,
                    __typename: 'TractionMetricsPoint'
                },
                __typename: 'TractionMetricsInfo'
            },
            linkedinFollowerCount: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1682,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-08T09:29:06.256Z',
                        metricValue: 1603,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-25T22:06:28.342Z',
                        metricValue: 1576,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-11T05:44:53.244Z',
                        metricValue: 1542,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-22T10:10:16.901Z',
                        metricValue: 1521,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-06T08:04:54.810Z',
                        metricValue: 1507,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-23T08:38:14.768Z',
                        metricValue: 1503,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-02T05:37:20.234Z',
                        metricValue: 1482,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-17T18:09:01.726Z',
                        metricValue: 1468,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-07T07:42:13.741Z',
                        metricValue: 1468,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-16T05:21:21.165Z',
                        metricValue: 1391,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-20T07:15:05.394Z',
                        metricValue: 1063,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-06T08:32:21.551Z',
                        metricValue: 1048,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-19T07:14:15.333Z',
                        metricValue: 1035,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-05T08:11:36.508Z',
                        metricValue: 980,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-21T05:22:57.534Z',
                        metricValue: 973,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-07T07:08:30.015Z',
                        metricValue: 894,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-24T19:45:36.966Z',
                        metricValue: 883,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-13T13:54:58.230Z',
                        metricValue: 842,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-01T08:04:59.914Z',
                        metricValue: 842,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T09:54:05.514Z',
                        metricValue: 796,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T06:23:37.204Z',
                        metricValue: 796,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-25T09:36:20.217Z',
                        metricValue: 767,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-11T00:00:00.000Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-07-13T08:24:52.810Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-29T06:09:01.499Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-15T07:52:45.964Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-18T10:26:48.253Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-04T14:43:48.020Z',
                        metricValue: 652,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-27T09:29:16.489Z',
                        metricValue: 652,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-26T18:48:01.885Z',
                        metricValue: 652,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-03-08T03:58:06.024Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-22T01:26:52.497Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-04T11:22:02.232Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-20T05:57:19.839Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-13T06:20:53.110Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-30T07:04:33.086Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-16T02:07:09.931Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                ago90d: {
                    percentChange: 11.61247511612475,
                    value: 1507,
                    change: 1,
                    __typename: 'TractionMetricsPoint'
                },
                __typename: 'TractionMetricsInfo'
            },
            headcount: {
                ago90d: {
                    percentChange: 9.67741935483871,
                    value: 31,
                    change: 1,
                    __typename: 'TractionMetricsPoint'
                },
                metrics: [
                    {
                        timestamp: '2023-08-26T04:19:45.501Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-25T02:42:36.337Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-24T01:17:02.717Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-12T02:57:26.589Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-11T03:45:04.956Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-08T09:29:06.256Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-04T02:54:11.110Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-03T06:03:13.975Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-25T22:06:28.342Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-20T06:31:41.850Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-19T01:59:08.633Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-11T05:44:53.244Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-09T03:25:16.437Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-08T03:07:29.206Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-29T03:29:48.950Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-28T02:30:53.887Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-27T10:39:46.541Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-22T10:10:16.901Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-16T03:19:10.051Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-15T01:43:47.053Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-14T01:37:08.913Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-13T19:56:32.251Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-06T08:04:54.810Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-31T03:33:04.044Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-30T03:43:23.652Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-23T08:38:14.768Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-04T20:19:52.955Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-03T03:49:18.811Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-02T05:37:20.234Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-19T04:04:00.900Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-18T05:00:22.485Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-17T05:57:45.983Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-16T06:26:20.999Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-13T11:49:04.795Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-07T07:42:13.741Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-29T02:24:24.762Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-21T05:14:19.367Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-16T05:21:21.165Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-08T03:34:08.761Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-25T02:31:18.188Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-17T02:23:44.562Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-09T03:29:01.773Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-29T21:27:32.611Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-20T07:15:05.394Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-19T07:16:02.043Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-18T20:09:47.066Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-07T02:37:04.473Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-06T08:32:21.551Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-19T07:14:15.333Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-05T08:11:36.508Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-21T05:22:57.534Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-07T07:08:30.015Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-24T19:45:36.966Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-13T13:54:58.230Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-01T08:04:59.914Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T09:54:05.514Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-25T09:36:20.217Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-11T00:00:00.000Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-07-13T08:24:52.810Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-29T06:09:01.499Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-15T07:52:45.964Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-18T10:26:48.253Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-04T14:43:48.020Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-27T09:29:16.489Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-26T18:48:01.885Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-03-08T03:58:06.024Z',
                        metricValue: 23,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-22T01:26:52.497Z',
                        metricValue: 24,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-04T11:22:02.232Z',
                        metricValue: 21,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-20T05:57:19.839Z',
                        metricValue: 22,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-13T06:20:53.110Z',
                        metricValue: 20,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-30T07:04:33.086Z',
                        metricValue: 19,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-16T02:07:09.931Z',
                        metricValue: 18,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-01T15:31:37.925Z',
                        metricValue: 16,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-17T18:48:47.638Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-13T12:36:43.509Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-10T15:02:48.425Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-28T04:10:32.996Z',
                        metricValue: 15,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-23T17:43:51.301Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-21T17:31:32.710Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-13T12:06:28.350Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-06T12:07:23.818Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-29T12:06:23.638Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-25T14:12:14.635Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-22T16:20:57.455Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-21T21:17:13.296Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-05T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-20T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-04T19:15:44.018Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-28T20:48:27.967Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-09T00:12:38.727Z',
                        metricValue: 8,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-23T18:18:19.442Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-17T04:29:18.076Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-11T22:22:24.843Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-18T20:11:51.139Z',
                        metricValue: 5,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-16T19:24:43.137Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-30T00:24:28.734Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-15T01:14:43.234Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-27T14:17:05.523Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-19T10:58:37.099Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-11T07:40:08.675Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-25T11:16:36.337Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-10T12:48:31.983Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            externalHeadcount: null,
            correctedHeadcount: {
                ago90d: {
                    percentChange: 9.67741935483871,
                    value: 31,
                    change: 1,
                    __typename: 'TractionMetricsPoint'
                },
                metrics: [
                    {
                        timestamp: '2023-08-26T04:19:45.501Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-25T02:42:36.337Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-24T01:17:02.717Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-12T02:57:26.589Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-11T03:45:04.956Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-08T09:29:06.256Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-04T02:54:11.110Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-03T06:03:13.975Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-25T22:06:28.342Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-20T06:31:41.850Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-19T01:59:08.633Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-11T05:44:53.244Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-09T03:25:16.437Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-08T03:07:29.206Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-29T03:29:48.950Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-28T02:30:53.887Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-27T10:39:46.541Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-22T10:10:16.901Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-16T03:19:10.051Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-15T01:43:47.053Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-14T01:37:08.913Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-13T19:56:32.251Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-06T08:04:54.810Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-31T03:33:04.044Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-30T03:43:23.652Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-23T08:38:14.768Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-04T20:19:52.955Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-03T03:49:18.811Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-02T05:37:20.234Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-19T04:04:00.900Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-18T05:00:22.485Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-17T05:57:45.983Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-16T06:26:20.999Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-13T11:49:04.795Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-07T07:42:13.741Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-29T02:24:24.762Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-21T05:14:19.367Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-16T05:21:21.165Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-08T03:34:08.761Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-25T02:31:18.188Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-17T02:23:44.562Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-09T03:29:01.773Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-29T21:27:32.611Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-20T07:15:05.394Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-19T07:16:02.043Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-18T20:09:47.066Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-07T02:37:04.473Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-06T08:32:21.551Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-19T07:14:15.333Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-05T08:11:36.508Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-21T05:22:57.534Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-07T07:08:30.015Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-24T19:45:36.966Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-13T13:54:58.230Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-01T08:04:59.914Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T09:54:05.514Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-25T09:36:20.217Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-11T00:00:00.000Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-07-13T08:24:52.810Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-29T06:09:01.499Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-15T07:52:45.964Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-18T10:26:48.253Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-04T14:43:48.020Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-27T09:29:16.489Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-26T18:48:01.885Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-03-08T03:58:06.024Z',
                        metricValue: 23,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-22T01:26:52.497Z',
                        metricValue: 24,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-04T11:22:02.232Z',
                        metricValue: 21,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-20T05:57:19.839Z',
                        metricValue: 22,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-13T06:20:53.110Z',
                        metricValue: 20,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-30T07:04:33.086Z',
                        metricValue: 19,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-16T02:07:09.931Z',
                        metricValue: 18,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-01T15:31:37.925Z',
                        metricValue: 16,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-17T18:48:47.638Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-13T12:36:43.509Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-10T15:02:48.425Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-28T04:10:32.996Z',
                        metricValue: 15,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-23T17:43:51.301Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-21T17:31:32.710Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-13T12:06:28.350Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-06T12:07:23.818Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-29T12:06:23.638Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-25T14:12:14.635Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-22T16:20:57.455Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-21T21:17:13.296Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-05T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-20T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-04T19:15:44.018Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-28T20:48:27.967Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-09T00:12:38.727Z',
                        metricValue: 8,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-23T18:18:19.442Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-17T04:29:18.076Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-11T22:22:24.843Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-18T20:11:51.139Z',
                        metricValue: 5,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-16T19:24:43.137Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-30T00:24:28.734Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-15T01:14:43.234Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-27T14:17:05.523Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-19T10:58:37.099Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-11T07:40:08.675Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-25T11:16:36.337Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-10T12:48:31.983Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            webTraffic: {
                ago90d: {
                    percentChange: 9.67741935483871,
                    value: 15,
                    change: 1,
                    __typename: 'TractionMetricsPoint'
                },
                metrics: [
                    {
                        timestamp: '2023-08-26T04:19:45.501Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-25T02:42:36.337Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-24T01:17:02.717Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-12T02:57:26.589Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-11T03:45:04.956Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-08T09:29:06.256Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-04T02:54:11.110Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-03T06:03:13.975Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-25T22:06:28.342Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-20T06:31:41.850Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-19T01:59:08.633Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-11T05:44:53.244Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-09T03:25:16.437Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-08T03:07:29.206Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-29T03:29:48.950Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-28T02:30:53.887Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-27T10:39:46.541Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-22T10:10:16.901Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-16T03:19:10.051Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-15T01:43:47.053Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-14T01:37:08.913Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-13T19:56:32.251Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-06T08:04:54.810Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-31T03:33:04.044Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-30T03:43:23.652Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-23T08:38:14.768Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-04T20:19:52.955Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-03T03:49:18.811Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-02T05:37:20.234Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-19T04:04:00.900Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-18T05:00:22.485Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-17T05:57:45.983Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-16T06:26:20.999Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-13T11:49:04.795Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-07T07:42:13.741Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-29T02:24:24.762Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-21T05:14:19.367Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-16T05:21:21.165Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-08T03:34:08.761Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-25T02:31:18.188Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-17T02:23:44.562Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-09T03:29:01.773Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-29T21:27:32.611Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-20T07:15:05.394Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-19T07:16:02.043Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-18T20:09:47.066Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-07T02:37:04.473Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-06T08:32:21.551Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-19T07:14:15.333Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-05T08:11:36.508Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-21T05:22:57.534Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-07T07:08:30.015Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-24T19:45:36.966Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-13T13:54:58.230Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-01T08:04:59.914Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T09:54:05.514Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-25T09:36:20.217Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-11T00:00:00.000Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-07-13T08:24:52.810Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-29T06:09:01.499Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-15T07:52:45.964Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-18T10:26:48.253Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-04T14:43:48.020Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-27T09:29:16.489Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-26T18:48:01.885Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-03-08T03:58:06.024Z',
                        metricValue: 23,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-22T01:26:52.497Z',
                        metricValue: 24,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-04T11:22:02.232Z',
                        metricValue: 21,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-20T05:57:19.839Z',
                        metricValue: 22,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-13T06:20:53.110Z',
                        metricValue: 20,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-30T07:04:33.086Z',
                        metricValue: 19,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-16T02:07:09.931Z',
                        metricValue: 18,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-01T15:31:37.925Z',
                        metricValue: 16,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-17T18:48:47.638Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-13T12:36:43.509Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-10T15:02:48.425Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-28T04:10:32.996Z',
                        metricValue: 15,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-23T17:43:51.301Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-21T17:31:32.710Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-13T12:06:28.350Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-06T12:07:23.818Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-29T12:06:23.638Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-25T14:12:14.635Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-22T16:20:57.455Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-21T21:17:13.296Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-05T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-20T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-04T19:15:44.018Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-28T20:48:27.967Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-09T00:12:38.727Z',
                        metricValue: 8,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-23T18:18:19.442Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-17T04:29:18.076Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-11T22:22:24.843Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-18T20:11:51.139Z',
                        metricValue: 5,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-16T19:24:43.137Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-30T00:24:28.734Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-15T01:14:43.234Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-27T14:17:05.523Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-19T10:58:37.099Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-11T07:40:08.675Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-25T11:16:36.337Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-10T12:48:31.983Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountAdvisor: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountCustomerSuccess: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountData: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountDesign: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountEngineering: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountFinance: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountLegal: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountMarketing: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountOperations: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountOther: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountPeople: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountProduct: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountSales: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountSupport: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            __typename: 'TractionMetrics'
        },
        __typename: 'Company'
    }
};
const getCompanyMetricsWithoutDepartments = {
    getCompanyById: {
        id: 1,
        headcount: 34,
        correctedHeadcount: 35,
        externalHeadcount: 36,
        foundingDate: null,
        socials: {
            facebook: null,
            twitter: {
                status: null,
                followerCount: 171,
                url: 'https://twitter.com/harmonic_ai',
                __typename: 'SocialMetadata'
            },
            instagram: null,
            linkedin: {
                status: null,
                followerCount: 1682,
                url: 'https://linkedin.com/company/harmonicai',
                __typename: 'SocialMetadata'
            },
            crunchbase: {
                status: null,
                followerCount: null,
                url: 'https://www.crunchbase.com/organization/harmonic-ai',
                __typename: 'SocialMetadata'
            },
            pitchbook: {
                status: null,
                followerCount: null,
                url: 'https://pitchbook.com/profiles/company/453414-79',
                __typename: 'SocialMetadata'
            },
            indeed: null,
            youtube: null,
            monster: null,
            stackoverflow: null,
            angellist: null,
            __typename: 'Socials'
        },
        tractionMetrics: {
            facebookLikeCount: {
                metrics: [],
                ago90d: {
                    percentChange: null,
                    value: null,
                    change: null,
                    __typename: 'TractionMetricsPoint'
                },
                __typename: 'TractionMetricsInfo'
            },
            webTraffic: {
                metrics: [],
                ago90d: {
                    percentChange: null,
                    value: null,
                    change: null,
                    __typename: 'TractionMetricsPoint'
                },
                __typename: 'TractionMetricsInfo'
            },
            externalHeadcount: null,
            twitterFollowerCount: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-08T09:29:06.256Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-25T22:06:28.342Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-11T05:44:53.244Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-22T10:10:16.901Z',
                        metricValue: 171,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-06T08:04:54.810Z',
                        metricValue: 156,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-23T08:38:14.768Z',
                        metricValue: 156,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-02T05:37:20.234Z',
                        metricValue: 151,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-17T18:09:01.726Z',
                        metricValue: 124,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-07T07:42:13.741Z',
                        metricValue: 124,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-16T05:21:21.165Z',
                        metricValue: 124,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-20T07:15:05.394Z',
                        metricValue: 116,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-06T08:32:21.551Z',
                        metricValue: 118,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-19T07:14:15.333Z',
                        metricValue: 108,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-05T08:11:36.508Z',
                        metricValue: 106,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-21T05:22:57.534Z',
                        metricValue: 90,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-07T07:08:30.015Z',
                        metricValue: 63,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-24T19:45:36.966Z',
                        metricValue: 146,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-13T13:54:58.230Z',
                        metricValue: 146,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-01T08:04:59.914Z',
                        metricValue: 146,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T09:54:05.514Z',
                        metricValue: 144,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T06:23:37.204Z',
                        metricValue: 144,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-25T09:36:20.217Z',
                        metricValue: 140,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-11T00:00:00.000Z',
                        metricValue: 133,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-07-13T08:24:52.810Z',
                        metricValue: 130,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-29T06:09:01.499Z',
                        metricValue: 129,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-15T07:52:45.964Z',
                        metricValue: 130,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-18T10:26:48.253Z',
                        metricValue: 130,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-04T14:43:48.020Z',
                        metricValue: 118,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-27T09:29:16.489Z',
                        metricValue: 118,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-26T18:48:01.885Z',
                        metricValue: 118,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-03-08T03:58:06.024Z',
                        metricValue: 102,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-22T01:26:52.497Z',
                        metricValue: 95,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-04T11:22:02.232Z',
                        metricValue: 69,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-20T05:57:19.839Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-13T06:20:53.110Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-30T07:04:33.086Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-16T02:07:09.931Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-01T15:31:37.925Z',
                        metricValue: 25,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                ago90d: {
                    percentChange: 9.615384615384617,
                    value: 156,
                    change: 1,
                    __typename: 'TractionMetricsPoint'
                },
                __typename: 'TractionMetricsInfo'
            },
            instagramFollowerCount: {
                metrics: [],
                ago90d: {
                    percentChange: null,
                    value: null,
                    change: null,
                    __typename: 'TractionMetricsPoint'
                },
                __typename: 'TractionMetricsInfo'
            },
            linkedinFollowerCount: {
                metrics: [
                    {
                        timestamp: '2023-08-24T15:14:24.190Z',
                        metricValue: 1682,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-08T09:29:06.256Z',
                        metricValue: 1603,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-25T22:06:28.342Z',
                        metricValue: 1576,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-11T05:44:53.244Z',
                        metricValue: 1542,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-22T10:10:16.901Z',
                        metricValue: 1521,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-06T08:04:54.810Z',
                        metricValue: 1507,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-23T08:38:14.768Z',
                        metricValue: 1503,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-02T05:37:20.234Z',
                        metricValue: 1482,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-17T18:09:01.726Z',
                        metricValue: 1468,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-07T07:42:13.741Z',
                        metricValue: 1468,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-16T05:21:21.165Z',
                        metricValue: 1391,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-20T07:15:05.394Z',
                        metricValue: 1063,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-06T08:32:21.551Z',
                        metricValue: 1048,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-19T07:14:15.333Z',
                        metricValue: 1035,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-05T08:11:36.508Z',
                        metricValue: 980,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-21T05:22:57.534Z',
                        metricValue: 973,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-07T07:08:30.015Z',
                        metricValue: 894,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-24T19:45:36.966Z',
                        metricValue: 883,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-13T13:54:58.230Z',
                        metricValue: 842,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-01T08:04:59.914Z',
                        metricValue: 842,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T09:54:05.514Z',
                        metricValue: 796,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T06:23:37.204Z',
                        metricValue: 796,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-25T09:36:20.217Z',
                        metricValue: 767,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-11T00:00:00.000Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-07-13T08:24:52.810Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-29T06:09:01.499Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-15T07:52:45.964Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-18T10:26:48.253Z',
                        metricValue: 667,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-04T14:43:48.020Z',
                        metricValue: 652,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-27T09:29:16.489Z',
                        metricValue: 652,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-26T18:48:01.885Z',
                        metricValue: 652,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-03-08T03:58:06.024Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-22T01:26:52.497Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-04T11:22:02.232Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-20T05:57:19.839Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-13T06:20:53.110Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-30T07:04:33.086Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-16T02:07:09.931Z',
                        metricValue: 81,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                ago90d: {
                    percentChange: 11.61247511612475,
                    value: 1507,
                    change: 1,
                    __typename: 'TractionMetricsPoint'
                },
                __typename: 'TractionMetricsInfo'
            },
            headcount: {
                ago90d: {
                    percentChange: 9.67741935483871,
                    value: 31,
                    change: 1,
                    __typename: 'TractionMetricsPoint'
                },
                metrics: [
                    {
                        timestamp: '2023-08-26T04:19:45.501Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-25T02:42:36.337Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-24T01:17:02.717Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-12T02:57:26.589Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-11T03:45:04.956Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-08T09:29:06.256Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-04T02:54:11.110Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-03T06:03:13.975Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-25T22:06:28.342Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-20T06:31:41.850Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-19T01:59:08.633Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-11T05:44:53.244Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-09T03:25:16.437Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-08T03:07:29.206Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-29T03:29:48.950Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-28T02:30:53.887Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-27T10:39:46.541Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-22T10:10:16.901Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-16T03:19:10.051Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-15T01:43:47.053Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-14T01:37:08.913Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-13T19:56:32.251Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-06T08:04:54.810Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-31T03:33:04.044Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-30T03:43:23.652Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-23T08:38:14.768Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-04T20:19:52.955Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-03T03:49:18.811Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-02T05:37:20.234Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-19T04:04:00.900Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-18T05:00:22.485Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-17T05:57:45.983Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-16T06:26:20.999Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-13T11:49:04.795Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-07T07:42:13.741Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-29T02:24:24.762Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-21T05:14:19.367Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-16T05:21:21.165Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-08T03:34:08.761Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-25T02:31:18.188Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-17T02:23:44.562Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-09T03:29:01.773Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-29T21:27:32.611Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-20T07:15:05.394Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-19T07:16:02.043Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-18T20:09:47.066Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-07T02:37:04.473Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-06T08:32:21.551Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-19T07:14:15.333Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-05T08:11:36.508Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-21T05:22:57.534Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-07T07:08:30.015Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-24T19:45:36.966Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-13T13:54:58.230Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-01T08:04:59.914Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T09:54:05.514Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-25T09:36:20.217Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-11T00:00:00.000Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-07-13T08:24:52.810Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-29T06:09:01.499Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-15T07:52:45.964Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-18T10:26:48.253Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-04T14:43:48.020Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-27T09:29:16.489Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-26T18:48:01.885Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-03-08T03:58:06.024Z',
                        metricValue: 23,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-22T01:26:52.497Z',
                        metricValue: 24,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-04T11:22:02.232Z',
                        metricValue: 21,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-20T05:57:19.839Z',
                        metricValue: 22,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-13T06:20:53.110Z',
                        metricValue: 20,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-30T07:04:33.086Z',
                        metricValue: 19,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-16T02:07:09.931Z',
                        metricValue: 18,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-01T15:31:37.925Z',
                        metricValue: 16,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-17T18:48:47.638Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-13T12:36:43.509Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-10T15:02:48.425Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-28T04:10:32.996Z',
                        metricValue: 15,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-23T17:43:51.301Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-21T17:31:32.710Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-13T12:06:28.350Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-06T12:07:23.818Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-29T12:06:23.638Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-25T14:12:14.635Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-22T16:20:57.455Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-21T21:17:13.296Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-05T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-20T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-04T19:15:44.018Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-28T20:48:27.967Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-09T00:12:38.727Z',
                        metricValue: 8,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-23T18:18:19.442Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-17T04:29:18.076Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-11T22:22:24.843Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-18T20:11:51.139Z',
                        metricValue: 5,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-16T19:24:43.137Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-30T00:24:28.734Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-15T01:14:43.234Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-27T14:17:05.523Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-19T10:58:37.099Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-11T07:40:08.675Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-25T11:16:36.337Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-10T12:48:31.983Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            correctedHeadcount: {
                ago90d: {
                    percentChange: 9.67741935483871,
                    value: 31,
                    change: 1,
                    __typename: 'TractionMetricsPoint'
                },
                metrics: [
                    {
                        timestamp: '2023-08-26T04:19:45.501Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-25T02:42:36.337Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-24T01:17:02.717Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-12T02:57:26.589Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-11T03:45:04.956Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-08T09:29:06.256Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-04T02:54:11.110Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-08-03T06:03:13.975Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-25T22:06:28.342Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-20T06:31:41.850Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-19T01:59:08.633Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-11T05:44:53.244Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-09T03:25:16.437Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-07-08T03:07:29.206Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-29T03:29:48.950Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-28T02:30:53.887Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-27T10:39:46.541Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-22T10:10:16.901Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-16T03:19:10.051Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-15T01:43:47.053Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-14T01:37:08.913Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-13T19:56:32.251Z',
                        metricValue: 30,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-06-06T08:04:54.810Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-31T03:33:04.044Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-30T03:43:23.652Z',
                        metricValue: 31,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-23T08:38:14.768Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-04T20:19:52.955Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-03T03:49:18.811Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-05-02T05:37:20.234Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-19T04:04:00.900Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-18T05:00:22.485Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-17T05:57:45.983Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-16T06:26:20.999Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-13T11:49:04.795Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-04-07T07:42:13.741Z',
                        metricValue: 34,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-29T02:24:24.762Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-21T05:14:19.367Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-16T05:21:21.165Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-03-08T03:34:08.761Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-25T02:31:18.188Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-17T02:23:44.562Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-02-09T03:29:01.773Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-29T21:27:32.611Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-20T07:15:05.394Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-19T07:16:02.043Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-18T20:09:47.066Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-07T02:37:04.473Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2023-01-06T08:32:21.551Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-19T07:14:15.333Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-12-05T08:11:36.508Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-21T05:22:57.534Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-11-07T07:08:30.015Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-24T19:45:36.966Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-13T13:54:58.230Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-10-01T08:04:59.914Z',
                        metricValue: 36,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-09-17T09:54:05.514Z',
                        metricValue: 35,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-25T09:36:20.217Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-08-11T00:00:00.000Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-07-13T08:24:52.810Z',
                        metricValue: 29,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-29T06:09:01.499Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-06-15T07:52:45.964Z',
                        metricValue: 33,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-18T10:26:48.253Z',
                        metricValue: 32,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-05-04T14:43:48.020Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-27T09:29:16.489Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-04-26T18:48:01.885Z',
                        metricValue: 27,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-03-08T03:58:06.024Z',
                        metricValue: 23,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-22T01:26:52.497Z',
                        metricValue: 24,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-02-04T11:22:02.232Z',
                        metricValue: 21,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-20T05:57:19.839Z',
                        metricValue: 22,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2022-01-13T06:20:53.110Z',
                        metricValue: 20,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-30T07:04:33.086Z',
                        metricValue: 19,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-16T02:07:09.931Z',
                        metricValue: 18,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-12-01T15:31:37.925Z',
                        metricValue: 16,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-17T18:48:47.638Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-13T12:36:43.509Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-11-10T15:02:48.425Z',
                        metricValue: 17,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-28T04:10:32.996Z',
                        metricValue: 15,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-23T17:43:51.301Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-21T17:31:32.710Z',
                        metricValue: 14,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-13T12:06:28.350Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-10-06T12:07:23.818Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-29T12:06:23.638Z',
                        metricValue: 11,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-25T14:12:14.635Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-22T16:20:57.455Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-21T21:17:13.296Z',
                        metricValue: 10,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-09-05T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-20T00:00:00.000Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-08-04T19:15:44.018Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-28T20:48:27.967Z',
                        metricValue: 9,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-07-09T00:12:38.727Z',
                        metricValue: 8,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-23T18:18:19.442Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-17T04:29:18.076Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-06-11T22:22:24.843Z',
                        metricValue: 7,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-18T20:11:51.139Z',
                        metricValue: 5,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-05-16T19:24:43.137Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-30T00:24:28.734Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-04-15T01:14:43.234Z',
                        metricValue: 3,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-27T14:17:05.523Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-19T10:58:37.099Z',
                        metricValue: 2,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-03-11T07:40:08.675Z',
                        metricValue: 1,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-25T11:16:36.337Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    },
                    {
                        timestamp: '2021-02-10T12:48:31.983Z',
                        metricValue: 0,
                        __typename: 'TractionMetricsTimeSeriesMetric'
                    }
                ],
                __typename: 'TractionMetricsInfo'
            },
            headcountAdvisor: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountCustomerSuccess: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountData: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountDesign: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountEngineering: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountFinance: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountLegal: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountMarketing: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountOperations: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountOther: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountPeople: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountProduct: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountSales: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            headcountSupport: {
                metrics: [],
                __typename: 'TractionMetricsInfo'
            },
            __typename: 'TractionMetrics'
        },
        __typename: 'Company'
    }
};
export { getCompanyMetricsWithDepartments as getCompanyMetricsWithDepartments, getCompanyMetricsWithoutDepartments as getCompanyMetricsWithoutDepartments };
