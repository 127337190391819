import { ListCustomFieldType, ListNamedViewDisplayType } from '__generated__/graphql';
export const COMPANY_WATCHLIST_MOCK = {
    __typename: 'CompanyWatchlist',
    owner: {
        entityUrn: 'urn:harmonic:user:411',
        email: 'samrat.luintel.58@gmail.com',
        __typename: 'User'
    },
    id: 'f6ed4871-96b6-473a-9b4f-af19d87f4707',
    entityUrn: 'urn:harmonic:company_watchlist:f6ed4871-96b6-473a-9b4f-af19d87f4707',
    name: 'Companies #1',
    userWatchlistType: 'USER_MANAGED_WATCHLIST',
    sharedWithTeam: true
};
const GET_COMPANY_WATCHLIST_MOCK = {
    getCompanyWatchlistByIdOrUrn: {
        ...COMPANY_WATCHLIST_MOCK,
        companyIds: ['2261160'],
        customFields: [
            {
                __typename: 'CompanyListCustomField',
                type: ListCustomFieldType.TEXT,
                urn: 'urn:harmonic:company_list_custom_field:5',
                name: 'Text custom field',
                metadata: null,
                defaultValue: null,
                createdAt: '2024-01-01T00:00:00Z'
            },
            {
                __typename: 'CompanyListCustomField',
                type: ListCustomFieldType.NUMBER,
                urn: 'urn:harmonic:company_list_custom_field:7',
                name: 'Number custom field',
                metadata: null,
                defaultValue: null,
                createdAt: '2024-01-01T00:00:00Z'
            },
            {
                __typename: 'CompanyListCustomField',
                type: ListCustomFieldType.SINGLE_SELECT,
                urn: 'urn:harmonic:company_list_custom_field:6',
                name: 'Select custom field',
                defaultValue: null,
                createdAt: '2024-01-01T00:00:00Z',
                metadata: {
                    __typename: 'SelectListCustomFieldMetadata',
                    options: [
                        {
                            color: 'expressive-45',
                            name: 'Option 1',
                            urn: 'urn:harmonic:select_list_custom_field_value_option:b164fdb7-8dce-48b1-9966-2ba2c9d1745a'
                        },
                        {
                            color: 'expressive-5',
                            name: 'Option 2',
                            urn: 'urn:harmonic:select_list_custom_field_value_option:ed2e9e7c-fdff-4eaf-8f1f-77aad120be55'
                        }
                    ]
                }
            }
        ],
        namedViews: [
            {
                __typename: 'CompanyListNamedView',
                id: 1,
                entityUrn: 'urn:harmonic:company_list_named_view:1',
                name: 'test',
                searchQuery: null,
                displayType: ListNamedViewDisplayType.GRID,
                visibleColumns: [
                    'urn:harmonic:company_list_custom_field:5',
                    'urn:harmonic:company_field:company_description',
                    'urn:harmonic:company_field:company_highlights',
                    'urn:harmonic:company_field:company_website_url',
                    'urn:harmonic:company_field:company_team',
                    'urn:harmonic:company_field:company_customers_connections',
                    'urn:harmonic:company_field:company_customers_connections_last_email_at',
                    'urn:harmonic:company_field:company_customers_connections_last_meeting_at',
                    'urn:harmonic:company_field:company_team_notes',
                    'urn:harmonic:company_field:company_investor_names',
                    'urn:harmonic:company_field:company_funding_total',
                    'urn:harmonic:company_field:company_last_funding_type',
                    'urn:harmonic:company_field:company_last_funding_date',
                    'urn:harmonic:company_field:company_headcount',
                    'urn:harmonic:company_field:company_headcount_percent_change_90d_ago'
                ],
                groupByField: null,
                hideEmptyColumns: false
            }
        ]
    }
};
export { GET_COMPANY_WATCHLIST_MOCK };
