const GET_USER_PEOPLE_IMPORTS_BY_PEOPLE_LIST_MOCKED_RESPONSE = {
    getUserPeopleImportsByPeopleListUrnOrId: {
        __typename: 'UserPeopleImports',
        totalCount: 1,
        imports: [
            {
                pendingImportsCount: 100,
                totalImportsCount: 131,
                failedImportsCount: 11,
                createdAt: '2023-10-26T23:38:33.313316',
                entityUrn: 'urn:harmonic:people_watchlist:d9e3eaa9-b05d-46cc-a148-00e083a7a192',
                foundImportsCount: 131,
                fileName: 'harmonic_export_10262023.csv',
                userId: '123',
                userUrn: 'urn:harmonic:user:123',
                customerId: null,
                customerUrn: null,
                customer: null,
                user: {
                    __typename: 'User',
                    email: 'ali@harmonic.ai',
                    name: 'Ali'
                },
                __typename: 'UserPeopleImport'
            }
        ]
    }
};
export default GET_USER_PEOPLE_IMPORTS_BY_PEOPLE_LIST_MOCKED_RESPONSE;
