export const UPDATE_COMPANY_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE = {
    updateCompanyWatchlistCustomField: {
        __typename: 'CompanyListCustomField',
        urn: 'urn:harmonic:company_list_custom_field:2637',
        name: '12',
        type: 'TEXT',
        createdAt: '2025-02-08T16:54:12Z',
        defaultValue: {
            textDefaultValue: '',
            __typename: 'TextCustomFieldValue'
        },
        metadata: null
    }
};
