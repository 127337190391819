const GET_PERSON_PROFILE_HEADER_MOCK = {
    getPersonById: {
        id: 1,
        fullName: 'Scott Barnett',
        profilePictureUrl: 'https://assets.harmonic.ai/person_ef2d127de37b942baad06145e54b0c619a1f22327b2ebbcfbec78f5564afe39d.png',
        linkedinHeadline: 'Founder & CEO at Meetaway',
        linkedinProfileVisibilityType: 'EXPERIENCE_AND_EDUCATION_BLURRED',
        location: {
            location: 'Boston, Massachusetts, United States',
            __typename: 'LocationMetadata'
        },
        highlights: [
            {
                category: 'Elite Industry Experience',
                text: 'Elite Industry Experience, Scott Barnett: Consultant @ Deloitte, 1/2006-1/2008',
                __typename: 'PersonHighlight',
                sourceCompany: null
            }
        ],
        socials: {
            linkedin: {
                status: null,
                url: 'https://linkedin.com/in/barnettscott',
                followerCount: null,
                __typename: 'SocialMetadata'
            },
            crunchbase: null,
            pitchbook: null,
            angellist: null,
            twitter: null,
            facebook: null,
            instagram: null,
            indeed: null,
            youtube: null,
            monster: null,
            stackoverflow: null,
            __typename: 'Socials'
        },
        contact: {
            __typename: 'ContactMetadata',
            emails: ['scott@scott.com']
        },
        emailEnrichmentStatus: {
            __typename: 'EmailEnrichmentStatus',
            enrichedAt: '2020-01-01T00:00:00.000Z'
        },
        investorUrn: null,
        __typename: 'Person'
    }
};
const GET_PERSON_PROFILE_HEADER_NO_EMAILS_AND_NO_PRIOR_EMAIL_ENRICHMENT_MOCK = {
    getPersonById: {
        id: 1,
        fullName: 'Scott Barnett',
        profilePictureUrl: 'https://assets.harmonic.ai/person_ef2d127de37b942baad06145e54b0c619a1f22327b2ebbcfbec78f5564afe39d.png',
        linkedinHeadline: 'Founder & CEO at Meetaway',
        linkedinProfileVisibilityType: 'EXPERIENCE_AND_EDUCATION_BLURRED',
        location: {
            location: 'Boston, Massachusetts, United States',
            __typename: 'LocationMetadata'
        },
        highlights: [
            {
                category: 'Elite Industry Experience',
                text: 'Elite Industry Experience, Scott Barnett: Consultant @ Deloitte, 1/2006-1/2008',
                __typename: 'PersonHighlight',
                sourceCompany: null
            }
        ],
        socials: {
            linkedin: {
                status: null,
                url: 'https://linkedin.com/in/barnettscott',
                followerCount: null,
                __typename: 'SocialMetadata'
            },
            crunchbase: null,
            pitchbook: null,
            angellist: null,
            twitter: null,
            facebook: null,
            instagram: null,
            indeed: null,
            youtube: null,
            monster: null,
            stackoverflow: null,
            __typename: 'Socials'
        },
        contact: {
            __typename: 'ContactMetadata',
            emails: []
        },
        emailEnrichmentStatus: {
            __typename: 'EmailEnrichmentStatus',
            enrichedAt: null
        },
        investorUrn: null,
        __typename: 'Person'
    }
};
const GET_PERSON_PROFILE_HEADER_NO_EMAILS_BUT_PREVIOUSLY_ENRICHED_MOCK = {
    getPersonById: {
        id: 1,
        fullName: 'Scott Barnett',
        profilePictureUrl: 'https://assets.harmonic.ai/person_ef2d127de37b942baad06145e54b0c619a1f22327b2ebbcfbec78f5564afe39d.png',
        linkedinHeadline: 'Founder & CEO at Meetaway',
        linkedinProfileVisibilityType: 'EXPERIENCE_AND_EDUCATION_BLURRED',
        location: {
            location: 'Boston, Massachusetts, United States',
            __typename: 'LocationMetadata'
        },
        highlights: [
            {
                category: 'Elite Industry Experience',
                text: 'Elite Industry Experience, Scott Barnett: Consultant @ Deloitte, 1/2006-1/2008',
                __typename: 'PersonHighlight',
                sourceCompany: null
            }
        ],
        socials: {
            linkedin: {
                status: null,
                url: 'https://linkedin.com/in/barnettscott',
                followerCount: null,
                __typename: 'SocialMetadata'
            },
            crunchbase: null,
            pitchbook: null,
            angellist: null,
            twitter: null,
            facebook: null,
            instagram: null,
            indeed: null,
            youtube: null,
            monster: null,
            stackoverflow: null,
            __typename: 'Socials'
        },
        contact: {
            __typename: 'ContactMetadata',
            emails: []
        },
        emailEnrichmentStatus: {
            __typename: 'EmailEnrichmentStatus',
            enrichedAt: '2020-01-01T00:00:00.000Z'
        },
        investorUrn: null,
        __typename: 'Person'
    }
};
export { GET_PERSON_PROFILE_HEADER_MOCK, GET_PERSON_PROFILE_HEADER_NO_EMAILS_AND_NO_PRIOR_EMAIL_ENRICHMENT_MOCK };
