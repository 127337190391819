export const GET_ALL_TEAM_MEMBERS_BY_CUSTOMER_MOCKS = {
    getAllTeamMembersByCustomer: [
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'andrew@harmonic.ai',
                entityUrn: 'urn:harmonic:user:342',
                name: 'Andrew Michelson',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'brandt.liam@gmail.com',
                entityUrn: 'urn:harmonic:user:430',
                name: 'Liam',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'kdumovic@gmail.com',
                entityUrn: 'urn:harmonic:user:16',
                name: 'Kyle Dumovic',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'eric@harmonic.ai',
                entityUrn: 'urn:harmonic:user:407',
                name: 'Eric',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+test121212@harmonic.ai',
                entityUrn: 'urn:harmonic:user:426',
                name: 'Austin Test',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'yinebebz@gmail.com',
                entityUrn: 'urn:harmonic:user:441',
                name: 'Yinebeb Zenaw',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'ACTIVE',
            user: {
                email: 'austin+321@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2604',
                name: 'A',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austinberke+1234567890@gmail.com',
                entityUrn: 'urn:harmonic:user:1746',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'austin+aaa@harmonic.ai',
                entityUrn: 'urn:harmonic:user:1747',
                name: 'A',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+123456@harmonic.ai',
                entityUrn: 'urn:harmonic:user:422',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+test1212121@harmonic.ai',
                entityUrn: 'urn:harmonic:user:427',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+test1919@harmonic.ai',
                entityUrn: 'urn:harmonic:user:465',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'connorltinsley@gmail.com',
                entityUrn: 'urn:harmonic:user:477',
                name: 'Connor Tinsley',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'billcountry.m@turing.com',
                entityUrn: 'urn:harmonic:user:482',
                name: 'Billcountry',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+123123123@harmonic.ai',
                entityUrn: 'urn:harmonic:user:501',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'emmanuel.o@turing.com',
                entityUrn: 'urn:harmonic:user:557',
                name: 'Emmanuel Ogunbo',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'ogunbotemilola@yahoo.com',
                entityUrn: 'urn:harmonic:user:559',
                name: 'Emmanuel Ogunbo',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'unsubscribe@harmonic.ai',
                entityUrn: 'urn:harmonic:user:709',
                name: 'Test',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'akbar@harmonic.ai',
                entityUrn: 'urn:harmonic:user:447',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'reachmeatrish@gmail.com',
                entityUrn: 'urn:harmonic:user:410',
                name: 'Rishabh',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+123123@harmonic.ai',
                entityUrn: 'urn:harmonic:user:423',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'austin+123test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:448',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'bill@harmonic.ai',
                entityUrn: 'urn:harmonic:user:14',
                name: 'Bill Kabanga',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'ajay@harmonic.ai',
                entityUrn: 'urn:harmonic:user:1',
                name: 'Ajay Sohmshetty',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'bryan@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2',
                name: 'Bryan Casey',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austinberke@gmail.com',
                entityUrn: 'urn:harmonic:user:23',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'max@harmonic.ai',
                entityUrn: 'urn:harmonic:user:262',
                name: 'max ruderman',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'nvutri@gmail.com',
                entityUrn: 'urn:harmonic:user:263',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:269',
                name: 'Test User For harmonic.ai',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austinberke+admin@gmail.com',
                entityUrn: 'urn:harmonic:user:43',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'kalq28@gmail.com',
                entityUrn: 'urn:harmonic:user:374',
                name: 'Kamil',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'leejayxia@gmail.com',
                entityUrn: 'urn:harmonic:user:379',
                name: 'Leejay Xia',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'sulaimanmoses13@gmail.com',
                entityUrn: 'urn:harmonic:user:382',
                name: 'Kayizzi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'sumit@sumit-ghosh.com',
                entityUrn: 'urn:harmonic:user:417',
                name: 'Sumit',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+1234321@harmonic.ai',
                entityUrn: 'urn:harmonic:user:487',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'vinnie.rossi12@gmail.com',
                entityUrn: 'urn:harmonic:user:511',
                name: 'Vinnie',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'micahwilliams@college.harvard.edu',
                entityUrn: 'urn:harmonic:user:528',
                name: 'Micah',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'naveedsonline@gmail.com',
                entityUrn: 'urn:harmonic:user:546',
                name: 'Naveed Ahmad',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'sang@harmonic.ai',
                entityUrn: 'urn:harmonic:user:389',
                name: 'Sang',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'jason.morton@gmail.com',
                entityUrn: 'urn:harmonic:user:603',
                name: 'Jason Morton',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'kamal.shadi85@gmail.com ',
                entityUrn: 'urn:harmonic:user:629',
                name: 'Kamal',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'jspeiser@gmail.com',
                entityUrn: 'urn:harmonic:user:633',
                name: 'Joe Speiser',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'kamal.shadi85@gmail.com',
                entityUrn: 'urn:harmonic:user:635',
                name: 'kamal shadi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'tom+test1@harmonic.ai',
                entityUrn: 'urn:harmonic:user:638',
                name: 'Tom Test',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'tom+test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:665',
                name: 'Tom Alexander',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'akbar23@gmail.com',
                entityUrn: 'urn:harmonic:user:3189',
                name: 'Akbar',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'oghenerd@gmail.com',
                entityUrn: 'urn:harmonic:user:673',
                name: 'Michael Aworoghene',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+test123123123123@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2975',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'tyler@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2976',
                name: 'Tyler Lagaly',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'ali+1@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2977',
                name: 'Ali',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'anthony.okumura@gmail.com',
                entityUrn: 'urn:harmonic:user:2991',
                name: 'Anthony Okumura',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'thomas.h.vincent@gmail.com',
                entityUrn: 'urn:harmonic:user:2992',
                name: 'Tommy Vincent',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'zach.m.weiler@gmail.com',
                entityUrn: 'urn:harmonic:user:777',
                name: 'Zach Weiler',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'tlagaly@gmail.com',
                entityUrn: 'urn:harmonic:user:821',
                name: 'Tyler Lagaly',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'emilydgiglio@gmail.com',
                entityUrn: 'urn:harmonic:user:2904',
                name: 'Emily',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'simant@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2909',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'samrat.luintel.58@gmail.com',
                entityUrn: 'urn:harmonic:user:411',
                name: 'Samrat',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'alihashemi7@gmail.com',
                entityUrn: 'urn:harmonic:user:2694',
                name: 'Ali Hashemi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'kaushiksimanta@gmail.com',
                entityUrn: 'urn:harmonic:user:481',
                name: 'Simant',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'm@kmil.es',
                entityUrn: 'urn:harmonic:user:758',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'yangsiyin0409@gmail.com',
                entityUrn: 'urn:harmonic:user:485',
                name: 'siyinyang',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+123321@harmonic.ai',
                entityUrn: 'urn:harmonic:user:1783',
                name: 'austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'ACTIVE',
            user: {
                email: 'austin+12345321@harmonic.ai',
                entityUrn: 'urn:harmonic:user:1785',
                name: 'A',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+abd@harmonic.ai',
                entityUrn: 'urn:harmonic:user:1792',
                name: 'AB',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'abdeewm@gmail.com',
                entityUrn: 'urn:harmonic:user:449',
                name: 'Abdi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'highlights@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3054',
                name: 'Highlights',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'sabasabir134@gmail.com',
                entityUrn: 'urn:harmonic:user:2626',
                name: 'Saba Sabir',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'daniel@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2727',
                name: 'Daniel Blankenship',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'madeline@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2671',
                name: 'Madeline Le',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'esther@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2789',
                name: 'Esther',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'shirin@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2845',
                name: 'shirin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'kong-plugin-user-1@harmonic.ai',
                entityUrn: 'urn:harmonic:user:637',
                name: 'kong-plugin-user-1',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'kong-plugin-user-2@harmonic.ai',
                entityUrn: 'urn:harmonic:user:636',
                name: 'kong-plugin-user-2',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'brady@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2836',
                name: 'Brady Berryessa',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'demo@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3048',
                name: 'Demo',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'hightlights@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3053',
                name: 'Highlights',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'shivensamant94@gmail.com',
                entityUrn: 'urn:harmonic:user:3065',
                name: 'Shiven',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'alex@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3073',
                name: 'Alex Moser',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'simant123455@gmail.com',
                entityUrn: 'urn:harmonic:user:3078',
                name: 'Simant Kaushik Luitel',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'tombrady@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3087',
                name: 'Tom Brady',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'brady+0@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3153',
                name: 'Brady Berryessa',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'brady+1@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3154',
                name: 'Brady Berryessa',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'brady+2@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3155',
                name: 'Brady Berryessa',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'nate@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3208',
                name: 'nate',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'darius@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3149',
                name: 'Darius Tan',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'john@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3287',
                name: 'john',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'foo123@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3217',
                name: 'fooooo123123',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'sang.m@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3228',
                name: 'sangeem',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'zhansaya@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2720',
                name: 'Zhansaya',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'jared@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3295',
                name: 'Jared',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'justin@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3361',
                name: 'Justin Sharp',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'dan@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3392',
                name: 'Daniel',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'shiven@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3359',
                name: 'Shiven',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'carter@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3249',
                name: 'Carter',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'eugene@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3521',
                name: 'Eugene Yi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'rcugarte@gmail.com',
                entityUrn: 'urn:harmonic:user:3569',
                name: 'Roman',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'testingtest123@gmail.com',
                entityUrn: 'urn:harmonic:user:3954',
                name: 'testingtestttt',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'miles@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3849',
                name: 'Miles Konstantin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'miguel-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3853',
                name: 'Miguel',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'mike@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3964',
                name: 'Mike Palmer',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'arthur@harmonic.ai',
                entityUrn: 'urn:harmonic:user:22',
                name: 'Arthur Nangai',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'stephanie@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4089',
                name: 'steph',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'aiham@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2999',
                name: 'Aiham Hammami',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'brandon-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4203',
                name: 'Brandon',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'samrat@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4293',
                name: 'Samrat Luintel',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'miles+test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4349',
                name: 'Miles Test',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'samrat+test2@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4355',
                name: 'Samrat Test 2',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'ali@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4253',
                name: 'Ali Hashemi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'mohit-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4287',
                name: 'Mohit',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'daniel-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4436',
                name: 'Daniel Nieh',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'tristen@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4440',
                name: 'Tristen',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'john+test1@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4460',
                name: 'John Yeglinski',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'tom+prd@harmonic.ai',
                entityUrn: 'urn:harmonic:user:3479',
                name: 'Tom Alexander',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'notification@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4542',
                name: 'Harmonic Team',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'johnb@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4273',
                name: 'John Brkich',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'tu@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4563',
                name: 'Tu Le',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'shafaq@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4646',
                name: 'Shafaq Tirmizi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'billing@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4654',
                name: 'Billing',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'austin@harmonic.ai',
                entityUrn: 'urn:harmonic:user:357',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'miguel@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4621',
                name: 'Miguel Caballero Pinto',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'kailee@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4683',
                name: 'Kailee Hicks',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'yassine@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4699',
                name: 'Yassine Haddioui',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'shiven+test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4739',
                name: 'Shiven Samant',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'tom+dev@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4801',
                name: 'Tom Alexander',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'samw@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4812',
                name: 'Sam',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'abdi@harmonic.ai',
                entityUrn: 'urn:harmonic:user:2948',
                name: 'abdi Wibowo Maarif',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'austinberke+testingseats1@gmail.com',
                entityUrn: 'urn:harmonic:user:4855',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'stephanie+testtest@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4856',
                name: 'stephtest',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephsjlee@gmail.com',
                entityUrn: 'urn:harmonic:user:4858',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'alihashemi7+seatsharmonic@gmail.com',
                entityUrn: 'urn:harmonic:user:4866',
                name: 'Ali Hashemi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'ali+1357@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4925',
                name: 'Ali',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'john+test-followed@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4889',
                name: 'John Yeglinski',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'mohit@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4877',
                name: 'Mohit Yadav',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'aaron@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4941',
                name: 'Aaron',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'shiven+test_nov@harmonic.ai',
                entityUrn: 'urn:harmonic:user:4988',
                name: 'shiven_test',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+ok3@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5042',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'john+test3@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5095',
                name: 'John Yeglinski',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED_ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'stephanie+testtest1@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5233',
                name: 'steph',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'long@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5386',
                name: 'Long Chu',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'nate+0@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5390',
                name: 'Nate Shirley',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'ali+testinvitebug@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5398',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PAUSED',
            user: {
                email: 'francesca.peralta@persona.team',
                entityUrn: 'urn:harmonic:user:5417',
                name: 'Francesca Peralta',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'boogidad15@gmail.com',
                entityUrn: 'urn:harmonic:user:5418',
                name: 'Brad Couch',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'hishamwolley@hotmail.com',
                entityUrn: 'urn:harmonic:user:5490',
                name: 'Hisham Wolley',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'ACTIVE',
            user: {
                email: 'sortes23@hotmail.com',
                entityUrn: 'urn:harmonic:user:5491',
                name: 'Sergio Jose Morell Loreto',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'jstephens1234@gmail.com',
                entityUrn: 'urn:harmonic:user:5537',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'purnima@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5555',
                name: 'Purnima Kumar',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'miles+retool@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5760',
                name: 'Miles (Retool)',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'ali+testemailsends@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5800',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'ali+testemailbug3@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5801',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'jimmy-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5810',
                name: 'Jimmy Lafontaine Rivera',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'moses-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5815',
                name: 'Moses Mulondo',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'allan-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5799',
                name: 'Allan Embedded',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'johnny-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5816',
                name: 'Johnny Choi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'alihashemi7+testinginviteflow1@gmail.com',
                entityUrn: 'urn:harmonic:user:5851',
                name: 'Ali',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'alberto-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5961',
                name: 'Alberto Colon',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'jimmy@harmonic.ai',
                entityUrn: 'urn:harmonic:user:6038',
                name: 'Jimmy Rivera',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'kevin@harmonic.ai',
                entityUrn: 'urn:harmonic:user:6057',
                name: 'K L',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'stephanie+test100@harmonic.ai',
                entityUrn: 'urn:harmonic:user:6084',
                name: 'steph',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'arthur.nangai@gmail.com',
                entityUrn: 'urn:harmonic:user:6113',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'aantorik@harmonic.ai',
                entityUrn: 'urn:harmonic:user:6124',
                name: 'Aantorik Ganguly',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'allan+1@harmonic.ai',
                entityUrn: 'urn:harmonic:user:6154',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'stephanie+testtest2@harmonic.ai',
                entityUrn: 'urn:harmonic:user:6222',
                name: 'steph',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'kenny+test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:6225',
                name: 'Kenny',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'ACTIVE',
            user: {
                email: 'stephanie+test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7250',
                name: 'stephanie',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+newauth@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7270',
                name: 'Austin Newauth',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'ACTIVE',
            user: {
                email: 'johnny+test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7271',
                name: 'Johnny Choi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'ACTIVE',
            user: {
                email: 'ali+limited@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7277',
                name: 'Ali Limited',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'akbar+test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7280',
                name: 'Test Khan',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'aaron+test@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7281',
                name: 'Aaron Delete May4th',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED_ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'stephanie+test2@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7284',
                name: 'steph',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'johnny+test2@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7404',
                name: 'Johnny Choi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'stephanie+onboarding@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7472',
                name: 'steph',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+onboardingtest@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7497',
                name: 'Austin',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'outlook_2a8e78df4d31ce56@outlook.com',
                entityUrn: 'urn:harmonic:user:7532',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austinberke@outlook.com',
                entityUrn: 'urn:harmonic:user:7545',
                name: 'Austin Berke',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'ACTIVE',
            user: {
                email: 'mike+test5@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7582',
                name: 'Mike Palmer test',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'ali+testingmagiclink@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7591',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'stephanie+testtesttest@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7600',
                name: 'steph',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+jun9@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7607',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+testtest1000@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7627',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+324u32084983290@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7633',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+teststandard@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7638',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+testrandomrandom@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7639',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+testrandomrandom2023@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7640',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'ACTIVE',
            user: {
                email: 'stephanie+randomrandomrandom2023@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7641',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+test333@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7642',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+test332@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7643',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+finaltest@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7654',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+finalenterprise@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7655',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'miguel+standardtest@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7660',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+sadsadasdas@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7664',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+sadasdasdasa@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7665',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+finalfinaltest@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7676',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+crocodile@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7677',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+9280493280948032@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7680',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'samrat+3928402309@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7681',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'stephanie+32840823098@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7682',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'soham@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7708',
                name: 'Soham Kamat',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'ben@harmonic.ai',
                entityUrn: 'urn:harmonic:user:7709',
                name: 'Benjamin Fowler',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'johnny@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5990',
                name: 'Johnny Choi',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'aberke@g.ucla.edu',
                entityUrn: 'urn:harmonic:user:8123',
                name: 'AUSTIN BERKE',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'ACTIVE',
            user: {
                email: 'aberke@ucla.edu',
                entityUrn: 'urn:harmonic:user:8156',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'darrel-embedded@harmonic.ai',
                entityUrn: 'urn:harmonic:user:8164',
                name: 'Darrel Pollard',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'samrat+testjuly19@harmonic.ai',
                entityUrn: 'urn:harmonic:user:8265',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'kassandra.liwalas@persona.team',
                entityUrn: 'urn:harmonic:user:8423',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'ryan@harmonic.ai',
                entityUrn: 'urn:harmonic:user:8454',
                name: 'Ryan Cahalane',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PAUSED',
            user: {
                email: 'darrel@harmonic.ai',
                entityUrn: 'urn:harmonic:user:8525',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'darrel+1@harmonic.ai',
                entityUrn: 'urn:harmonic:user:8607',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'vidur@harmonic.ai',
                entityUrn: 'urn:harmonic:user:8780',
                name: 'Vidur Bahal',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'erin@harmonic.ai',
                entityUrn: 'urn:harmonic:user:8778',
                name: 'Erin McCarthy',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED_ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'travis@harmonic.ai',
                entityUrn: 'urn:harmonic:user:8799',
                name: 'Travis Hairfield',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'olivia@harmonic.ai',
                entityUrn: 'urn:harmonic:user:8924',
                name: 'Olivia Haskell',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'alihashemi7+magiclink@gmail.com',
                entityUrn: 'urn:harmonic:user:9233',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PENDING_ACTIVATION',
            user: {
                email: 'ali+123456@harmonic.ai',
                entityUrn: 'urn:harmonic:user:9234',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PAUSED',
            user: {
                email: 'manna.rizvi@gmail.com',
                entityUrn: 'urn:harmonic:user:9267',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'ACTIVE',
            user: {
                email: 'austin+webflow@harmonic.ai',
                entityUrn: 'urn:harmonic:user:9273',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'LIMITED',
            status: 'PAUSED',
            user: {
                email: 'toobazehra2004@gmail.com',
                entityUrn: 'urn:harmonic:user:9292',
                name: 'Tooba Zehra',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'MEMBER',
            status: 'PAUSED',
            user: {
                email: 'seanjohnson08@gmail.com',
                entityUrn: 'urn:harmonic:user:9353',
                name: 'Sean Johnson',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'ACTIVE',
            user: {
                email: 'kenny@harmonic.ai',
                entityUrn: 'urn:harmonic:user:5530',
                name: 'Kenny Xu',
                __typename: 'User'
            },
            __typename: 'TeamMember'
        },
        {
            role: 'ADMIN',
            status: 'PENDING_PILOT_AGREEMENT_ACCEPTANCE',
            user: {
                email: 'kenny+1@harmonic.ai',
                entityUrn: 'urn:harmonic:user:9711',
                name: null,
                __typename: 'User'
            },
            __typename: 'TeamMember'
        }
    ]
};
