import { COMPANIES_BY_IDS_HARMONIC_DETAILS_MOCK } from './get_companies_by_ids';
export const GET_EMPY_COMPANIES_IN_WATCHLIST_MOCK = {
    getCompanyWatchlistByIdOrUrn: {
        id: 'f6ed4871-96b6-473a-9b4f-af19d87f4707',
        companyEntries: {
            __typename: 'CompanyWatchlistEntriesConnection',
            edges: [],
            totalCount: 0,
            pageInfo: {
                hasNextPage: true,
                startCursor: 'WzU5LDIxNjgzOTFd',
                endCursor: 'WzAsODYzMjM0OV0=',
                __typename: 'PageInfo'
            }
        },
        __typename: 'CompanyWatchlist'
    }
};
export const GET_COMPANIES_IN_WATCHLIST_MOCK = {
    getCompanyWatchlistByIdOrUrn: {
        id: 'f6ed4871-96b6-473a-9b4f-af19d87f4707',
        companyEntries: {
            __typename: 'CompanyWatchlistEntriesConnection',
            edges: [
                {
                    cursor: 'WzU5LDIxNjgzOTFd',
                    node: {
                        __typename: 'CompanyWatchlistEntryNode',
                        entryUrn: 'urn:harmonic:company_watchlist_entry:10027783',
                        entryCreatedAt: '2024-08-22T21:13:22.096429',
                        pendingFields: [],
                        customFieldValues: [
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:1038',
                                    name: 'Multi-select',
                                    type: 'MULTI_SELECT',
                                    metadata: {
                                        __typename: 'SelectListCustomFieldMetadata',
                                        options: [
                                            {
                                                color: 'neutral',
                                                name: '1',
                                                urn: 'urn:harmonic:select_list_custom_field_value_option:229656ef-6e06-446e-960f-4502b5f6e765',
                                                __typename: 'SelectListCustomFieldValueOption'
                                            },
                                            {
                                                color: 'expressive-45',
                                                name: '2',
                                                urn: 'urn:harmonic:select_list_custom_field_value_option:64d866db-e554-4cf5-9d68-408be90a252d',
                                                __typename: 'SelectListCustomFieldValueOption'
                                            },
                                            {
                                                color: 'expressive-5',
                                                name: '3',
                                                urn: 'urn:harmonic:select_list_custom_field_value_option:a1b4ba32-b5eb-48df-bedd-2b672c19ba25',
                                                __typename: 'SelectListCustomFieldValueOption'
                                            }
                                        ],
                                        default: null
                                    }
                                },
                                data: {
                                    __typename: 'MultiSelectCustomFieldValue',
                                    multiSelectValue: []
                                },
                                updatedAt: '2024-10-11T17:50:34Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:17809',
                                createdAt: '2024-10-11T17:50:34Z',
                                __typename: 'CompanyListCustomFieldValue'
                            },
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:1036',
                                    name: 'Date',
                                    type: 'DATE',
                                    metadata: {
                                        __typename: 'DateListCustomFieldMetadata',
                                        dateFormat: 'MM_DD_YYYY'
                                    }
                                },
                                data: {
                                    __typename: 'DateListCustomFieldValue',
                                    dateValue: '2024-10-09'
                                },
                                updatedAt: '2024-10-11T17:50:44Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:17810',
                                createdAt: '2024-10-11T17:50:44Z',
                                __typename: 'CompanyListCustomFieldValue'
                            },
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:1035',
                                    name: 'Person',
                                    type: 'PERSON',
                                    metadata: null
                                },
                                data: {
                                    __typename: 'PersonSelectCustomFieldValue',
                                    personSelectValue: []
                                },
                                updatedAt: '2024-10-11T17:50:46Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:17811',
                                createdAt: '2024-10-11T17:50:46Z',
                                __typename: 'CompanyListCustomFieldValue'
                            },
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:677',
                                    name: 'Status',
                                    type: 'SINGLE_SELECT',
                                    metadata: {
                                        __typename: 'SelectListCustomFieldMetadata',
                                        options: [
                                            {
                                                color: 'expressive-11',
                                                name: 'Reach out later',
                                                urn: 'urn:harmonic:select_list_custom_field_value_option:d6025362-4db2-4482-ba33-bfaee2be5b31',
                                                __typename: 'SelectListCustomFieldValueOption'
                                            }
                                        ],
                                        default: null
                                    }
                                },
                                data: {
                                    __typename: 'SingleSelectCustomFieldValue',
                                    singleSelectValue: 'urn:harmonic:select_list_custom_field_value_option:d6025362-4db2-4482-ba33-bfaee2be5b31'
                                },
                                updatedAt: '2024-08-22T21:15:32Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:8557',
                                createdAt: '2024-08-22T21:15:32Z',
                                __typename: 'CompanyListCustomFieldValue'
                            },
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:1034',
                                    name: 'Number',
                                    type: 'NUMBER',
                                    metadata: {
                                        __typename: 'NumberListCustomFieldMetadata',
                                        numberFormat: 'NUMBER'
                                    }
                                },
                                data: {
                                    __typename: 'NumberListCustomFieldValue',
                                    numberValue: 1
                                },
                                updatedAt: '2024-10-11T17:50:51Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:17812',
                                createdAt: '2024-10-11T17:50:51Z',
                                __typename: 'CompanyListCustomFieldValue'
                            },
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:1033',
                                    name: 'Text',
                                    type: 'TEXT',
                                    metadata: null
                                },
                                data: {
                                    __typename: 'TextCustomFieldValue',
                                    textValue: 'adawdad'
                                },
                                updatedAt: '2024-10-11T17:53:33Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:17814',
                                createdAt: '2024-10-11T17:53:33Z',
                                __typename: 'CompanyListCustomFieldValue'
                            },
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:1008',
                                    name: 'Test',
                                    type: 'TEXT',
                                    metadata: null
                                },
                                data: {
                                    __typename: 'TextCustomFieldValue',
                                    textValue: 'awdadadadawdadadadwwdadadwdadadadwadaadwadaadawdadwadada'
                                },
                                updatedAt: '2024-10-07T22:02:25Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:16546',
                                createdAt: '2024-10-07T21:50:16Z',
                                __typename: 'CompanyListCustomFieldValue'
                            },
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:1142',
                                    name: '123',
                                    type: 'TEXT',
                                    metadata: null
                                },
                                data: {
                                    __typename: 'TextCustomFieldValue',
                                    textValue: 'dadawda'
                                },
                                updatedAt: '2024-10-29T19:37:38Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:19349',
                                createdAt: '2024-10-29T19:37:38Z',
                                __typename: 'CompanyListCustomFieldValue'
                            },
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:826',
                                    name: 'Status',
                                    type: 'STATUS',
                                    metadata: {
                                        __typename: 'SelectListCustomFieldMetadata',
                                        options: [
                                            {
                                                color: 'neutral',
                                                name: '1',
                                                urn: 'urn:harmonic:select_list_custom_field_value_option:f7a39261-9f8e-487c-9230-216d135b27a5',
                                                __typename: 'SelectListCustomFieldValueOption'
                                            },
                                            {
                                                color: 'expressive-45',
                                                name: '2',
                                                urn: 'urn:harmonic:select_list_custom_field_value_option:c7e94257-59c8-4343-8a9e-2823dceff4a2',
                                                __typename: 'SelectListCustomFieldValueOption'
                                            }
                                        ],
                                        default: null
                                    }
                                },
                                data: {
                                    __typename: 'StatusListCustomFieldValue',
                                    statusValue: 'urn:harmonic:select_list_custom_field_value_option:c7e94257-59c8-4343-8a9e-2823dceff4a2'
                                },
                                updatedAt: '2024-10-13T17:34:03Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:16543',
                                createdAt: '2024-10-07T21:44:50Z',
                                __typename: 'CompanyListCustomFieldValue'
                            },
                            {
                                customField: {
                                    __typename: 'CompanyListCustomField',
                                    urn: 'urn:harmonic:company_list_custom_field:1119',
                                    name: 'Checkbox',
                                    type: 'CHECKBOX',
                                    metadata: null
                                },
                                data: {
                                    __typename: 'CheckboxListCustomFieldValue',
                                    checkboxValue: true
                                },
                                updatedAt: '2024-10-28T01:55:26Z',
                                urn: 'urn:harmonic:company_list_custom_field_value:19286',
                                createdAt: '2024-10-28T01:55:26Z',
                                __typename: 'CompanyListCustomFieldValue'
                            }
                        ],
                        addedBy: {
                            __typename: 'User',
                            id: '1',
                            name: 'Harmonic',
                            entityUrn: 'urn:harmonic:user:1',
                            email: 'harmonic@harmonic.com'
                        },
                        company: {
                            ...COMPANIES_BY_IDS_HARMONIC_DETAILS_MOCK
                        }
                    },
                    __typename: 'CompanyWatchlistEntryEdge'
                }
            ],
            totalCount: 34,
            pageInfo: {
                hasNextPage: true,
                startCursor: 'WzU5LDIxNjgzOTFd',
                endCursor: 'WzAsODYzMjM0OV0=',
                __typename: 'PageInfo'
            }
        },
        __typename: 'CompanyWatchlist'
    }
};
