export const UPDATE_WATCHLIST_MOCKED_RESPONSE = {
    data: {
        updateCompanyWatchlist: {
            id: '63805f2b-258e-4c87-bbae-b81c87e093ea',
            entityUrn: 'urn:harmonic:company_watchlist:63805f2b-258e-4c87-bbae-b81c87e093ea',
            companyIds: [],
            name: 'Test New Name',
            sharedWithTeam: false,
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            __typename: 'CompanyWatchlist'
        }
    }
};
