export const GET_PERSON_SAVED_SEARCH_RESULTS_MOCKED_RESPONSE = {
    getSavedSearch: {
        id: 117805,
        results: {
            totalCount: 191768952,
            pageInfo: {
                endCursor: 'WzI2XQ==',
                hasNextPage: true,
                startCursor: 'WzFd',
                __typename: 'PageInfo'
            },
            edges: [
                {
                    __typename: 'SavedSearchResultEdge',
                    cursor: 'WzJd',
                    node: {
                        __typename: 'SavedSearchResultNode',
                        netNewId: null,
                        entity: {
                            __typename: 'Person',
                            personUrn: 'urn:harmonic:person:2',
                            id: 2,
                            fullName: 'Harry Schmiedeke',
                            firstName: 'Harry',
                            lastName: 'Schmiedeke',
                            about: null,
                            watchlists: [],
                            profilePictureUrl: null,
                            linkedinHeadline: null,
                            location: null,
                            highlights: [],
                            correspondenceSummary: null,
                            socials: {
                                linkedin: null,
                                twitter: null
                            },
                            experience: [],
                            education: [],
                            contact: null,
                            emailEnrichmentStatus: null,
                            updates: [],
                            userConnections: []
                        }
                    }
                },
                {
                    __typename: 'SavedSearchResultEdge',
                    cursor: 'WzNd',
                    node: {
                        __typename: 'SavedSearchResultNode',
                        netNewId: null,
                        entity: {
                            __typename: 'Person',
                            personUrn: 'urn:harmonic:person:3',
                            id: 3,
                            fullName: 'Jimmy Chebat',
                            firstName: 'Jimmy',
                            lastName: 'Chebat',
                            about: null,
                            watchlists: [],
                            profilePictureUrl: null,
                            linkedinHeadline: null,
                            location: null,
                            highlights: [],
                            correspondenceSummary: null,
                            socials: {
                                linkedin: null,
                                twitter: null
                            },
                            experience: [],
                            education: [],
                            contact: null,
                            emailEnrichmentStatus: null,
                            updates: [],
                            userConnections: []
                        }
                    }
                }
            ],
            __typename: 'SavedSearchResultsConnection'
        },
        __typename: 'SavedSearch'
    }
};
