export const getUseFetchWatchlists = {
    getCompanyWatchlistsForTeam: [
        {
            owner: {
                entityUrn: 'urn:harmonic:user:2885',
                name: 'Ali',
                email: 'john@harmonic.ai',
                __typename: 'User'
            },
            id: 'eabf58fa-26d0-4f1b-84a7-742d939eac2a',
            entityUrn: 'urn:harmonic:company_watchlist:eabf58fa-26d0-4f1b-84a7-742d939eac2a',
            name: 'test-update-1-13',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: 'DISSIMILAR',
            sharedWithTeam: true,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: 'af21a51b-2a47-49ba-9fa5-b53329c69d13',
            entityUrn: 'urn:harmonic:company_watchlist:af21a51b-2a47-49ba-9fa5-b53329c69d13',
            name: 'followed by me 6',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: 'SIMILAR',
            sharedWithTeam: true,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '711615ba-d93e-4788-beca-6b2b10a225c6',
            entityUrn: 'urn:harmonic:company_watchlist:711615ba-d93e-4788-beca-6b2b10a225c6',
            name: 'brand new list',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: 'b5f16f1f-1842-44d2-9dfc-cd902da850b4',
            entityUrn: 'urn:harmonic:company_watchlist:b5f16f1f-1842-44d2-9dfc-cd902da850b4',
            name: 'my great new list',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: 'SIMILAR',
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '551198b0-52b5-4392-83ab-baa59b719860',
            entityUrn: 'urn:harmonic:company_watchlist:551198b0-52b5-4392-83ab-baa59b719860',
            name: 'my new list',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '276809c3-bff2-4dbd-a295-56c4d48f1362',
            entityUrn: 'urn:harmonic:company_watchlist:276809c3-bff2-4dbd-a295-56c4d48f1362',
            name: 'harmonic',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: 'SIMILAR',
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '9d24c52e-599b-4e19-b80c-5c21c6836a41',
            entityUrn: 'urn:harmonic:company_watchlist:9d24c52e-599b-4e19-b80c-5c21c6836a41',
            name: 'New blank list',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '3792ae7a-f82b-49f7-9801-2bcdd918aa7c',
            entityUrn: 'urn:harmonic:company_watchlist:3792ae7a-f82b-49f7-9801-2bcdd918aa7c',
            name: 'New blank list 2',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: 'DISSIMILAR',
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '5a72d5f4-4edf-41cb-b19e-f81beef67254',
            entityUrn: 'urn:harmonic:company_watchlist:5a72d5f4-4edf-41cb-b19e-f81beef67254',
            name: 'My new list 12',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: 'SIMILAR',
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '52394b37-0c35-4bf5-a365-a352d5c10bb6',
            entityUrn: 'urn:harmonic:company_watchlist:52394b37-0c35-4bf5-a365-a352d5c10bb6',
            name: 'Lads',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '7fa69353-d2a3-426d-ada2-08cd0f341464',
            entityUrn: 'urn:harmonic:company_watchlist:7fa69353-d2a3-426d-ada2-08cd0f341464',
            name: 'My new list',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '8015e4a7-52f7-4884-b35a-45db9519add9',
            entityUrn: 'urn:harmonic:company_watchlist:8015e4a7-52f7-4884-b35a-45db9519add9',
            name: 'Hidden companies',
            userWatchlistType: 'USER_HIDDEN_ENTITIES',
            userRelevanceScoreInfluenceType: 'DISSIMILAR',
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '8ea17be9-37cf-4428-a228-81e2d1989231',
            entityUrn: 'urn:harmonic:company_watchlist:8ea17be9-37cf-4428-a228-81e2d1989231',
            name: 'nuNew',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: 'c3d3e914-3aeb-44f5-af06-bf679b9df314',
            entityUrn: 'urn:harmonic:company_watchlist:c3d3e914-3aeb-44f5-af06-bf679b9df314',
            name: 'new 100',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '280e74c1-af8e-4eda-bc8e-4ab0c384f83e',
            entityUrn: 'urn:harmonic:company_watchlist:280e74c1-af8e-4eda-bc8e-4ab0c384f83e',
            name: 'the new',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '89470f8a-67db-49c8-aad9-11f5ad91864c',
            entityUrn: 'urn:harmonic:company_watchlist:89470f8a-67db-49c8-aad9-11f5ad91864c',
            name: 'newNu2',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '5e0ff873-1ef3-4152-a6b9-42b81aaa0526',
            entityUrn: 'urn:harmonic:company_watchlist:5e0ff873-1ef3-4152-a6b9-42b81aaa0526',
            name: 'new1',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: 'd82cf9f8-15c8-4702-883d-911ccb8ccb44',
            entityUrn: 'urn:harmonic:company_watchlist:d82cf9f8-15c8-4702-883d-911ccb8ccb44',
            name: 'new2',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '3ca35889-f3e4-4d4a-bc23-8f83be4de726',
            entityUrn: 'urn:harmonic:company_watchlist:3ca35889-f3e4-4d4a-bc23-8f83be4de726',
            name: 'asdf',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '8569bc6d-609a-477d-b180-0c7563e2cecf',
            entityUrn: 'urn:harmonic:company_watchlist:8569bc6d-609a-477d-b180-0c7563e2cecf',
            name: 'hi2',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: 'c8bb3e24-7044-43a3-b7d4-6f72882a6c5c',
            entityUrn: 'urn:harmonic:company_watchlist:c8bb3e24-7044-43a3-b7d4-6f72882a6c5c',
            name: 'asdfasdf',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '2322f90b-edd8-48ab-b49f-868729c19881',
            entityUrn: 'urn:harmonic:company_watchlist:2322f90b-edd8-48ab-b49f-868729c19881',
            name: 'Liked companies',
            userWatchlistType: 'USER_FAVORITED_ENTITIES',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '1608ed26-b7a7-42b0-8285-f4f8a8d2e1f6',
            entityUrn: 'urn:harmonic:company_watchlist:1608ed26-b7a7-42b0-8285-f4f8a8d2e1f6',
            name: 'My great new list 1',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: 'ff775793-afef-44f5-93af-dbf4a21a9269',
            entityUrn: 'urn:harmonic:company_watchlist:ff775793-afef-44f5-93af-dbf4a21a9269',
            name: 'no ',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '6ceaa173-16de-46a5-bfc3-2ef0f42fd4eb',
            entityUrn: 'urn:harmonic:company_watchlist:6ceaa173-16de-46a5-bfc3-2ef0f42fd4eb',
            name: 'nwe list',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '600f0fe1-cb14-4953-9727-d77de1572226',
            entityUrn: 'urn:harmonic:company_watchlist:600f0fe1-cb14-4953-9727-d77de1572226',
            name: 'new',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '166018d8-b196-4805-8939-4f2db544d4e6',
            entityUrn: 'urn:harmonic:company_watchlist:166018d8-b196-4805-8939-4f2db544d4e6',
            name: 'my new',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '114ba566-3d76-446d-a736-32b27670cee6',
            entityUrn: 'urn:harmonic:company_watchlist:114ba566-3d76-446d-a736-32b27670cee6',
            name: 'AA',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        },
        {
            owner: {
                entityUrn: 'urn:harmonic:user:429',
                name: 'Austin',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: 'cfbe313b-9f55-4d47-a42d-df752ac56a55',
            entityUrn: 'urn:harmonic:company_watchlist:cfbe313b-9f55-4d47-a42d-df752ac56a55',
            name: 'new list!',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            userRelevanceScoreInfluenceType: null,
            sharedWithTeam: false,
            __typename: 'CompanyWatchlist',
            createdAt: '2024-06-20T17:01:13.766052',
            updatedAt: '2024-06-20T17:01:13.766052',
            namedViews: []
        }
    ]
};
