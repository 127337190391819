export const UPDATE_PERSON_WATCHLIST_MOCKED_RESPONSE = {
    data: {
        updatePeopleWatchlist: {
            creator: {
                entityUrn: 'urn:harmonic:user:357',
                email: 'austin@harmonic.ai',
                __typename: 'User'
            },
            id: '0890fb4a-6bd9-488d-8272-fb0b7d7bf0e0',
            entityUrn: 'urn:harmonic:people_watchlist:0890fb4a-6bd9-488d-8272-fb0b7d7bf0e0',
            name: 'Test person list',
            sharedWithTeam: false,
            __typename: 'PeopleWatchlist'
        }
    }
};
