export const UPSERT_COMPANY_WATCHLIST_CUSTOM_FIELD_VALUE_MOCKED_RESPONSE = {
    upsertCompanyWatchlistCustomFieldValue: {
        customField: {
            urn: 'urn:harmonic:company_list_custom_field:5',
            type: 'TEXT',
            __typename: 'CompanyListCustomField'
        },
        data: {
            __typename: 'TextCustomFieldValue',
            textValue: '1'
        },
        updatedAt: '2025-02-08T17:06:57Z',
        urn: 'urn:harmonic:company_list_custom_field_value:296144',
        createdAt: '2025-02-08T17:06:57Z',
        __typename: 'CompanyListCustomFieldValue'
    }
};
