const getCompanyFinancingV2 = {
    getCompanyById: {
        id: 1,
        name: 'Harmonic',
        fundingPerEmployee: 370221.85,
        funding: {
            fundingTotal: '37170000',
            numFundingRounds: 9,
            investors: [
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:638220',
                    name: 'SVA',
                    id: 638220,
                    logoUrl: 'https://assets.harmonic.ai/company_9a64b50a483b3d4b8f85fbb1b24a2de5f1d811b29694415e43996ded3bca7f75.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:1455926',
                    name: 'Bain Capital Ventures',
                    id: 1455926,
                    logoUrl: 'https://assets.harmonic.ai/company_f69019b786f8309911cad0191513907d8bd28fa7e86dd2ca3c6b15929fa6aebb.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:1489950',
                    name: 'Augusta Investments LLC',
                    id: 1489950,
                    logoUrl: null
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:703199',
                    name: '50 South Capital',
                    id: 703199,
                    logoUrl: 'https://assets.harmonic.ai/company_8edac032a04e87f8ff5d531bad4680f080c0895e851894957f32e8fa2894b5e2.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:630577',
                    name: 'Hyde Park Venture Partners',
                    id: 630577,
                    logoUrl: 'https://assets.harmonic.ai/company_af0d38dd52400691e55f2fc1cd39621739a9aca3b530f9440abb0e9a1e2daa58.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:3295467',
                    name: 'SoftBank Group Corp.',
                    id: 3295467,
                    logoUrl: 'https://assets.harmonic.ai/company_839fed660439c32b466357fee38c76602c6da70bf1308295936074d9feed10a2.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:710731',
                    name: 'Bluestein Ventures',
                    id: 710731,
                    logoUrl: 'https://assets.harmonic.ai/company_96f88ec857e52f7abda2658dea77274292906d147edec95a62181b79cf2a73ae.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:744615',
                    name: 'Otter Rock Capital',
                    id: 744615,
                    logoUrl: 'https://static.licdn.com/aero-v1/sc/h/cs8pjfgyw96g44ln9r7tct85f'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:3362842',
                    name: 'Kube VC',
                    id: 3362842,
                    logoUrl: 'https://assets.harmonic.ai/company_2666d4ab2622c959662e6944fac8119d66fe416a132b3c16f3e56aa24df20ee5.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:740295',
                    name: 'CapitalX - Early Stage VC',
                    id: 740295,
                    logoUrl: 'https://assets.harmonic.ai/company_4dd81a2f6d987a637f4016360f17570a2d09c6f2b3be545fbb88301fab4f483e.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:12929774',
                    name: 'NFQ',
                    id: 12929774,
                    logoUrl: 'https://assets.harmonic.ai/company_ea0bed0fd7dd84faf5818024c46bb7edcf71bfc483e8d150f65dba64dd95e6be.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:712850',
                    name: 'FJ Labs',
                    id: 712850,
                    logoUrl: 'https://assets.harmonic.ai/company_9041b7906b2bc258f09100180521e8b548fa740410360fbe109b044200cc7dc3.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:613046',
                    name: 'LetsVenture',
                    id: 613046,
                    logoUrl: 'https://assets.harmonic.ai/company_9a2ed0268d13c3706668fc7457efdb67d623f9942dc2bc455dc905eb7002b4e1.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:2163604',
                    name: 'Silicon Valley Bank',
                    id: 2163604,
                    logoUrl: 'https://assets.harmonic.ai/company_9a7e5a9884594830d042dbbdb9e3c51e4c24b6d08fc931ee64e12fe89935a446.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:4878744',
                    name: 'Pine Grove Holdings, LLC',
                    id: 4878744,
                    logoUrl: 'https://assets.harmonic.ai/company_da55c936f8a342fdd1a6e1743f2a954768fbefebb745deee3e8e0d32b5ee7f56.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:1450143',
                    name: 'Startcaps Ventures',
                    id: 1450143,
                    logoUrl: 'https://assets.harmonic.ai/company_fd4b214b6fd67c07ce9438c58ebdaf5ba4877c0f53366cb783235ed3114e3ee1.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:627547',
                    name: 'Reynolds and Company Venture Partners, LLC',
                    id: 627547,
                    logoUrl: 'https://assets.harmonic.ai/default-stealth-company.4ee20cd0.svg'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:2137562',
                    name: 'Wefunder',
                    id: 2137562,
                    logoUrl: 'https://assets.harmonic.ai/company_443e125f45a83e23dbb9df769187d82715fdb4fefc1d2807e0df9b0d42f67337.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:11529224',
                    name: 'Identified',
                    id: 11529224,
                    logoUrl: 'https://assets.harmonic.ai/company_2e85b0791695ed08cc77e1af962127009233e7d5cffabe9fdfe8ee5c5969133d.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:746838',
                    name: 'Recruit Strategic Partners',
                    id: 746838,
                    logoUrl: 'https://assets.harmonic.ai/company_7bd24a91ead7a7de617c44bd09789fd57dbadcbf9501c08139a512893f94b05a.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:716217',
                    name: 'Bragiel Brothers',
                    id: 716217,
                    logoUrl: 'https://assets.harmonic.ai/company_b5cbdcdbc396fec5883cc16b5c9d60e6b948875e6dfb750c3ae58e3c2f08d6b7.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:732155',
                    name: 'Menlo Ventures',
                    id: 732155,
                    logoUrl: 'https://assets.harmonic.ai/company_f0c086df92119022bf61c99bf1f38750f993eac8cc8b1cdd5e771c0af36ceceb.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:3114822',
                    name: 'Russell Long',
                    id: 3114822,
                    logoUrl: null
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:1729703',
                    name: 'Y Combinator',
                    id: 1729703,
                    logoUrl: 'https://assets.harmonic.ai/company_7985d278eb157feff558ab20766a7a5a38d47ad50104d1b3a155b73b79e69fde.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:1676404',
                    name: 'SoftBank Investment Advisers',
                    id: 1676404,
                    logoUrl: 'https://assets.harmonic.ai/company_43d58ca350589aa66647a23e4a76be997cf1751ff604cc1bd7ee751f37c8a969.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:1500425',
                    name: 'Zillionize',
                    id: 1500425,
                    logoUrl: 'https://assets.harmonic.ai/company_6cca6edc4c3c489368f5df2993d0f5b3f60b36b74265ec7e474ac100de2bcf17.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:1893157',
                    name: 'Joseph Montana',
                    id: 1893157,
                    logoUrl: null
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:635393',
                    name: 'FundersClub',
                    id: 635393,
                    logoUrl: 'https://assets.harmonic.ai/company_fb43f60da43c03d2d31ae690d303f4aefab0832cb2bca27079fbeed108444af7.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:731330',
                    name: 'Service Provider Capital',
                    id: 731330,
                    logoUrl: 'https://assets.harmonic.ai/company_23cd568e1441dc25372235519774083f8f3f3b62e98679f87ca0a90470b9a510.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:776086',
                    name: 'Network Ventures',
                    id: 776086,
                    logoUrl: 'https://assets.harmonic.ai/company_9aacb96b3340f58320d0f5e9ca3c24a7a26355b7c9efc41e3dd031de4a95e2d4.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:760084',
                    name: 'Slojo Investments LLC',
                    id: 760084,
                    logoUrl: 'https://assets.harmonic.ai/company_a45243586058ef572f877089a3c1e7ad64a3c2cecb3165a56e097aa7a7a1ec12.png'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:8330',
                    name: 'Fabrice Grinda',
                    id: 8330,
                    logoUrl: 'https://assets.harmonic.ai/person_6d1a1e482ee8e89826600f85acf97591c855e32e07096e013ed5e81f4805849c.png'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:8359',
                    name: 'Daniel Curran',
                    id: 8359,
                    logoUrl: 'https://assets.harmonic.ai/person_425ab9e9e0aa2213b4c3e192fa110a9be0a55c21956a474204ea526d294d8bac.png'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:8879',
                    name: 'Adeyemi Ajao',
                    id: 8879,
                    logoUrl: 'https://assets.harmonic.ai/person_66f7336802f0ce5836eeaf2ce8eae334459e179f6772ab66d4ff9093bcce091a.png'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:11593',
                    name: 'Ignacio V.',
                    id: 11593,
                    logoUrl: 'https://assets.harmonic.ai/person_b69c30792e55c3ef0df3feda96412196c296f23da3a95b1fc1c31db53a67befe.png'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:8870',
                    name: 'Kevin Moore',
                    id: 8870,
                    logoUrl: 'https://assets.harmonic.ai/person_feeb4bc4b817b2f6d5464ac7f6210faab1b3fc18661828c42b158176fff00e4a.png'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:13232',
                    name: "Steve O'Brien",
                    id: 13232,
                    logoUrl: 'https://assets.harmonic.ai/person_ce22191277eef4735bf5dce2d0bcc6b7c526cb36f3795c20f2aa8c29cb16d236.png'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:10712',
                    name: 'Dan Bragiel',
                    id: 10712,
                    logoUrl: 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:8365',
                    name: 'Brendan Wallace',
                    id: 8365,
                    logoUrl: 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:10959',
                    name: 'Erik Peterson',
                    id: 10959,
                    logoUrl: 'https://assets.harmonic.ai/person_b78054b9aebab2fd0249e89ffdc3c37e197131400f18424e02f3b7293586b12a.png'
                },
                {
                    __typename: 'Person',
                    personUrn: 'urn:harmonic:person:157461729',
                    name: 'Russell Long',
                    id: 157461729,
                    logoUrl: 'https://static-exp1.licdn.com/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
                }
            ],
            fundingRounds: [
                {
                    announcementDate: '2021-07-01T00:00:00.000Z',
                    fundingAmount: '200000000',
                    fundingCurrency: 'USD',
                    fundingRoundType: 'SERIES_E',
                    postMoneyValuation: 1000000000000,
                    sourceUrl: 'https://www.finsmes.com/2021/07/shipbob-raises-200m-in-series-e-funding.html',
                    additionalSources: [],
                    investors: [
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'SoftBank',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Angels',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:613046',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 613046,
                                name: 'LetsVenture',
                                companyUrn: 'urn:harmonic:company:613046',
                                __typename: 'Company'
                            },
                            investorName: 'LetsVenture',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:3295467',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 3295467,
                                name: 'SoftBank Group Corp.',
                                companyUrn: 'urn:harmonic:company:3295467',
                                __typename: 'Company'
                            },
                            investorName: 'SoftBank Group Corp.',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1676404',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1676404,
                                name: 'SoftBank Investment Advisers',
                                companyUrn: 'urn:harmonic:company:1676404',
                                __typename: 'Company'
                            },
                            investorName: 'SoftBank Investment Advisers',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:4878744',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 4878744,
                                name: 'Pine Grove Holdings, LLC',
                                companyUrn: 'urn:harmonic:company:4878744',
                                __typename: 'Company'
                            },
                            investorName: 'Pine Grove Holdings, LLC',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1455926',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1455926,
                                name: 'Bain Capital Ventures',
                                companyUrn: 'urn:harmonic:company:1455926',
                                __typename: 'Company'
                            },
                            investorName: 'Bain Capital Ventures',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:2163604',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 2163604,
                                name: 'Silicon Valley Bank',
                                companyUrn: 'urn:harmonic:company:2163604',
                                __typename: 'Company'
                            },
                            investorName: 'Silicon Valley Bank',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'SoftBank',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'SoftBank',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:703199',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 703199,
                                name: '50 South Capital',
                                companyUrn: 'urn:harmonic:company:703199',
                                __typename: 'Company'
                            },
                            investorName: '50 South Capital',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:732155',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 732155,
                                name: 'Menlo Ventures',
                                companyUrn: 'urn:harmonic:company:732155',
                                __typename: 'Company'
                            },
                            investorName: 'Menlo Ventures',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:630577',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 630577,
                                name: 'Hyde Park Venture Partners',
                                companyUrn: 'urn:harmonic:company:630577',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Venture Partners',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1455926',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1455926,
                                name: 'Bain Capital Ventures',
                                companyUrn: 'urn:harmonic:company:1455926',
                                __typename: 'Company'
                            },
                            investorName: 'Bain Capital Ventures',
                            isLead: true,
                            __typename: 'FundingRoundInvestor'
                        }
                    ],
                    __typename: 'FundingRound'
                },
                {
                    announcementDate: '2020-09-28T00:00:00.000Z',
                    fundingAmount: '68000000',
                    fundingCurrency: 'USD',
                    fundingRoundType: 'SERIES_D',
                    postMoneyValuation: 500000000,
                    sourceUrl: 'https://www.finsmes.com/2020/09/shipbob-raises-68m-in-series-d-funding.html',
                    additionalSources: [],
                    investors: [
                        {
                            entityUrn: 'urn:harmonic:company:1455926',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1455926,
                                name: 'Bain Capital Ventures',
                                companyUrn: 'urn:harmonic:company:1455926',
                                __typename: 'Company'
                            },
                            investorName: 'Bain Capital Ventures',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:613046',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 613046,
                                name: 'LetsVenture',
                                companyUrn: 'urn:harmonic:company:613046',
                                __typename: 'Company'
                            },
                            investorName: 'LetsVenture',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'SoftBank Vision Fund 2',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Angels',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:630577',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 630577,
                                name: 'Hyde Park Venture Partners',
                                companyUrn: 'urn:harmonic:company:630577',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Venture Partners',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:732155',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 732155,
                                name: 'Menlo Ventures',
                                companyUrn: 'urn:harmonic:company:732155',
                                __typename: 'Company'
                            },
                            investorName: 'Menlo Ventures',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'SoftBank Vision Fund 2',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'SoftBank Vision Fund 2',
                            isLead: true,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1729703',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1729703,
                                name: 'Y Combinator',
                                companyUrn: 'urn:harmonic:company:1729703',
                                __typename: 'Company'
                            },
                            investorName: 'Y Combinator',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1676404',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1676404,
                                name: 'SoftBank Investment Advisers',
                                companyUrn: 'urn:harmonic:company:1676404',
                                __typename: 'Company'
                            },
                            investorName: 'SoftBank Investment Advisers',
                            isLead: true,
                            __typename: 'FundingRoundInvestor'
                        }
                    ],
                    __typename: 'FundingRound'
                },
                {
                    announcementDate: '2018-09-07T00:00:00.000Z',
                    fundingAmount: '40000000',
                    fundingCurrency: 'USD',
                    fundingRoundType: 'SERIES_C',
                    postMoneyValuation: null,
                    sourceUrl: 'https://www.finsmes.com/2018/09/shipbob-raises-40m-funding.html',
                    additionalSources: [],
                    investors: [
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'Hyde Park Angels',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Angels',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:732155',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 732155,
                                name: 'Menlo Ventures',
                                companyUrn: 'urn:harmonic:company:732155',
                                __typename: 'Company'
                            },
                            investorName: 'Menlo Ventures',
                            isLead: true,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1729703',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1729703,
                                name: 'Y Combinator',
                                companyUrn: 'urn:harmonic:company:1729703',
                                __typename: 'Company'
                            },
                            investorName: 'Y Combinator',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1455926',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1455926,
                                name: 'Bain Capital Ventures',
                                companyUrn: 'urn:harmonic:company:1455926',
                                __typename: 'Company'
                            },
                            investorName: 'Bain Capital Ventures',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:630577',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 630577,
                                name: 'Hyde Park Venture Partners',
                                companyUrn: 'urn:harmonic:company:630577',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Venture Partners',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:613046',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 613046,
                                name: 'LetsVenture',
                                companyUrn: 'urn:harmonic:company:613046',
                                __typename: 'Company'
                            },
                            investorName: 'LetsVenture',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        }
                    ],
                    __typename: 'FundingRound'
                },
                {
                    announcementDate: '2017-06-13T00:00:00.000Z',
                    fundingAmount: '17500000',
                    fundingCurrency: 'USD',
                    fundingRoundType: 'SERIES_B',
                    postMoneyValuation: null,
                    sourceUrl: 'https://www.finsmes.com/2017/06/shipbob-raises-17-5m-in-series-b-funding.html',
                    additionalSources: [],
                    investors: [
                        {
                            entityUrn: 'urn:harmonic:person:8359',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 8359,
                                name: 'Daniel Curran',
                                personUrn: 'urn:harmonic:person:8359',
                                __typename: 'Person'
                            },
                            investorName: 'Daniel Curran',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:712850',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 712850,
                                name: 'FJ Labs',
                                companyUrn: 'urn:harmonic:company:712850',
                                __typename: 'Company'
                            },
                            investorName: 'FJ Labs',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:613046',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 613046,
                                name: 'LetsVenture',
                                companyUrn: 'urn:harmonic:company:613046',
                                __typename: 'Company'
                            },
                            investorName: 'LetsVenture',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'FJ Labs LLC',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Angels',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1455926',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1455926,
                                name: 'Bain Capital Ventures',
                                companyUrn: 'urn:harmonic:company:1455926',
                                __typename: 'Company'
                            },
                            investorName: 'Bain Capital Ventures',
                            isLead: true,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'FJ Labs LLC',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'FJ Labs LLC',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:630577',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 630577,
                                name: 'Hyde Park Venture Partners',
                                companyUrn: 'urn:harmonic:company:630577',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Venture Partners',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:person:8330',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 8330,
                                name: 'Fabrice Grinda',
                                personUrn: 'urn:harmonic:person:8330',
                                __typename: 'Person'
                            },
                            investorName: 'Fabrice Grinda',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:person:10959',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 10959,
                                name: 'Erik Peterson',
                                personUrn: 'urn:harmonic:person:10959',
                                __typename: 'Person'
                            },
                            investorName: 'Erik Peterson',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:635393',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 635393,
                                name: 'FundersClub',
                                companyUrn: 'urn:harmonic:company:635393',
                                __typename: 'Company'
                            },
                            investorName: 'FundersClub',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:716217',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 716217,
                                name: 'Bragiel Brothers',
                                companyUrn: 'urn:harmonic:company:716217',
                                __typename: 'Company'
                            },
                            investorName: 'Bragiel Brothers',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        }
                    ],
                    __typename: 'FundingRound'
                },
                {
                    announcementDate: '2016-12-31T00:00:00.000Z',
                    fundingAmount: '469000',
                    fundingCurrency: 'USD',
                    fundingRoundType: 'DEBT_GENERAL',
                    sourceUrl: null,
                    additionalSources: [],
                    postMoneyValuation: null,
                    investors: [],
                    __typename: 'FundingRound'
                },
                {
                    announcementDate: '2016-06-17T00:00:00.000Z',
                    fundingAmount: '4000000',
                    fundingCurrency: 'USD',
                    fundingRoundType: 'SERIES_A',
                    postMoneyValuation: null,
                    sourceUrl: 'https://www.finsmes.com/2016/06/e-commerce-logistics-shipping-software-shipbob-closes-4m-series-a-funding.html',
                    additionalSources: [],
                    investors: [
                        {
                            entityUrn: 'urn:harmonic:company:635393',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 635393,
                                name: 'FundersClub',
                                companyUrn: 'urn:harmonic:company:635393',
                                __typename: 'Company'
                            },
                            investorName: 'FundersClub',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:710731',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 710731,
                                name: 'Bluestein Ventures',
                                companyUrn: 'urn:harmonic:company:710731',
                                __typename: 'Company'
                            },
                            investorName: 'Bluestein Ventures',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:person:15068',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 15068,
                                name: 'Russell Long',
                                personUrn: 'urn:harmonic:person:15068',
                                __typename: 'Person'
                            },
                            investorName: 'Russell Long',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:627547',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 627547,
                                name: 'Reynolds and Company Venture Partners, LLC',
                                companyUrn: 'urn:harmonic:company:627547',
                                __typename: 'Company'
                            },
                            investorName: 'Reynolds and Company Venture Partners, LLC',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:731330',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 731330,
                                name: 'Service Provider Capital',
                                companyUrn: 'urn:harmonic:company:731330',
                                __typename: 'Company'
                            },
                            investorName: 'Service Provider Capital',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1450143',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1450143,
                                name: 'Startcaps Ventures',
                                companyUrn: 'urn:harmonic:company:1450143',
                                __typename: 'Company'
                            },
                            investorName: 'Startcaps Ventures',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:613046',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 613046,
                                name: 'LetsVenture',
                                companyUrn: 'urn:harmonic:company:613046',
                                __typename: 'Company'
                            },
                            investorName: 'LetsVenture',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:746838',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 746838,
                                name: 'Recruit Strategic Partners',
                                companyUrn: 'urn:harmonic:company:746838',
                                __typename: 'Company'
                            },
                            investorName: 'Recruit Strategic Partners',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:712850',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 712850,
                                name: 'FJ Labs',
                                companyUrn: 'urn:harmonic:company:712850',
                                __typename: 'Company'
                            },
                            investorName: 'FJ Labs',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1729703',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1729703,
                                name: 'Y Combinator',
                                companyUrn: 'urn:harmonic:company:1729703',
                                __typename: 'Company'
                            },
                            investorName: 'Y Combinator',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:12929774',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 12929774,
                                name: 'NFQ',
                                companyUrn: 'urn:harmonic:company:12929774',
                                __typename: 'Company'
                            },
                            investorName: 'NFQ',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:776086',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 776086,
                                name: 'Network Ventures',
                                companyUrn: 'urn:harmonic:company:776086',
                                __typename: 'Company'
                            },
                            investorName: 'Network Ventures',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'Bluestein and Associates',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'SV Angel',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:638220',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 638220,
                                name: 'SVA',
                                companyUrn: 'urn:harmonic:company:638220',
                                __typename: 'Company'
                            },
                            investorName: 'SVA',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'Bluestein and Associates',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'NFQ Capital',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:person:10959',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 10959,
                                name: 'Erik Peterson',
                                personUrn: 'urn:harmonic:person:10959',
                                __typename: 'Person'
                            },
                            investorName: 'Erik Peterson',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'Bluestein and Associates',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Angels',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:630577',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 630577,
                                name: 'Hyde Park Venture Partners',
                                companyUrn: 'urn:harmonic:company:630577',
                                __typename: 'Company'
                            },
                            investorName: 'Hyde Park Venture Partners',
                            isLead: true,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'Bluestein and Associates',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'Bluestein and Associates',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:person:13232',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 13232,
                                name: "Steve O'Brien",
                                personUrn: 'urn:harmonic:person:13232',
                                __typename: 'Person'
                            },
                            investorName: "Steve O'Brien",
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        }
                    ],
                    __typename: 'FundingRound'
                },
                {
                    announcementDate: '2015-04-29T00:00:00.000Z',
                    fundingAmount: '1000000',
                    fundingCurrency: 'USD',
                    fundingRoundType: 'SEED',
                    postMoneyValuation: null,
                    sourceUrl: 'https://techcrunch.com/2015/04/29/yc-backed-shipbob-raises-1m-to-help-small-businesses-deliver-orders-on-time/',
                    additionalSources: [],
                    investors: [
                        {
                            entityUrn: 'urn:harmonic:company:638220',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 638220,
                                name: 'SVA',
                                companyUrn: 'urn:harmonic:company:638220',
                                __typename: 'Company'
                            },
                            investorName: 'SVA',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1489950',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1489950,
                                name: 'Augusta Investments LLC',
                                companyUrn: 'urn:harmonic:company:1489950',
                                __typename: 'Company'
                            },
                            investorName: 'Augusta Investments LLC',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:635393',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 635393,
                                name: 'FundersClub',
                                companyUrn: 'urn:harmonic:company:635393',
                                __typename: 'Company'
                            },
                            investorName: 'FundersClub',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:person:8365',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 8365,
                                name: 'Brendan Wallace',
                                personUrn: 'urn:harmonic:person:8365',
                                __typename: 'Person'
                            },
                            investorName: 'Brendan Wallace',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:person:8359',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 8359,
                                name: 'Daniel Curran',
                                personUrn: 'urn:harmonic:person:8359',
                                __typename: 'Person'
                            },
                            investorName: 'Daniel Curran',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:2137562',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 2137562,
                                name: 'Wefunder',
                                companyUrn: 'urn:harmonic:company:2137562',
                                __typename: 'Company'
                            },
                            investorName: 'WeFunder',
                            isLead: true,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:person:8879',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 8879,
                                name: 'Adeyemi Ajao',
                                personUrn: 'urn:harmonic:person:8879',
                                __typename: 'Person'
                            },
                            investorName: 'Adeyemi Ajao',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1500425',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1500425,
                                name: 'Zillionize',
                                companyUrn: 'urn:harmonic:company:1500425',
                                __typename: 'Company'
                            },
                            investorName: 'Zillionize',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:1450143',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 1450143,
                                name: 'Startcaps Ventures',
                                companyUrn: 'urn:harmonic:company:1450143',
                                __typename: 'Company'
                            },
                            investorName: 'Startcaps Ventures',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:person:8870',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 8870,
                                name: 'Kevin Moore',
                                personUrn: 'urn:harmonic:person:8870',
                                __typename: 'Person'
                            },
                            investorName: 'Kevin Moore',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:635393',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 635393,
                                name: 'FundersClub',
                                companyUrn: 'urn:harmonic:company:635393',
                                __typename: 'Company'
                            },
                            investorName: 'FundersClub',
                            isLead: true,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:2137562',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 2137562,
                                name: 'Wefunder',
                                companyUrn: 'urn:harmonic:company:2137562',
                                __typename: 'Company'
                            },
                            investorName: 'Wefunder',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:744615',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: 744615,
                                name: 'Otter Rock Capital',
                                companyUrn: 'urn:harmonic:company:744615',
                                __typename: 'Company'
                            },
                            investorName: 'Otter Rock Capital',
                            isLead: false,
                            __typename: 'FundingRoundInvestor'
                        },
                        {
                            entityUrn: 'urn:harmonic:company:-1',
                            investorUrn: 'urn:harmonic:investor:1',
                            investor: {
                                id: -1,
                                name: 'SV Angels',
                                companyUrn: 'urn:harmonic:company:-1',
                                __typename: 'Company'
                            },
                            investorName: 'SV Angels',
                            isLead: true,
                            __typename: 'FundingRoundInvestor'
                        }
                    ],
                    __typename: 'FundingRound'
                },
                {
                    announcementDate: '2014-08-19T00:00:00.000Z',
                    fundingAmount: '120000',
                    fundingCurrency: 'USD',
                    fundingRoundType: 'ACCELERATOR_INCUBATOR',
                    postMoneyValuation: null,
                    sourceUrl: null,
                    additionalSources: [],
                    investors: [],
                    __typename: 'FundingRound'
                }
            ],
            __typename: 'Funding'
        },
        __typename: 'Company'
    }
};
const getCompanyFinancingV2NoRbr = {
    getCompanyById: {
        id: 1,
        name: 'Harmonic',
        fundingPerEmployee: null,
        funding: {
            fundingTotal: '37170000',
            numFundingRounds: 2,
            investors: [
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:638220',
                    name: 'SVA',
                    id: 638220,
                    logoUrl: 'https://assets.harmonic.ai/company_9a64b50a483b3d4b8f85fbb1b24a2de5f1d811b29694415e43996ded3bca7f75.png'
                },
                {
                    __typename: 'Company',
                    companyUrn: 'urn:harmonic:company:1455926',
                    name: 'Bain Capital Ventures',
                    id: 1455926,
                    logoUrl: 'https://assets.harmonic.ai/company_f69019b786f8309911cad0191513907d8bd28fa7e86dd2ca3c6b15929fa6aebb.png'
                }
            ],
            fundingRounds: [],
            __typename: 'Funding'
        },
        __typename: 'Company'
    }
};
const getCompanyFinancingNoInvestors = {
    getCompanyById: {
        id: 1,
        name: 'Harmonic',
        fundingPerEmployee: 0,
        funding: {
            fundingTotal: '37170000',
            numFundingRounds: 2,
            investors: [],
            fundingRounds: [],
            __typename: 'Funding'
        },
        __typename: 'Company'
    }
};
const getCompanyFinancingWithUndisclosedValue = {
    getCompanyById: {
        id: 1,
        name: 'Harmonic',
        fundingPerEmployee: 0,
        funding: {
            fundingTotal: '0',
            numFundingRounds: 1,
            investors: [],
            fundingRounds: [
                {
                    announcementDate: '2016-06-17T00:00:00.000Z',
                    fundingAmount: '0',
                    fundingCurrency: 'USD',
                    fundingRoundType: 'SERIES_A',
                    postMoneyValuation: null,
                    sourceUrl: 'https://www.finsmes.com/2016/06/e-commerce-logistics-shipping-software-shipbob-closes-4m-series-a-funding.html',
                    additionalSources: [],
                    investors: [],
                    __typename: 'FundingRound'
                }
            ],
            __typename: 'Funding'
        },
        __typename: 'Company'
    }
};
export { getCompanyFinancingNoInvestors, getCompanyFinancingV2, getCompanyFinancingV2NoRbr, getCompanyFinancingWithUndisclosedValue };
