import { CompanyStage } from '__generated__/graphql';
const GET_COMPANY_PROFILE_HEADER_MOCKED_RESPONSE = {
    getCompanyById: {
        id: 1,
        name: 'Harmonic',
        stage: CompanyStage.SERIES_A,
        headcount: 50,
        funding: {
            lastFundingType: 'SERIES_A',
            lastFundingAt: '2024-01-01T00:00:00Z',
            fundingStage: 'SERIES_A',
            fundingTotal: 10000000,
            __typename: 'Funding'
        },
        logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
        description: 'Harmonic is on mission to make the startup universe discoverable.\n\nToday, harmonic.ai is used by teams like Brex, Greylock, Index, a16z, MongoDB, Notion, and Bloomberg to connect startups with the capital and resources that they need to drive growth. Our team is backed by top-tier investors like Craft, Sozo, Floodgate, SOMA, Teamworthy, AVG and many others.\n\nOpen roles: https://harmonic.ai/careers',
        tagsV2: [
            {
                displayValue: 'Business (B2B)',
                type: 'CUSTOMER_TYPE',
                __typename: 'Tag'
            },
            {
                type: 'PRODUCT_TYPE',
                displayValue: 'Service',
                __typename: 'Tag'
            },
            {
                type: 'TECHNOLOGY_TYPE',
                displayValue: 'Software',
                __typename: 'Tag'
            },
            {
                type: 'TECHNOLOGY_TYPE',
                displayValue: 'Data Analytics',
                __typename: 'Tag'
            },
            {
                type: 'MARKET_VERTICAL',
                displayValue: 'Business services',
                __typename: 'Tag'
            }
        ],
        typed_tags_v2: [
            {
                entityUrn: 'urn:harmonic:tag:95b56831-4f72-4f15-b55f-9d53b2dcb07c',
                tag_value: 'Business (B2B)',
                dateAdded: '2023-04-04T16:58:41.000Z',
                tag_type: 'CUSTOMER_TYPE',
                __typename: 'Tag'
            },
            {
                entityUrn: 'urn:harmonic:tag:abc12345-6d78-9e01-2345-6789abcdef01',
                tag_value: 'Service',
                dateAdded: '2023-04-05T10:15:30.000Z',
                tag_type: 'PRODUCT_TYPE',
                __typename: 'Tag'
            },
            {
                entityUrn: 'urn:harmonic:tag:fedcba98-7654-3210-9abc-def123456789',
                tag_value: 'Software',
                dateAdded: '2023-04-06T12:00:00.000Z',
                tag_type: 'TECHNOLOGY_TYPE',
                __typename: 'Tag'
            },
            {
                entityUrn: 'urn:harmonic:tag:1234abcd-5678-ef90-1234-56789abcdef0',
                tag_value: 'Data Analytics',
                dateAdded: '2023-04-07T14:45:00.000Z',
                tag_type: 'TECHNOLOGY_TYPE',
                __typename: 'Tag'
            },
            {
                entityUrn: 'urn:harmonic:tag:abcdef12-3456-7890-abcd-ef1234567890',
                tag_value: 'Business services',
                dateAdded: '2023-04-08T09:30:00.000Z',
                tag_type: 'MARKET_VERTICAL',
                __typename: 'Tag'
            }
        ],
        location: {
            state: 'California',
            city: 'San Francisco',
            country: 'United States',
            __typename: 'LocationMetadata'
        },
        website: {
            url: 'https://harmonic.ai',
            domain: 'harmonic.ai',
            __typename: 'Website'
        },
        foundingDate: {
            date: '2020-01-01T00:00:00Z',
            __typename: 'FoundingDate'
        },
        socials: {
            linkedin: {
                status: null,
                url: 'https://linkedin.com/company/harmonicai',
                followerCount: 0,
                __typename: 'SocialMetadata'
            },
            crunchbase: {
                status: null,
                url: 'https://www.crunchbase.com/organization/harmonic-ai',
                followerCount: 0,
                __typename: 'SocialMetadata'
            },
            pitchbook: {
                status: null,
                url: 'https://pitchbook.com/profiles/company/453414-79',
                followerCount: 0,
                __typename: 'SocialMetadata'
            },
            angellist: null,
            twitter: null,
            facebook: null,
            indeed: null,
            youtube: null,
            monster: null,
            stackoverflow: null,
            instagram: {
                status: null,
                url: 'https://instagram.com/samrat-raja',
                followerCount: 0,
                __typename: 'SocialMetadata'
            },
            __typename: 'Socials'
        },
        relevanceScore: 25,
        investorUrn: null,
        __typename: 'Company'
    }
};
export const GET_COMPANY_PROFILE_HEADER_MOCKED_RESPONSE_WITHOUT_SOCIALS = {
    getCompanyById: {
        id: 1,
        name: 'Harmonic',
        description: 'Company',
        stage: null,
        headcount: null,
        funding: {
            lastFundingType: null,
            lastFundingAt: null,
            fundingStage: null,
            fundingTotal: null,
            __typename: 'Funding'
        },
        tagsV2: [],
        typed_tags_v2: [],
        logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
        location: {
            state: 'California',
            city: 'San Francisco',
            country: 'United States',
            __typename: 'LocationMetadata'
        },
        website: {
            url: 'https://harmonic.ai',
            domain: 'harmonic.ai',
            __typename: 'Website'
        },
        foundingDate: {
            date: '2020-01-01T00:00:00Z',
            __typename: 'FoundingDate'
        },
        socials: {
            linkedin: {
                status: null,
                followerCount: null,
                url: null,
                __typename: 'SocialMetadata'
            },
            crunchbase: {
                status: null,
                followerCount: null,
                url: null,
                __typename: 'SocialMetadata'
            },
            pitchbook: {
                status: null,
                followerCount: null,
                url: 'https://pitchbook.com/profiles/company/453414-79',
                __typename: 'SocialMetadata'
            },
            angellist: null,
            twitter: null,
            facebook: null,
            indeed: null,
            youtube: null,
            monster: null,
            stackoverflow: null,
            instagram: null,
            __typename: 'Socials'
        },
        relevanceScore: 25,
        investorUrn: null,
        __typename: 'Company'
    }
};
export const GET_COMPANY_PROFILE_HEADER_MOCKED_RESPONSE_FOR_STEALTH = {
    getCompanyById: {
        id: 1,
        name: 'Stealth Company(Kevin Luintel)',
        description: 'Company',
        stage: null,
        headcount: null,
        funding: {
            lastFundingType: null,
            lastFundingAt: null,
            fundingStage: null,
            fundingTotal: null,
            __typename: 'Funding'
        },
        tagsV2: [],
        typed_tags_v2: [],
        logoUrl: 'https://assets.harmonic.ai/company_6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b.png',
        location: {
            state: 'California',
            city: 'San Francisco',
            country: 'United States',
            __typename: 'LocationMetadata'
        },
        website: {
            url: 'https://harmonic.ai',
            domain: 'harmonic.ai',
            __typename: 'Website'
        },
        foundingDate: {
            date: '2020-01-01T00:00:00Z',
            __typename: 'FoundingDate'
        },
        socials: {
            linkedin: {
                status: null,
                followerCount: null,
                url: null,
                __typename: 'SocialMetadata'
            },
            crunchbase: {
                status: null,
                followerCount: null,
                url: null,
                __typename: 'SocialMetadata'
            },
            pitchbook: {
                status: null,
                followerCount: null,
                url: 'https://pitchbook.com/profiles/company/453414-79',
                __typename: 'SocialMetadata'
            },
            angellist: null,
            twitter: null,
            facebook: null,
            indeed: null,
            youtube: null,
            monster: null,
            instagram: null,
            stackoverflow: null,
            __typename: 'Socials'
        },
        relevanceScore: 25,
        investorUrn: null,
        __typename: 'Company'
    }
};
export default GET_COMPANY_PROFILE_HEADER_MOCKED_RESPONSE;
