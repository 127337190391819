export const GET_USER_PEOPLE_IMPORT_BY_ID_OR_URN_MOCKED_RESPONSE = {
    getUserPeopleImportByIdOrUrn: {
        id: '817aedb0-50e6-4a0b-b433-5bc43d9f270e',
        entityUrn: 'urn:harmonic:user_people_import:817aedb0-50e6-4a0b-b433-5bc43d9f270e',
        fileName: 'test.csv',
        flatfileBatchId: '817aedb0-50e6-4a0b-b433-5bc43d9f270e',
        peopleListId: '817aedb0-50e6-4a0b-b433-5bc43d9f270e',
        userId: '1',
        userUrn: 'urn:harmonic:user:1',
        totalImportsCount: 200,
        pendingImportsCount: 100,
        foundImportsCount: 50,
        failedImportsCount: 49,
        createdAt: '2021-01-01T00:00:00.000Z',
        __typename: 'UserPeopleImport',
        customerId: null,
        customerUrn: null
    }
};
export const GET_USER_PEOPLE_IMPORT_SUCCESSES_MOCKED_RESPONSE = {
    getUserPeopleImportByIdOrUrn: {
        __typename: 'UserPeopleImport',
        foundImports: [
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                invalidUrn: null,
                errorCode: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                }
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                invalidUrn: null,
                errorCode: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                }
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                invalidUrn: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                }
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                invalidUrn: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                }
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                invalidUrn: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                }
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                invalidUrn: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                }
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                invalidUrn: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                }
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                invalidUrn: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                }
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                invalidUrn: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                }
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                },
                invalidUrn: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: {
                    __typename: 'Person',
                    id: 1,
                    fullName: 'John Doe',
                    entityUrn: 'urn:harmonic:person:1'
                },
                invalidUrn: null
            }
        ]
    }
};
export const GET_USER_PEOPLE_IMPORT_PENDING_MOCKED_RESPONSE = {
    getUserPeopleImportByIdOrUrn: {
        __typename: 'UserPeopleImport',
        pendingImports: [
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: 'urn:harmonic:person:1'
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: 'urn:harmonic:person:1'
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: 'urn:harmonic:person:1'
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: 'urn:harmonic:person:1'
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: 'urn:harmonic:person:1'
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: 'urn:harmonic:person:1'
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: 'urn:harmonic:person:1'
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: 'urn:harmonic:person:1'
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: null,
                matchedPerson: null,
                invalidUrn: 'urn:harmonic:person:1'
            }
        ]
    }
};
export const GET_USER_PEOPLE_IMPORT_FAILED_MOCKED_RESPONSE = {
    getUserPeopleImportByIdOrUrn: {
        __typename: 'UserPeopleImport',
        failedImports: [
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'PROFILE_NOT_FOUND',
                invalidUrn: null,
                matchedPerson: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'PROFILE_NOT_FOUND',
                invalidUrn: null,
                matchedPerson: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'PROFILE_NOT_FOUND',
                invalidUrn: null,
                matchedPerson: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'PROFILE_NOT_FOUND',
                invalidUrn: null,
                matchedPerson: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'PROFILE_NOT_FOUND',
                invalidUrn: null,
                matchedPerson: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'INVALID_CANONICALS',
                invalidUrn: null,
                matchedPerson: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'INVALID_CANONICALS',
                invalidUrn: null,
                matchedPerson: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'INVALID_CANONICALS',
                invalidUrn: null,
                matchedPerson: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'INVALID_CANONICALS',
                invalidUrn: null,
                matchedPerson: null
            },
            {
                __typename: 'UserImportedPerson',
                id: '1',
                canonicals: {
                    __typename: 'PersonCanonical',
                    linkedinUrl: 'https://www.linkedin.com/in/1'
                },
                errorCode: 'SOMETHING_ELSE',
                invalidUrn: null,
                matchedPerson: null
            }
        ]
    }
};
